import Vue from 'vue'
import vmodal from 'vue-js-modal'

Vue.use(vmodal, {
  componentName: 'modal',
  dynamic: true,
  dynamicDefaults: {
    height: 'auto',
    scrollable: true
  }
})
