export default `/*
@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,700');

html {
  font-family: Nunito, sans-serif;
}

.lp {
  @apply .bg-gray-700;
}

.lp #form > section {
  border-radius: 0;
  box-shadow: none;
}

.button {
  border-radius: 30px;
}
*/`
