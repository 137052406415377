import Vue from 'vue'

Vue.mixin({
  methods: {
    setValidationErrors (error, prefix = '') {
      if (!error.graphQLErrors || !error.graphQLErrors[0]) {
        return
      }

      const { extensions } = error.graphQLErrors[0]

      if (extensions && extensions.code === 'BAD_USER_INPUT') {
        extensions.details.forEach(({ path, message }) => {
          const refName = `${prefix ? `${prefix}.` : ''}${path}`
          const ref = this.$refs[refName]
          if (ref) {
            ref.setErrors([message])
          }
        })
      }
    },

    formatDateTime (dateString) {
      if (!dateString) {
        return ''
      }

      return new Intl.DateTimeFormat('de', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }).format(new Date(dateString))
    },

    formatPrice (price, currency) {
      const displayLang = currency === 'EUR' ? 'de-DE' : 'de-CH'

      return new Intl.NumberFormat(displayLang, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(price)
    },

    formatDate (dateString) {
      if (!dateString) {
        return ''
      }

      return new Intl.DateTimeFormat('de', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }).format(new Date(dateString))
    }
  }
})
