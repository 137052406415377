<template>
  <div>
    <div class="flex items-center justify-between px-10 py-8 bg-gray-100 border-b rounded-t-lg">
      <h1 class="text-xl font-semibold tracking-wider text-gray-800 uppercase">
        Company Tags
      </h1>

      <div class="flex justify-center flex-shrink ml-16 font-semibold text-gray-600 uppercase btn-inline-small">
        <button
          class="flex items-center btn-inline-small"
          @click="showAddTagModal"
        >
          <fa
            :icon="faPlus"
            class="mr-1"
          />
          Neuen Tag erstellen
        </button>
      </div>
    </div>

    <!-- existing tags -->
    <div class="flex flex-wrap justify-center w-full px-10 py-8 mx-auto">
      <tag
        v-for="tag in tags"
        :key="tag.id"
        :tag="tag"
        :can-edit="true"
        :on-edit="shoUpdateTagModal"
        :on-delete="showDeleteTagModal"
        class="w-full lg:w-1/3 xl:w-1/4"
      />
    </div>
  </div>
</template>

<script>
import LIST_TAGS from '@/graphql/ListTags.gql'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import TagModal from '../components/modals/tag'
import Tag from '@/components/tag'

export default {
  components: {
    Tag
  },
  apollo: {
    tags: {
      query: LIST_TAGS
    }
  },
  data () {
    return {
      faPlus,
      tags: []
    }
  },
  methods: {
    showAddTagModal () {
      this.$modal.show(TagModal, { action: 'create' })
    },

    shoUpdateTagModal (tag) {
      this.$modal.show(TagModal, { initialTag: tag, action: 'update' })
    },

    showDeleteTagModal (tag) {
      this.$modal.show(TagModal, { initialTag: tag, action: 'delete' })
    }
  }
}
</script>
