<template>
  <div class="flex flex-col">
    <div class="w-full px-2 mb-6 -mx-2 form-group">
      <input-text-validated
        ref="ambassador.salutation"
        v-model="ambassador.salutation"
        input-class="w-1/2"
        label="Anrede"
        placeholder="Herr"
      />
    </div>
    <div class="flex flex-wrap mb-6 -mx-2">
      <div class="w-full px-2 form-group">
        <input-text-validated
          ref="ambassador.name"
          v-model="ambassador.name"
          input-class="w-full"
          label="Name"
          placeholder="Max"
          rules="required"
        />
      </div>
    </div>

    <div class="flex flex-col w-full mb-6">
      <input-text-validated
        ref="ambassador.role"
        v-model="ambassador.role"
        input-class="w-full"
        label="Rolle"
        placeholder="Geschäftsführer"
        rules="required"
      />
    </div>

    <div class="flex flex-col w-full mb-6">
      <input-text-validated
        ref="ambassador.email"
        v-model="ambassador.email"
        input-class="w-full"
        label="E-Mail"
        placeholder="max.makler@max-makler.immobilien"
        rules="required|email"
      />
    </div>

    <div class="flex flex-wrap mb-6 -mx-2">
      <div class="w-1/2 px-2 form-group">
        <input-text-validated
          ref="ambassador.phone"
          v-model="ambassador.phone"
          input-class="w-full"
          label="Telefon"
          placeholder="+4917531123456"
          rules="required|phone"
        />
      </div>

      <div class="w-1/2 px-2 form-group">
        <input-text-validated
          ref="ambassador.mobile"
          v-model="ambassador.mobile"
          input-class="w-full"
          label="Mobil"
          placeholder="+4917531123456"
          rules="required|phone"
        />
      </div>
    </div>

    <div class="flex flex-col w-full mb-6">
      <input-text-validated
        ref="ambassador.contactSlogan"
        v-model="ambassador.contactSlogan"
        input-class="w-full"
        label="Kontakt Slogan"
        placeholder="Immobilienexperte aus ..."
      />
    </div>

    <div class="flex flex-col w-full mb-6">
      <textarea-validated
        ref="ambassador.contactText"
        v-model="ambassador.contactText"
        input-class="w-full"
        label="Kontakt Text"
        placeholder="Sie benötigen eine professionelle Einschätzung? Bei Fragen rund um Ihre Immobilie stehen wir gerne mit Rat und Tat zur Seite."
      />
    </div>

    <div class="flex flex-col w-full mb-6">
      <textarea-validated
        ref="ambassador.signature.text"
        v-model="ambassador.signature.text"
        input-class="w-full"
        label="E-Mail Text Signatur"
        placeholder="Signatur als Text …"
      />
    </div>

    <div class="flex flex-col w-full mb-6">
      <textarea-validated
        ref="ambassador.signature.html"
        v-model="ambassador.signature.html"
        input-class="w-full"
        label="E-Mail HTML Signatur"
        placeholder="Signatur als Text …"
      />
    </div>

    <div class="flex flex-col w-full mb-6">
      <input-file-validated
        ref="ambassador.image.upload"
        v-model="ambassador.image.upload"
        label="Neues Bild"
        accept="image/jpg, image/png"
      />
    </div>
  </div>
</template>

<script>
import InputTextValidated from '@/components/form/text-validated'
import InputFileValidated from '@/components/form/file-validated'
import TextareaValidated from '@/components/form/textarea-validated'
import { faPen } from '@fortawesome/free-solid-svg-icons'

export default {
  components: {
    InputTextValidated,
    InputFileValidated,
    TextareaValidated
  },
  props: {
    ambassador: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      faPen
    }
  }
}
</script>
