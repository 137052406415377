import Vue from 'vue'
import IBAN from 'iban'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { required, numeric, max } from 'vee-validate/dist/rules'
import { imageFromFile } from '@/lib/imageHelper'

const EMAIL = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i

extend('required', {
  ...required,
  message: 'Dies ist ein Pflichtfeld'
})

extend('email', {
  validate: value => EMAIL.test(value),
  message: 'Bitte eine gültige E-Mail eintragen'
})

extend('numeric', {
  ...numeric,
  message: 'Bitte nur Zahlen eintragen'
})

extend('productAmount', {
  validate: value => value >= 1 && value <= 20,
  message: 'Bitte nur Zahlen zwischen 1 und 20 eintragen'
})

extend('percent', {
  validate: value => value >= 0 && value <= 100,
  message: 'Bitte eine Zahl zwischen 0 und 100 eingeben'
})

extend('decimal', {
  validate: value => value.match(/^[0-9]+(,[0-9]{1,2})?$/) && parseFloat(value.replace(',', '.')) < 100,
  message: 'Bitte nur Zahlen eintragen (z.B. 5 oder 4,2)'
})

extend('slug', {
  validate: value => value.match(/^[a-z0-9](-?[a-z0-9])*$/),
  message: 'Darf nur Kleinbuchstaben/Zahlen mit Bindestrich getrennt enthalten'
})

extend('bleed', {
  validate: value => value.match(/^\d,\d,\d,\d$/),
  message: 'Muss 4 Zahlen mit Komma getrennt entahlten (1,2,3,4)'
})

extend('gtm', {
  validate: value => value.match(/^GTM-[A-Z0-9]{1,8}$/),
  message: 'Muss valide GTM-Id sein. (z.B. GTM-1234ABCD)'
})

extend('phone', {
  validate: value => value.match(/^\+?\d+$/),
  message: 'Nummer muss mit + anfangen und darf nur Zahlen enthalten'
})

extend('hexcolor', {
  validate: value => value.match(/^#([0-9a-f]{3}|[0-9a-f]{6})$/i),
  message: 'HEX Code mit 3 oder 6 Zeichen z.B #CC33FF'
})

extend('protocol', {
  validate: value => value.match(/^https?:\/\//),
  message: 'Bitte eine URL mit http(s):// eintragen.'
})

extend('iban', {
  validate: value => IBAN.isValid(value),
  message: 'Bitte eine valide IBAN eintragen.'
})

extend('bic', {
  validate: value => value.match(/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/),
  message: 'Bitte eine valide BIC eintragen.'
})

extend('maxLength', {
  ...max,
  message: 'Eingabe zu lang.'
})

extend('minImageSize', {
  validate: async (value, { minWidth, minHeight }) => {
    const image = await imageFromFile(value.file)
    return image.width >= parseInt(minWidth) && image.height >= parseInt(minHeight)
  },
  params: ['minWidth', 'minHeight'],
  message: 'Das Bild muss mindestens {minWidth} Pixel breit und {minHeight} Pixel hoch sein'
})

extend('maxImageSize', {
  validate: async (value, { maxWidth, maxHeight }) => {
    const image = await imageFromFile(value.file)
    return image.width < parseInt(maxWidth) && image.height < parseInt(maxHeight)
  },
  params: ['maxWidth', 'maxHeight'],
  message: 'Das Bild darf maximal {maxWidth} Pixel breit und {maxHeight} Pixel hoch sein'
})

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
