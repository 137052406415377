<template>
  <div class="flex flex-wrap w-full">
    <div class="w-1/2 px-4 mb-3">
      <input-select-validated
        ref="data.cancellationReason"
        v-model="data.cancellationReason"
        label="Kündigungsgrund"
        :options="cancellationReasons"
        :rules="rules"
        :disabled="disabled"
      />
    </div>
    <div class="w-1/2 px-4 mb-3">
      <input-date-validated
        ref="data.cancelledAt"
        v-model="data.cancelledAt"
        label="Kündigungsdatum"
        :rules="rules"
        :disabled="disabled"
      />
    </div>
    <div class="w-full px-4 mb-3">
      <input-date-validated
        ref="data.cancelledAt"
        v-model="data.endDate"
        :label="`Abweichendes Vertragsende von ${formatDate(data.minUntilDate)}`"
        :disabled="disabled"
      />
    </div>
    <div class="w-full px-4 mb-3">
      <input-textarea-validated
        ref="data.cancellationComment"
        v-model="data.cancellationComment"
        label="Notiz"
        input-class="w-full"
        :disabled="disabled"
      />
    </div>
  </div>
</template>

<script>
import InputDateValidated from '@/components/form/date-validated'
import InputSelectValidated from '@/components/form/select-validated'
import InputTextareaValidated from '@/components/form/textarea-validated'
import { CANCELLATION_REASONS } from '@/config'

export default {
  components: {
    InputDateValidated,
    InputSelectValidated,
    InputTextareaValidated
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean
    }
  },
  computed: {
    rules () {
      return this.disabled ? '' : 'required'
    }
  },
  created () {
    this.cancellationReasons = CANCELLATION_REASONS
  }
}
</script>
