<template>
  <div>
    <div class="mb-6">
      <em>Noch nicht implementiert 🤷‍♂️</em>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    }
  }
}
</script>
