<template>
  <ValidationObserver
    ref="validationObserver"
    v-slot="{ validated, invalid }"
  >
    <div class="p-8 pt-0">
      <div
        v-if="errorMessage || (validated && invalid)"
        class="flex mt-8"
      >
        <div class="w-full">
          <alert type="error">
            {{ errorMessage || 'Bitte überprüfe die markierten Felder' }}
          </alert>
        </div>
      </div>

      <section class="flex flex-col mt-10">
        <div class="flex flex-wrap">
          <template v-if="isNew">
            <div class="w-1/2 px-2 mb-6">
              <input-select-validated
                ref="landingpageTemplate.type"
                v-model="landingpageTemplate.type"
                :options="selectableTypes"
                label="Typ"
                rules="required"
              />
            </div>

            <div class="w-1/2 px-2 mb-6">
              <input-select-validated
                ref="landingpageTemplate.countryCodes"
                v-model="landingpageTemplate.countryCodes"
                :options="selectableCountryCodes"
                label="Länder"
                rules="required"
              />
            </div>

            <div class="w-1/2 px-2 mb-6">
              <input-text-validated
                ref="landingpageTemplate.slug"
                v-model="landingpageTemplate.slug"
                input-class="w-full"
                label="Slug"
                rules="required|slug"
              />
            </div>

            <div class="w-1/2 px-2 mb-6">
              <input-text-validated
                ref="landingpageTemplate.name"
                v-model="landingpageTemplate.name"
                input-class="w-full"
                label="Name"
                rules="required"
              />
            </div>
          </template>
        </div>

        <div class="flex justify-center">
          <div class="pt-8">
            <button
              :disabled="isSubmitting"
              class="btn-primary"
              @click="submit()"
            >
              <span v-if="isSubmitting">Landingpage wird gespeichert</span>
              <span v-else>Landingpage speichern</span>
            </button>
          </div>
        </div>
      </section>
    </div>
  </ValidationObserver>
</template>

<script>
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import InputSelectValidated from '@/components/form/select-validated'
import InputTextValidated from '@/components/form/text-validated'
import Alert from '@/components/alert'

export default {
  components: {
    InputSelectValidated,
    InputTextValidated,
    Alert
  },

  props: {
    landingpageTemplate: {
      type: Object,
      required: true
    },
    onSubmit: {
      type: Function,
      required: true
    },
    errorMessage: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      faPlus,
      faTrash,
      isSubmitting: false,
      selectableTypes: [
        { name: 'Ratgeber', value: 'GUIDE' },
        { name: 'Checkliste', value: 'CHECKLIST' },
        { name: 'Quizzard', value: 'QUIZZARD' }
      ],
      selectableCountryCodes: [
        { name: 'Alle', value: 'all' },
        { name: 'Deutschland', value: 'de' },
        { name: 'Österreich', value: 'at' },
        { name: 'Schweiz', value: 'ch' },
        { name: 'Türkei', value: 'tr' }
      ]
    }
  },

  computed: {
    isNew () {
      return !this.landingpageTemplate.id
    }
  },

  methods: {
    async submit () {
      this.isSubmitting = true

      const isValid = await this.$refs.validationObserver.validate()
      if (!isValid) {
        return
      }

      try {
        await this.onSubmit()
      } catch (error) {
        this.setValidationErrors(error, 'landingpageTemplate')
      }
      this.isSubmitting = false
    }
  }
}
</script>
