<template>
  <div class="flex flex-col">
    <div class="w-full px-2 mb-6 -mx-2 form-group">
      <input-text-validated
        ref="contactPerson.salutation"
        v-model="contactPerson.salutation"
        input-class="w-1/2"
        label="Anrede"
        placeholder="Herr"
      />
    </div>
    <div class="w-full px-2 mb-6 -mx-2 form-group">
      <input-text-validated
        ref="contactPerson.companyName"
        v-model="contactPerson.companyName"
        input-class="w-full"
        label="Firma"
      />
    </div>
    <div class="flex flex-wrap mb-6 -mx-2">
      <div class="w-1/2 px-2 form-group">
        <input-text-validated
          ref="contactPerson.firstname"
          v-model="contactPerson.firstname"
          input-class="w-full"
          label="Vorname"
          placeholder="Max"
          rules="required"
        />
      </div>

      <div class="w-1/2 px-2 form-group">
        <input-text-validated
          ref="contactPerson.lastname"
          v-model="contactPerson.lastname"
          input-class="w-full"
          label="Nachname"
          placeholder="Makler"
          rules="required"
        />
      </div>
    </div>

    <div class="flex flex-col w-full mb-6">
      <input-text-validated
        ref="contactPerson.email"
        v-model="contactPerson.email"
        input-class="w-full"
        label="E-Mail"
        placeholder="max.makler@max-makler.immobilien"
        rules="required|email"
      />
    </div>

    <div class="flex flex-wrap mb-6 -mx-2">
      <div class="w-1/2 px-2 form-group">
        <input-text-validated
          ref="contactPerson.phone"
          v-model="contactPerson.phone"
          input-class="w-full"
          label="Telefon"
          placeholder="+4917531123456"
          rules="required|phone"
        />
      </div>

      <div class="w-1/2 px-2 form-group">
        <input-text-validated
          ref="contactPerson.mobile"
          v-model="contactPerson.mobile"
          input-class="w-full"
          label="Mobil"
          placeholder="+4917531123456"
          rules="required|phone"
        />
      </div>
    </div>

    <div class="flex flex-col w-full mb-6">
      <input-text-validated
        ref="contactPerson.address.street"
        v-model="contactPerson.address.street"
        input-class="w-full"
        label="Straße"
        placeholder="Hauptstraße 1"
      />
    </div>

    <div class="flex flex-wrap mb-6 -mx-2">
      <div class="w-1/2 px-2 form-group">
        <input-text-validated
          ref="contactPerson.address.zip"
          v-model="contactPerson.address.zip"
          input-class="w-full"
          label="PLZ"
          placeholder="78467"
        />
      </div>

      <div class="w-1/2 px-2 form-group">
        <input-text-validated
          ref="contactPerson.address.city"
          v-model="contactPerson.address.city"
          input-class="w-full"
          label="Stadt"
          placeholder="Konstanz"
        />
      </div>
    </div>

    <div class="w-full px-2 mb-6 -mx-2 form-group">
      <input-select-validated
        ref="contactPerson.address.country"
        v-model="contactPerson.address.country"
        :options="countriesForAddress"
        class="w-1/2"
        input-class="w-full"
        label="Land"
      />
    </div>

    <div class="flex flex-col w-full mb-6">
      <textarea-validated
        ref="contactPerson.note"
        v-model="contactPerson.note"
        input-class="w-full"
        label="Notiz"
        placeholder="Notiz"
      />
    </div>
  </div>
</template>

<script>
import { COUNTRIES_FOR_ADDRESS } from '@/config'
import InputTextValidated from '@/components/form/text-validated'
import InputSelectValidated from '@/components/form/select-validated'
import TextareaValidated from '@/components/form/textarea-validated'

export default {
  components: {
    InputTextValidated,
    InputSelectValidated,
    TextareaValidated
  },
  props: {
    contactPerson: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      countriesForAddress: COUNTRIES_FOR_ADDRESS
    }
  }
}
</script>
