<template>
  <div>
    <div class="flex justify-between p-4">
      <div class="flex">
        <div class="px-4">
          <input
            v-model="failed"
            type="checkbox"
            class="w-4 h-4 form-checkbox"
          >
          Fehlgeschlagen ({{ failedMaterials.length }})
        </div>
        <div class="px-4">
          <input
            v-model="ready"
            type="checkbox"
            class="w-4 h-4 form-checkbox"
          >
          Bereit ({{ readyMaterials.length }})
        </div>
        <div class="px-4">
          <input
            v-model="generating"
            type="checkbox"
            class="w-4 h-4 form-checkbox"
          >
          Generierung ({{ generatingMaterials.length }})
        </div>
      </div>
      <div>
        <button @click="queryMaterials">
          <fa :icon="faRedo" />
          Aktualisieren
        </button>
      </div>
    </div>
    <table class="w-full h-full border-0">
      <thead>
        <tr
          class="text-sm font-semibold tracking-wide text-left text-gray-700 uppercase bg-gray-200"
        >
          <th class="w-2/12 p-4">
            Status
          </th>
          <th class="w-5/12 p-4">
            Dateiname
          </th>
          <th class="w-5/12 p-4">
            Firma
          </th>
        </tr>
      </thead>
      <tbody class="text-sm">
        <tr
          v-for="material in filteredMaterials"
          :key="material.id"
          class="text-gray-500 transition duration-250 group even:bg-gray-100 odd:bg-white hover:text-gray-700"
        >
          <td class="hidden w-2/12 px-4 py-2 group-hover:bg-gray-200 sm:table-cell">
            {{ material.status }}
          </td>
          <td class="hidden w-5/12 px-4 py-2 uppercase group-hover:bg-gray-200 md:table-cell">
            {{ material.prettyFileName }}
          </td>
          <td class="hidden w-5/12 px-4 py-2 uppercase group-hover:bg-gray-200 md:table-cell">
            <a
              target="_blank"
              :href="`/companies/${material.companyId}#landingpages`"
            >
              {{ material.companyName }}
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { faRedo } from '@fortawesome/free-solid-svg-icons'

import GET_CAMPAIGN_MATERIALS from '@/graphql/GetCampaignMaterials.gql'

export default {
  props: {
    campaignId: { type: String, required: true }
  },
  data: function () {
    return {
      faRedo,
      failed: false,
      ready: false,
      generating: false,
      materials: []
    }
  },
  computed: {
    readyMaterials () {
      return this.materials.filter(({ status }) => status === 'READY')
    },
    failedMaterials () {
      return this.materials.filter(({ status }) => status === 'GENERATION_FAILED')
    },
    generatingMaterials () {
      return this.materials.filter(({ status }) => status === 'GENERATING')
    },
    filteredMaterials () {
      return [
        ...(this.failed ? this.failedMaterials : []),
        ...(this.ready ? this.readyMaterials : []),
        ...(this.generating ? this.generatingMaterials : [])
      ]
    }
  },
  methods: {
    async queryMaterials () {
      const { data: { materials } } = await this.$apollo.query({
        query: GET_CAMPAIGN_MATERIALS,
        variables: { campaignId: this.campaignId },
        fetchPolicy: 'network-only'
      })
      this.materials = materials
    }
  },
  apollo: {
    materials: {
      query: GET_CAMPAIGN_MATERIALS,
      variables () {
        return {
          campaignId: this.campaignId
        }
      }
    }
  }
}
</script>
