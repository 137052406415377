<template>
  <li class="flex items-center justify-between px-4 py-2 hover:bg-gray-200 group">
    <div class="flex items-center">
      <img
        :src="`/img/country-flags/${countryCode}.png`"
        class="inline-block w-5 mr-3"
      >
      {{ name }}
    </div>

    <div class="flex items-center">
      <div class="ml-8">
        <router-link
          :to="`/landingpages/${id}`"
          title="Landingpage-Template anzeigen"
          class="opacity-25 btn-icon hover:opacity-100"
        >
          <fa
            :icon="faEye"
            fixed-width
          />
        </router-link>
      </div>
    </div>
  </li>
</template>

<script>
import { faEye } from '@fortawesome/free-solid-svg-icons'

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    countryCode: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      faEye
    }
  }
}
</script>
