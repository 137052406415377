<template>
  <section class="w-full">
    <h1 class="flex items-center justify-between">
      Automatische E-Mails (Hoppermation)
      <div class="flex text-sm text-gray-600">
        <button
          class="font-semibold uppercase hover:text-gray-800"
          :loading="loading"
          :disabled="loading"
          @click="checkStatus"
        >
          Aktualisieren
        </button>
        <div class="mx-3 font-bold">
          |
        </div>
        <button
          class="font-semibold uppercase hover:text-gray-800"
          @click="showSendVerificationEmailModal(company)"
        >
          Postmark Bestätigungsemail auslösen
        </button>
      </div>
    </h1>

    <div class="flex flex-wrap w-full -mx-4">
      <div class="w-1/2 p-4">
        <data-field
          label="Status"
          :value="status"
        />
      </div>
      <div class="w-1/2 p-4">
        <data-field
          label="DKIM verifiziert"
          :value="domain?.isDKIMVerified ? 'ja' : 'nein'"
        />
        <data-field
          label="Return-Path verifiziert"
          :value="domain?.isReturnPathDomainVerified ? 'ja' : 'nein'"
        />
      </div>
    </div>

    <div
      v-if="senders?.length > 0"
      class="flex flex-wrap w-full -mx-4 px-4"
    >
      <div class="p-4 flex flex-row w-full bg-gray-100 border-t border-gray-200">
        <div class="w-1/4 font-semibold uppercase text-gray-600">
          Email
        </div>
        <div class="w-1/4 font-semibold uppercase text-gray-600">
          Name
        </div>
        <div class="w-1/4 font-semibold uppercase text-gray-600">
          Default
        </div>
        <div class="w-1/4 font-semibold uppercase text-gray-600">
          Verifiziert
        </div>
      </div>
      <div
        v-for="(sender, index) of senders"
        :key="sender.id"
        class="p-4 flex flex-row w-full bg-gray-100 border-t border-gray-200"
        :class="{ 'bg-gray-300' : index % 2 === 0, 'bg-gray-100' : index % 2 === 1}"
      >
        <div class="w-1/4 ">
          {{ sender.fromEmail }}
        </div>
        <div class="w-1/4 ">
          {{ sender.name }}
        </div>
        <div class="w-1/4 ">
          {{ sender.isDefault ? 'ja' : 'nein' }}
        </div>
        <div class="w-1/4 flex justify-between">
          <div>{{ sender.isVerified ? 'ja' : 'nein' }}</div>
          <button
            class="font-semibold uppercase hover:text-red-600 mx-6"
            @click="() => showDeleteSenderModal(sender.id, sender.fromEmail, sender.isDefault)"
          >
            Löschen
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import HOPPERMATION_DEFAULT_SENDER from '@/graphql/emailVerification/HoppermationDefaultSender.gql'
import HOPPERMATION_SENDERS from '@/graphql/emailVerification/HoppermationSenders.gql'
import CHECK_DEFAULT_SENDER_VERIFICATION from '@/graphql/emailVerification/CheckDefaultSenderVerification.gql'
import GET_DOMAIN from '@/graphql/emailVerification/GetDomain.gql'
import HOPPERMATION_ORGANIZATION from '@/graphql/emailVerification/HoppermationOrganization.gql'
import SendEmailVerificationModal from '@/components/modals/company/emailVerification'
import DeleteSenderModal from '@/components/modals/company/deleteSender'
import dataField from '../data-field.vue'
import eventBus, { eventNames } from '@/lib/eventBus'

export default {
  components: { dataField },
  props: {
    company: { type: Object, required: true }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    isSent () {
      return this.defaultSender
    },
    isVerified () {
      return this.defaultSender?.isVerified
    },
    status () {
      return {
        INACTIVE: 'inaktiv',
        ACTIVE: 'aktiv',
        PENDING: 'ausstehend'
      }[this.organization?.status]
    }
  },
  methods: {
    showSendVerificationEmailModal () {
      this.$modal.show(
        SendEmailVerificationModal,
        {
          companyId: this.company.id,
          verificationEmail: this.company.ambassador.email,
          defaultSender: this.defaultSender,
          updateQuery: this.updateQuery
        },
        { height: 'auto' }
      )
    },
    showDeleteSenderModal (senderId, email, isDefault) {
      this.$modal.show(
        DeleteSenderModal,
        { senderId, email, isDefault },
        { height: 'auto' }
      )
    },
    async checkStatus () {
      this.loading = true
      try {
        await this.$apollo.mutate({
          mutation: CHECK_DEFAULT_SENDER_VERIFICATION,
          variables: { companyId: this.company.id },
          update: this.updateQuery,
          refetchQueries: [{
            query: GET_DOMAIN,
            variables: { companyId: this.company.id }
          }]
        })
      } catch (err) {
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Fehler beim Aktualisieren.' })
      } finally {
        this.loading = false
      }
    },
    updateQuery (store, { data: { defaultSender } }) {
      const data = store.readQuery({ query: HOPPERMATION_DEFAULT_SENDER, variables: { companyId: this.company.id } })
      data.defaultSender = defaultSender
      store.writeQuery({ query: HOPPERMATION_DEFAULT_SENDER, data, variables: { companyId: this.company.id } })
    }
  },
  apollo: {
    domain: {
      query: GET_DOMAIN,
      skip () {
        return !this.company?.ambassador?.email
      },
      variables () {
        return { companyId: this.company.id }
      }
    },
    defaultSender: {
      query: HOPPERMATION_DEFAULT_SENDER,
      variables () {
        return {
          companyId: this.company.id
        }
      }
    },
    senders: {
      query: HOPPERMATION_SENDERS,
      variables () {
        return {
          companyId: this.company.id
        }
      }
    },
    organization: {
      query: HOPPERMATION_ORGANIZATION,
      variables () {
        return {
          companyId: this.company.id
        }
      }
    }
  }
}
</script>
