<template>
  <section class="w-full">
    <h1 class="flex items-center justify-between">
      Verträge
      <button
        class="btn-inline-small"
        @click="showCreateModal()"
      >
        <fa :icon="faPlus" />
        Erstellen
      </button>
    </h1>
    <div class="w-full">
      <div
        v-for="contract in sortedContracts"
        :key="contract.id"
        class="w-full p-4 my-4 bg-gray-100 rounded"
      >
        <h2 class="w-full flex justify-between text-xl uppercase font-bold border-b-4 mb-2">
          Vertrag ({{ getPricelistName(contract.priceListName) }})
          <span
            v-if="contract.isTrial"
            class="font-semibold text-gray-600 uppercase"
          >
            Testzugang
          </span>
          <div>
            <button
              class="btn-inline-small px-4"
              @click="showDeleteModal(contract.id)"
            >
              <fa :icon="faTrash" />
              Löschen
            </button>
            <button
              class="btn-inline-small px-4"
              @click="showNotesModal(contract)"
            >
              <fa :icon="faPen" />
              Notizen
            </button>
          </div>
        </h2>
        <div class="flex flex-wrap">
          <div class="flex-col w-full xl:w-1/3">
            <data-field label="Startdatum">
              {{ formatDate(contract.startDate) }}
            </data-field>
          </div>
          <div class="flex-col w-full xl:w-1/3">
            <data-field label="Enddatum">
              {{ formatDate(contract.endDate) }}
            </data-field>
          </div>
          <div class="flex-col w-full xl:w-1/3">
            <data-field label="Kündigungsdatum">
              {{ formatDate(contract.cancelledAt) }}
            </data-field>
          </div>
          <data-field
            v-if="contract.note"
            class="mt-2"
            label="Notizen"
            :ratio="1"
          >
            <textarea
              :value="contract.note"
              class="w-4/5"
              readonly
            />
          </data-field>
          <data-field
            v-if="contract.cancellation && contract.cancellation.comment"
            label="Kündigungskommentar"
            :ratio="1"
          >
            <textarea
              :value="contract.cancellation && contract.cancellation.comment"
              class="w-4/5"
              readonly
            />
          </data-field>
        </div>
        <products
          :company-id="companyId"
          :contract="contract"
          :product-templates="productTemplates"
        />
        <documents
          :documents="contract.documents"
          :contract-id="contract.id"
        />
      </div>
    </div>
  </section>
</template>

<script>
import CreateModal from '@/components/modals/contract/create'
import DeleteModal from '@/components/modals/contract/delete'
import UpdateNoteModal from '@/components/modals/contract/note'

import DataField from '@/components/data-field'
import Documents from '@/components/company/contract/documents'
import Products from '@/components/company/contract/products'
import { faTimesCircle, faPlus, faPen, faUndo, faTrash } from '@fortawesome/free-solid-svg-icons'
import { getCancellationReasonName, getPricelistName } from '@/config'

import GET_PRODUCT_TEMPLATES from '@/graphql/GetProductTemplates.gql'
import GET_COMPANY_CONTRACTS from '@/graphql/GetCompanyContracts.gql'

export default {
  components: { Documents, Products, DataField },
  props: {
    companyId: { type: String, required: true }
  },
  data () {
    return {
      faTimesCircle, faPlus, faPen, faUndo, faTrash
    }
  },
  computed: {
    sortedContracts () {
      return this.company?.contracts.slice().sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
    }
  },
  methods: {
    getPricelistName,
    getCancellationReasonName,
    showCreateModal () {
      this.$modal.show(
        CreateModal,
        {
          companyId: this.companyId,
          productTemplates: this.productTemplates
        }, {
          width: 800
        }
      )
    },
    showNotesModal (contract) {
      this.$modal.show(
        UpdateNoteModal,
        { contract: { ...contract } },
        { width: 800 }
      )
    },
    showDeleteModal (id) {
      this.$modal.show(
        DeleteModal,
        { companyId: this.companyId, id }
      )
    }
  },
  apollo: {
    productTemplates: {
      query: GET_PRODUCT_TEMPLATES
    },
    company: {
      query: GET_COMPANY_CONTRACTS,
      variables () {
        return { id: this.companyId }
      }
    }
  }
}
</script>
