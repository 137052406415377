<template>
  <crud-modal
    title="Landingpages erstellen"
    action="create"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <div
      v-for="template in landingpageTemplates"
      :key="template.id"
    >
      <label class="inline-flex items-center">
        <input
          v-model="selectedSlugs"
          type="checkbox"
          class="w-4 h-4 form-checkbox"
          :value="template.slug"
        >
        <span class="ml-2 text-base normal-case">{{ template.name }}</span>
      </label>
    </div>

    <p
      v-if="!hasLandingpageTemplates"
      class="px-3 py-3 mt-6 text-yellow-800 bg-yellow-200 bg-opacity-75 rounded-md"
    >
      Es wurden bereits alle zur Verfügung stehenden Landingpages erstellt.
    </p>

    <p class="px-3 py-3 mt-6 text-yellow-800 bg-yellow-200 bg-opacity-75 rounded-md">
      Materialien werden NICHT automatisch generiert.
    </p>

    <button
      class="my-2 btn-secondary"
      @click="deselectAll"
    >
      Alle abwählen
    </button>
  </crud-modal>
</template>

<script>
import CrudModal from '@/components/modals/crud-modal'

import GET_COMPANY from '@/graphql/GetCompany.gql'
import CREATE_LANDINGPAGES from '@/graphql/CreateLandingpages.gql'
import LANDINGPAGE_TEMPLATES_FOR_COMPANY from '@/graphql/GetLandingpageTemplatesForCompany.gql'
import MATERIALS from '@/graphql/material/materials.gql'

export default {
  components: { CrudModal },
  props: {
    companyId: {
      type: String,
      required: true
    },
    variant: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      selectedSlugs: [],
      defaultLandingpages: []
    }
  },

  computed: {
    hasLandingpageTemplates () {
      return this.landingpageTemplates && this.landingpageTemplates.length !== 0
    }
  },

  apollo: {
    landingpageTemplates: {
      query: LANDINGPAGE_TEMPLATES_FOR_COMPANY,
      variables () {
        return {
          input: {
            companyId: this.companyId
          }
        }
      },
      update ({ landingpageTemplates }) {
        this.selectedSlugs = landingpageTemplates
          .filter(({ slug }) => this.defaultLandingpages.includes(slug))
          .map(tmpl => tmpl.slug)
        return landingpageTemplates
      }
    }
  },

  async created () {
    const companyFeatures = await this.$features.getFeaturesForCompany(this.companyId, {
      apolloProvider: this.$apollo.provider
    })
    this.defaultLandingpages = companyFeatures.landingpage.isActive ? companyFeatures.landingpage?.config?.defaultLandingpages : []
  },

  methods: {
    deselectAll () {
      this.selectedSlugs = []
    },
    async submit () {
      await this.$apollo.mutate({
        mutation: CREATE_LANDINGPAGES,
        variables: {
          input: {
            companyId: this.companyId,
            slugs: this.selectedSlugs
          }
        },
        update: (store, { data: createLandingpages }) => {
          const queryParams = {
            query: GET_COMPANY,
            variables: { id: this.companyId }
          }
          const data = store.readQuery(queryParams)
          data.company.landingpages.push(...createLandingpages.landingpages)
          store.writeQuery({ ...queryParams, data })
        },
        refetchQueries: [{
          query: MATERIALS,
          variables: { companyId: this.companyId }
        }]
      })
    }
  }
}
</script>
