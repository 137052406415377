<template>
  <div class="flex flex-col w-full mb-1">
    <data-field label="Schlüssel eingetragen">
      {{ onofficeConfig.token?.length > 0 ? 'ja' : 'nein' }}
    </data-field>
    <data-field label="Schlüssel gültig">
      {{ onofficeConfig.isTokenVerified ? 'ja' : 'nein' }}
    </data-field>
    <data-field label="Gewählte Kontaktart">
      {{ statuses }}
    </data-field>
  </div>
</template>

<script>
import ONOFFICE_CONFIG from '@/graphql/OnofficeConfig.gql'
import ONOFFICE_CONTACT_TYPES from '@/graphql/OnofficeContactTypes.gql'
import DataField from '@/components/data-field'

export default {
  components: {
    DataField
  },
  props: {
    companyId: { type: String, default: '' }
  },
  data () {
    return {
      onofficeConfig: { selectedContactTypes: [] },
      onofficeContactTypes: []
    }
  },
  computed: {
    statuses () {
      const onofficeContactTypesMap = new Map([
        ...this.onofficeContactTypes.map(status => [status.id, status.name]),
        ['ALL', 'Alle Kontakte'],
        ['UNDEFINED', 'Kontakte ohne Kontaktart']
      ])
      return this.onofficeConfig.selectedContactTypes.map(id => onofficeContactTypesMap.get(id)).join(', ')
    }
  },
  apollo: {
    onofficeConfig: {
      query: ONOFFICE_CONFIG,
      variables () {
        return {
          companyId: this.companyId
        }
      }
    },
    onofficeContactTypes: {
      query: ONOFFICE_CONTACT_TYPES,
      variables () {
        return {
          companyId: this.companyId
        }
      }
    }
  }
}
</script>
