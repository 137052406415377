<template>
  <crud-modal
    title="Kündigung zurückziehen"
    action="update"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <cancel-form
      :data="{
        ...product,
        cancellationReason: product.cancellation.reason,
        cancellationComment: product.cancellation.comment,
      }"
      disabled
    />
    <div class="flex flex-wrap w-full">
      <div class="w-full px-4 mb-3">
        <input-textarea-validated
          ref="product.revokeCancellation"
          v-model="product.revokeCancellation"
          label="Notiz zum Zurückziehen der Kündigung"
          input-class="w-full h-40"
        />
      </div>
    </div>
  </crud-modal>
</template>
<script>
import CancelForm from '@/components/modals/product/cancel-form'
import CrudModal from '@/components/modals/crud-modal'
import InputTextareaValidated from '@/components/form/textarea-validated'

import REVOKE_PRODUCT_CANCELLATION from '@/graphql/RevokeProductCancellation.gql'
import GET_COMPANY_CONTRACTS from '@/graphql/GetCompanyContracts.gql'

export default {
  components: { CrudModal, InputTextareaValidated, CancelForm },
  props: {
    product: { type: Object, required: true },
    companyId: { type: String, required: true },
    contractId: { type: String, required: true }
  },
  methods: {
    async submit () {
      await this.$apollo.mutate({
        mutation: REVOKE_PRODUCT_CANCELLATION,
        variables: {
          input: {
            contractId: this.contractId,
            productId: this.product.id,
            comment: this.product.revokeCancellation
          }
        },
        refetchQueries: [{ query: GET_COMPANY_CONTRACTS, variables: { id: this.companyId } }]
      })
    }
  }
}
</script>
