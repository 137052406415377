<template>
  <div class="min-h-screen">
    <div class="flex items-center justify-between px-4 py-8 bg-gray-100 rounded-t-lg">
      <input
        v-model="search"
        type="text"
        placeholder="Suchen nach: Name, Landingpage"
        class="w-2/5"
      >

      <div class="flex flex-row mr-4">
        <button
          class="flex-shrink ml-16 font-semibold text-gray-600 uppercase"
          @click="showImportModal()"
        >
          <fa
            :icon="faUpload"
            fixed-width
          />
          Kampagne Importieren
        </button>
        <router-link
          to="/campaigns/create"
          class="flex-shrink ml-16 font-semibold text-gray-600 uppercase"
        >
          <fa
            :icon="faPlus"
            fixed-width
          />
          Kampagne erstellen
        </router-link>
      </div>
    </div>
    <div class="flex items-center justify-between px-4 py-4 text-gray-600 bg-gray-100">
      <p class="">
        {{ filteredCampaigns.length }} Kampagnen
        <a
          href="#"
          class="pl-6 underline"
          @click.prevent="clearAllFilter"
        >Alle Filter und Suche löschen</a>
      </p>
    </div>
    <table class="w-full h-full border-0">
      <thead>
        <tr
          class="text-sm font-semibold tracking-wide text-left text-gray-700 uppercase bg-gray-200"
        >
          <th class="w-2/12 p-4">
            Name
          </th>
          <th class="w-2/12 p-4">
            Slug
          </th>
          <th class="w-2/12 p-4">
            <SortableField
              :sortable-items="campaigns"
              field="createdAt"
            >
              Erstelldatum
            </SortableField>
          </th>
          <th class="relative hidden w-6/12 p-4 cursor-pointer md:table-cell">
            <div
              class="flex justify-between"
              @click="showLandingpageFilter = !showLandingpageFilter"
            >
              <div>Landingpage</div>
              <fa
                :icon="faFilter"
                class="self-center inline-block ml-2 cursor-pointer"
                :class="{'text-teal-500':landingpageFilter.length}"
              />
            </div>
            <div
              v-if="showLandingpageFilter"
              v-click-outside="hideLandingpageFilter"
              class="absolute flex flex-col p-2 mt-2 overflow-visible font-light whitespace-no-wrap bg-white border rounded-sm shadow-sm"
            >
              <div
                v-for="landingpage in landingpages"
                :key="landingpage"
                class="px-2 py-1 cursor-pointer"
                @click="toggleFilter('landingpageFilter', landingpage)"
              >
                <fa
                  :icon="faCheck"
                  class="self-center inline-block mr-2"
                  :class="{'text-teal-500':landingpageFilter.includes(landingpage)}"
                />
                {{ landingpage || '- ohne -' }}
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="text-sm">
        <tr v-if="!filteredCampaigns.length">
          <td colspan="7">
            <div
              v-if="$apollo.queries.campaigns.loading"
              class="loading"
            >
              <span style="width: 100%;" />
            </div>
            <div
              v-else
              class="p-8 text-base text-center"
            >
              Zu den eingestellten Filtern oder zur Suche ist kein Eintrag vorhanden.
              <a
                href="#"
                class="underline"
                @click.prevent="clearAllFilter"
              >Alle Filter und Suche löschen</a>
            </div>
          </td>
        </tr>
        <tr
          v-for="campaign in filteredCampaigns"
          v-else
          :key="campaign.id"
          class="text-gray-500 transition cursor-pointer duration-250 group even:bg-gray-100 odd:bg-white hover:text-gray-700"
          @click="$router.push(`/campaigns/${campaign.id}`) "
        >
          <td class="hidden w-2/12 px-4 py-2 group-hover:bg-gray-200 sm:table-cell">
            {{ campaign.name }}
          </td>
          <td class="hidden w-2/12 px-4 py-2 group-hover:bg-gray-200 sm:table-cell">
            {{ campaign.slug }}
          </td>
          <td class="hidden w-2/12 px-4 py-2 group-hover:bg-gray-200 sm:table-cell">
            {{ formatDate(campaign.createdAt) }}
          </td>
          <td class="hidden w-6/12 px-4 py-2 uppercase group-hover:bg-gray-200 md:table-cell">
            {{ campaign.landingpages.map((lp) => `${lp.country}: ${lp.landingpageSlug}`).join(' | ') }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import LIST_CAMPAIGNS from '@/graphql/ListCampaigns.gql'
import vClickOutside from 'v-click-outside'
import { faFilter, faCheck, faUpload, faPlus } from '@fortawesome/free-solid-svg-icons'
import ImportCampaign from '@/components/modals/campaign/import'
import SortableField from '@/components/SortableField.vue'

const escapeRegxChars = (value) =>
  value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')

export default {
  name: 'Campaigns',
  components: {
    SortableField
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  data () {
    return {
      landingpages: [],
      campaigns: [],
      faFilter,
      faCheck,
      faUpload,
      faPlus,
      landingpageFilter: [],
      showLandingpageFilter: false,
      search: ''
    }
  },
  computed: {
    filteredCampaigns () {
      return this.campaigns
        .filter(this.landingpageFilter.length > 0 ? this.filterByLandingpage : () => true)
        .filter(this.search.length > 0 ? this.filterBySearch : () => true)
    }
  },
  apollo: {
    campaigns: {
      query: LIST_CAMPAIGNS,
      result ({ data }) {
        if (data) {
          const { campaigns } = data
          this.landingpages = [...new Set(
            campaigns.flatMap(({ landingpages }) => landingpages.map(({ landingpageSlug }) => landingpageSlug))
          )]
        }
      }
    }
  },
  mounted () {
    this.popupItem = this.$el
    this.restoreFilter('landingpageFilter')
  },
  methods: {
    last: (list) => list[list.length - 1],
    showImportModal () {
      this.$modal.show(ImportCampaign)
    },
    hideLandingpageFilter () {
      this.showLandingpageFilter = false
    },
    restoreFilter (key) {
      if (localStorage.getItem(key)) {
        try {
          this[key] = JSON.parse(localStorage.getItem(key))
        } catch (e) {
          localStorage.removeItem(key)
        }
      }
    },
    saveFilter (key) {
      localStorage.setItem(key, JSON.stringify(this[key]))
    },
    toggleFilter (filterName, item) {
      if (!this[filterName].includes(item)) {
        this[filterName].push(item)
      } else {
        this[filterName].splice(this[filterName].indexOf(item), 1)
      }
      this.saveFilter(filterName)
    },
    filterByLandingpage ({ landingpages }) {
      return landingpages.find((lp) => this.landingpageFilter.includes(lp.landingpageSlug))
    },
    filterBySearch ({ name, slug }) {
      const searchableFields = [name, slug]

      const regx = new RegExp(escapeRegxChars(this.search), 'i')

      return searchableFields.some((value) => regx.test(value))
    },
    clearAllFilter () {
      this.landingpageFilter = []
      this.saveFilter('landingpageFilter')
      this.search = ''
    }
  }
}
</script>

<style >
.loading {
  height: 5px;
  position: relative;
}
.loading span {
  display: block;
  height: 100%;
  background-color: rgb(43, 194, 83);
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, rgb(43, 194, 83)),
    color-stop(1, rgb(84, 240, 84))
  );
  background-image: -moz-linear-gradient(
    center bottom,
    rgb(43, 194, 83) 37%,
    rgb(84, 240, 84) 69%
  );
  position: relative;
  overflow: hidden;
  width: 0;
  animation: expandWidth 1s linear;
}
.loading > span:after {
  content: "";
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  z-index: 1;
  background-size: 50px 50px;
  animation: move 2s linear infinite;
}
@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}
@keyframes expandWidth {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
</style>
