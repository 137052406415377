<template>
  <div class="flex items-center">
    <label
      v-if="label"
      class="block w-full mb-0 mr-2"
    >
      {{ label }}:
    </label>
    <span
      class="toggle-wrapper"
      :class="small && 'small'"
      role="checkbox"
      :aria-checked="isEnabled.toString()"
      tabindex="0"
      @click="toggle"
      @keydown.space.prevent="toggle"
    >
      <span
        class="toggle-background"
        :class="backgroundStyles"
      />
      <span
        class="toggle-indicator"
        :style="indicatorStyles"
      />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    inputClass: {
      type: [Object, Array, String],
      default: ''
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      isEnabled: this.value
    }
  },
  computed: {
    backgroundStyles () {
      return {
        active: this.isEnabled,
        inactive: !this.isEnabled
      }
    },
    indicatorStyles () {
      const enabledWidth = this.small ? '18px' : '25px'
      return { transform: `translateX(${this.isEnabled ? enabledWidth : '0'})` }
    }
  },
  watch: {
    value: {
      handler (newValue, oldValue) {
        this.isEnabled = newValue
      },
      deep: true
    }
  },
  methods: {
    toggle () {
      this.$emit('onChange', !this.isEnabled)
    }
  }
}
</script>
<style scoped>
.active {
  background-color: #2c5282;
}

.inactive {
  background-color: #c2c2c2;
}

.toggle-wrapper {
  display: inline-block;
  position: relative;
  cursor: pointer;
  border-radius: 9999px;
}

.toggle-wrapper:focus {
  outline: 0;
}

.toggle-wrapper,
.toggle-background {
  width: 50px;
  height: 24px;
}

.toggle-background {
  display: inline-block;
  border-radius: 9999px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color .4s ease;
}

.toggle-indicator {
  position: absolute;
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: #ffffff;
  border-radius: 9999px;
  box-shadow:  0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform .4s ease;
}

.small.toggle-wrapper,
.small .toggle-background {
  height: 18px;
  width: 36px;
}

.small .toggle-indicator {
  height: 14px;
  width: 14px;
}
</style>
