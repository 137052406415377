<template>
  <div
    v-if="landingpageTemplate"
    id="lpTemplate-details"
    class="pb-1"
  >
    <div class="flex items-center justify-between p-8 bg-gray-100 border-b rounded-t-lg">
      <!-- Heading -->
      <div class="text-gray-800 ">
        <div class="flex items-center font-semibold uppercase">
          <img
            :src="`/img/country-flags/${landingpageTemplate.countryCode}.png`"
            class="inline-block w-5 mr-3"
          >
          {{ landingpageTemplate.name }}
        </div>
        <em class="ml-8 text-gray-600">{{ landingpageTemplate.slug }}</em>
      </div>

      <!-- Actions -->
      <div class="flex flex-row">
        <router-link
          to="/landingpages"
          class="flex items-center font-semibold text-gray-600 uppercase hover:text-gray-800"
        >
          <fa
            :icon="faCaretLeft"
            fixed-width
          />
          Zurück
        </router-link>
      </div>
    </div>

    <!-- Details -->
    <section class="flex flex-wrap w-1/2 m-8">
      <data-field
        label="Typ"
        :value="landingpageTemplate.type"
      />
    </section>

    <LandingpageTemplateList
      :landingpage-templates="otherLandingpageTemplates"
      :create-link="landingpageTemplateCreateLink"
      title="Landingpages für andere Länder"
      class="m-8"
    />

    <MaterialTemplateList
      :material-templates="landingpageTemplate.materialTemplates"
      :landingpage-template-id="landingpageTemplateId"
      class="m-8"
      @material-deleted="handleMaterialDeleted"
    />
  </div>
</template>

<script>
import {
  faPen,
  faPlus,
  faCogs,
  faTrash,
  faDownload,
  faCaretLeft
} from '@fortawesome/free-solid-svg-icons'
import DataField from '@/components/data-field.vue'
import LandingpageTemplateList from '@/components/landingpage-template/list.vue'
import MaterialTemplateList from '@/components/material-template/list.vue'
import GET_LANDINGPAGE_TEMPLATE from '@/graphql/GetLandingpageTemplate.gql'
import LIST_LANDINGPAGE_TEMPLATES from '@/graphql/ListLandingpageTemplates.gql'

export default {
  components: {
    DataField,
    MaterialTemplateList,
    LandingpageTemplateList
  },

  data: function () {
    return {
      faPen,
      faPlus,
      faCogs,
      faTrash,
      faDownload,
      faCaretLeft
    }
  },

  computed: {
    landingpageTemplateId () {
      return this.$route.params.landingpageTemplateId
    },

    otherLandingpageTemplates () {
      return this.landingpageTemplates?.filter(({ id }) => id !== this.landingpageTemplateId)
    },

    landingpageTemplateCreateLink () {
      const { slug, name, type } = this.landingpageTemplate
      const searchParams = new URLSearchParams({ slug, name, type })
      return `/landingpages/create?${searchParams}`
    }
  },

  apollo: {
    landingpageTemplate: {
      query: GET_LANDINGPAGE_TEMPLATE,
      variables () {
        return {
          input: {
            landingpageTemplateId: this.landingpageTemplateId
          }
        }
      }
    },

    landingpageTemplates: {
      query: LIST_LANDINGPAGE_TEMPLATES,
      variables () {
        return {
          input: {
            slug: this.landingpageTemplate.slug
          }
        }
      },
      skip () {
        return !this.landingpageTemplate
      }
    }
  },

  methods: {
    handleMaterialDeleted (result) {
      const cache = this.$apollo.getClient()

      const params = {
        query: GET_LANDINGPAGE_TEMPLATE,
        variables: {
          input: {
            landingpageTemplateId: this.landingpageTemplateId
          }
        }
      }

      const data = cache.readQuery(params)
      data.landingpageTemplate.materialTemplates = data.landingpageTemplate.materialTemplates
        .filter(materialTemplate => materialTemplate.id !== result.deletedMaterialTemplate.id)

      cache.writeQuery({ ...params, data })
    }
  }
}
</script>
