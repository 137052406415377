<template>
  <validation-provider
    ref="provider"
    v-slot="{ errors }"
    :rules="rules"
  >
    <label
      v-if="label"
      class="block w-full"
    >
      {{ label }}:
    </label>
    <textarea
      v-model="model"
      :class="[inputClass,
               {'border-red-500': errors.length,
                'bg-gray-400': disabled,
                'border-gray-500': disabled
               }]"
      v-bind="$attrs"
      :disabled="disabled"
    />
    <p
      v-if="errors.length"
      class="text-xs text-red-500"
      v-text="errors[0]"
    />
  </validation-provider>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: null
    },
    inputClass: {
      type: [Object, Array, String],
      default: ''
    },
    rules: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean
    }
  },
  data: () => ({
    model: ''
  }),
  watch: {
    model (value) {
      this.$emit('input', value)
    }
  },
  beforeMount () {
    this.model = this.value
  },
  methods: {
    validate () {
      return this.$refs.provider.validate()
    },
    setErrors (errors) {
      this.$refs.provider.setErrors(errors)
    }
  }
}
</script>
