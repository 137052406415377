<template>
  <crud-modal
    title="Vertrag erstellen"
    action="create"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <create-form
      :contract="contract"
      :expert-product="expertProduct"
      :setup-expert-product="setupExpertProduct"
      :basic-product="basicProduct"
      :setup-basic-product="setupBasicProduct"
    />
  </crud-modal>
</template>
<script>
import CreateForm from './create-form'
import CrudModal from '@/components/modals/crud-modal'
import { getDurationObject } from '@/config'

import CREATE_CONTRACT from '@/graphql/CreateContract.gql'
import GET_COMPANY_CONTRACTS from '@/graphql/GetCompanyContracts.gql'

export default {
  components: {
    CreateForm,
    CrudModal
  },
  props: {
    companyId: { type: String, required: true },
    productTemplates: { type: Array, required: true }
  },
  data: function () {
    return {
      contract: {
        addSetup: true,
        type: 'EXPERT',
        product: {},
        followRuntimeProduct: {},
        setupProduct: {}
      }
    }
  },
  computed: {
    expertProduct () {
      return this.productTemplates.find(({ type }) => type === 'EXPERT')
    },
    setupExpertProduct () {
      return this.productTemplates.find(({ id }) => id === this.expertProduct.setupTemplate)
    },
    basicProduct () {
      return this.productTemplates.find(({ type }) => type === 'BASIC')
    },
    setupBasicProduct () {
      return this.productTemplates.find(({ id }) => id === this.basicProduct.setupTemplate)
    }
  },
  methods: {
    async submit () {
      const {
        isTrial,
        startDate,
        priceListName,
        product,
        addSetup,
        setupProduct,
        followRuntimeProduct,
        differentFollowRuntimeConditions,
        note,
        type
      } = this.contract

      const productTemplate = type === 'EXPERT' ? this.expertProduct : this.basicProduct
      const setupTemplate = type === 'EXPERT' ? this.setupExpertProduct : this.setupBasicProduct
      const addMonth = (date, months) => new Date(new Date(date).setMonth(new Date(date).getMonth() + months))
      const addDay = (date, days) => new Date(new Date(date).setDate(new Date(date).getDate() + days))

      const products = [{
        duration: getDurationObject(product.duration),
        startDate,
        ...(differentFollowRuntimeConditions && {
          endDate: addDay(addMonth(startDate, getDurationObject(product.duration).value), -1)
        }),
        templateId: productTemplate.id,
        discountPercentage: product.discountPercentage || 0,
        wasPaidInAdvance: product.wasPaidInAdvance
      }]

      if (differentFollowRuntimeConditions) {
        products.push({
          duration: getDurationObject(followRuntimeProduct.duration),
          startDate: addMonth(startDate, getDurationObject(product.duration).value),
          templateId: productTemplate.id,
          discountPercentage: followRuntimeProduct.discountPercentage || 0,
          wasPaidInAdvance: followRuntimeProduct.wasPaidInAdvance
        })
      }

      if (addSetup) {
        products.push({
          startDate,
          templateId: setupTemplate.id,
          discountPercentage: setupProduct.discountPercentage || 0
        })
      }

      await this.$apollo.mutate({
        mutation: CREATE_CONTRACT,
        variables: {
          input: {
            companyId: this.companyId,
            isTrial,
            startDate,
            priceListName,
            note,
            products
          }
        },
        refetchQueries: [{ query: GET_COMPANY_CONTRACTS, variables: { id: this.companyId } }]
      })
    }
  }
}
</script>
