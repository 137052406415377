<template>
  <div>
    <div class="flex items-center justify-between px-10 py-8 bg-gray-100 border-b rounded-t-lg">
      <h1 class="text-xl font-semibold tracking-wider text-gray-800 uppercase">
        Kampagne bearbeiten
      </h1>
      <router-link
        :to="`/campaigns/${campaignId}`"
        class="font-semibold text-gray-600 uppercase hover:text-gray-800"
      >
        Abbrechen
      </router-link>
    </div>

    <CampaignForm
      v-if="campaign.id"
      :on-submit="submit"
      :campaign="campaign"
    />
  </div>
</template>

<script>
import GET_CAMPAIGN from '@/graphql/GetCampaign.gql'
import UPDATE_CAMPAIGN from '@/graphql/UpdateCampaign.gql'
import CampaignForm from './form.vue'

export default {
  components: { CampaignForm },
  data () {
    return {
      campaign: {},
      formData: {}
    }
  },
  apollo: {
    campaign: {
      query: GET_CAMPAIGN,
      variables () {
        return {
          campaignId: this.campaignId
        }
      },
      update ({ campaign }) {
        return {
          ...campaign,
          landingpages: campaign.landingpages.map(({ country, landingpageSlug }) => ({
            country,
            landingpageSlug
          }))
        }
      }
    }
  },
  computed: {
    campaignId () {
      return this.$route.params.id
    }
  },
  methods: {
    async submit () {
      const { id, name, slug, landingpages } = this.campaign

      await this.$apollo.mutate({
        mutation: UPDATE_CAMPAIGN,
        variables: {
          input: {
            id, name, slug, landingpages
          }
        }
      })

      this.$router.push({
        path: `/campaigns/${this.campaignId}`
      })
    }
  }
}
</script>
