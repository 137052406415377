<template>
  <crud-modal
    title="Lead löschen"
    action="delete"
    :on-submit="submit"
    :on-close="close"
  >
    <div class="mb-2">
      Soll der Lead wirklich gelöscht werden?
    </div>
  </crud-modal>
</template>

<script>
import CrudModal from '@/components/modals/crud-modal'

import DELETE_LEAD from '@/graphql/DeleteLead.gql'
import GET_COMPANY from '@/graphql/GetCompany.gql'

export default {
  components: {
    CrudModal
  },
  props: {
    companyId: {
      type: String,
      required: true
    },
    leadId: {
      type: String,
      required: true
    }
  },
  methods: {
    close () {
      this.$emit('close')
      this.$router.push(`/companies/${this.companyId}`)
    },
    async submit () {
      const {
        data: { deleteLead: deleted }
      } = await this.$apollo.mutate({
        mutation: DELETE_LEAD,
        variables: {
          id: this.leadId,
          deletedBy: this.$auth.user.email
        },
        refetchQueries: [
          { query: GET_COMPANY, variables: { id: this.companyId } }
        ]
      })

      if (!deleted) {
        throw Error('Deletion failed')
      }
    }
  }
}
</script>
