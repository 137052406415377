<template>
  <div class="modal">
    <div class="modal-content">
      <h1
        class="w-full mb-6 text-xl font-semibold tracking-wide text-gray-800 uppercase"
      >
        Snippet zum Einbetten
      </h1>
      <div class="mb-2">
        <textarea
          v-model="snippet"
          class="w-full h-32 p-4 text-white bg-gray-800 rounded-lg outline-none resize-none"
          readonly
          @click="copy"
        />
        <span
          v-if="copied"
          class="text-green-700"
        >
          Snippet in Zwischenablage kopiert
        </span>
      </div>
    </div>
    <div class="modal-footer">
      <button
        class="btn-primary"
        @click="$emit('close')"
      >
        Schließen
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    companyId: {
      type: String,
      required: true
    },
    companySlug: {
      type: String,
      required: true
    },
    landingpageSlug: {
      type: String,
      required: true
    }
  },
  data: () => ({
    copied: false
  }),
  computed: {
    snippet () {
      return `<script
src="${process.env.VUE_APP_IFRAME_LOADER_URL}"
data-company="${this.companySlug}"
data-slug="${this.landingpageSlug}"
data-bottimmo
defer><\/script>`.replace(/\n/g, ' ') // eslint-disable-line no-useless-escape
    }
  },
  methods: {
    copy (evt) {
      evt.target.select()
      document.execCommand('copy')
      this.copied = true
    }
  }
}
</script>
