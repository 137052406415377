<template>
  <div
    v-if="campaign"
    id="campaign-details"
  >
    <div class="flex items-center justify-between px-10 py-8 bg-gray-100 border-b rounded-t-lg">
      <router-link
        to="/campaigns"
        class="flex items-center font-semibold text-gray-600 uppercase hover:text-gray-800"
      >
        Zurück zur Übersicht
      </router-link>
      <div class="flex flex-row">
        <button
          class="flex-shrink mx-4 font-semibold text-gray-600 uppercase hover:text-gray-800"
          @click="showDeleteModal()"
        >
          <fa
            :icon="faTrash"
            fixed-width
          />
          Löschen
        </button>
        <button
          class="flex-shrink mx-4 font-semibold text-gray-600 uppercase hover:text-gray-800"
          @click="exportCampaign"
        >
          <fa
            :icon="faDownload"
            fixed-width
          />
          Exportieren
        </button>
        <router-link
          :to="`/campaigns/${campaignId}/update`"
          class="flex items-center mx-4 font-semibold text-gray-600 uppercase hover:text-gray-800"
        >
          <fa
            :icon="faPen"
            fixed-width
          />
          Bearbeiten
        </router-link>
      </div>
    </div>
    <section class="w-full">
      <div class="flex flex-wrap justify-between p-8 pb-0">
        <input-switch
          v-model="campaign.isActive"
          label="Kampagne Aktiv"
          @onChange="handleChange"
        />
      </div>
      <div class="flex flex-wrap p-8">
        <data-field
          label="Name"
          :value="campaign.name"
        />
        <data-field
          label="Slug"
          :value="campaign.slug"
        />
        <data-field
          label="Statisch"
          :value="getReadableCampaignType(campaign.type)"
        />
        <data-field
          label="Landingpage Deutschland"
          :value="getLandingpageSlug('de')"
        />
        <data-field
          label="Landingpage Österreich"
          :value="getLandingpageSlug('at')"
        />
        <data-field
          label="Landingpage Schweiz"
          :value="getLandingpageSlug('ch')"
        />
        <data-field
          label="Landingpage Türkei"
          :value="getLandingpageSlug('tr')"
        />
      </div>
      <div class="flex flex-wrap px-8">
        <div class="items-center w-1/3 px-2 mb-6 form-group">
          <button
            class="w-full btn-secondary"
            @click="showDocumentUploadModal()"
          >
            <fa
              :icon="faPlus"
              fixed-width
            />
            Begleitdokument hinzufügen
          </button>
        </div>
        <div class="items-center w-1/3 px-2 mb-6 form-group">
          <button
            class="w-full btn-secondary"
            @click="showMoovlyVideoModal()"
          >
            <fa
              :icon="faPlus"
              fixed-width
            />
            Video hinzufügen
          </button>
        </div>
        <div class="items-center w-1/3 px-2 mb-6 form-group">
          <button
            class="w-full btn-secondary"
            @click="showBrandEasyModal()"
          >
            <fa
              :icon="faPlus"
              fixed-width
            />
            BrandEasy hinzufügen
          </button>
        </div>
      </div>
      <div
        v-for="template in campaign.materialTemplates"
        :key="template.id"
        class="flex flex-wrap items-center justify-between p-2 mx-8 my-2 border-2 rounded-md"
      >
        <span class="w-1/6 pl-4">
          {{ template.generationService }}
        </span>
        <span class="w-2/6">
          {{ template.displayName }}
        </span>
        <span
          v-if="template.staticConfig"
          class="w-2/6"
        >
          <a
            target="_blank"
            :href="template.staticConfig.file.cdnUrl"
          >{{ template.staticConfig.file.name }}</a>
          | {{ template.staticConfig.variants.join(', ') }}
        </span>
        <span
          v-if="template.s3VideoConfig"
          class="w-2/6"
        >
          {{ template.s3VideoConfig.directory }}
        </span>
        <span
          v-if="template.brandEasyConfig"
          class="w-2/6"
        >
          {{ template.brandEasyConfig.template }} |
          {{ template.brandEasyConfig.target }} |
          {{ template.brandEasyConfig.bleed }}
        </span>
        <span
          v-if="template.moovlyConfig"
          class="w-2/6"
        >
          {{ template.moovlyConfig.template }} |
          {{ template.moovlyConfig.resolution }}

        </span>
        <button
          class="w-1/6 text-red-600"
          @click="deleteMaterialTemplate(template.id)"
        >
          Löschen
          <fa
            :icon="faTrash"
            fixed-width
          />
        </button>
      </div>
      <div class="flex flex-wrap items-end p-8">
        <div class="flex w-full px-4">
          <div class="w-1/3">
            <div>
              Es werden gerade {{ runningCampaignJobs.RUNNING }} Jobs bearbeitet.
            </div>
            <div>
              Es befinden sich {{ runningCampaignJobs.CREATED }} Jobs in der Warteschleife.
            </div>
          </div>
          <div class="items-center w-1/3 px-2 mx-6 mb-6 form-group">
            <button
              title="Kampagne generieren"
              class="w-full btn-secondary"
              @click="showGenerateCampaignModal()"
            >
              <fa
                :icon="faCogs"
                fixed-width
              />
              Kampagne generieren
            </button>
          </div>
          <div class="w-1/3">
            <button
              :disabled="runningCampaignJobs.CREATED === '0'"
              class="btn-secondary"
              @click="cancelCampaignJobs()"
            >
              Generierung Abbrechen
            </button>
          </div>
        </div>
      </div>
    </section>
    <materials :campaign-id="campaignId" />
  </div>
</template>

<script>

import { faPlus, faCogs, faTrash, faDownload, faPen } from '@fortawesome/free-solid-svg-icons'
import InputSwitch from '@/components/form/switch.vue'
import DataField from '@/components/data-field.vue'
import Generate from '@/components/modals/campaign/generate'
import CreateStaticDocument from '@/components/modals/campaign/create-static-document'
import CreateVideo from '@/components/modals/campaign/create-moovly.vue'
import CreateBrandEasy from '@/components/modals/campaign/create-brandeasy.vue'
import DeleteCampaign from '@/components/modals/campaign/delete.vue'

import GET_CAMPAIGN from '@/graphql/GetCampaign.gql'
import UPDATE_CAMPAIGN_IS_ACTIVE from '@/graphql/UpdateCampaignIsActive.gql'
import GET_RUNNING_CAMPAIGN_JOBS from '@/graphql/GetRunningCampaignJobs.gql'
import CANCEL_CAMPAIGN_JOBS from '@/graphql/CancelCampaignJobs.gql'
import EXPORT_CAMPAIGN from '@/graphql/ExportCampaign.gql'
import DELETE_CAMPAIGN_MATERIALS from '@/graphql/DeleteCampaignMaterials.gql'
import Materials from './materials.vue'
import readableCampaignTypes from '@/lib/enums/campaign/campaignTypes'

export default {
  components: { DataField, Materials, InputSwitch },
  data: function () {
    return {
      faPlus,
      faCogs,
      faTrash,
      faDownload,
      faPen,
      runningCampaignJobs: {}
    }
  },
  computed: {
    campaignId () {
      return this.$route.params.id
    }
  },
  methods: {

    async exportCampaign  () {
      const { data: { exportCampaign: data } } = await this.$apollo.query({
        query: EXPORT_CAMPAIGN,
        variables: { campaignId: this.campaignId }
      })
      const blob = new Blob([JSON.stringify(data)], { type: 'application/json' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `Campaign-${this.campaign.name}_${this.formatDate(new Date())}.json`
      link.click()
    },

    getReadableCampaignType (type) {
      return readableCampaignTypes[type]
    },

    getLandingpageSlug (country) {
      return this.campaign.landingpages.find((lp) => lp.country === country)?.landingpageSlug
    },
    cancelCampaignJobs () {
      this.$apollo.mutate({
        mutation: CANCEL_CAMPAIGN_JOBS,
        variables: { campaignId: this.campaignId }
      })
    },
    deleteMaterialTemplate (materialTemplateId) {
      this.$apollo.mutate({
        mutation: DELETE_CAMPAIGN_MATERIALS,
        variables: { materialTemplateId },
        refetchQueries: [{ query: GET_CAMPAIGN, variables: { campaignId: this.campaignId } }]
      })
    },
    showGenerateCampaignModal () {
      this.$modal.show(
        Generate,
        {
          onSubmit: this.onGenerate,
          campaign: this.campaign
        }
      )
    },
    showDocumentUploadModal () {
      this.$modal.show(
        CreateStaticDocument,
        { campaignId: this.campaign.id },
        { width: 900 }
      )
    },
    showMoovlyVideoModal () {
      this.$modal.show(
        CreateVideo,
        { campaignId: this.campaign.id }
      )
    },
    showBrandEasyModal () {
      this.$modal.show(
        CreateBrandEasy,
        { campaignId: this.campaign.id }
      )
    },
    showDeleteModal () {
      this.$modal.show(
        DeleteCampaign,
        {
          campaignId: this.campaign.id,
          name: this.campaign.name
        }
      )
    },
    async onGenerate () {
      this.pollRunningCampaignJobs()
    },
    async pollRunningCampaignJobs () {
      this.$apollo.queries.runningCampaignJobs.setOptions({
        pollInterval: 10000,
        fetchPolicy: 'cache-and-network'
      })
    },
    handleChange (newValue) {
      this.$apollo.mutate({
        mutation: UPDATE_CAMPAIGN_IS_ACTIVE,
        variables: {
          input: { isActive: newValue, id: this.campaignId }
        }
      })
    }
  },
  apollo: {
    campaign: {
      query: GET_CAMPAIGN,
      variables () {
        return {
          campaignId: this.campaignId
        }
      }
    },
    runningCampaignJobs: {
      query: GET_RUNNING_CAMPAIGN_JOBS,
      variables () {
        return { campaignId: this.campaignId }
      }
    }
  }
}
</script>
