import Vue from 'vue'
import VueRouter from 'vue-router'
import { authenticated, hasRole, publicOnly } from './auth/guards'
import Health from './pages/health.vue'
import Login from './pages/auth/login.vue'
import AuthCallback from './pages/auth/callback.vue'
import Admin from './pages/admin.vue'
import Dashboard from './pages/dashboard.vue'
import Statistics from './pages/statistics.vue'
import Campaigns from './pages/campaigns/index.vue'
import CreateCampaign from './pages/campaigns/create.vue'
import UpdateCampaign from './pages/campaigns/update.vue'
import Campaign from './pages/campaigns/detail.vue'
import Companies from './pages/companies/index.vue'
import InactiveEmails from './pages/inactiveEmails.vue'
import CreateCompany from './pages/companies/create.vue'
import Company from './pages/companies/detail.vue'
import UpdateCompany from './pages/companies/update.vue'
import Landingpages from './pages/landingpages/index.vue'
import LandingpageTemplate from './pages/landingpages/detail.vue'
import CreateLandingpageTemplate from './pages/landingpages/create.vue'
import Lead from './pages/lead.vue'
import featureNames from './lib/featureNames'
import { ROLES } from './mixins/role'

Vue.use(VueRouter)

const routes = [
  {
    path: '/_health',
    name: 'health',
    component: Health
  },
  {
    path: '/',
    name: 'home',
    redirect: {
      name: 'login'
    }
  },
  {
    path: '/auth/login',
    name: 'login',
    component: Login,
    beforeEnter: publicOnly
  },
  {
    path: '/auth/callback',
    name: 'auth-callback',
    component: AuthCallback
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
    meta: { feature: featureNames.BACKOFFICE_ADMIN },
    beforeEnter: authenticated
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    beforeEnter: authenticated
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: Statistics,
    meta: { feature: featureNames.STATISTICS },
    beforeEnter: authenticated
  },
  {
    path: '/campaigns',
    name: 'campaigns',
    component: Campaigns,
    beforeEnter: hasRole(ROLES.SUPER_ADMIN)
  },
  {
    path: '/campaigns/create',
    name: 'createCampaign',
    component: CreateCampaign,
    beforeEnter: hasRole(ROLES.SUPER_ADMIN)
  },
  {
    path: '/campaigns/:id',
    name: 'campaign',
    component: Campaign,
    beforeEnter: hasRole(ROLES.SUPER_ADMIN)
  },
  {
    path: '/campaigns/:id/update',
    name: 'updateCampaign',
    component: UpdateCampaign,
    beforeEnter: hasRole(ROLES.SUPER_ADMIN)
  },
  {
    path: '/companies',
    name: 'companies',
    component: Companies,
    beforeEnter: authenticated
  },
  {
    path: '/inactive-emails',
    name: 'inactive-emails',
    component: InactiveEmails,
    beforeEnter: authenticated
  },
  {
    path: '/companies/create',
    name: 'createCompany',
    component: CreateCompany,
    beforeEnter: authenticated
  },
  {
    path: '/companies/:id',
    name: 'company',
    component: Company,
    beforeEnter: authenticated
  },
  {
    path: '/companies/:id/update',
    name: 'updateCompany',
    component: UpdateCompany,
    beforeEnter: authenticated
  },
  {
    path: '/leads/:leadId',
    name: 'lead',
    component: Lead,
    meta: { feature: featureNames.BACKOFFICE_LEADS },
    beforeEnter: authenticated
  },
  {
    path: '/landingpages',
    name: 'landingpages',
    component: Landingpages,
    beforeEnter: hasRole(ROLES.SUPER_ADMIN)
  },
  {
    path: '/landingpages/create',
    name: 'createLandingpageTemplate',
    component: CreateLandingpageTemplate,
    beforeEnter: hasRole(ROLES.SUPER_ADMIN)
  },
  {
    path: '/landingpages/:landingpageTemplateId',
    name: 'landingpageTemplateDetail',
    component: LandingpageTemplate,
    beforeEnter: hasRole(ROLES.SUPER_ADMIN)
  },
  {
    path: '*',
    name: '404',
    redirect: {
      name: 'home'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
