import 'regenerator-runtime/runtime.js'
import Vue from 'vue'
import { FeaturePlugin } from 'feature-config'
import bus, { eventNames } from '@/lib/eventBus'
import App from './App.vue'
import router from './router'
import { Auth0Plugin } from './auth'
import { createProvider } from './vue-apollo'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import './plugins/clipboard'
import './plugins/file-upload'
import './plugins/font-awesome'
import './plugins/mixins'
import './plugins/modal'
import './plugins/vee-validate'
import './registerServiceWorker'
import { getFeatureGuard } from './lib/featureGuard'

const apolloProvider = createProvider()

Vue.use(FeaturePlugin, {
  onCreated () {
    bus.$on(eventNames.USER_LOGGED_IN, () => {
      this.loadAllFeatures({ apolloProvider })
    })
  }
})

Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  connection: process.env.VUE_APP_AUTH0_CONNECTION,
  redirectUri: window.location.origin + '/auth/callback',
  onRedirectCallback: targetPath => {
    router.push({
      ...(targetPath
        ? { path: targetPath }
        : { name: 'dashboard' }
      ),
      replace: true
    })
  }
})

Sentry.init({
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [new VueIntegration({ Vue, attachProps: true })],
  environment: process.env.VUE_APP_STAGE,
  enabled: process.env.VUE_APP_STAGE && process.env.VUE_APP_STAGE !== 'local'
})

Vue.config.productionTip = false

router.beforeEach(getFeatureGuard(apolloProvider))

new Vue({
  router,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')
