<template>
  <div class="w-full py-2">
    <h2 class="flex justify-between w-full mb-2 uppercase border-b-4">
      <div class="w-3/12 px-1 font-bold">
        Produkte
      </div>
      <div class="w-3/12 px-1">
        Startdatum ( - Enddatum)
      </div>
      <div class="w-1/12 px-1 text-right">
        Laufzeit
      </div>
      <div class="w-2/12 px-1 text-right">
        Preis
      </div>
      <button
        class="w-3/12 font-bold text-right btn-inline-small "
        @click="showCreateProductModal()"
      >
        <fa :icon="faPlus" />
        Hinzufügen
      </button>
    </h2>
    <div
      v-for="product in sortedProducts"
      :key="product.id"
      class="flex-col py-1 border-b-2 hover:bg-gray-200 group"
    >
      <div class="flex w-full px-1">
        <div class="w-3/12 px-1 ">
          <p class="font-bold">
            {{ product.name }}
          </p>
          <span>{{ product.note }}</span>
        </div>
        <div class="w-3/12 px-1 text-left">
          <span>{{ formatDate(product.startDate) }}</span>
          <span v-if="product.endDate"> - {{ formatDate(product.endDate) }}</span>
        </div>
        <div class="w-1/12 text-right">
          <span>{{ duration(product.condition) }}</span>
          <span v-if="product.minUntilDate"> ({{ formatDate(product.minUntilDate) }})</span>
        </div>
        <div class="flex flex-col w-2/12 text-right">
          <product-price
            :discount-percentage="product.discountPercentage"
            :price="product.condition.price"
          />
          <span v-if="product.wasPaidInAdvance">
            <strong>Vorauszahlung</strong>
          </span>
        </div>
        <div class="w-3/12 px-1 pr-2 text-right">
          <button
            v-if="product.cancelledAt"
            class="btn-inline-small"
            @click="showRevokeCancelProductModal(product)"
          >
            <fa :icon="faUndo" />
            <span class="pl-2">Gekündigt ({{ formatDate(product.cancelledAt) }})</span>
          </button>
          <button
            v-else-if="product.monthly && product.type !== CREDIT"
            class="btn-inline-small"
            @click="showCancelProductModal(product)"
          >
            <fa :icon="faTimesCircle" />
            <span class="pl-2">Kündigen</span>
          </button>
          <button
            class="pl-2 btn-inline-small"
            @click="showDeleteProductModal(product)"
          >
            <fa :icon="faTrash" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreateProductModal from '@/components/modals/product/create'
import CancelProductModal from '@/components/modals/product/cancel'
import RevokeCancelProductModal from '@/components/modals/product/revoke-cancel'
import DeleteProductModal from '@/components/modals/product/delete'
import { faPlus, faTimesCircle, faUndo, faTrash } from '@fortawesome/free-solid-svg-icons'
import { getCancellationReasonName, PRODUCT_TYPES, getDurationNameFromObject } from '@/config'
import productPrice from './product-price.vue'

export default {
  components: { productPrice },
  props: {
    productTemplates: { type: Array, default: () => ([]) },
    contract: { type: Object, default: () => ({}) },
    companyId: { type: String, required: true }
  },
  data () {
    return { faPlus, faTimesCircle, faUndo, faTrash }
  },
  computed: {
    sortedProducts () {
      return [...this.contract.products].sort((a, b) => a.readableId.localeCompare(b.readableId))
    }
  },
  created () {
    this.SETUP = PRODUCT_TYPES.SETUP
    this.CREDIT = PRODUCT_TYPES.CREDIT
    this.REMINDER_FEE = PRODUCT_TYPES.REMINDER_FEE
  },
  methods: {
    getCancellationReasonName,
    duration (condition) {
      return condition?.minDuration && getDurationNameFromObject(condition.minDuration)
    },
    showCreateProductModal () {
      this.$modal.show(
        CreateProductModal,
        {
          companyId: this.companyId,
          contract: this.contract,
          productReadableIds: this.contract.products.map(({ readableId }) => (readableId)),
          productTemplates: this.productTemplates
        }, {
          width: 800
        }
      )
    },
    showCancelProductModal (product) {
      this.$modal.show(
        CancelProductModal,
        {
          companyId: this.companyId,
          product: { ...product },
          contractId: this.contract.id
        }
      )
    },
    showRevokeCancelProductModal (product) {
      this.$modal.show(
        RevokeCancelProductModal,
        {
          companyId: this.companyId,
          product,
          contractId: this.contract.id
        }
      )
    },
    showDeleteProductModal (product) {
      this.$modal.show(
        DeleteProductModal,
        {
          id: product.id,
          companyId: this.companyId,
          contract: this.contract
        }
      )
    }
  }
}
</script>
