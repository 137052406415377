<template>
  <validation-provider
    ref="provider"
    v-slot="{ errors }"
    :rules="rules"
  >
    <input-select
      :label="label"
      :options="options"
      :select-class="{'border-red-500': errors.length}"
      v-bind="$attrs"
      :value="value"
      :disabled="disabled"
      v-on="$listeners"
    />
    <p
      v-if="errors.length"
      class="text-xs text-red-500"
      v-text="errors[0]"
    />
  </validation-provider>
</template>

<script>
import InputSelect from '@/components/form/select'

export default {
  components: {
    InputSelect
  },
  props: {
    value: {
      type: [Array, String, Number],
      default: null
    },
    label: {
      type: String,
      default: null
    },
    rules: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      default: () => []
    },
    disabled: Boolean
  },
  methods: {
    validate () {
      return this.$refs.provider.validate()
    },
    setErrors (errors) {
      this.$refs.provider.setErrors(errors)
    }
  }
}
</script>
