<template>
  <crud-modal
    title="Aktive Produkte eines Monats"
    action="export"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <div class="flex flex-wrap w-full">
      <div class="w-1/2 px-4 mb-3">
        <input-date-validated
          ref="billingMonth"
          v-model="billingMonth"
          label="Abrechnungsmonat"
          type="month"
          rules="required"
        />
      </div>
      <div class="w-1/2 px-4 mb-3">
        <input-date-validated
          ref="lastBillingDate"
          v-model="lastBillingDate"
          label="Letzte Abrechnung"
          rules="required"
        />
      </div>
      <h2 class="w-full flex items-center font-medium pl-4">
        <input
          v-model="groupByCompany"
          type="checkbox"
          class="w-4 h-4 mr-2 form-checkbox border-2 border-gray-600"
          :value="groupByCompany"
        >
        Produkte pro Kunde gruppieren (1 Zeile pro Kunde mit Produktliste)
      </h2>
    </div>
  </crud-modal>
</template>
<script>
import CrudModal from '@/components/modals/crud-modal'
import InputDateValidated from '@/components/form/date-validated'

import { exportProducts } from '@/lib/export'

export default {
  components: { CrudModal, InputDateValidated },
  data () {
    return {
      billingMonth: new Date(),
      groupByCompany: false,
      lastBillingDate: null
    }
  },
  methods: {
    async submit () {
      const billingMonth = this.billingMonth.toISOString().slice(0, 7)
      const lastBillingDate = this.lastBillingDate.toISOString().slice(0, 10)
      await exportProducts({ billingMonth, lastBillingDate, groupByCompany: Boolean(this.groupByCompany) })
    }
  }
}
</script>
