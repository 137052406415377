<template>
  <div
    class="flex h-8 px-2 m-1 border-2 rounded tag"
    :class="inputClass"
    :style="{ background: isSelected ? adjustColor(tag.color, -30) : tag.color, borderColor: adjustColor(tag.color,-30), cursor: (canClick || canEdit) ? 'pointer': 'default' }"
  >
    <div
      class="flex items-center flex-grow max-w-xs"
      @click="handleClick"
    >
      <p class="w-full tag-name">
        {{ tag.name }}
      </p>
    </div>

    <div
      v-if="canEdit"
      class="flex items-center justify-end w-1/4 ml-2"
    >
      <fa
        class="text-gray-400 cursor-pointer"
        :icon="faTrash"
        @click="handleDelete"
      />
    </div>
  </div>
</template>

<script>
import { faTrash, faPen } from '@fortawesome/free-solid-svg-icons'

export default {
  props: {
    tag: {
      type: Object,
      default: () => ({})
    },
    inputClass: {
      type: [Object, Array, String],
      default: ''
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    canClick: {
      type: Boolean,
      default: false
    },
    onEdit: {
      type: Function,
      default: () => {}
    },
    onDelete: {
      type: Function,
      default: () => {}
    },
    initialSelection: {
      type: Boolean,
      default: false
    },
    onClick: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      faTrash,
      faPen,
      isSelected: false
    }
  },
  created () {
    this.isSelected = this.initialSelection
  },
  methods: {
    adjustColor (color, amount) {
      if (color) return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2))
    },
    handleClick () {
      if (!this.canEdit) {
        if (this.canClick) {
          this.isSelected = !this.isSelected
          this.onClick(this.tag)
        }
      } else {
        this.onEdit(this.tag)
      }
    },
    handleDelete () {
      this.onDelete(this.tag)
    }
  }
}
</script>

<style>
.tag{
  min-width: 150px;
}

.tag-name{
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
