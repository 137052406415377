<template>
  <crud-modal
    title="Kampagne importieren"
    action="import"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <div class="mt-4">
      <input
        type="file"
        accept="application/json"
        @change="onFileChange"
      >
    </div>
  </crud-modal>
</template>

<script>
import CrudModal from '@/components/modals/crud-modal'

import IMPORT_CAMPAIGN from '@/graphql/ImportCampaign.gql'
import LIST_CAMPAIGNS from '@/graphql/ListCampaigns.gql'

export default {
  components: { CrudModal },
  data () {
    return {
      importData: {}
    }
  },
  methods: {
    onFileChange  (e) {
      const files = e?.target.files || e.dataTransfer.files
      if (!files.length) return
      const reader = new FileReader()
      reader.onload = (e) => {
        this.importData.value = JSON.parse(e.target.result)
      }
      reader.readAsText(files[0])
    },
    async submit () {
      this.$apollo.mutate({
        mutation: IMPORT_CAMPAIGN,
        variables: {
          data: this.importData.value
        },
        refetchQueries: [{ query: LIST_CAMPAIGNS }]
      })
    }
  }
}
</script>
