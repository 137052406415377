<template>
  <crud-modal
    :title="titleByAction()"
    :action="action"
    :on-submit="submitFunctionByAction"
    :on-close="() => $emit('close')"
    :error-message="errorMessage"
  >
    <div
      v-if="action !== 'delete'"
      class="flex flex-wrap mb-6"
    >
      <div class="w-full mb-2">
        <label>Name:</label>
        <div class="flex items-stretch w-full">
          <div class="w-1/2">
            <input-text-validated
              v-model="tag.name"
              input-class="w-full"
              :rules="action !== 'delete' ? 'required':''"
            />
          </div>
          <div class="flex items-center justify-center w-1/2 ml-2 rounded">
            <a
              href="https://emojipedia.org/"
              target="_blank"
            >📙 Emoji Verzeichnis</a>
          </div>
        </div>
      </div>

      <div class="w-full mb-2">
        <label>Farbe:</label>
        <div class="flex items-stretch w-full">
          <div class="w-1/2">
            <input-text-validated
              ref="tag.color"
              v-model="tag.color"
              input-class="w-full"
              rules="hexcolor"
            />
          </div>
          <div
            class="w-1/2 ml-2 rounded"
            :style="{ background: tag.color }"
          />
        </div>
      </div>
    </div>

    <div v-else>
      Soll der Tag <b>"{{ tag.name }}"</b> wirklich gelöscht werden?
    </div>
  </crud-modal>
</template>

<script>
import LIST_TAGS from '@/graphql/ListTags.gql'
import ADD_TAG from '@/graphql/AddTag.gql'
import UPDATE_TAG from '@/graphql/UpdateTag.gql'
import DELETE_TAG from '@/graphql/DeleteTag.gql'
import InputTextValidated from '@/components/form/text-validated'
import CrudModal from '@/components/modals/crud-modal'

export default {
  components: {
    InputTextValidated,
    CrudModal
  },
  props: {
    initialTag: {
      type: Object,
      default: () => ({})
    },
    action: {
      type: String,
      default: 'create'
    }
  },
  data () {
    return {
      tag: {
        name: '',
        color: '#FFFFFF'
      },
      errorMessage: ''
    }
  },
  created () {
    if (this.action !== 'create') { this.tag = { ...this.initialTag } }
  },
  methods: {
    formatTagColor (tag) {
      if (tag.color.length <= 0) {
        tag.color = '#FFFFFF'
      } else if (tag.color.length === 4) {
        tag.color = tag.color.split('').map(c => c + c).join('').substring(1)
      }
    },

    titleByAction () {
      switch (this.action) {
        case 'create':
          return 'Tag erstellen'
        case 'update':
          return 'Tag aktualisieren'
        case 'delete':
          return 'Tag entfernen'
        default:
          break
      }
    },

    async submitFunctionByAction () {
      switch (this.action) {
        case 'create':
          await this.addTag()
          break
        case 'update':
          await this.updateTag()
          break
        case 'delete':
          await this.deleteTag()
          break
        default:
          break
      }
    },

    async addTag () {
      this.errorMessage = ''
      this.formatTagColor(this.tag)
      await this.$apollo.mutate({
        mutation: ADD_TAG,
        variables: {
          input: {
            name: this.tag.name,
            color: this.tag.color
          }
        },
        refetchQueries: [
          { query: LIST_TAGS }
        ]
      })
      this.tag = { name: '', color: '' }
    },

    async updateTag () {
      this.errorMessage = ''
      this.formatTagColor(this.tag)
      await this.$apollo.mutate({
        mutation: UPDATE_TAG,
        variables: {
          input: {
            id: this.tag.id,
            name: this.tag.name,
            color: this.tag.color
          }
        },
        refetchQueries: [
          { query: LIST_TAGS }
        ]
      })
      this.tag = { name: '', color: '' }
    },

    async deleteTag () {
      this.errorMessage = ''
      try {
        await this.$apollo.mutate({
          mutation: DELETE_TAG,
          variables: {
            tagId: this.tag.id
          },
          refetchQueries: [
            { query: LIST_TAGS }
          ]
        })
      } catch (error) {
        const isUseError = error.graphQLErrors?.[0]?.extensions?.type === 'in-use'
        if (isUseError) {
          this.errorMessage = 'Dieser Tag ist Firmen zugeordnet. Bitte entferne alle Referenzen und versuche es erneut.'
        } else {
          this.errorMessage = 'Ein unbekannter Fehler ist aufgetreten.'
        }
        throw error
      }
    }

  }
}
</script>
