<template>
  <Transition name="slide-fade">
    <alert
      v-if="isVisible"
      :type="type"
      style="width: 300px"
      class="absolute bottom-0 right-0 mb-5 mr-5"
    >
      {{ text }}
    </alert>
  </Transition>
</template>

<script>
import alert from '@/components/alert.vue'
import bus, { eventNames } from '@/lib/eventBus'

export default {
  components: { alert },
  data () {
    return {
      isVisible: false,
      text: '',
      color: '',
      hideTimeout: null
    }
  },
  mounted () {
    bus.$on(eventNames.SHOW_SNACKBAR, ({ type, text, time }) => {
      this.showSnackbar(type, text, time)
    })
  },
  methods: {
    showSnackbar (newType, newText, newTime) {
      this.type = newType
      this.text = newText
      this.isVisible = true
      this.hideTimeout = setTimeout(() => (this.isVisible = false), newTime || 5000)
    }
  },
  unmounted () {
    clearTimeout(this.hideTimeout)
  }
}
</script>

<style scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.4s;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(400px);
  opacity: 0;
}
</style>
