import Vue from 'vue'
import createAuth0Client from '@auth0/auth0-spa-js'
import bus, { eventNames } from '@/lib/eventBus'

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

let instance

export const getInstance = () => instance

export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  domain,
  audience,
  clientId,
  connection
}) => {
  if (instance) return instance

  instance = new Vue({
    data () {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        popupOpen: false,
        error: null
      }
    },
    async created () {
      this.auth0Client = await createAuth0Client({
        domain,
        audience,
        connection,
        client_id: clientId,
        redirect_uri: redirectUri,
        cacheLocation: 'localstorage',
        useRefreshTokens: true
      })

      try {
        if (
          window.location.search.includes('code=') &&
          window.location.search.includes('state=')
        ) {
          const { appState } = await this.auth0Client.handleRedirectCallback()
          onRedirectCallback(appState)
        }
      } catch (err) {
        this.error = err
      } finally {
        this.isAuthenticated = await this.auth0Client.isAuthenticated()
        this.user = prepareUser(await this.auth0Client.getUser())
        this.hasRole = (role) => this.user.roles.includes(role)
        this.loading = false

        if (this.isAuthenticated) {
          bus.$emit(eventNames.USER_LOGGED_IN)
        }
      }
    },
    methods: {
      async loginWithPopup (options) {
        this.popupOpen = true

        try {
          await this.auth0Client.loginWithPopup(options)
        } catch (err) {
          // eslint-disable-next-line
          console.error(err);
        } finally {
          this.popupOpen = false
        }

        this.user = prepareUser(await this.auth0Client.getUser())
        this.isAuthenticated = true
      },

      async handleRedirectCallback () {
        this.loading = true
        try {
          await this.auth0Client.handleRedirectCallback()
          this.user = prepareUser(await this.auth0Client.getUser())
          this.isAuthenticated = true
        } catch (err) {
          this.error = err
        } finally {
          this.loading = false
        }
      },

      loginWithRedirect (options) {
        return this.auth0Client.loginWithRedirect(options)
      },

      getIdTokenClaims (options) {
        return this.auth0Client.getIdTokenClaims(options)
      },

      getTokenSilently (options) {
        return this.auth0Client.getTokenSilently(options)
      },

      getTokenWithPopup (options) {
        return this.auth0Client.getTokenWithPopup(options)
      },

      logout (options) {
        return this.auth0Client.logout({
          returnTo: window.location.origin,
          ...options
        })
      }
    }
  })

  const prepareUser = user => {
    if (user) {
      return {
        ...user,
        ...user[process.env.VUE_APP_AUTH0_META_CLAIM] || {},
        roles: user[`${process.env.VUE_APP_AUTH0_META_CLAIM}/roles`] || []
      }
    }
    return user
  }

  return instance
}

export const Auth0Plugin = {
  install (Vue, options) {
    Vue.prototype.$auth = useAuth0(options)
  }
}
