<template>
  <div>
    <label
      v-if="label"
      class="block w-full"
    >
      {{ label }}:
    </label>
    <input
      :type="type"
      :class="inputClass"
      :value="date"
      :disabled="disabled"
      v-bind="$attrs"
      @input="$emit('input', new Date($event.target.value))"
      @blur="$emit('blur')"
    >
  </div>
</template>
<style scoped>
input {
  height: 44px;
  width: 100%;
}
</style>

<script>
export default {
  props: {
    value: {
      type: [Date, String],
      default: ''
    },
    label: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'date',
      validator: (value) => ['date', 'month'].includes(value)
    },
    inputClass: {
      type: [Object, Array, String],
      default: ''
    },
    disabled: Boolean
  },
  computed: {
    date () {
      return this.value ? this.formatDate(new Date(this.value)) : ''
    }
  },
  methods: {
    formatDate (value) {
      const charCount = this.type === 'date' ? 10 : 7
      return value.toISOString().substring(0, charCount)
    }

  }
}
</script>
