<template>
  <crud-modal
    title="Material erstellen"
    action="create"
    :on-submit="submit"
    :on-close="() => $emit('close')"
    :error-message="errorMessage"
  >
    <div class="mb-6">
      <input-text-validated
        ref="displayName"
        v-model="generalInputs.displayName"
        input-class="w-full"
        label="Anzeigename"
        rules="required"
        placeholder="Social Media Bild 1"
      />
    </div>

    <div class="mb-6">
      <input-text-validated
        ref="prettyFileName"
        v-model="generalInputs.prettyFileName"
        input-class="w-full"
        label="Neuer Dateiname"
        rules="required"
        placeholder="GWsocialbild-situations-analyse"
      />
    </div>

    <div
      v-if="landingpageTemplateId"
      class="mb-6"
    >
      <input-select-validated
        ref="type"
        v-model="generalInputs.type"
        label="Typ"
        :options="availableTypesForLandingpages"
        rules="required"
      />
    </div>

    <div
      v-if="landingpageTemplateId"
      class="mb-6"
    >
      <input
        ref="addToExistingLandingpages"
        v-model="generalInputs.addToExistingLandingpages"
        type="checkbox"
      >
      Zu bestehenden Landingpages nachträglich hinzufügen
    </div>

    <div class="mb-6">
      <input-select-validated
        ref="generationService"
        v-model="selectedGenerationService"
        label="Service"
        :options="availableGenerationServices"
        rules="required"
      />
    </div>

    <BrandEasy
      v-if="selectedGenerationService === 'BRAND_EASY'"
      v-model="brandEasyInputs"
    />

    <Moovly
      v-if="selectedGenerationService === 'MOOVLY'"
      v-model="moovlyInputs"
    />

    <Static
      v-if="selectedGenerationService === 'STATIC'"
      v-model="staticInputs"
    />
  </crud-modal>
</template>

<script>
import CrudModal from '@/components/modals/crud-modal'
import InputSelectValidated from '@/components/form/select-validated'
import InputTextValidated from '@/components/form/text-validated'
import BrandEasy from './brand-easy.vue'
import Moovly from './moovly.vue'
import Static from './static.vue'
import CREATE_BRAND_EASY_MATERIAL_TEMPLATE from '@/graphql/CreateBrandEasyMaterialTemplate.gql'
import GET_LANDINGPAGE_TEMPLATE from '@/graphql/GetLandingpageTemplate.gql'

export default {
  components: {
    CrudModal,
    InputSelectValidated,
    InputTextValidated,
    BrandEasy,
    Moovly,
    Static
  },

  props: {
    landingpageTemplateId: {
      type: String,
      default: null
    },
    campaignId: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      errorMessage: '',
      selectedGenerationService: 'BRAND_EASY',

      availableGenerationServices: [
        { value: 'BRAND_EASY', name: 'BrandEasy' },
        { value: 'MOOVLY', name: 'Moovly' },
        { value: 'STATIC', name: 'Statisch' }
      ],

      availableTypesForLandingpages: [
        { value: 'DOWNLOAD', name: 'Download-Dokument (Ratgeber / Checkliste)' },
        { value: 'BASIC_AD', name: 'Grundwerbemittel' }
      ],

      generalInputs: {
        type: this.campaignId ? 'CAMPAIGN' : 'DOWNLOAD',
        displayName: '',
        prettyFileName: '',
        addToExistingLandingpages: true
      },

      brandEasyInputs: {
        target: '',
        template: this.campaignId ? 'bot_kam_{variant}-' : 'bot_rat_{variant}-',
        bleed: '0,0,0,0'
      },

      moovlyInputs: {
        // To be implemented
      },

      staticInputs: {
        // To be implemented
      }
    }
  },

  methods: {
    async submit () {
      this.errorMessage = ''

      return ({
        BRAND_EASY: this.submitBrandEasy,
        MOOVLY: this.submitMoovly,
        STATIC: this.submitStatic
      })[this.selectedGenerationService]()
    },

    async submitBrandEasy () {
      try {
        await this.$apollo.mutate({
          mutation: CREATE_BRAND_EASY_MATERIAL_TEMPLATE,
          variables: {
            input: {
              ...this.generalInputs,
              brandEasyConfig: this.brandEasyInputs,
              landingpageTemplateId: this.landingpageTemplateId,
              campaignId: this.campaignId
            }
          },
          // Currently update / removing MaterialTemplate from cache only works for LandingpageTemplates.
          // A better way needs to be found when implementing it for campaigns.
          // Nicest way would be to use events (this.$emit('added')) but don't know how to access it from
          // outside because it's an (CRUD)-modal.
          update: (cache, { data: { materialTemplate } }) => {
            const params = {
              query: GET_LANDINGPAGE_TEMPLATE,
              variables: {
                input: {
                  landingpageTemplateId: this.landingpageTemplateId
                }
              }
            }
            const data = cache.readQuery(params)
            data.landingpageTemplate.materialTemplates.push(materialTemplate)
            cache.writeQuery({
              ...params,
              data
            })
          }
        })
      } catch (err) {
        this.errorMessage = err.graphQLErrors?.[0]?.message || err.message
        throw err
      }
    },

    async submitMoovly () {
      throw new Error('To be implemented')
    },

    async submitStatic () {
      throw new Error('To be implemented')
    }
  }
}
</script>
