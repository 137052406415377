<template>
  <crud-modal
    title="Bild bearbeiten"
    action="update"
    :on-submit="submit"
    :on-close="() => $emit('close')"
    :error-message="errorMessage"
  >
    <div class="flex flex-col w-full mb-2">
      <label>Aktuelles Bild:</label>
      <img
        :src="image.url"
        class="object-contain w-auto h-64 mb-6 border-2 rounded"
      >
    </div>

    <div class="flex flex-col w-full mb-6">
      <input-file-validated
        ref="upload"
        v-model="upload"
        label="Neues Bild"
        rules="required"
        :accept="mimeType"
      />
    </div>
  </crud-modal>
</template>

<script>
import InputFileValidated from '@/components/form/file-validated'
import CrudModal from '@/components/modals/crud-modal'

import UPDATE_COMPANY_IMAGE from '@/graphql/UpdateCompanyImage.gql'
import GET_COMPANY from '@/graphql/GetCompany.gql'

export default {
  components: {
    InputFileValidated,
    CrudModal
  },
  props: {
    companyId: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    image: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      upload: null,
      errorMessage: ''
    }
  },
  computed: {
    mimeType () {
      const mimeTypes = {
        logo: 'image/png',
        team: 'image/png, image/jpeg',
        office: 'image/png, image/jpeg',
        region: 'image/png, image/jpeg',
        lpValuationHero: 'image/png, image/jpeg'
      }

      return mimeTypes[this.type]
    }
  },
  mounted () {
    this.upload = { ...this.image }
  },
  methods: {
    async submit () {
      const { valid } = await this.$refs.upload.validate()
      if (!valid) {
        this.errorMessage = 'Ungültiges Bild!'
        throw new Error('Invalid image')
      }

      await this.$apollo.mutate({
        mutation: UPDATE_COMPANY_IMAGE,
        variables: {
          input: {
            companyId: this.companyId,
            type: this.type,
            image: {
              id: this.image.id,
              file: this.upload.file
            }
          }
        },
        refetchQueries: [
          { query: GET_COMPANY, variables: { id: this.companyId } }
        ]
      })
    }
  }
}
</script>
