<template>
  <section class="w-full">
    <h1 class="flex items-center justify-between">
      Details
      <div class="text-sm text-gray-600">
        <button
          class="font-semibold uppercase hover:text-gray-800"
          @click="showCompanyDeleteModal()"
        >
          Löschen
        </button>
        <span class="mx-3 font-bold">|</span>
        <router-link
          :to="`/companies/${company.id}/update`"
          class="inline-block font-semibold uppercase hover:text-gray-800"
        >
          Bearbeiten
        </router-link>
      </div>
    </h1>

    <div class="flex flex-wrap w-full -mx-4">
      <div class="w-full p-4 border-r-2 lg:w-1/2">
        <data-field
          label="Firmenname"
          :value="company.name"
          required
        />
        <data-field
          label="Slogan"
          :value="company.slogan"
          required
        />
        <data-field
          label="Slug"
          :value="company.slug"
          required
        />
        <data-field
          label="Länderkennung"
          :value="countryFlag"
          required
        />
        <data-field
          label="Adresse"
          :has-value="Boolean(company.address && company.address.street && company.address.zip && company.address.city && company.address.country)"
          required
        >
          <ul v-if="company.address">
            <li>{{ company.address.street }}</li>
            <li>{{ company.address.zip }} {{ company.address.city }}</li>
            <li>{{ company.address.country }}</li>
          </ul>
        </data-field>

        <data-field
          label="Name der Region"
          :value="company.regionName"
        />
        <data-field label="Provision">
          <span v-if="company.commission">{{ company.commission }} %</span>
          <em
            v-else
            class="text-gray-500"
          >nicht konfiguriert</em>
        </data-field>
        <Feature :feature-slug="featureNames.CLIENT_ADVISOR">
          <data-field
            label="Kundenberater"
            :value="company.clientAdvisor && company.clientAdvisor.name"
          />
        </Feature>
        <Feature :feature-slug="featureNames.SALES_PERSON">
          <data-field
            label="Sales-Mitarbeiter"
            :value="company.salesPerson && company.salesPerson.name"
          />
        </Feature>
        <Feature
          v-slot="{feature}"
          :feature-slug="featureNames.BILLWERK"
        >
          <div>
            <data-field
              v-if="feature.isActive"
              label="Debitoren ID"
              :value="company.debtorId"
              required
            />
            <data-field
              else
              label="Kundennummer"
              :value="company.customerNumber"
              required
            />
          </div>
        </Feature>

        <Feature
          v-slot="{feature}"
          :feature-slug="featureNames.COMPANY"
        >
          <data-field
            v-if="feature.config.isLogoEditable"
            label="Logo freigegeben"
            :value="company.isReferenceCustomer ? 'ja' : 'nein'"
          />
        </Feature>
      </div>

      <div class="w-full p-4 lg:w-1/2">
        <data-field
          class="overflow-x-scroll"
          label="Initiale Webseite vorhanden"
          :has-value="true"
          required
        >
          <em
            class="text-gray-500"
          >{{ company.hasNoInitialWebsite ? 'Nein' : 'Ja' }}</em>
        </data-field>
        <div
          v-for="link in LINK_LABELS"
          :key="link.key"
          class="flex w-full mb-1"
        >
          <data-field
            class="overflow-x-scroll"
            :label="link.name"
            :has-value="Boolean(company.links && company.links[link.key])"
            required
          >
            <a
              v-if="company.links[link.key]"
              :href="company.links[link.key]"
              target="_blank"
              rel="noopener noreferrer"
              class="underline"
            >{{ company.links[link.key] }}</a>
            <em
              v-else
              class="text-gray-500"
            >nicht konfiguriert</em>
          </data-field>
        </div>

        <data-field label="Performance">
          <a
            v-if="company.googlePerformanceReportId"
            :href="`https://datastudio.google.com/open/${company.googlePerformanceReportId}`"
            target="_blank"
            class="underline"
            rel="external noopener noreferrer"
          >Google Performance Report</a>
          <em
            v-else
            class="text-gray-500"
          >nicht konfiguriert</em>
        </data-field>

        <data-field
          label="Bcc E-Mail"
          :value="company.leadCorrespondenceBccEmail"
        />
        <div>
          <data-field
            label="Advertisement Id"
            :value="company.advertisementId"
          />
          <data-field
            label="FB Pixel Id"
            :value="company.fbPixelId"
          />
          <data-field
            label="FB Business Manager Id"
            :value="company.fbBusinessManagerId"
          />
          <data-field label="Pixel Event Source URL">
            {{ formatFbPixelEventSourceUrlType(facebook?.pixelEventSourceUrlType) }}
            <span v-if="facebook?.pixelEventSourceUrlType === 'CUSTOM'">
              ({{ facebook?.customPixelEventSourceUrl }})
            </span>
          </data-field>
          <data-field label="Pixel Access Token">
            <span v-if="company.hasFbPixelAccessToken">Token bereits hinterlegt <br></span>
            <button
              class="font-semibold hover:text-gray-800"
              @click="showFbPixelAccessTokenModal()"
            >
              <fa :icon="faPen" />
              setzen
            </button>
            (wird aus Sicherheitsgründen nicht angezeigt)
          </data-field>
        </div>

        <div
          v-for="(social, index) in company.socials"
          :key="index"
        >
          <data-field :label="social.type">
            <a
              class="w-3/5 overflow-hidden"
              :href="social.link"
              target="_blank"
            >{{ social.link }}</a>
          </data-field>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import DeleteCompanyModal from '@/components/modals/company/delete'
import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons'
import dataField from '../data-field.vue'
import { COUNTRIES, LINK_LABELS } from '@/config'
import feature from '@/mixins/feature'
import FbPixelAccessTokenModal from '@/components/modals/company/performance/fbPixelAccessToken'

export default {
  components: { dataField },
  mixins: [feature],
  props: {
    company: { type: Object, default: () => ({}) }
  },
  data () {
    return { faPen, faPlus, LINK_LABELS }
  },
  computed: {
    countryFlag () {
      return COUNTRIES[this.company.country] || ''
    },
    facebook () {
      return this.company?.performance?.facebook
    }
  },
  methods: {
    showCompanyDeleteModal () {
      this.$modal.show(
        DeleteCompanyModal,
        {
          companyId: this.company.id,
          companyName: this.company.name
        },
        { height: 'auto' }
      )
    },
    formatFbPixelEventSourceUrlType (value) {
      return ({
        COMPANY_WEBSITE: 'Hinterlegte Website',
        LANDINGPAGE_DEFAULT: 'Standard Landingpage-URL',
        CUSTOM: 'Benutzerdefiniert'
      })[value]
    },
    showFbPixelAccessTokenModal () {
      this.$modal.show(
        FbPixelAccessTokenModal,
        { companyId: this.company.id }
      )
    }
  }
}
</script>
