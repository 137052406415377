<template>
  <crud-modal
    title="Materialien neu generieren"
    action="generate"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <input-select
      v-model="selectedType"
      :options="types"
      class="mb-6"
      label="Material-Art"
    />

    <p class="mb-6">
      Es werden insgesamt <strong>{{ generationCandidates.length }} Materialien</strong> generiert,
      welche Kosten in Höhe von etwa <strong>{{ generationCosts }} €</strong> verursachen.
    </p>

    <p class="mb-6">
      Wenn nur einzelne Landingpages betroffen sind, dann bitte bei der Landingpage generieren!
    </p>

    <p
      v-if="isCurrentlyGenerating"
      class="px-3 py-3 mt-6 text-yellow-800 bg-yellow-200 bg-opacity-75 rounded-md"
    >
      <span class="font-semibold text-yellow-900">Wichtiger Hinweis:</span>
      Es werden aktuell Materialien generiert. Ein erneutes Generieren bricht die aktuellen Vorgänge ab
      und verursacht möglicherweise unnötige Kosten. Die Generierung kann bis zu einer Stunde dauern!
    </p>
  </crud-modal>
</template>

<script>
import CrudModal from '@/components/modals/crud-modal'
import InputSelect from '@/components/form/select'
import { generationCosts } from '@/lib/generationCosts'
import GENERATE_MATERIALS from '@/graphql/material/generateMaterials.gql'
import eventBus, { eventNames } from '@/lib/eventBus'

const TYPE_ALL = 'all'

const types = {
  [TYPE_ALL]: 'Alle',
  CAMPAIGN: 'Kampagnen',
  DOWNLOAD: 'Download-Dokumente',
  BASIC_AD: 'Grundwerbemittel'
}

export default {
  components: {
    CrudModal,
    InputSelect
  },

  props: {
    companyId: {
      type: String,
      default: null
    },
    landingpageId: {
      type: String,
      default: null
    },
    materials: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    selectedType: TYPE_ALL
  }),

  computed: {
    types () {
      return Object.entries(types).map(([value, name]) => ({
        disabled: value !== TYPE_ALL && !this.hasMaterialOfType(value),
        value,
        name
      }))
    },

    isCurrentlyGenerating () {
      return this.materials.some(material => (
        ['QUEUED', 'GENERATING'].includes(material.status) &&
        this.isTypeSelected(material.type)
      ))
    },

    generationCandidates () {
      return this.materials.filter(material => this.isGeneratedAndSelected(material))
    },

    generationCosts () {
      if (process.env.VUE_APP_FIX_MATERIAL_GENERATION_COSTS) {
        return process.env.VUE_APP_FIX_MATERIAL_GENERATION_COSTS
      }
      const moovlyMaterials = this.materials.filter(material => material.prettyFileName.includes('GWvideo') && this.isGeneratedAndSelected(material))
      const otherMaterials = this.materials.filter(material => !material.prettyFileName.includes('GWvideo') && this.isGeneratedAndSelected(material))
      const { min, max } = generationCosts({ brandEasyMaterials: otherMaterials.length, moovlyMaterials: moovlyMaterials.length })
      return min === max ? min : `${min} bis ${max}`
    }
  },

  created () {
    if (!this.companyId && !this.landingpageId) {
      throw new Error('Either companyId or landingpageId must be provided')
    } else if (this.companyId && this.landingpageId) {
      throw new Error('Only companyId or landingpageId can be provided')
    }
  },

  methods: {
    isTypeSelected (type) {
      return [TYPE_ALL, type].indexOf(this.selectedType) !== -1
    },

    hasMaterialOfType (type) {
      return this.materials.some(material => material.type === type)
    },

    isGeneratedAndSelected (material) {
      return material.mode === 'GENERATED' && this.isTypeSelected(material.type)
    },

    async submit () {
      try {
        await this.$apollo.mutate({
          mutation: GENERATE_MATERIALS,
          variables: {
            input: {
            // Either companyId or landingpageId is provided
              ...(this.companyId && {
                companyId: this.companyId
              }),
              ...(this.landingpageId && {
                landingpageId: this.landingpageId
              }),
              ...(this.selectedType !== TYPE_ALL && {
                types: [this.selectedType]
              })
            }
          }
        })
      } catch (error) {
        if (error.message === 'GraphQL error: Missing permission') {
          eventBus.$emit(eventNames.SHOW_SNACKBAR, { type: 'error', text: 'Keine Berechtigung zum Generieren.' })
        } else {
          eventBus.$emit(eventNames.SHOW_SNACKBAR, { type: 'error', text: 'Fehler beim Generieren.' })
        }
      }
    }
  }
}
</script>
