<template>
  <div>
    <div class="flex items-center justify-between px-10 py-8 bg-gray-100 border-b rounded-t-lg">
      <h1 class="text-xl font-semibold tracking-wider text-gray-800 uppercase">
        Immobilienfirma erstellen
      </h1>
      <router-link
        :to="`/companies`"
        class="font-semibold text-gray-600 uppercase hover:text-gray-800"
      >
        Abbrechen
      </router-link>
    </div>

    <ValidationObserver
      ref="validationObserver"
      v-slot="{ validated, invalid }"
    >
      <div
        id="wizard"
        class="p-10 pt-0"
      >
        <div
          v-if="validated && invalid"
          class="flex mt-4"
        >
          <div class="w-1/3" />
          <div class="w-2/3">
            <alert type="error">
              Bitte überprüfe die markierten Felder
            </alert>
          </div>
        </div>

        <section>
          <div class="w-1/3 description">
            <h1>Grunddaten</h1>
            <p>Alle weiteren Daten werden über den Onboarding-Wizard erfasst.</p>

            <p>
              Alle Seiten werden unter folgender Domain ausgespielt:
              <span class="font-semibold text-gray-700">
                {{ companyDomain }}</span>
            </p>
          </div>
          <div class="w-2/3">
            <div class="flex flex-wrap">
              <Feature :feature-slug="featureNames.CLIENT_ADVISOR">
                <div class="flex flex-col w-1/2 px-2 mb-6">
                  <input-select-validated
                    ref="company.clientAdvisor"
                    v-model="company.clientAdvisor"
                    :options="clientAdvisors"
                    label="Kundenberater"
                    optional
                  />
                </div>
              </Feature>
              <Feature :feature-slug="featureNames.SALES_PERSON">
                <div class="flex flex-col w-1/2 px-2 mb-6">
                  <input-select-validated
                    ref="company.salesPerson"
                    v-model="company.salesPerson"
                    :options="salesPersons"
                    label="Sales-Mitarbeiter"
                  />
                </div>
              </Feature>
            </div>
            <div class="flex flex-wrap mb-6">
              <div class="w-1/2 px-2 form-group">
                <input-text-validated
                  ref="company.slug"
                  v-model="company.slug"
                  input-class="w-full"
                  label="Eindeutiges Firmenkürzel (Slug)"
                  placeholder="max-makler-immmobilien"
                  rules="required|slug"
                />
              </div>
              <div class="w-1/2 px-2 form-group">
                <input-text-validated
                  ref="company.name"
                  v-model="company.name"
                  input-class="w-full"
                  label="Firmenname"
                  placeholder="Max Makler Immobilien"
                  rules="required"
                />
              </div>
            </div>

            <div class="flex flex-wrap mb-6">
              <div
                v-if="countriesForCompany.length > 1"
                class="w-1/2 px-2 form-group"
              >
                <input-select-validated
                  ref="company.country"
                  v-model="company.country"
                  :options="countriesForCompany"
                  label="Länderkennung"
                  rules="required"
                  @change="onCountryChange"
                />
                <p class="pl-1 text-xs text-gray-700">
                  Kann nachträglich nicht mehr geändert werden
                </p>
              </div>
              <Feature
                v-slot="{feature}"
                :feature-slug="featureNames.VARIANT"
              >
                <div
                  v-if="hasMultipleVariants(feature.config.variants)"
                  class="w-1/2 px-2 form-group"
                >
                  <input-select-validated
                    ref="company.variant"
                    v-model="company.variant"
                    :options="variants"
                    label="Variante"
                    rules="required"
                    :disabled="!company.country"
                  />
                  <p
                    v-if="!company.country"
                    class="pl-1 text-xs text-gray-700 "
                  >
                    Zuerst Land auswählen
                  </p>
                </div>
              </Feature>
            </div>

            <Feature
              :feature-slug="featureNames.BILLWERK"
              use-is-enabled
            >
              <div class="w-1/2 px-2 form-group">
                <div class="flex flex-row items-center">
                  <input
                    v-model="doCreateBillwerkOrder"
                    type="checkbox"
                    class="w-4 h-4 form-checkbox"
                    :value="doCreateBillwerkOrder"
                  >
                  <span class="ml-2 text-base normal-case">Kunde in Billwerk anlegen</span>
                </div>
              </div>
            </Feature>
          </div>
        </section>

        <Feature
          v-slot="{feature}"
          use-is-enabled
          :feature-slug="featureNames.ZOHO"
        >
          <section v-if="feature.config.showOnCreateCompany">
            <div class="w-1/3 description">
              <h1>Zoho Crm</h1>
              <p>Falls der Kunde schon in Zoho exisitiert, dann kann hier die KundenId eingetragen werden.</p>
              <p>Das kann der Fall sein, wenn zum Beispiel ein Lead gewonnen wurde und dadurch der Kunde automatisch erstellt wurde.</p>
              <p>Die KundenId ist die letzte Zahl in der URL: https://crm.zoho.eu/crm/..../tab/Accounts/<b>427825000001965371</b></p>
            </div>
            <div class="w-2/3">
              <div class="w-1/2 px-2 form-group">
                <input-text-validated
                  ref="company.externalCrmId"
                  v-model="company.externalCrmId"
                  input-class="w-full"
                  label="Zoho Account/Kunden Id"
                  placeholder="427825000001965371"
                />
              </div>
            </div>
          </section>
        </Feature>

        <Feature
          :feature-slug="featureNames.BILLWERK"
          use-is-enabled
        >
          <section v-if="doCreateBillwerkOrder && bookableSalesPlans">
            <div class="w-1/3 description">
              <h1>Billwerk</h1>
              <p>Wähle das passende Produkt aus. Falls es ein Rabatt gibt, wird dieser verwendet</p>
            </div>
            <div class="w-2/3">
              <BillwerkOrder
                change
                create
                :order-data="orderData"
                :bookable-sales-plans="bookableSalesPlans"
              />
              <div class="w-full px-3">
                <input-select-validated
                  v-for="(campaign, index) in Array.from([1, ...selectedCampaigns.filter(Boolean)])"
                  :key="campaign+index"
                  ref="company.variant"
                  v-model="selectedCampaigns[index]"
                  :options="campaigns"
                  :label="'Gratis Kampagne ' + (index + 1)"
                  :optional="true"
                />
              </div>
            </div>
          </section>
          <section v-else>
            <div class="w-1/3 description">
              <h1>Firma ohne Billwerk anlegen</h1>
              <p>Wähle das passende Produkt aus.</p>
            </div>
            <div class="w-1/3 pr-4">
              <input-select-validated
                ref="productTypeWithoutBillwerk"
                v-model="productTypeWithoutBillwerk"
                label="Produkt"
                rules="required"
                :options="availableProductTypes"
              />
            </div>
            <div
              v-if="availableComponents?.length > 0"
              class="w-1/3"
            >
              <input-select-validated
                ref="productTypeWithoutBillwerk"
                v-model="components"
                label="Komponenten"
                multiple
                :options="availableComponents"
              />
            </div>
          </section>
        </Feature>

        <div class="flex justify-center">
          <div class="pt-8">
            <button
              :disabled="isSubmitting"
              class="btn-primary"
              @click="submit()"
            >
              <span v-if="isSubmitting">Immobilienfirma wird erstellt</span>
              <span v-else>Immobilienfirma erstellen</span>
            </button>
          </div>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import InputSelectValidated from '@/components/form/select-validated'
import InputTextValidated from '@/components/form/text-validated'
import BillwerkOrder from '@/components/form/billwerk-order'
import Alert from '@/components/alert'
import CREATE_COMPANY from '@/graphql/CreateCompany.gql'
import CREATE_ORDER from '@/graphql/CreateOrder.gql'
import CHANGE_CONTRACT from '@/graphql/ChangeContract.gql'
import GET_BOOKABLE_SALES_PLANS from '@/graphql/GetBookableSalesPlans.gql'
import LIST_CAMPAIGNS from '@/graphql/ListCampaigns.gql'
import GET_CLIENT_ADVISORS from '@/graphql/GetClientAdvisors.gql'
import GET_SALES_CONTACTS from '@/graphql/GetSalesPersons.gql'
import CREATE_MATERIALS_FOR_CAMPAIGN_AND_COMPANIES from '@/graphql/CreateMaterialsForCampaignAndCompanies.gql'
import featureMixin from '@/mixins/feature'
import variantMixin from '@/mixins/variant'
import brandingMixin from '@/mixins/branding'
import { COUNTRIES_FOR_COMPANY } from '@/config'

export default {
  components: {
    InputSelectValidated,
    InputTextValidated,
    BillwerkOrder,
    Alert
  },

  mixins: [
    featureMixin,
    variantMixin,
    brandingMixin
  ],

  data () {
    return {
      components: [],
      campaigns: [],
      selectedCampaigns: [],
      variants: [],
      company: {
        slug: '',
        country: '',
        variant: null,
        clientAdvisor: '',
        salesPerson: '',
        name: '',
        externalCrmId: ''
      },
      doCreateBillwerkOrder: false,
      isSubmitting: false,
      bookableSalesPlans: [],
      orderData: {
        plan: '',
        planVariant: '',
        discount: '',
        components: [],
        contractId: '',
        customerId: '',
        startDate: null
      },
      productTypeWithoutBillwerk: null
    }
  },
  apollo: {
    clientAdvisors: {
      query: GET_CLIENT_ADVISORS,
      update (data) {
        const activeAdvisors = data.clientAdvisors?.filter((advisor) => advisor.isActive)
        return activeAdvisors?.map((advisor) => ({
          value: advisor.id,
          name: advisor.name
        }))
      }
    },

    salesPersons: {
      query: GET_SALES_CONTACTS,
      update (data) {
        const activeSalesPeople = data.salesPersons?.filter((salesPerson) => salesPerson.isActive)
        return activeSalesPeople?.map((salesPerson) => ({
          value: salesPerson.id,
          name: salesPerson.name
        }))
      }
    },

    bookableSalesPlans: {
      query: GET_BOOKABLE_SALES_PLANS,
      variables () {
        return {
          countryCode: this.company.country
        }
      }
    },

    campaigns: {
      query: LIST_CAMPAIGNS,
      update ({ campaigns }) {
        return campaigns?.map(({ id, name }) => ({
          value: id,
          name: name
        }))
      }
    }
  },

  computed: {
    availableProductTypes () {
      return this.$features.feature(this.featureNames.COMPANY).config.availableProductTypes?.map((type) => ({ value: type, name: type }))
    },
    availableComponents () {
      return this.$features.feature(this.featureNames.COMPANY).config.availableComponents?.map((type) => ({ value: type, name: type }))
    },
    companyDomain () {
      return this.company.slug
        ? `${this.company.slug}.${this.landingpageBaseDomain}`
        : `<max-makler-immobilien>.${this.landingpageBaseDomain}`
    },
    productType () {
      if (this.doCreateBillwerkOrder && this.orderData?.plan) {
        const planName = this.bookableSalesPlans?.find((plan) => plan.id === this.orderData.plan)?.name
        if (planName) {
          return planName.match(/Basic/) ? 'BASIC' : 'EXPERT'
        }
      }

      if (!this.doCreateBillwerkOrder && this.productTypeWithoutBillwerk) {
        return this.productTypeWithoutBillwerk
      }

      return null
    }
  },

  created () {
    this.variants = this.getMappedVariants()
    this.countriesForCompany = COUNTRIES_FOR_COMPANY.filter(({ value }) =>
      this.$features.feature(this.featureNames.COMPANY).config.availableCountryCodes.includes(value)
    )
    if (this.variants.length === 1) {
      this.company.variant = this.variants[0].value
    }
    if (this.countriesForCompany.length === 1) {
      this.company.country = this.countriesForCompany[0].value
    }
  },

  methods: {
    onCountryChange () {
      this.company.variant = this.variants[0].value
    },

    async submit () {
      const isValid = await this.$refs.validationObserver.validate()

      if (!isValid) {
        window.scrollTo({ top: 0 })
        return
      }

      try {
        this.isSubmitting = true
        const { data } = await this.$apollo.mutate({
          mutation: CREATE_COMPANY,
          variables: {
            input: {
              ...this.company,
              productType: this.productType,
              ...(!this.doCreateBillwerkOrder && { components: this.components })
            }
          }
        })

        for (const campaign of this.selectedCampaigns.filter(Boolean)) {
          await this.$apollo.mutate({
            mutation: CREATE_MATERIALS_FOR_CAMPAIGN_AND_COMPANIES,
            variables: {
              input: {
                companyIds: [data.company.id],
                campaignId: campaign
              }
            }
          })
        }

        if (this.doCreateBillwerkOrder) {
          if (this.orderData.contractId && this.orderData.customerId) {
            await this.$apollo.mutate({
              mutation: CHANGE_CONTRACT,
              variables: {
                input: {
                  companyId: this.company.id,
                  contractId: this.orderData.contractId,
                  customerId: this.orderData.customerId
                }
              }
            })
          } else {
            await this.$apollo.mutate({
              mutation: CREATE_ORDER,
              variables: {
                input: {
                  companyId: data.company.id,
                  companyName: this.company.name,
                  countryCode: this.company.country,
                  startDate: this.orderData.startDate,
                  planVariantId: this.orderData.planVariant,
                  discounts: this.orderData.discounts,
                  components: this.orderData.components
                }
              }
            })
          }
        }

        this.$router.push({
          path: `/companies/${data.company.id}`
        })

        window.scrollTo({ top: 0 })
        this.isSubmitting = false
      } catch (error) {
        this.setValidationErrors(error, 'company')
        window.scrollTo({ top: 0 })
        this.isSubmitting = false
      }
    }
  }
}
</script>
