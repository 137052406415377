import featureNames from '@/lib/featureNames'

export default {
  methods: {
    hasMultipleVariants (variants) {
      if (variants.length > 1) {
        return true
      }
      return false
    },
    getVariants () {
      const { config: { variants } } = this.$features.feature(featureNames.VARIANT)
      return variants
    },
    getMappedVariants () {
      const variants = this.getVariants()
      return variants.map(variant => ({ value: variant, name: variant }))
    }
  }
}
