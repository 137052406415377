<template>
  <div class="flex items-center">
    <div><slot /></div>
    <div class="flex flex-col ml-2">
      <fa
        :icon="faSortUp"
        class="self-start cursor-pointer"
        :class="{'text-teal-500':sortOption.field === field && sortOption.order === 'asc'}"
        @click="sortBy(field, 'asc')"
      />
      <fa
        :icon="faSortDown"
        class="self-end cursor-pointer"
        :class="{'text-teal-500':sortOption.field === field && sortOption.order === 'desc'}"
        @click="sortBy(field, 'desc')"
      />
    </div>
  </div>
</template>

<script>
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons'

export default {
  props: {
    sortableItems: {
      type: Array,
      required: true
    },
    field: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      faSortUp,
      faSortDown,
      sortOption: { field: '', order: '' }
    }
  },
  methods: {
    sortBy (field, order) {
      this.sortOption = { field, order }
      this.sortableItems = this.sortableItems.sort((a, b) => {
        if (a[field] < b[field]) {
          return order === 'asc' ? -1 : 1
        }
        if (a[field] > b[field]) {
          return order === 'asc' ? 1 : -1
        }
        return 0
      })
    }
  }
}
</script>
