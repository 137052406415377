<template>
  <div class="flex flex-col w-full">
    <h2 class="flex items-center justify-between w-full my-4 font-bold uppercase border-b-4">
      <slot />
      <span
        v-if="priceListName"
        class="normal-case"
      >{{ oneTime ? 'einmalig' : 'monatlich' }}
        <product-price
          :discount-percentage="discountPercentage"
          :price="price(product.prices)"
        />
      </span>
    </h2>
    <div
      v-if="!hidden"
      class="flex flex-row "
    >
      <div
        v-if="!oneTime"
        class="w-1/4 mx-3 mb-3"
      >
        <input-select-validated
          ref="data.duration"
          v-model="data.duration"
          label="Laufzeit"
          rules="required"
          :options="durations"
        />
      </div>
      <div class="w-1/4 mx-3 mb-3">
        <input-number-validated
          ref="discountPercentage"
          data-ref="discountPercentage"
          :value="discountPercentage"
          label="Rabatt %"
          rules="percent"
          optional
          @input="setDiscountPercentage"
        />
      </div>
      <div class="w-1/4 mx-3 mb-3">
        <input-number-validated
          ref="discountAbsolute"
          data-ref="discountAbsolute"
          :value="discountAbsolute"
          label="Rabatt Absolut"
          optional
          @input="setDiscountAbsolute"
        />
      </div>
      <div
        class="w-1/3 px-3 mb-3"
      >
        <label
          v-if="product.type !== CREDIT && !oneTime"
          class="block w-full"
        >
          Vorauszahlung
          <input
            v-model="data.wasPaidInAdvance"
            type="checkbox"
            class="w-4 h-4 mb-1 form-checkbox"
            :value="data.wasPaidInAdvance"
          >
        </label>
        <label class="flex flex-row">
          <span>Rabatt: </span>
          <button
            v-for="discount of prepayDiscounts"
            :key="discount"
            class="px-1 ml-2 border border-gray-400 rounded "
            @click="addDiscount(discount)"
          >
            +{{ discount }}%
          </button>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import InputSelectValidated from '@/components/form/select-validated'
import InputNumberValidated from '@/components/form/number-validated'
import { DURATIONS, DISCOUNTS, DISCOUNTS_SETUP, PRICELISTS, TRIAL_DURATIONS, PRODUCT_TYPES } from '@/config'
import ProductPrice from '@/components/company/contract/product-price.vue'
import formatNumber from '@/lib/formatNumber'

const PREPAY_DISCOUNTS = [3, 5, 7]
const equals = (a, b) => a.value === b.value && a.unit === b.unit

export default {
  components: {
    InputSelectValidated,
    InputNumberValidated,
    ProductPrice
  },
  props: {
    data: { type: Object, default: () => ({}) },
    product: { type: Object, required: true },
    priceListName: { type: String, default: '' },
    isTrial: { type: Boolean, equired: true },
    hidden: { type: Boolean, equired: true }
  },
  data () {
    return {
      discountPercentage: this.data.discountPercentage || 0,
      discountAbsolute: 0,
      prepayDiscounts: PREPAY_DISCOUNTS,
      focusedField: 'discountPercentage'
    }
  },
  computed: {
    oneTime () {
      return this.product.minDuration.length === 0
    },
    priceValue () {
      return this.price(this.product.prices)?.value
    },
    durations () {
      return this.isTrial
        ? TRIAL_DURATIONS
        : DURATIONS.filter(({ duration }) => this.product.minDuration.find((minDuration) => equals(duration, minDuration)))
    }
  },
  watch: {
    discountPercentage (value) {
      this.data.discountPercentage = value
    },
    discountAbsolute (value) {
      this.data.discountAbsolute = value
    }
  },
  created () {
    this.discounts = DISCOUNTS
    this.discountsSetup = DISCOUNTS_SETUP
    this.priceLists = PRICELISTS
    this.CREDIT = PRODUCT_TYPES.CREDIT
    document.addEventListener('focusin', this.setFocusedField)
  },
  beforeDestroy () {
    document.removeEventListener('focusin', this.setFocusedField)
  },
  methods: {
    price (prices) {
      return prices.find(({ name }) => name === this.priceListName)?.price
    },
    addDiscount (discount) {
      this.data.wasPaidInAdvance = true
      this.setDiscountPercentage(100 - (((100 - this.discountPercentage) / 100) * (1 - (discount / 100))) * 100)
    },
    setDiscountPercentage (value) {
      this.discountPercentage = value
      if (this.focusedField !== 'discountAbsolute') {
        this.discountAbsolute = formatNumber(this.priceValue - this.priceValue * (100 - value) / 100, 4)
      }
    },
    setDiscountAbsolute (value) {
      this.discountAbsolute = value
      if (this.focusedField !== 'discountPercentage') {
        this.discountPercentage = formatNumber(Math.abs((this.priceValue - value) / this.priceValue * 100 - 100), 4)
      }
    },
    setFocusedField ({ target: { parentElement } }) {
      this.focusedField = parentElement.getAttribute('data-ref')
    }
  }
}
</script>
