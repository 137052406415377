<template>
  <crud-modal
    title="User hinzufügen"
    action="create"
    :on-submit="submit"
    :on-close="() => $emit('close')"
    validation-scope="user"
    :error-message="errorMessage"
  >
    <user-form
      :company-id="companyId"
      :user="user"
    />
  </crud-modal>
</template>

<script>
import UserForm from './form'
import CrudModal from '@/components/modals/crud-modal'

import CREATE_USER from '@/graphql/CreateUser.gql'
import GET_USERS from '@/graphql/GetUsers.gql'

export default {
  components: {
    UserForm,
    CrudModal
  },
  props: {
    companyId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      user: {
        salutation: '',
        firstname: '',
        lastname: '',
        email: '',
        role: {},
        receiveNotificationEmails: {
          leadAction: true
        }
      },
      errorMessage: ''
    }
  },
  methods: {
    async submit () {
      const { id, salutation, firstname, lastname, email, role: { id: roleId }, receiveNotificationEmails } = this.user
      try {
        await this.$apollo.mutate({
          mutation: CREATE_USER,
          variables: {
            input: {
              companyId: this.companyId,
              id,
              salutation,
              firstname,
              lastname,
              email,
              role: roleId,
              receiveNotificationEmails
            }
          },
          refetchQueries: [
            { query: GET_USERS, variables: { companyId: this.companyId } }
          ]
        })
      } catch (error) {
        const emailError = error.graphQLErrors?.[0]?.extensions?.details?.[0]?.type === 'is-unique'
        if (emailError) {
          this.errorMessage = 'Ein Nutzer mit dieser E-Mail existiert bereits.'
        } else {
          this.errorMessage = 'Ein unbekannter Fehler ist aufgetreten.'
        }
        throw error
      }
    }
  }
}
</script>
