<template>
  <div class="flex flex-col w-full">
    <div class="flex flex-wrap">
      <div class="w-1/2 px-3 mb-3">
        <input-select-validated
          ref="company.billing.paymentMethod"
          v-model="company.billing.paymentMethod"
          input-class="w-full"
          label="Rechnungsart"
          :options="paymentMethods"
          rules="required"
        />
      </div>
      <div class="w-1/2 px-3 mb-3">
        <input-text-validated
          ref="company.billing.vatId"
          v-model="company.billing.vatId"
          input-class="w-full"
          label="Umsatzsteuer ID"
        />
      </div>
      <div class="w-1/2 px-3 mb-3">
        <input-text-validated
          ref="company.billing.bic"
          v-model="company.billing.bic"
          input-class="w-full"
          label="BIC"
          rules="bic"
        />
      </div>
      <div class="w-1/2 px-3 mb-3">
        <input-text-validated
          ref="company.billing.iban"
          v-model="company.billing.iban"
          input-class="w-full"
          label="IBAN"
          rules="iban"
        />
      </div>
      <div class="w-1/2 px-3 mb-3">
        <input-date-validated
          ref="company.billing.sepaValidAsOf"
          v-model="company.billing.sepaValidAsOf"
          input-class="w-full"
          label="SEPA gültig ab"
        />
      </div>
    </div>
    <h2 class="flex items-center justify-between w-full my-4 font-medium uppercase border-b-4">
      Repräsentant
    </h2>
    <div class="flex flex-wrap">
      <div class="w-full px-3 mb-3">
        <input-text-validated
          ref="company.billing.contact.companyName"
          v-model="company.billing.contact.companyName"
          label="Firmenname"
          input-class="w-full"
          rules="maxLength:100"
        />
      </div>
      <div class="w-full px-3 mb-3">
        <input-text-validated
          ref="company.billing.contact.companyNameAddition"
          v-model="company.billing.contact.companyNameAddition"
          label="Firmenname Zusatz"
          input-class="w-full"
          rules="maxLength:50"
        />
      </div>
      <div class="w-1/2 px-3 mb-3">
        <input-text-validated
          ref="company.billing.contact.firstname"
          v-model="company.billing.contact.firstname"
          label="Vorname"
          input-class="w-full"
        />
      </div>
      <div class="w-1/2 px-3 mb-3">
        <input-text-validated
          ref="company.billing.contact.lastname"
          v-model="company.billing.contact.lastname"
          label="Nachname"
          input-class="w-full"
        />
      </div>
      <div class="w-1/3 px-3 mb-3">
        <input-text-validated
          ref="company.billing.contact.phone"
          v-model="company.billing.contact.phone"
          label="Telefon"
          input-class="w-full"
          rules="phone"
        />
      </div>
      <div class="w-2/3 px-3 mb-3">
        <input-text-validated
          ref="company.billing.contact.email"
          v-model="company.billing.contact.email"
          label="E-Mail"
          input-class="w-full"
          rules="email"
        />
      </div>
    </div>
    <h2 class="flex items-center w-full my-4 font-medium uppercase border-b-4">
      <input
        v-model="company.billing.hasSeparateBillingAddress"
        type="checkbox"
        class="w-4 h-4 mx-4 border-2 border-gray-600 form-checkbox"
        :value="company.billing.hasSeparateBillingAddress || false"
      >
      Abweichende Rechnungs Adresse
    </h2>
    <div class="flex flex-wrap">
      <div class="w-full px-3 mb-3">
        <input-text-validated
          ref="company.billing.address.street"
          v-model="company.billing.address.street"
          label="Straße"
          input-class="w-full"
          :disabled="!company.billing.hasSeparateBillingAddress"
          :rules="addressRules"
        />
      </div>
      <div class="w-1/3 px-3 mb-3">
        <input-text-validated
          ref="company.billing.address.zip"
          v-model="company.billing.address.zip"
          label="PLZ"
          input-class="w-full"
          :disabled="!company.billing.hasSeparateBillingAddress"
          :rules="addressRules"
        />
      </div>
      <div class="w-2/3 px-3 mb-3">
        <input-text-validated
          ref="company.billing.address.city"
          v-model="company.billing.address.city"
          label="Stadt"
          input-class="w-full"
          :disabled="!company.billing.hasSeparateBillingAddress"
          :rules="addressRules"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InputTextValidated from '@/components/form/text-validated'
import InputSelectValidated from '@/components/form/select-validated'
import InputDateValidated from '@/components/form/date-validated'
import { PAYMENT_METHODS } from '@/config'

export default {
  components: {
    InputTextValidated,
    InputSelectValidated,
    InputDateValidated
  },
  props: {
    company: { type: Object, default: () => ({}) }
  },
  computed: {
    addressRules () {
      return this.company.billing.hasSeparateBillingAddress ? 'maxLength:50|required' : 'maxLength:50'
    }
  },
  created () {
    this.paymentMethods = PAYMENT_METHODS
  }
}
</script>
