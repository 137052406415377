<template>
  <div class="modal">
    <div class="modal-content">
      <h1
        class="w-full mb-6 text-xl font-semibold tracking-wide text-gray-800 uppercase"
      >
        Passwort zurücksetzen
      </h1>

      <alert
        v-if="errorMessage"
        type="error"
      >
        {{ errorMessage }}
      </alert>

      <p
        class="mt-4"
      >
        Dem User wird eine Mail mit einem Link zum Zurücksetzen seines Passworts geschickt.
      </p>

      <p class="mt-2">
        Beim erstmaligen Versand (bzw. bis der User sein Passwort das erste Mal geändert hat)
        wird eine Willkommens-Mail versendet. Bei jedem weiteren Mal nur noch eine
        klassische Passwort-Vergessen-Mail.
      </p>
    </div>

    <div class="modal-footer">
      <button
        class="btn-inline"
        @click="$emit('close')"
      >
        Abbrechen
      </button>
      <button
        :disabled="isSubmitting"
        class="btn-danger"
        @click="submit()"
      >
        E-Mail senden
      </button>
    </div>
  </div>
</template>

<script>
import Alert from '@/components/alert'
import SEND_USER_PASSWORD_RESET_MAIL from '@/graphql/SendUserPasswordResetMail.gql'

export default {
  components: {
    Alert
  },
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    errorMessage: null,
    isSubmitting: false
  }),
  methods: {
    async submit () {
      try {
        this.isSubmitting = true

        await this.$apollo.mutate({
          mutation: SEND_USER_PASSWORD_RESET_MAIL,
          variables: {
            userId: this.userId
          }
        })

        this.$emit('close')
        this.isSubmitting = false
      } catch (err) {
        this.errorMessage = `Senden fehlgeschlagen: ${err.message}`
        this.isSubmitting = false
      }
    }
  }
}
</script>
