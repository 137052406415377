<template>
  <div>
    <div class="flex items-center justify-between px-10 py-8 bg-gray-100 border-b rounded-t-lg">
      <h1 class="text-xl font-semibold tracking-wider text-gray-800 uppercase">
        Lead Details
      </h1>
      <router-link
        :to="`/companies/${lead.company.id}`"
        class="font-semibold text-gray-600 uppercase hover:text-gray-800"
      >
        Zurück zu {{ lead.company.name }}
      </router-link>
    </div>

    <div class="p-10">
      <div class="flex justify-between mb-32">
        <div class="w-1/2 pr-4">
          <div class="flex py-2 border-b-2">
            <div class="w-1/3 font-semibold text-gray-800 uppercase">
              E-Mail:
            </div>
            <div class="w-2/3 text-right">
              {{ lead.email }}
            </div>
          </div>
          <div class="flex py-2 border-b-2">
            <div class="w-1/3 font-semibold text-gray-800 uppercase">
              Name:
            </div>
            <div class="w-2/3 text-right">
              {{ lead.firstname }} {{ lead.lastname }}
            </div>
          </div>
          <div class="flex items-center py-2 border-b-2">
            <div class="w-1/3 font-semibold text-gray-800 uppercase">
              E-Mail bestätigt:
            </div>
            <div class="w-2/3 text-right">
              {{ formatDateTime(lead.confirmedAt) }}
            </div>
          </div>
          <div class="flex items-center py-2 border-b-2">
            <div class="w-1/3 font-semibold text-gray-800 uppercase">
              Nicht bestätigte Subscriptions:
            </div>
            <div class="flex flex-wrap items-center justify-end w-2/3">
              <div
                v-for="pendingSubscription in pendingSubscriptions"
                :key="pendingSubscription.id"
                class="px-4 py-1 mb-2 ml-2 font-semibold text-red-700 bg-red-200 rounded-full"
              >
                {{ pendingSubscription.campaign.name }}
              </div>
            </div>
          </div>
          <div class="flex items-center py-2 border-b-2">
            <div class="w-1/3 font-semibold text-gray-800 uppercase">
              Aktive Subscriptions:
            </div>
            <div class="flex flex-wrap items-center justify-end w-2/3">
              <div
                v-for="subscription in activeSubscriptions"
                :key="subscription.id"
                class="px-4 py-1 mb-2 ml-2 font-semibold text-green-700 bg-green-200 rounded-full"
              >
                {{ subscription.campaign.name }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="lead.conversions.length"
        class="flex flex-col w-full -mt-4"
      >
        <h2 class="mt-4 text-xl font-semibold text-gray-800 uppercase">
          Conversions
        </h2>
        <div class="flex py-3 text-gray-700 uppercase border-b-4">
          <div class="w-1/2 font-semibold">
            Quelle
          </div>
          <div class="w-1/2 font-semibold">
            Erstellt am
          </div>
        </div>
        <div
          v-for="(conversion, index) in lead.conversions"
          :key="conversion.id"
          :class="{ 'bg-gray-200': index % 2 }"
        >
          <div
            v-if="conversion.type === 'RETURN_CALL'"
            class="pb-3"
          >
            <div class="flex py-3">
              <div class="w-1/2">
                Rückruf ({{ conversion.origin }})
              </div>
              <div class="w-1/2">
                {{ formatDateTime(conversion.createdAt) }}
              </div>
            </div>
            <div class="flex w-full">
              <div class="w-1/2">
                <div>
                  <span class="font-semibold">Vorname:</span>
                  {{ conversion.personalData.firstname }}
                </div>
                <div>
                  <span class="font-semibold">Nachname:</span>
                  {{ conversion.personalData.lastname }}
                </div>
                <div>
                  <span class="font-semibold">E-Mail:</span>
                  {{ conversion.personalData.email }}
                </div>
                <div>
                  <span class="font-semibold">Telefon:</span>
                  {{ conversion.personalData.phone }}
                </div>
              </div>
              <div class="w-1/2">
                <div>
                  <span class="font-semibold"> Themen:</span>
                  <span
                    v-for="topic in conversion.returnCall.topics"
                    :key="topic"
                  >
                    {{ getReadableTopic(topic, conversion.returnCall.topicsOther) }}
                  </span>
                </div>
                <div class="mt-4">
                  <span class="font-semibold">Zeitraum:</span>
                  <span
                    v-for="time in conversion.returnCall.callbackTimeOfDay"
                    :key="time"
                  >
                    {{ getReadableCallbackTime(time) }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="conversion.type === 'LIFE_ANNUITY'"
            class="pb-3"
          >
            <div class="flex py-3">
              <div class="w-1/2">
                Leibrenten-Check ({{ conversion.origin }})
              </div>
              <div class="w-1/2">
                {{ formatDateTime(conversion.createdAt) }}
              </div>
            </div>
            <div class="flex w-full">
              <div class="w-1/2">
                <div>
                  <span class="font-semibold">Vorname:</span>
                  {{ conversion.personalData.firstname }}
                </div>
                <div>
                  <span class="font-semibold">Nachname:</span>
                  {{ conversion.personalData.lastname }}
                </div>
                <div>
                  <span class="font-semibold">Telefon:</span>
                  {{ conversion.personalData.phone }}
                </div>
                <div>
                  <span class="font-semibold">E-Mail:</span>
                  {{ conversion.personalData.email }}
                </div>
                <div>
                  <span class="font-semibold">Geschlecht:</span>
                  {{ conversion.personalData.gender }}
                </div>
                <div>
                  <span class="font-semibold">Geburtsjahr:</span>
                  {{ conversion.personalData.yearOfBirth }}
                </div>
                <div>
                  <span class="font-semibold">Rückrufzeit:</span>
                  <span
                    v-for="time in conversion.lifeAnnuity.callbackTimeOfDay"
                    :key="time"
                  >
                    {{ getReadableCallbackTime(time) }}
                  </span>
                </div>
              </div>
              <div class="w-1/2">
                <div>
                  <span class="font-semibold">Gebäudetyp:</span>
                  {{ conversion.lifeAnnuity.property.type }}
                </div>
                <div>
                  <span class="font-semibold">Baujahr:</span>
                  {{ conversion.lifeAnnuity.property.buildingYear }}
                </div>
                <div>
                  <span class="font-semibold">Wohnraum:</span>
                  {{ conversion.lifeAnnuity.property.livingArea }}
                </div>
                <div>
                  <span class="font-semibold">Anzahl Garagen:</span>
                  {{ conversion.lifeAnnuity.property.garageAmount }}
                </div>
                <div>
                  <span class="font-semibold">Wert:</span>
                  {{ conversion.lifeAnnuity.property.value }}
                </div>
                <div>
                  <span class="font-semibold">Postleitzahl:</span>
                  {{ conversion.lifeAnnuity.property.postcode }}
                </div>
                <div>
                  <span class="font-semibold">Selbstbewohnt:</span>
                  {{ conversion.lifeAnnuity.property.selfOccupied }}
                </div>
              </div>
              <div class="w-1/2">
                <div>
                  <span class="font-semibold">Auszahlungsweise:</span>
                  {{ conversion.lifeAnnuity.payoutType }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="conversion.type === 'PARTIAL_SALE'"
            class="pb-3"
          >
            <div class="flex py-3">
              <div class="w-1/2">
                Teilverkaufs-Check ({{ conversion.origin }})
              </div>
              <div class="w-1/2">
                {{ formatDateTime(conversion.createdAt) }}
              </div>
            </div>
            <div class="flex w-full">
              <div class="w-1/2">
                <div>
                  <span class="font-semibold">Vorname:</span>
                  {{ conversion.personalData.firstname }}
                </div>
                <div>
                  <span class="font-semibold">Nachname:</span>
                  {{ conversion.personalData.lastname }}
                </div>
                <div>
                  <span class="font-semibold">Telefon:</span>
                  {{ conversion.personalData.phone }}
                </div>
                <div>
                  <span class="font-semibold">E-Mail:</span>
                  {{ conversion.personalData.email }}
                </div>
                <div>
                  <span class="font-semibold">Rückrufzeit:</span>
                  <span
                    v-for="time in conversion.partialSale.callbackTimeOfDay"
                    :key="time"
                  >
                    {{ getReadableCallbackTime(time) }}
                  </span>
                </div>
              </div>
              <div class="w-1/2">
                <div>
                  <span class="font-semibold">Gebäudetyp:</span>
                  {{ conversion.partialSale.property.type }}
                </div>
                <div>
                  <span class="font-semibold">Baujahr:</span>
                  {{ conversion.partialSale.property.buildingYear }}
                </div>
                <div>
                  <span class="font-semibold">Wohnraum:</span>
                  {{ conversion.partialSale.property.livingArea }}
                </div>
                <div>
                  <span class="font-semibold">Anzahl Garagen:</span>
                  {{ conversion.partialSale.property.garageAmount }}
                </div>
                <div>
                  <span class="font-semibold">Wert:</span>
                  {{ conversion.partialSale.property.value }}
                </div>
                <div>
                  <span class="font-semibold">Postleitzahl:</span>
                  {{ conversion.partialSale.property.postcode }}
                </div>
                <div>
                  <span class="font-semibold">Selbstbewohnt:</span>
                  {{ conversion.partialSale.property.selfOccupied }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="conversion.type === 'PROPERTY_REQUEST'"
            class="pb-3"
          >
            <div class="flex py-3">
              <div class="w-1/2">
                Kaufgesuch ({{ conversion.origin }})
              </div>
              <div class="w-1/2">
                {{ formatDateTime(conversion.createdAt) }}
              </div>
            </div>
            <div class="flex w-full">
              <div class="w-1/2">
                <div>
                  <span class="font-semibold">Vorname:</span>
                  {{ conversion.personalData.firstname }}
                </div>
                <div>
                  <span class="font-semibold">Nachname:</span>
                  {{ conversion.personalData.lastname }}
                </div>
                <div>
                  <span class="font-semibold">Telefon:</span>
                  {{ conversion.personalData.phone }}
                </div>
                <div>
                  <span class="font-semibold">E-Mail:</span>
                  {{ conversion.personalData.email }}
                </div>
                <div>
                  <span class="font-semibold">Wohnsituation:</span>
                  {{ LivingSituationLabels[conversion.propertyRequest.currentLivingSituation] }}
                </div>
                <div>
                  <span class="font-semibold">Zeitrahmen:</span>
                  {{ AvailableStartDateLabels[conversion.propertyRequest.availableStartDate] }}
                </div>
                <div>
                  <span class="font-semibold">Finanzierung:</span>
                  {{ FinancingLabels[conversion.propertyRequest.financing] }}
                </div>
              </div>
              <div class="w-1/2">
                <div>
                  <span class="font-semibold">Mindestanzahl Zimmer:</span>
                  {{ conversion.propertyRequest.property.minNumberOfRooms }}
                </div>
                <div>
                  <span class="font-semibold">Mindestanzahl Badezimmer:</span>
                  {{ conversion.propertyRequest.property.minNumberOfBathrooms }}
                </div>
                <div>
                  <span class="font-semibold">Mindestanzahl Parkplätze:</span>
                  {{ conversion.propertyRequest.property.minNumberOfParkingSpaces }}
                </div>
                <div>
                  <span class="font-semibold">Barrierefreiheit:</span>
                  {{ AccessibilityLabels[conversion.propertyRequest.property.accessibility] }}
                </div>
                <div>
                  <span class="font-semibold">Ort:</span>
                  {{ conversion.propertyRequest.property.location.description }} (Radius: {{ conversion.propertyRequest.property.location.radius }}km)
                </div>
                <div>
                  <span class="font-semibold">Umgebung:</span>
                  {{ conversion.propertyRequest.property.surroundings.map((x) => SurroundingsLabels[x]).join(", ") }}
                </div>
                <div>
                  <span class="font-semibold">Lage:</span>
                  {{ ResidentialAreaLabels[conversion.propertyRequest.property.residentialArea] }}
                </div>
                <div>
                  <span class="font-semibold">Preis:</span>
                  {{ conversion.propertyRequest.property.minPrice }} - {{ conversion.propertyRequest.property.maxPrice }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="conversion.type === 'VALUATION'"
            class="pb-3"
          >
            <div class="flex py-3">
              <div class="w-1/2">
                Immobilienbewertung ({{ conversion.origin }})
              </div>
              <div class="w-1/2">
                {{ formatDateTime(conversion.createdAt) }}
              </div>
            </div>
            <div class="flex w-full">
              <div class="w-1/2">
                <div>
                  <span class="font-semibold">Vorname:</span>
                  {{ conversion.personalData.firstname }}
                </div>
                <div>
                  <span class="font-semibold">Nachname:</span>
                  {{ conversion.personalData.lastname }}
                </div>
                <div>
                  <span class="font-semibold">E-Mail:</span>
                  {{ conversion.personalData.email }}
                </div>
                <div>
                  <span class="font-semibold">Telefon:</span>
                  {{ conversion.personalData.phone }}
                </div>
                <div class="mb-2" />
                <div>
                  <span class="font-semibold">Grund:</span>
                  {{ getReadableReason(conversion.valuation.reason) }}
                </div>

                <div class="mb-2" />

                <div
                  v-if="hasResults(conversion.valuation)"
                  class="font-semibold"
                >
                  Kalkulierte Preise:
                </div>
                <div v-if="hasResults(conversion.valuation)">
                  <span class="font-semibold">Durschnitt:</span>
                  {{
                    formatPrice(conversion.valuation.results.property.value, conversion.valuation.results.currency)
                  }}
                </div>
                <div v-if="hasResults(conversion.valuation)">
                  <span class="font-semibold">Minimum:</span>
                  {{
                    formatPrice(conversion.valuation.results.property.valueRange.min, conversion.valuation.results.currency)
                  }}
                </div>
                <div v-if="hasResults(conversion.valuation)">
                  <span class="font-semibold">Maximum:</span>
                  {{
                    formatPrice(conversion.valuation.results.property.valueRange.max, conversion.valuation.results.currency)
                  }}
                </div>
              </div>
              <div class="w-1/2">
                <div>
                  <span class="font-semibold">Anbieter:</span>
                  {{ conversion.valuation.provider }}
                </div>
                <div>
                  <span class="font-semibold">Grundstückstyp:</span>
                  {{ getReadablePropertyType(conversion.valuation.inputs.type) }}
                </div>
                <div v-show="conversion.valuation.inputs.subType">
                  <span class="font-semibold">Sub-Typ:</span>
                  {{ getReadablePropertySubType(conversion.valuation.inputs.subType) }}
                </div>
                <div v-if="conversion.valuation.inputs.address">
                  <span class="font-semibold">Adresse:</span>
                  {{ conversion.valuation.inputs.address.street }}
                  {{ conversion.valuation.inputs.address.houseNumber }},
                  {{ conversion.valuation.inputs.address.zip }}
                  {{ conversion.valuation.inputs.address.city }}
                </div>
                <br>
                <div>
                  <span class="font-semibold">Baujahr:</span>
                  {{ conversion.valuation.inputs.buildingYear }}
                </div>
                <div v-if="conversion.valuation.inputs.type !== PropertyType.MULTI_FAMILY_HOUSE">
                  <span class="font-semibold">Anzahl der Zimmer:</span>
                  {{ conversion.valuation.inputs.numberOfRooms }}
                </div>
                <div v-if="conversion.valuation.inputs.type === PropertyType.MULTI_FAMILY_HOUSE">
                  <span class="font-semibold">Anzahl der Einheiten:</span>
                  {{ conversion.valuation.inputs.numberOfUnits }}
                </div>
                <div v-if="conversion.valuation.inputs.type === PropertyType.MULTI_FAMILY_HOUSE">
                  <span class="font-semibold">Jährliche Mieteinnahmen:</span>
                  {{ conversion.valuation.inputs.annualRentIncome }}
                </div>
                <div>
                  <span class="font-semibold">Wohnfläche:</span>
                  {{ conversion.valuation.inputs.livingArea }}
                </div>
                <div v-if="conversion.valuation.inputs.landArea">
                  <span class="font-semibold">Grundstücksfläche:</span>
                  {{ conversion.valuation.inputs.landArea }}
                </div>
                <div v-if="conversion.valuation.inputs.balconyArea">
                  <span class="font-semibold">Garten-/Balkonfläche:</span>
                  {{ conversion.valuation.inputs.balconyArea }}
                </div>
                <div v-if="conversion.valuation.inputs.quality">
                  <span class="font-semibold">Qualität:</span>
                  {{ getReadableQuality(conversion.valuation.inputs.quality) }}
                </div>
                <div v-if="conversion.valuation.inputs.condition">
                  <span class="font-semibold">Zustand:</span>
                  {{ getReadableCondition(conversion.valuation.inputs.condition) }}
                </div>
                <div
                  v-if="conversion.valuation.results"
                  class="mt-4"
                >
                  <span class="font-semibold">Bewertung:</span>
                  <a
                    :href="getValuationPreviewUrl(conversion)"
                    class="underline"
                    target="_blank"
                  >Vorschau</a>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="conversion.type === 'PLOT_VALUATION'"
            class="pb-3"
          >
            <div class="flex py-3">
              <div class="w-1/2">
                Grundstücksbewertung ({{ conversion.origin }})
              </div>
              <div class="w-1/2">
                {{ formatDateTime(conversion.createdAt) }}
              </div>
            </div>
            <div class="flex w-full">
              <div class="w-1/2">
                <div>
                  <span class="font-semibold">E-Mail:</span>
                  {{ conversion.personalData.email }}
                </div>
              </div>
              <div class="w-1/2">
                <div>
                  <span class="font-semibold">Grundstückstyp:</span>
                  {{ getReadablePlotType(conversion.plotValuation.type) }}
                </div>
                <div>
                  <span class="font-semibold">Fläche:</span>
                  {{ conversion.plotValuation.landArea }} m²
                </div>
                <div>
                  <span class="font-semibold">Erschlossen:</span>
                  {{ getReadablePlotCondition(conversion.plotValuation.conditions) }}
                </div>
                <div>
                  <span class="font-semibold">Bebauungsmöglichkeiten:</span>
                  {{ getReadablePlotDevelopment(conversion.plotValuation.development) }}
                </div>
                <div>
                  <span class="font-semibold">Erreichbarkeit:</span>
                  {{ getReadableCallbackTime(conversion.plotValuation.callbackTimeOfDay) }}
                </div>
                <div>
                  <span class="font-semibold">Adresse:</span>
                  {{ conversion.plotValuation.address.street }}
                  {{ conversion.plotValuation.address.streetNumber }},
                  {{ conversion.plotValuation.address.postcode }}
                  {{ conversion.plotValuation.address.city }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="conversion.type === 'DOWNLOAD'"
            class="pb-3"
          >
            <div class="flex py-3">
              <div class="w-1/2">
                Download ({{ conversion.origin }})
              </div>
              <div class="w-1/2">
                {{ formatDateTime(conversion.createdAt) }}
              </div>
            </div>
            <div class="flex w-full">
              <div class="w-1/2">
                <div>
                  <span class="font-semibold">E-Mail:</span>
                  {{ conversion.personalData.email }}
                </div>
              </div>
              <div class="w-1/2">
                <div>
                  <span class="font-semibold"> Quelle:</span>
                  {{ conversion?.landingpage?.slug }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex mt-24">
        <button
          class="btn-danger"
          @click="showModal('delete')"
        >
          Lead löschen
        </button>
        <button
          class="ml-4 btn-secondary"
          :disabled="!hasSubscriptions"
          @click="showModal('unsubscribe')"
        >
          Funnels stoppen
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import GET_LEAD from '@/graphql/GetLead.gql'
import DeleteModal from '@/components/modals/lead/delete'
import UnsubscribeModal from '@/components/modals/lead/unsubscribe'
import { readablePropertyType, readablePropertySubType } from '@/lib/propertyTypes'
import { readableCallbackTime, readableTopic } from '../lib/returnCallEnums'
import { readableCondition, readableQuality, readableReason } from '../lib/valuationEnums'
import { readablePlotType, readablePlotDevelopment, readablePlotCondition } from '../lib/plotValuationEnums'
import PropertyType from '@/components/leads/enums/valuation/PropertyType'
import {
  AccessibilityLabels,
  AvailableStartDateLabels,
  FinancingLabels,
  LivingSituationLabels,
  ResidentialAreaLabels,
  SurroundingsLabels
} from '@/lib/propertyRequest'

const modals = {
  delete: DeleteModal,
  unsubscribe: UnsubscribeModal
}

export default {
  apollo: {
    lead: {
      query: GET_LEAD,
      variables () {
        return {
          id: this.$route.params.leadId
        }
      }
    }
  },
  data () {
    return {
      lead: {
        landingpage: {},
        company: {},
        subscriptions: [],
        conversions: []
      },
      PropertyType,
      AccessibilityLabels,
      AvailableStartDateLabels,
      FinancingLabels,
      LivingSituationLabels,
      ResidentialAreaLabels,
      SurroundingsLabels
    }
  },
  computed: {
    activeSubscriptions () {
      return this.lead.subscriptions && this.lead.subscriptions.filter(sub => sub.subscribedAt && !sub.unsubscribedAt)
    },
    pendingSubscriptions () {
      return this.lead.subscriptions && this.lead.subscriptions.filter(sub => !sub.subscribedAt)
    },
    hasSubscriptions () {
      return this.lead.subscriptions && Boolean(this.lead.subscriptions.length)
    }
  },
  methods: {
    hasResults (valuation) {
      return valuation.provider !== 'MANUAL'
    },
    getReadablePropertyType (type) {
      return readablePropertyType[type]
    },
    getReadablePropertySubType (subType) {
      return readablePropertySubType[subType]
    },
    getReadablePlotType (type) {
      return readablePlotType[type]
    },
    getReadablePlotDevelopment (value) {
      return readablePlotDevelopment[value]
    },
    getReadableCallbackTime (value) {
      return readableCallbackTime[value]
    },
    getReadablePlotCondition (conditions) {
      return Object.entries(conditions).filter(([key, value]) => value).map(([key]) => (readablePlotCondition[key])).join(', ')
    },
    getReadableCondition (condition) {
      return readableCondition[condition]
    },
    getReadableQuality (quality) {
      return readableQuality[quality]
    },
    getReadableReason (reason) {
      return readableReason[reason] || 'Keine Angabe'
    },
    getReadableTopic (topic, topicsOther) {
      if (topic === 'OTHER' && topicsOther.length > 0) {
        return topicsOther
      }
      return readableTopic[topic]
    },
    getValuationPreviewUrl (conversion) {
      return conversion?.valuation?.pdf?.file?.cdnUrl
    },

    showModal (type) {
      if (modals[type]) {
        this.$modal.show(
          modals[type],
          {
            leadId: this.lead.id,
            companyId: this.lead.company.id
          },
          {
            height: 'auto'
          }
        )
      }
    }

  }
}
</script>
