<template>
  <div>
    <label v-if="label">
      {{ label }}:
    </label>
    <div
      class="form-group-file"
      :class="groupClass"
    >
      <file-upload
        v-model="uploaded"
        :input-id="elementId"
        :multiple="false"
        :accept="accept"
        :disabled="disabled"
        :class="{disabled}"
      >
        Datei auswählen
      </file-upload>
      <input
        :disabled="disabled"
        :value="file.name"
      >
    </div>
  </div>
</template>

<style lang="postcss">
.form-group-file span.disabled {
  @apply .bg-gray-400 .border-gray-500;
  label {
    @apply .cursor-not-allowed;
  }
}
</style>

<script>
let elementIdCounter = 0

export default {
  model: {
    event: 'change'
  },
  props: {
    label: {
      type: String,
      default: null
    },
    groupClass: {
      type: [String, Object],
      default: null
    },
    accept: {
      type: String,
      default: ''
    },
    disabled: Boolean
  },
  data () {
    return {
      uploaded: [],
      file: {
        name: '',
        content: ''
      },
      elementId: `input-file-${elementIdCounter++}`
    }
  },
  watch: {
    uploaded: {
      async handler () {
        if (this.uploaded[0]) {
          const { name, file, type, size } = this.uploaded[0]
          this.file = {
            file,
            name,
            type,
            size
          }

          this.$emit('change', this.file)
        }
      },
      deep: true
    }
  },
  methods: {
    encodeBase64 (file) {
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.readAsDataURL(file)

        reader.addEventListener('load', () => resolve(reader.result), false)
      })
    }
  }
}
</script>
