<template>
  <crud-modal
    title="Soll der User wirklich gelöscht werden?"
    action="delete"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <user-form
      :user="user"
      disabled
    />
  </crud-modal>
</template>

<script>
import UserForm from './form'
import CrudModal from '@/components/modals/crud-modal'

import DELETE_USER from '@/graphql/DeleteUser.gql'
import GET_COMPANY from '@/graphql/GetCompany.gql'

export default {
  components: {
    UserForm, CrudModal
  },
  props: {
    companyId: {
      type: String,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  methods: {
    async submit () {
      await this.$apollo.mutate({
        mutation: DELETE_USER,
        variables: {
          id: this.user.id
        },
        refetchQueries: [
          { query: GET_COMPANY, variables: { id: this.companyId } }
        ]
      })
    }
  }
}
</script>
