<template>
  <section class="w-full">
    <div
      v-if="areValuationProviderEditable"
      class="flex flex-col w-full mb-8"
    >
      <h1 class="mb-4 text-lg font-semibold text-gray-700">
        MWA Anbieter
      </h1>
      <div class="w-full">
        <h2 class="font-bold">
          In App
        </h2>
        <div class="flex w-full mb-1">
          <div
            v-for="valPro in inAppValuationProvider"
            :key="valPro.property"
            class="w-1/4 mr-1"
          >
            <input-select-validated
              v-model="valPro.provider"
              :label="getReadablePropertyType(valPro.property)"
              :options="providerOptions"
              rules="required"
              @change="handleChangeInApp"
            />
          </div>
        </div>
      </div>
      <div class="w-full mt-4">
        <h2 class="font-bold">
          Landingpages
        </h2>
        <div class="flex w-full mb-1">
          <div
            v-for="valPro in valuationProvider"
            :key="valPro.property"
            class="w-1/4 mr-1"
          >
            <input-select-validated
              v-model="valPro.provider"
              :label="getReadablePropertyType(valPro.property)"
              :options="providerOptions"
              rules="required"
              @change="handleChange"
            />
          </div>
        </div>
      </div>
    </div>

    <Feature :feature-slug="featureNames.CRM">
      <div class="flex flex-col w-full mb-8">
        <h1 class="mb-4 text-lg font-semibold text-gray-700">
          CRM
        </h1>
        <div class="flex ">
          <div class="flex flex-col w-1/2 mb-1">
            <data-field label="CRM-Name">
              {{ crmConfig.crmProvider }}
            </data-field>
            <data-field label="Synchronisation zu BOTTIMMO">
              {{ crmConfig.syncModus === 'MANUALLY' ? 'Manuell' : 'Automatisch' }}
            </data-field>
            <data-field label="Status">
              {{ crmConfig.isActive ? 'gestartet' : 'gestoppt' }}
            </data-field>
          </div>
          <div class="flex flex-col w-1/2 mb-1">
            <component
              :is="crmProviderComponent"
              v-if="crmConfig.crmProvider"
              :company-id="companyId"
            />
          </div>
        </div>
      </div>
    </Feature>

    <Feature :feature-slug="featureNames.PLOT_VALUATION">
      <div class="flex flex-col w-full mb-8">
        <h1 class="mb-4 text-lg font-semibold text-gray-700">
          Grundstücksbewertung
        </h1>
        <div class="w-full">
          <div class="flex w-full mb-1">
            <div class="w-2/5 font-semibold text-gray-600 uppercase">
              Grundstücksbewertung in Immobilienbewertungen:
            </div>
            <div class="w-3/5">
              {{ settings && settings.company.plotValuation && settings.company.plotValuation.showInPropertyValuation ? 'ja' : 'nein' }}
            </div>
          </div>
        </div>
      </div>
    </Feature>

    <div class="flex flex-col w-full">
      <h1 class="mb-4 text-lg font-semibold text-gray-700">
        Ratgeber Versand
      </h1>
      <table class="w-full h-full border-0">
        <thead>
          <tr class="text-sm font-semibold tracking-wide text-left text-gray-700 uppercase bg-gray-200">
            <th class="w-1/5 p-4">
              Ratgeber
            </th>
            <th class="w-1/5 p-4">
              Versandstatus
            </th>
          </tr>
        </thead>
        <tbody class="text-sm">
          <tr
            v-for="guide in guides"
            :key="guide.id"
            class="text-gray-500 transition cursor-pointer duration-250 group even:bg-gray-100 odd:bg-white hover:text-gray-700"
          >
            <td class="hidden w-3/12 px-4 py-2 group-hover:bg-gray-200 lg:table-cell">
              {{ guide.name }}
            </td>
            <td class="hidden w-3/12 px-4 py-2 group-hover:bg-gray-200 lg:table-cell">
              {{ postalOption(guide.slug) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
import { postalShippingOptions } from '../../lib/postalShippingEnum'
import InputSelectValidated from '@/components/form/select-validated'
import GET_COMPANY_LANDINGPAGES from '@/graphql/GetCompanyLandingpages.gql'
import GET_COMPANY_SETTINGS from '@/graphql/GetSettings.gql'
import GET_COMPANY_CRM from '@/graphql/GetCompanyCrm.gql'
import UPDATE_VALUATION_PROVIDER from '@/graphql/UpdateValuationProvider.gql'
import UPDATE_IN_APP_VALUATION_PROVIDER from '@/graphql/UpdateInAppValuationProvider.gql'

import DataField from '@/components/data-field'
import Propstack from './crm/propstack'
import Flowfact from './crm/flowfact'
import Onoffice from './crm/onoffice'

import feature from '@/mixins/feature'
import eventBus, { eventNames } from '@/lib/eventBus'

import { readablePropertyType } from '@/lib/propertyTypes'

const VALUATION_PROVIDER = [
  { name: 'PRICE_HUBBLE', value: 'PRICE_HUBBLE' },
  { name: 'FPRE', value: 'FPRE' },
  { name: 'SPRENGNETTER', value: 'SPRENGNETTER' },
  { name: 'IMMOSPARROW', value: 'IMMOSPARROW' },
  { name: 'MANUAL', value: 'MANUAL' }
]

export default {
  components: {
    InputSelectValidated, DataField, Propstack, Flowfact, Onoffice
  },
  mixins: [feature],
  props: {
    site: { type: Object, default: () => ({}) },
    companyId: { type: String, default: '' }
  },
  data () {
    return {
      valuationProvider: [],
      inAppValuationProvider: [],
      crmConfig: {}
    }
  },
  computed: {
    guides () {
      if (!this.company) return
      return this.company.landingpages.filter((lp) => lp.type === 'GUIDE')
    },
    crmProviderComponent () {
      return {
        PROPSTACK: Propstack,
        FLOWFACT: Flowfact,
        ONOFFICE: Onoffice
      }[this.crmConfig.crmProvider]
    }
  },
  async created () {
    this.providerOptions = VALUATION_PROVIDER

    // this will get the platform feature
    this.areValuationProviderEditable = this.$features.feature('valuation').config.areValuationProviderEditable

    // this will get the company feature
    const companyFeatures = await this.$features.getFeaturesForCompany(this.companyId, {
      apolloProvider: this.$apollo.provider
    })
    const providerConfig = companyFeatures[this.featureNames.VALUATION].config.enabledTypes
    const providerInAppConfig = companyFeatures[this.featureNames.VALUATION].config.enabledInAppTypes

    this.valuationProvider = Object.entries(providerConfig).map(([property, provider]) => ({ property, provider }))
    this.inAppValuationProvider = Object.entries(providerInAppConfig).map(([property, provider]) => ({
      property,
      provider
    }))
  },
  apollo: {
    company: {
      query: GET_COMPANY_LANDINGPAGES,
      variables () {
        return {
          id: this.companyId
        }
      }
    },
    crmConfig: {
      query: GET_COMPANY_CRM,
      variables () {
        return {
          companyId: this.companyId
        }
      }
    },
    settings: {
      query: GET_COMPANY_SETTINGS,
      variables () {
        return {
          companyId: this.companyId
        }
      }
    }
  },
  methods: {
    postalOption (slug) {
      if (!this.settings) return
      if (this.settings.lead.postalShipping.type === 'NONE') {
        return postalShippingOptions.NONE
      } else if (
        this.settings.lead.postalShipping.allGuides ||
        this.settings.lead.postalShipping.activeLandingpages.indexOf(slug) > -1
      ) {
        return postalShippingOptions[this.settings.lead.postalShipping.type]
      } else {
        return postalShippingOptions.NONE
      }
    },
    getReadablePropertyType (type) {
      return readablePropertyType[type]
    },
    async handleChange () {
      const updatedProvider = this.valuationProvider.reduce((res, { property, provider }) => {
        res[property] = provider
        return res
      }, {})

      try {
        await this.$apollo.mutate({
          mutation: UPDATE_VALUATION_PROVIDER,
          variables: {
            input: { companyId: this.companyId, enabledTypes: updatedProvider }
          }
        })
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { type: 'success', text: 'Anbieter wurden aktualisiert' })
        this.$features.loadAllFeaturesForCompany(this.companyId, { apolloProvider: this.$apollo.provider })
      } catch (e) {
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { type: 'error', text: e.message })
      }
    },
    async handleChangeInApp () {
      const updatedProvider = this.inAppValuationProvider.reduce((res, { property, provider }) => {
        res[property] = provider
        return res
      }, {})

      try {
        await this.$apollo.mutate({
          mutation: UPDATE_IN_APP_VALUATION_PROVIDER,
          variables: {
            input: { companyId: this.companyId, enabledTypes: updatedProvider }
          }
        })
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { type: 'success', text: 'Anbieter wurden aktualisiert' })
        this.$features.loadAllFeaturesForCompany(this.companyId, { apolloProvider: this.$apollo.provider })
      } catch (e) {
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { type: 'error', text: e.message })
      }
    }
  }
}
</script>
