<template>
  <div class="flex flex-col w-full">
    <div
      v-if="change"
      class="flex flex-wrap border-bottom border-gray-500"
    >
      <div class="w-full px-3 text-gray-600">
        <p class="mb-2">
          Verknüpfe einen bestehenden Vertrag in Billwerk
        </p>
      </div>
      <div
        class="w-1/2 px-3 mb-3"
      >
        <input-text-validated
          v-model="orderData.customerId"
          input-class="w-full"
          label="Billwerk Kunden-ID"
          :rules="hasIds || !create ? 'required' : ''"
        />
      </div>
      <div
        class="w-1/2 px-3 mb-3"
      >
        <input-text-validated
          v-model="orderData.contractId"
          input-class="w-full"
          label="Billwerk Vertrags-ID"
          :rules="hasIds || !create ? 'required' : ''"
        />
      </div>
    </div>

    <div
      v-if="change && create"
      class="text-center my-4 relative mx-4"
    >
      <hr class="border-t border-gray-300 border-2 absolute w-full top-1-2">
      <span class="bg-white relative px-2">oder</span>
    </div>

    <div
      v-if="create"
      class="flex flex-wrap"
    >
      <div class="w-full px-3 text-gray-600">
        <p class="mb-2">
          Erstelle einen neuen Vertrag in Billwerk
        </p>
      </div>
      <div
        v-if="!hasInitalName"
        class="w-full px-3 mb-3"
      >
        <input-text-validated
          ref="name"
          v-model="orderData.name"
          :disabled="hasIds"
          input-class="w-full"
          label="Firmenname"
          :rules="!hasIds ? 'required': ''"
        />
      </div>
      <div class="w-1/2 px-3 mb-3">
        <input-select-validated
          ref="plan"
          v-model="orderData.plan"
          :disabled="hasIds"
          label="Produkt"
          :rules="!hasIds ? 'required': ''"
          :options="plans"
        />
      </div>
      <div class="w-1/2 px-3 mb-3">
        <input-date-validated
          ref="startDate"
          v-model="orderData.startDate"
          :disabled="hasIds"
          label="Vertragsstart"
          :rules="!hasIds ? 'required': ''"
          :min="minDate"
        />
      </div>
      <div
        v-if="orderData.plan"
        class="w-full px-3 mb-3"
      >
        <input-select-validated
          ref="variant"
          v-model="orderData.planVariant"
          :disabled="hasIds"
          label="Produktvariante"
          :rules="!hasIds ? 'required': ''"
          :options="planVariants"
          @input="resetSelection"
        />
      </div>
      <div
        v-if="discounts.length > 0"
        class="w-full px-3 mb-3"
      >
        <input-select-validated
          ref="discount"
          v-model="orderData.discount"
          :disabled="hasIds"
          label="Rabatt"
          :options="discounts"
          @input="updateDiscount"
        />
      </div>
      <div
        v-if="components.length > 0"
        class="w-full mt-4"
      >
        <div
          v-for="_, index in additionalComponents"
          :key="index"
          class="flex px-3"
        >
          <div class="w-3/4 mb-3 mr-2">
            <input-select-validated
              ref="component"
              v-model="additionalComponents[index].component"
              label="Zusatzkomponente"
              :rules="!hasIds ? 'required': ''"
              :options="components"
              @input="() => updateComponent(index)"
            />
          </div>
          <div class="flex justify-between w-1/4 mb-3">
            <input-number-validated
              v-model="additionalComponents[index].quantity"
              label="Anzahl"
              :rules="!hasIds ? 'required': ''"
              @input="() => updateComponent(index)"
            />
            <button
              class="mt-6 ml-2 btn-danger-small"
              style="{ padding: 0.5rem }"
              @click="() => remove(index)"
            >
              <fa :icon="faTrash" />
            </button>
          </div>
        </div>
        <div class="flex justify-end w-full px-3">
          <button
            class="mt-2 btn-primary-small"
            @click="add"
          >
            <fa :icon="faPlus" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons'
import InputSelectValidated from '@/components/form/select-validated'
import InputDateValidated from '@/components/form/date-validated'
import InputTextValidated from '@/components/form/text-validated'
import InputNumberValidated from '@/components/form/number-validated.vue'

export default {
  components: {
    InputSelectValidated,
    InputDateValidated,
    InputTextValidated,
    InputNumberValidated
  },
  props: {
    change: {
      type: Boolean,
      default: false
    },
    create: {
      type: Boolean,
      default: false
    },
    orderData: {
      type: Object,
      required: true
    },
    bookableSalesPlans: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      faTrash,
      faPlus,
      additionalComponents: [{ component: '', quantity: 1 }],
      plans: []
    }
  },
  computed: {
    hasIds () {
      return Boolean(this.orderData.customerId || this.orderData.contractId)
    },
    discounts () {
      return this.bookableSalesPlans
        ?.find((plan) => plan.id === this.orderData.plan)
        ?.variants
        ?.find((variant) => variant.id === this.orderData.planVariant)
        ?.discounts
        ?.map(({ id, name }) => ({ value: id, name })) || []
    },
    components () {
      return this.bookableSalesPlans
        ?.find((plan) => plan.id === this.orderData.plan)
        ?.variants
        ?.find((variant) => variant.id === this.orderData.planVariant)
        ?.components
        ?.map(({ componentId, name, endAfterMonths }) => ({
          value: componentId,
          endAfterMonths,
          name: endAfterMonths ? name + ' (' + endAfterMonths + ' Monate)' : name
        })) || []
    },
    planVariants () {
      return this.bookableSalesPlans
        ?.find((plan) => plan.id === this.orderData.plan)
        ?.variants
        ?.map(({ id, name }) => ({ value: id, name })) || []
    },
    minDate () {
      const tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate() + 1)
      return tomorrow.toISOString().substring(0, 10)
    }
  },
  watch: {
    bookableSalesPlans () {
      this.plans = this.bookableSalesPlans?.map(({ id, name }) => ({ value: id, name }))
    }
  },
  created () {
    this.hasInitalName = !!this.orderData.name
  },
  methods: {
    add () {
      this.additionalComponents.push({ component: '', quantity: 1 })
    },
    remove (index) {
      this.additionalComponents.splice(index, 1)
      this.orderData.components.splice(index, 1)
    },
    resetSelection () {
      this.additionalComponents = [{ component: '', quantity: 1 }]
      this.orderData.components = []
      this.orderData.discount = ''
      this.orderData.discounts = []
    },
    updateDiscount (val) {
      this.orderData.discounts.push(val)
    },
    updateComponent (index) {
      const component = this.components.find(({ value }) => value === this.additionalComponents[index].component)
      this.orderData.components[index] = {
        componentId: component?.value,
        quantity: this.additionalComponents[index].quantity,
        endAfterMonths: component?.endAfterMonths
      }
    }
  }
}
</script>

<style scoped>
.top-1-2 {
  top: 50%;
}
</style>
