<template>
  <crud-modal
    title="Custom Urls editieren"
    action="update"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <form action="#">
      <label
        v-for="landingpage in landingpages"
        :key="landingpage.id"
        class="block mb-6 normal-case"
      >
        <div>
          {{ getTypedLandingpageName(landingpage) }}
        </div>
        <input-text-validated
          v-model="formData[landingpage.id]"
          type="text"
          rules="protocol|maxLength:48"
          input-class="w-full"
          class="w-full"
        /></label>
    </form>
  </crud-modal>
</template>

<script>
import CrudModal from '@/components/modals/crud-modal'
import UPDATE_CUSTOM_URLS from '@/graphql/UpdateCustomUrls.gql'
import InputTextValidated from '@/components/form/text-validated'

export default {
  components: {
    InputTextValidated,
    CrudModal
  },
  props: {
    landingpages: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      formData: {}
    }
  },
  beforeMount () {
    this.formData = this.landingpages.reduce((reduced, { id, customUrl }) => {
      reduced[id] = customUrl
      return reduced
    }, {})
  },
  methods: {
    getTypedLandingpageName (landingpage) {
      if (landingpage.type === 'GUIDE') {
        return `Ratgeber: ${landingpage.name}`
      } else if (landingpage.type === 'CHECKLIST') {
        return `Checkliste: ${landingpage.name}`
      } else {
        return landingpage.name
      }
    },

    async submit () {
      const customUrls = Object.entries(this.formData)
        .map(([id, customUrl]) => ({
          landingpageId: id,
          customUrl
        }))

      await this.$apollo.mutate({
        mutation: UPDATE_CUSTOM_URLS,
        variables: {
          input: customUrls
        }
      })
    }
  }
}
</script>
