<template>
  <crud-modal
    title="Dokument löschen"
    action="delete"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <span>Dokument wirklich löschen? Dies kann nicht rückgängig gemacht werden!</span>
  </crud-modal>
</template>
<script>
import CrudModal from '@/components/modals/crud-modal'

import DELETE_DOCUMENT_ON_CONTRACT from '@/graphql/DeleteDocumentOnContract.gql'

export default {
  components: { CrudModal },
  props: {
    contractId: { type: String, required: true },
    documentId: { type: String, required: true }
  },
  methods: {
    async submit () {
      await this.$apollo.mutate({
        mutation: DELETE_DOCUMENT_ON_CONTRACT,
        variables: {
          input: {
            contractId: this.contractId,
            documentId: this.documentId
          }
        }
      })
    }
  }
}
</script>
