<template>
  <crud-modal
    title="Produkt löschen"
    action="delete"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <span>Produkt wirklich löschen? Dies kann nicht rückgängig gemacht werden!</span>
  </crud-modal>
</template>
<script>
import CrudModal from '@/components/modals/crud-modal'

import GET_COMPANY_CONTRACTS from '@/graphql/GetCompanyContracts.gql'
import DELETE_PRODUCT from '@/graphql/DeleteProduct.gql'

export default {
  components: {
    CrudModal
  },
  props: {
    id: { type: String, required: true },
    contract: { type: Object, required: true },
    companyId: { type: String, required: true }
  },
  methods: {
    async submit () {
      await this.$apollo.mutate({
        mutation: DELETE_PRODUCT,
        variables: { id: this.id },
        refetchQueries: [{ query: GET_COMPANY_CONTRACTS, variables: { id: this.companyId } }]
      })
    }
  }
}
</script>
