<template>
  <crud-modal
    title="Repräsentant bearbeiten"
    action="update"
    :on-submit="submit"
    :on-close="close"
    valdiation-scope="ambassador"
  >
    <ambassador-form
      :ambassador="ambassador"
    />
  </crud-modal>
</template>
<script>
import AmbassadorForm from './form'
import CrudModal from '@/components/modals/crud-modal'

import UPDATE_COMPANY_AMBASSADOR from '@/graphql/UpdateAmbassador.gql'
import GET_COMPANY from '@/graphql/GetCompany.gql'

export default {
  components: {
    AmbassadorForm,
    CrudModal
  },
  props: {
    companyId: {
      type: String,
      required: true
    },
    ambassador: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isSubmitting: false
    }
  },
  methods: {
    async submit () {
      const {
        salutation,
        name,
        role,
        email,
        phone,
        mobile,
        image,
        signature,
        contactSlogan,
        contactText
      } = this.ambassador

      const input = {
        companyId: this.companyId,
        salutation,
        name,
        role,
        email,
        phone,
        mobile,
        signature: {
          text: signature.text,
          html: signature.html
        },
        contactSlogan,
        contactText
      }

      if (image.upload) {
        input.image = {
          ...(image.id && { id: image.id }),
          file: image.upload.file
        }
      }

      await this.$apollo.mutate({
        mutation: UPDATE_COMPANY_AMBASSADOR,
        variables: {
          input
        },
        refetchQueries: [
          { query: GET_COMPANY, variables: { id: this.companyId } }
        ]
      })
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
