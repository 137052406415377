<template>
  <div>
    <div class="flex items-center justify-between px-10 py-8 bg-gray-100 border-b rounded-t-lg">
      <h1 class="text-xl font-semibold tracking-wider text-gray-800 uppercase">
        Statistiken
      </h1>
    </div>
    <div class="w-full h-full">
      <iframe
        :src="$features.feature(featureNames.STATISTICS).config.datastudioUrl"
        class="iframe"
        width="100%"
        height="900"
        frameborder="0"
        allowfullscreen
      />
    </div>
  </div>
</template>

<script>
import featureMixin from '@/mixins/feature'

export default {
  mixins: [featureMixin]
}
</script>
