<template>
  <crud-modal
    title="Build bei Netlify ausführen"
    action="create"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <div class="flex flex-col w-full mb-2">
      Soll ein neuer Build bei Netlify angestoßen werden?
    </div>
  </crud-modal>
</template>

<script>
import CREATE_COMPANY_SITE_BUILD from '@/graphql/CreateCompanySiteBuild.gql'
import CrudModal from '@/components/modals/crud-modal'

export default {
  components: { CrudModal },
  props: {
    companyId: {
      type: String,
      required: true
    }
  },
  methods: {
    async submit () {
      await this.$apollo.mutate({
        mutation: CREATE_COMPANY_SITE_BUILD,
        variables: {
          companyId: this.companyId
        }
      })
    }
  }
}
</script>
