<template>
  <section class="w-full">
    <h1 class="flex items-center justify-between">
      Tags
      <div class="text-sm text-gray-600">
        <button
          class="font-semibold uppercase hover:text-gray-800"
          @click="showEditCompanyTagModal()"
        >
          Bearbeiten
        </button>
      </div>
    </h1>

    <div class="flex flex-wrap w-full">
      <div
        class="flex flex-wrap w-full"
      >
        <tag
          v-for="tag in company.tags"
          :key="tag.id"
          :tag="tag"
        />
      </div>
    </div>
  </section>
</template>

<script>
import EditCompanyTagModal from '@/components/modals/company/tags'
import Tag from '@/components/tag'

export default {
  components: { Tag },
  props: {
    company: { type: Object, default: () => ({}) }
  },
  methods: {
    showEditCompanyTagModal () {
      this.$modal.show(
        EditCompanyTagModal,
        {
          companyId: this.company.id,
          companyTags: this.company.tags
        },
        { height: 'auto' }
      )
    }
  }
}
</script>
