<template>
  <div class="h-full modal">
    <div class="modal-content">
      <h1
        class="w-full mb-6 text-xl font-semibold tracking-wide text-gray-800 uppercase"
      >
        Büro deaktivieren
      </h1>
      <div class="flex flex-col w-full items-center justify-items-center">
        <span>Dies deaktiviert mit sofortiger Wirkung alle Zugänge, Landingpages und alle E-Mail Funnels des Büros <b>{{ companyName }}</b>.</span>
        <button
          class="btn-danger mt-4 w-1/2"
          @click="deactivateCompany()"
        >
          Büro jetzt deaktivieren
        </button>
      </div>
    </div>
    <div class="modal-footer">
      <button
        class="btn-inline"
        @click="() => $emit('close')"
      >
        Abbrechen
      </button>
    </div>
  </div>
</template>

<script>
import UPDATE_COMPANY_STATE from '@/graphql/UpdateCompanyState.gql'

export default {
  props: {
    companyId: {
      type: String,
      required: true
    },
    companyName: {
      type: String,
      default: ''
    }
  },
  methods: {
    deactivateCompany (event) {
      this.$apollo.mutate({
        mutation: UPDATE_COMPANY_STATE,
        variables: {
          input: {
            companyId: this.companyId,
            event: 'DEACTIVATE'
          }
        }
      })
      this.$emit('close')
    }
  }
}
</script>
