<template>
  <div class="flex flex-col w-full">
    <div class="flex flex-wrap">
      <div class="w-full px-3 mb-3">
        <label class="block w-full">
          Vertragsart:
        </label>
        <label class="inline-flex items-center">
          <input
            v-model="contract.isTrial"
            type="checkbox"
            class="w-4 h-4 form-checkbox"
            :value="contract.isTrial"
          >
          <span class="ml-2 text-base normal-case">Testzugang</span>
        </label>
      </div>
      <div class="w-1/2 px-3 mb-3">
        <input-select-validated
          ref="contract.priceListName"
          v-model="contract.priceListName"
          label="Preisliste"
          rules="required"
          :options="priceLists"
        />
      </div>
      <div class="w-1/2 px-3 mb-3">
        <input-date-validated
          ref="contract.startDate"
          v-model="contract.startDate"
          label="Vertragsstart"
          rules="required"
        />
      </div>
      <div class="w-full px-3 mb-3">
        <input-text-validated
          ref="contract.note"
          v-model="contract.note"
          input-class="w-full"
          label="Notiz"
        />
      </div>
    </div>
    <div class="w-1/2 px-3 mb-3">
      <input-select-validated
        ref="contract.type"
        v-model="contract.type"
        label="Produkt Typ"
        rules="required"
        :options="productTypeOptions"
      />
    </div>
    <product-form
      v-if="contract.type === 'EXPERT'"
      :data="contract"
      :product="expertProduct"
      :setup-product="setupExpertProduct"
    />
    <product-form
      v-else
      :data="contract"
      :product="basicProduct"
      :setup-product="setupBasicProduct"
    />
  </div>
</template>

<script>
import InputDateValidated from '@/components/form/date-validated'
import InputSelectValidated from '@/components/form/select-validated'
import InputTextValidated from '@/components/form/text-validated'
import { DISCOUNTS, DISCOUNTS_SETUP, PRICELISTS } from '@/config'
import ProductForm from '@/components/modals/product/product-form'

const PRODUCT_TYPE_OPTIONS = [
  { value: 'EXPERT', name: 'Expert Paket' },
  { value: 'BASIC', name: 'Basic Paket' }
]

export default {
  components: {
    InputDateValidated,
    InputSelectValidated,
    InputTextValidated,
    ProductForm
  },
  props: {
    contract: { type: Object, default: () => ({}) },
    expertProduct: { type: Object, required: true },
    setupExpertProduct: { type: Object, required: true },
    basicProduct: { type: Object, required: true },
    setupBasicProduct: { type: Object, required: true }
  },
  computed: {
    productTypeOptions () {
      return PRODUCT_TYPE_OPTIONS
    }
  },
  created () {
    this.discounts = DISCOUNTS
    this.discountsSetup = DISCOUNTS_SETUP
    this.priceLists = PRICELISTS
  }
}
</script>
