<template>
  <div class="flex w-full mb-1">
    <div :class="`w-${ratio}/5 font-semibold ${textColor} uppercase`">
      {{ label }}:
    </div>
    <div :class="`w-${5-ratio}/5`">
      <div v-if="shouldRenderValue">
        <span v-if="value">{{ value }}</span>
        <em
          v-else
          class="text-gray-500"
        >{{ placeholder }}</em>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: () => undefined
    },
    ratio: {
      type: Number,
      default: 2
    },
    hasValue: Boolean,
    placeholder: {
      type: String,
      default: 'nicht konfiguriert'
    },
    required: Boolean
  },
  computed: {
    shouldRenderValue () {
      return !this.$slots.default
    },
    textColor () {
      return this.required && !(this.value || this.hasValue) ? 'text-red-600' : 'text-gray-600'
    }
  }
}
</script>
