<template>
  <crud-modal
    title="Kampagne löschen"
    action="delete"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <div class="mt-4">
      Kampagne "{{ name }}" wirklich löschen
    </div>
  </crud-modal>
</template>

<script>
import CrudModal from '@/components/modals/crud-modal'

import DELETE_CAMPAIGN from '@/graphql/DeleteCampaign.gql'
import LIST_CAMPAIGNS from '@/graphql/ListCampaigns.gql'

export default {
  components: { CrudModal },
  props: {
    name: {
      type: String,
      required: true
    },
    campaignId: {
      type: String,
      required: true
    }
  },
  methods: {
    async submit () {
      await this.$apollo.mutate({
        mutation: DELETE_CAMPAIGN,
        variables: {
          campaignId: this.campaignId
        },
        refetchQueries: [{ query: LIST_CAMPAIGNS }]
      })
      this.$router.push({ path: '/campaigns' })
    }
  }
}
</script>
