<template>
  <crud-modal
    title="Custom CSS bearbeiten (nur iFrames)"
    action="update"
    :on-submit="submit"
    :on-close="() => $emit('close')"
    :error-message="errorMessage"
  >
    <div class="flex flex-1 w-full h-full mb-2">
      <div class="w-2/3">
        <prism-editor
          v-model="stylesModel"
          language="css"
          class="h-full rounded"
        />
      </div>

      <div class="w-1/3 pl-6">
        <h3 class="font-semibold">
          Hinweise:
        </h3>
        <p class="mt-2">
          Das hier eingefügte CSS wirkt sich derzeit lediglich auf die iFrame-Variante der
          Landingpages aus.
        </p>
        <p class="mt-2">
          Unterstützt werden sämtliche CSS Selektoren sowie Tailwind spezifische
          Konstrukte wie <code class="whitespace-no-wrap token atrule">@apply .some-class;</code>
        </p>

        <h3 class="mt-4 font-semibold">
          Kontext abhängige Klassen:
        </h3>
        <ul class="mt-2 code-list">
          <li><code>.lp</code> – Landingpage</li>
          <li><code>.mwa</code> – Marktwertanalyse</li>
          <li><code>.guide, .checklist</code> – LP Typ</li>
          <li><code>.{slug}</code> – LP Slug</li>
        </ul>

        <h3 class="mt-4 font-semibold">
          Globale Klassen:
        </h3>
        <ul class="mt-2 code-list">
          <li><code>.button</code> – Default Button (wirkt sich auch auf den Primary Button aus)</li>
          <li><code>.button--primary</code> – Primary Button</li>
        </ul>

        <h3 class="mt-4 font-semibold">
          Spezielle Element-IDs:
        </h3>
        <ul class="mt-2 code-list">
          <li><code>#wrapper</code></li>
          <li><code>#form</code></li>
        </ul>
      </div>
    </div>
  </crud-modal>
</template>

<script>
import UPDATE_COMPANY_THEME from '@/graphql/UpdateCompanyTheme.gql'
import GET_COMPANY from '@/graphql/GetCompany.gql'
import DEFAULT_STYLES from './css-template'
import CrudModal from '@/components/modals/crud-modal'

const PrismEditor = async () => {
  await import('prismjs')
  await import('prismjs/themes/prism.css')
  await import('vue-prism-editor/dist/VuePrismEditor.css')
  return import('vue-prism-editor')
}

export default {
  components: {
    PrismEditor,
    CrudModal
  },
  props: {
    companyId: {
      type: String,
      required: true
    },
    styles: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    stylesModel: '',
    errorMessage: '',
    isSubmitting: false
  }),
  created () {
    this.stylesModel = this.styles?.trim().length === 0 ? DEFAULT_STYLES : this.styles
  },
  methods: {
    async submit () {
      this.errorMessage = ''
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_COMPANY_THEME,
          variables: {
            input: {
              companyId: this.companyId,
              styles: this.stylesModel
            }
          },
          refetchQueries: [
            { query: GET_COMPANY, variables: { id: this.companyId } }
          ]
        })
      } catch (error) {
        const syntaxError = error.graphQLErrors?.[0]?.extensions?.details?.[0]?.type === 'is-valid-css'

        if (syntaxError) {
          this.errorMessage = 'Das CSS ist ungültig. Bitte überprüfe die Syntax.'
        }
        throw (error)
      }
    }
  }
}
</script>

<style scoped>
.code-list code {
  /* Same color as for prism selectors: .token.selector */
  color: #690;
}
</style>
