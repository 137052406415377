<template>
  <crud-modal
    title="Bild hinzufügen"
    action="update"
    :on-submit="submit"
    :on-close="() => $emit('close')"
    :error-message="errorMessage"
  >
    <div class="flex flex-col w-full mb-6">
      <input-file-validated
        ref="upload"
        v-model="upload"
        rules="required"
        :accept="mimeType"
      />
    </div>
  </crud-modal>
</template>

<script>
import InputFileValidated from '@/components/form/file-validated'
import CrudModal from '@/components/modals/crud-modal'

import ADD_COMPANY_IMAGE from '@/graphql/AddCompanyImage.gql'
import GET_COMPANY from '@/graphql/GetCompany.gql'

export default {
  components: {
    InputFileValidated,
    CrudModal
  },
  props: {
    companyId: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      upload: null,
      errorMessage: ''
    }
  },
  computed: {
    mimeType () {
      const mimeTypes = {
        logo: 'image/png',
        team: 'image/png, image/jpeg',
        office: 'image/png, image/jpeg',
        region: 'image/png, image/jpeg',
        lpValuationHero: 'image/png, image/jpeg'
      }

      return mimeTypes[this.type]
    }
  },
  methods: {
    async submit () {
      const { valid } = await this.$refs.upload.validate()
      if (!valid) {
        this.errorMessage = 'Ungültiges Bild!'
        throw new Error('Invalid image')
      }

      await this.$apollo.mutate({
        mutation: ADD_COMPANY_IMAGE,
        variables: {
          input: {
            companyId: this.companyId,
            type: this.type,
            image: {
              file: this.upload.file
            }
          }
        },
        refetchQueries: [
          { query: GET_COMPANY, variables: { id: this.companyId } }
        ]
      })
    }
  }
}
</script>
