<template>
  <crud-modal
    title="Performance Daten bearbeiten"
    action="update"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <performance-form
      :facebook="facebook"
      :google="google"
    />
  </crud-modal>
</template>
<script>
import PerformanceForm from './form'
import CrudModal from '@/components/modals/crud-modal'

import UPDATE_COMPANY_PERFORMANCE_DATA from '@/graphql/UpdateCompanyPerformanceData.gql'

export default {
  components: {
    PerformanceForm,
    CrudModal
  },
  props: {
    companyId: { type: String, required: true },
    facebook: { type: Object, required: true },
    google: { type: Object, required: true }
  },
  methods: {
    async submit () {
      await this.$apollo.mutate({
        mutation: UPDATE_COMPANY_PERFORMANCE_DATA,
        variables: {
          input: {
            companyId: this.companyId,
            facebook: {
              link: this.facebook.link,
              budget: this.facebook.budget,
              distributeBudget: this.facebook.distributeBudget,
              onboarding: {
                pages: this.facebook.onboarding.pages,
                adAccounts: this.facebook.onboarding.adAccounts,
                leadgenTos: this.facebook.onboarding.leadgenTos
              },
              zip: this.facebook.zip
            },
            google: {
              budget: this.google.budget,
              distributeBudget: this.google.distributeBudget,
              zip: this.google.zip,
              email: this.google.email,
              targetUrl: this.google.targetUrl
            }
          }
        }
      })
    }
  }
}
</script>
