const URL = window.URL || window.webkitURL

export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})

export async function dataUrlToFile (dataUrl, fileName, type = 'image/png') {
  const res = await fetch(dataUrl)
  const blob = await res.blob()
  return new File([blob], fileName, { type })
}

export function imageFromFile (file) {
  const objectUrl = URL.createObjectURL(file)
  return new Promise((resolve) => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.src = objectUrl
  })
}
