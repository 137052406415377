<template>
  <span>
    <span :class="{'line-through': discountPercentage, 'text-gray-600': discountPercentage}">{{ price.value }}</span>
    <span v-if="discountPercentage">  {{ discountedPrice }} </span> {{ price.currency }} </span>
</template>

<script>
import formatNumber from '@/lib/formatNumber'

export default {
  props: {
    price: {
      type: Object,
      required: true
    },
    discountPercentage: {
      type: [Number, String],
      default: 0
    }
  },
  computed: {
    discountedPrice () {
      return formatNumber(this.discountPercentage && this.price.value * (1 - this.discountPercentage / 100), 2)
    }
  }
}
</script>
