<template>
  <crud-modal
    title="User bearbeiten"
    action="update"
    :on-submit="submit"
    :on-close="() => $emit('close')"
    validation-scope="user"
  >
    <user-form
      :company-id="companyId"
      :user="user"
      :disabled="['email']"
      :settings="userWithNotificationSettings"
    />
  </crud-modal>
</template>

<script>
import UserForm from './form'
import CrudModal from '@/components/modals/crud-modal'

import UPDATE_USER from '@/graphql/UpdateUser.gql'
import GET_USERS from '@/graphql/GetUsers.gql'
import GET_USER_WITH_NOTIFICATION_SETTINGS from '@/graphql/GetUserWithNotificationSettings.gql'

export default {
  components: {
    UserForm,
    CrudModal
  },
  props: {
    companyId: {
      type: String,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return { userWithNotificationSettings: {} }
  },
  methods: {
    async submit () {
      const { id, salutation, firstname, lastname, role: { id: roleId }, receiveNotificationEmails: { __typename, ...notifications } } = this.user
      await this.$apollo.mutate({
        mutation: UPDATE_USER,
        variables: {
          input: {
            id,
            salutation,
            firstname,
            lastname,
            role: roleId,
            receiveNotificationEmails: notifications
          }
        },
        refetchQueries: [
          { query: GET_USERS, variables: { companyId: this.companyId } }
        ]
      })
    }
  },
  apollo: {
    userWithNotificationSettings: {
      query: GET_USER_WITH_NOTIFICATION_SETTINGS,
      variables () {
        return {
          id: this.user.id
        }
      }
    }
  }
}
</script>
