<template>
  <div class="relative text-gray-800 border rounded hover:shadow">
    <div class="px-4 py-2 bg-gray-200 border-b rounded-t group">
      <h4 class="font-semibold">
        {{ landingpage.name }}
        <span v-if="landingpage.status === 'INACTIVE'"> - DEAKTIVIERT </span>
      </h4>
      <span
        v-if="landingpage.customUrl"
        class="text-sm font-semibold text-gray-600"
      >
        {{ landingpage.customUrl }}
      </span>
      <em
        v-else
        class="text-sm text-gray-500"
      >
        Keine Custom URL hinterlegt
      </em>

      <div class="absolute top-0 right-0 flex items-center p-1 space-x-1 text-gray-400 group-hover:text-gray-900 set-y-0">
        <button
          v-if="hasRole(ROLES.SUPER_ADMIN)"
          title="Landingpage löschen"
          class="btn-icon btn-icon--delete"
          @click="showLandingpageDeleteModal(landingpage)"
        >
          <fa
            :icon="faTrash"
            fixed-width
          />
        </button>

        <button
          :title="`Landingpage ${landingpage.status === 'ACTIVE' ? 'de' : ''}aktivieren`"
          :class="`btn-icon ${landingpage.status === 'ACTIVE' ? 'btn-icon--delete': ''}`"
          @click="showLandingpageStatusModal(landingpage)"
        >
          <fa
            :icon="faPowerOff"
            fixed-width
          />
        </button>

        <a
          v-if="landingpage.status === 'ACTIVE'"
          :href="landingpage.originalUrl"
          target="_blank"
          title="Landingpage öffnen"
          class="btn-icon"
        >
          <fa
            :icon="faExternalLinkAlt"
            fixed-width
          />
        </a>

        <button
          v-if="landingpage.status === 'ACTIVE'"
          title="Einbindungs-Code"
          class="btn-icon"
          @click="showLandingpageSnippetModal(landingpage)"
        >
          <fa
            :icon="faCode"
            fixed-width
          />
        </button>

        <button
          v-if="hasRole(ROLES.SUPER_ADMIN) && landingpage.status === 'ACTIVE'"
          title="Materialien generieren"
          class="btn-icon"
          :disabled="hasNoMaterials"
          @click="showGenerateModal()"
        >
          <fa
            :icon="faSync"
            fixed-width
          />
        </button>
      </div>
    </div>

    <div
      v-if="hasNoMaterials || landingpage.status === 'INACTIVE'"
      class="p-4 text-gray-500"
    >
      <em>Diese Landingpage hat keine Materialien</em>
    </div>

    <ul
      v-else
      class="py-2"
    >
      <template v-if="downloads.length">
        <h4 class="pl-4 mt-2 text-xs text-gray-600 uppercase">
          Downloads
        </h4>
        <material-list-item
          v-for="material in downloads"
          :key="material.id"
          :material="material"
        />
      </template>

      <template v-if="basicAds.length">
        <h4 class="pl-4 mt-2 text-xs text-gray-600 uppercase">
          Grundausstattung Werbemittel
        </h4>
        <material-list-item
          v-for="material in basicAds"
          :key="material.id"
          :material="material"
        />
      </template>

      <template>
        <div
          v-for="[campaignId, campaignMaterials] in materialsByCampaign"
          :key="campaignId"
        >
          <h4 class="pl-4 mt-2 text-xs text-gray-600 uppercase">
            {{ campaigns.find(({id}) => id === campaignId).name }}
          </h4>
          <ul>
            <material-list-item
              v-for="material in campaignMaterials"
              :key="material.id"
              :material="material"
            />
          </ul>
        </div>
      </template>
    </ul>
  </div>
</template>

<script>
import {
  faSync,
  faCode,
  faTrash,
  faExternalLinkAlt,
  faPowerOff,
  faPhone
} from '@fortawesome/free-solid-svg-icons'
import DeleteLandingpageModal from '@/components/modals/landingpage/delete'
import UpdateStatusLandingpageModal from '@/components/modals/landingpage/status'
import LandingpageSnippetModal from '@/components/modals/landingpage/snippet'
import GenerateModal from '@/components/modals/landingpage/generate'
import materialListItem from './material-list-item'
import groupBy from '@/lib/groupBy'
import role from '@/mixins/role'

export default {
  components: { materialListItem },

  mixins: [role],

  props: {
    landingpage: { type: Object, default: () => ({}) },
    company: { type: Object, default: () => ({}) },
    materials: { type: Array, default: () => [] },
    campaigns: { type: Array, default: () => [] }
  },
  data () {
    return {
      faSync,
      faCode,
      faTrash,
      faExternalLinkAlt,
      faPowerOff,
      faPhone
    }
  },

  computed: {
    downloads () {
      return this.materials.filter(material => material.type === 'DOWNLOAD')
    },

    basicAds () {
      return this.materials.filter(material => material.type === 'BASIC_AD')
    },

    hasNoMaterials () {
      return this.materials.length === 0
    },

    materialsByCampaign () {
      const materials = this.materials.filter(({ campaignId }) => campaignId)
      return Object.entries(groupBy(materials, 'campaignId'))
    }
  },

  methods: {
    showGenerateModal () {
      this.$modal.show(GenerateModal, {
        landingpageId: this.landingpage.id,
        materials: this.materials
      })
    },

    showLandingpageSnippetModal (landingpage) {
      this.$modal.show(
        LandingpageSnippetModal,
        {
          companyId: this.company.id,
          companySlug: this.company.slug,
          landingpageSlug: landingpage.slug
        },
        {
          height: 'auto'
        }
      )
    },

    showLandingpageDeleteModal (landingpage) {
      this.$modal.show(
        DeleteLandingpageModal,
        {
          companyId: this.company.id,
          landingpageId: landingpage.id,
          slug: landingpage.slug
        },
        {
          height: 'auto'
        }
      )
    },

    showLandingpageStatusModal (landingpage) {
      this.$modal.show(
        UpdateStatusLandingpageModal,
        {
          landingpage,
          companyId: this.company.id
        }
      )
    }
  }
}
</script>
