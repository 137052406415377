<template>
  <div>
    <div
      v-for="(social, index) in socials"
      :key="social.type + index"
      class="py-2 border-b w-full flex justify-between"
    >
      <input-select-validated
        v-model="social.type"
        class="w-3/12"
        :options="socialTypes"
        rules="required"
      />

      <input-text-validated
        v-model="social.link"
        class="w-9/12 px-2"
        input-class="w-full"
        rules="protocol|required"
      />

      <button
        class="btn-inline-small w-1/12 "
        @click="removeFromList(index)"
      >
        <fa :icon="faTrash" />
      </button>
    </div>
    <div class="w-full mt-4 flex justify-end">
      <button
        class="btn-inline-small w-1/12 "
        @click="addToList"
      >
        <fa :icon="faPlus" />
      </button>
    </div>
  </div>
</template>

<script>
import { faPen, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import InputSelectValidated from '@/components/form/select-validated'
import InputTextValidated from '@/components/form/text-validated'
import { SOCIAL_TYPES } from '@/config'

export default {
  components: { InputSelectValidated, InputTextValidated },
  props: {
    socials: { type: Array, default: () => ([]) }
  },
  data () {
    return {
      faPen,
      faPlus,
      faTrash,
      socialTypes: SOCIAL_TYPES
    }
  },
  methods: {
    addToList () {
      this.socials.push({ type: '', link: '' })
    },
    removeFromList (index) {
      this.socials.splice(index, 1)
    }
  }
}
</script>
