export const readablePlotDevelopment = {
  COVERED_AREA: 'aktuell bebaut',
  NOW: 'kurzfristig bebaubar',
  NEAR_FUTURE: 'zukünftig bebaubar',
  RESTRICTED: 'eingeschränkt bebaubar',
  NONE: 'nicht bebaubar',
  UNKNOWN: 'weiß nicht'
}

export const readablePlotType = {
  CONSTRUCTION: 'Baugrundstück',
  EXPECTED: 'Bauerwartungsland',
  BUSINESS: 'Gewerbegrundstück'
}

export const readablePlotCondition = {
  power: 'Stromanschluss',
  road: 'Straßenanschluss',
  water: 'Wasseranschluss'
}
