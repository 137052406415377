<template>
  <div v-if="company">
    <div class="flex items-center justify-between px-10 py-8 bg-gray-100 border-b rounded-t-lg">
      <h1 class="text-xl font-semibold tracking-wider text-gray-800 uppercase">
        Immobilienfirma
        <span
          v-if="company.name"
          v-once
          class="text-gray-600"
        >{{ company.name }}</span>
        bearbeiten
      </h1>
      <router-link
        :to="`/companies/${company.id}`"
        class="font-semibold text-gray-600 uppercase hover:text-gray-800"
      >
        Abbrechen
      </router-link>
    </div>

    <ValidationObserver
      ref="validationObserver"
      v-slot="{ validated, invalid }"
    >
      <div
        id="wizard"
        class="p-10 pt-0"
      >
        <div
          v-if="validated && invalid"
          class="flex mt-4"
        >
          <div class="w-1/3" />
          <div class="w-2/3">
            <alert>Bitte überprüfe die markierten Felder</alert>
          </div>
        </div>

        <section>
          <div class="w-1/3 description">
            <h1>Immobilienfirma</h1>
            <p>Stammdaten zur Immobilienfirma</p>
          </div>
          <div class="w-2/3">
            <div class="flex flex-col w-full mb-6">
              <input-text-validated
                ref="company.name"
                v-model="company.name"
                input-class="w-full"
                label="Firmenname"
                placeholder="Max Makler Immobilien GmbH"
              />
            </div>

            <div class="flex flex-col w-full mb-6">
              <input-text-validated
                ref="company.address.street"
                v-model="company.address.street"
                input-class="w-full"
                label="Straße"
                placeholder="Hauptstraße 1"
                optional
              />
            </div>

            <div class="flex flex-wrap mb-6 -mx-2">
              <div class="w-1/2 px-2 form-group">
                <input-text-validated
                  ref="company.address.zip"
                  v-model="company.address.zip"
                  input-class="w-full"
                  label="PLZ"
                  placeholder="78464"
                  rules="numeric"
                  optional
                />
              </div>

              <div class="w-1/2 px-2 form-group">
                <input-text-validated
                  ref="company.address.city"
                  v-model="company.address.city"
                  input-class="w-full"
                  label="Ort"
                  placeholder="Konstanz"
                  optional
                />
              </div>
            </div>

            <div class="w-full px-2 mb-6 -mx-2 form-group">
              <input-select-validated
                ref="company.address.country"
                v-model="company.address.country"
                :options="countriesForAddress"
                class="w-1/2"
                label="Land"
                optional
              />
            </div>

            <div class="w-full mb-6 form-group">
              <input-text-validated
                ref="company.regionName"
                v-model="company.regionName"
                input-class="w-full"
                label="Name der Region"
                placeholder="Bodenseeregion"
                rules="maxLength:100"
              />
            </div>

            <div class="w-full mb-6 form-group">
              <input-text-validated
                ref="company.slogan"
                v-model="company.slogan"
                input-class="w-full"
                label="Slogan"
                placeholder="Die Immobilienexperten in Ihrer Umgebung"
              />
            </div>

            <div class="flex flex-col w-full mb-6">
              <input-text-validated
                ref="company.commission"
                v-model="company.commission"
                input-class="w-1/2"
                label="Provision in %"
                placeholder="5 oder 4,2"
                rules="decimal"
              />
            </div>
            <Feature :feature-slug="featureNames.CLIENT_ADVISOR">
              <div class="flex flex-col w-full mb-6">
                <input-select-validated
                  ref="clientAdvisorId"
                  v-model="clientAdvisorId"
                  :options="clientAdvisors"
                  class="w-1/2"
                  label="Kundenberater"
                  optional
                />
              </div>
            </Feature>

            <Feature :feature-slug="featureNames.SALES_PERSON">
              <div class="flex flex-col w-full mb-6">
                <input-select-validated
                  ref="salesPersonId"
                  v-model="salesPersonId"
                  :options="salesPersons"
                  class="w-1/2"
                  label="Sales-Mitarbeiter"
                  optional
                />
              </div>
            </Feature>

            <div class="flex flex-col w-full mb-6">
              <input-text-validated
                ref="company.leadCorrespondenceBccEmail"
                v-model="company.leadCorrespondenceBccEmail"
                input-class="w-full"
                label="BCC E-Mail (zur Dokumentation auf Kundenseite)"
                placeholder="max-makler@max-makler.immobilien"
                rule="email"
                optional
              />
            </div>
          </div>
        </section>
        <section>
          <div class="w-1/3 description">
            <h1>Metadaten</h1>
            <p>Interne Kundendaten</p>

            <p>
              Alle Seiten werden unter folgender Domain ausgespielt:
              <span class="font-semibold text-gray-700">
                {{ companyDomain }}
              </span>
            </p>
          </div>
          <div class="w-2/3">
            <div class="flex flex-col w-full mb-6">
              <Feature
                v-slot="{feature}"
                :feature-slug="featureNames.BILLWERK"
              >
                <input-text-validated
                  v-if="feature.isActive"
                  ref="company.debtorId"
                  v-model="company.debtorId"
                  input-class="w-full"
                  label="Debitoren ID"
                  autocomplete="off"
                  disabled
                />
                <input-text-validated
                  v-else
                  ref="company.customerNumber"
                  v-model="company.customerNumber"
                  input-class="w-full"
                  label="Kundennummer"
                  autocomplete="off"
                  rules="required|numeric"
                />
              </Feature>
            </div>

            <div class="flex flex-wrap mb-6 -mx-2">
              <div class="w-1/2 px-2 form-group">
                <input-text
                  v-model="company.slug"
                  input-class="w-full"
                  label="Eindeutiges Firmenkürzel"
                  disabled
                />
              </div>
              <div class="w-1/2 px-2 form-group">
                <input-select-validated
                  ref="company.country"
                  v-model="company.country"
                  :options="countriesForCompany"
                  label="Länderkennung"
                  disabled
                />
              </div>
            </div>
            <div class="flex flex-wrap mb-6 -mx-2">
              <div class="w-full px-2 form-group">
                <label class="block w-full">Google Drive Folder:</label>
                <div class="flex items-start">
                  <div>
                    <input-text
                      v-model.trim="company.googleDriveFolderId"
                      input-class="w-full"
                    />
                    <p class="text-xs text-gray-700">
                      Wenn hier ein Freigabelink eingetragen wird, wird
                      automatisch die ID extrahiert und nach dem Speichern
                      stattdessen angezeigt.
                    </p>
                  </div>
                  <a
                    target="_blank"
                    class="ml-4 btn-secondary"
                    rel="external noopener noreferrer"
                    :href="previewLinkGoogleDrive"
                    :disabled="!previewLinkGoogleDrive"
                  >Vorschau</a>
                </div>
              </div>
            </div>
            <div class="flex flex-wrap mb-6 -mx-2">
              <div class="w-full px-2 form-group">
                <label class="block w-full">Google Performance Report:</label>
                <div class="flex items-start">
                  <div>
                    <input-text
                      v-model.trim="company.googlePerformanceReportId"
                      input-class="w-full"
                    />
                    <p class="text-xs text-gray-700">
                      Wenn hier ein Freigabelink eingetragen wird, wird
                      automatisch die ID extrahiert und nach dem Speichern
                      stattdessen angezeigt.
                    </p>
                  </div>
                  <a
                    target="_blank"
                    class="ml-4 btn-secondary"
                    rel="external noopener noreferrer"
                    :href="previewLinkGooglePerformanceReport"
                    :disabled="!previewLinkGooglePerformanceReport"
                  >Vorschau</a>
                </div>
              </div>
              <div class="flex flex-wrap items-start w-full mt-4">
                <div class="w-full mx-2 mb-6">
                  <input-text-validated
                    ref="company.advertisementId"
                    v-model="company.advertisementId"
                    input-class="w-full"
                    label="Advertisement Id"
                    optional
                  />
                </div>
                <div class="w-1/2 px-2 mb-6">
                  <input-text-validated
                    ref="company.fbPixelId"
                    v-model="company.fbPixelId"
                    input-class="w-full"
                    label="Facebook Pixel Id"
                    optional
                  />
                </div>
                <div class="w-1/2 px-2 mb-6">
                  <input-text-validated
                    ref="company.fbBusinessManagerId"
                    v-model="company.fbBusinessManagerId"
                    input-class="w-full"
                    label="Facebook Business Manager Id"
                    optional
                  />
                </div>
                <div class="w-1/2 px-3 mb-3">
                  <input-select-validated
                    ref="company.performance.facebook.pixelEventSourceUrlType"
                    v-model="company.performance.facebook.pixelEventSourceUrlType"
                    input-class="w-full"
                    label="Pixel Event Source URL"
                    :options="fbPixelEventSourceUrlOptions"
                  />
                </div>
                <div class="w-1/2 px-3 mb-3">
                  <input-text-validated
                    v-if="company.performance.facebook.pixelEventSourceUrlType === 'CUSTOM'"
                    ref="company.performance.facebook.customPixelEventSourceUrl"
                    v-model="company.performance.facebook.customPixelEventSourceUrl"
                    input-class="w-full"
                    rules="required|protocol"
                    label="Benutzerdefinierte URL"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div class="w-1/3 description">
            <h1>Website &amp; Links</h1>
            <p>
              Angaben zur Firmenwebsite, welche auf den jeweiligen Seiten
              angezeigt &amp; verlinkt werden.
            </p>
          </div>
          <div class="w-2/3">
            <div class="flex flex-wrap -mx-2">
              <div
                v-for="link in LINK_LABELS"
                :key="link.key"
                class="w-1/2 px-2 mb-3 form-group"
              >
                <input-text-validated
                  :ref="`company.links.${[link.key]}`"
                  v-model="company.links[link.key]"
                  input-class="w-full"
                  rules="protocol"
                  :label="link.name"
                  :placeholder="`https://max-makler.immobilien/${link.name.replace(' ', '')}`"
                />
              </div>
            </div>
          </div>
        </section>

        <section>
          <div class="w-1/3 description">
            <h1>Social Links</h1>
            <p>Angaben zu sozialen Netzwerken der Firma.</p>
          </div>
          <div class="w-2/3">
            <socials :socials="company.socials" />
          </div>
        </section>

        <div class="flex justify-center pt-8">
          <button
            class="self-center btn-primary"
            @click="submit()"
          >
            Änderungen speichern
          </button>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import GET_COMPANY from '@/graphql/GetCompany.gql'
import GET_CLIENT_ADVISORS from '@/graphql/GetClientAdvisors.gql'
import GET_SALES_CONTACTS from '@/graphql/GetSalesPersons.gql'
import UPDATE_COMPANY from '@/graphql/UpdateCompany.gql'
import InputSelectValidated from '@/components/form/select-validated'
import InputTextValidated from '@/components/form/text-validated'
import InputText from '@/components/form/text'
import Socials from '@/components/company/socials'
import Alert from '@/components/alert'
import {
  COUNTRIES_FOR_ADDRESS,
  COUNTRIES_FOR_COMPANY,
  LINK_LABELS
} from '@/config'
import featureMixin from '@/mixins/feature'
import brandingMixin from '@/mixins/branding'

const extractGoogleId = (linkOrId, regex) => {
  const match = (linkOrId || '').match(regex)
  if (!match) {
    return ''
  }
  return match[3] || match[2]
}

const FB_PIXEL_EVENT_SOURCE_URL_OPTIONS = [
  { value: 'COMPANY_WEBSITE', name: 'Website' },
  { value: 'LANDINGPAGE_DEFAULT', name: 'Standard Landingpage-URL' },
  { value: 'CUSTOM', name: 'Benutzerdefiniert' }
]

export default {
  apollo: {
    company: {
      query: GET_COMPANY,
      variables () {
        return {
          id: this.$route.params.id
        }
      },
      manual: true,
      result ({ data: { company } = {} }) {
        if (company) {
          this.company = { ...company }
          this.clientAdvisorId = company.clientAdvisor?.id ?? ''
          this.salesPersonId = company.salesPerson?.id ?? ''
          this.company.socials = [...company.socials] || []
          this.company.tags = [...company.tags] || []
          this.company.address = { ...company.address } || {}
          this.company.links = { ...company.links } || {}
          this.company.performance = { ...company.performance } || {}
          this.company.address.zip = company.address.zip || ''
          this.company.address.street = company.address.street || ''
          this.company.address.city = company.address.city || ''
          this.company.address.country = company.address.country || ''
        }
      }
    },
    clientAdvisors: {
      query: GET_CLIENT_ADVISORS,
      update: (data) =>
        data.clientAdvisors?.map((advisor) => ({
          value: advisor.id,
          name: advisor.name
        }))
    },
    salesPersons: {
      query: GET_SALES_CONTACTS,
      update: (data) =>
        data.salesPersons?.map((contacts) => ({
          value: contacts.id,
          name: contacts.name
        }))
    }
  },
  components: {
    InputSelectValidated,
    InputTextValidated,
    InputText,
    Alert,
    Socials
  },
  mixins: [
    featureMixin,
    brandingMixin
  ],
  data () {
    return {
      countriesForAddress: COUNTRIES_FOR_ADDRESS,
      company: null,
      clientAdvisorId: '',
      salesPerson: '',
      LINK_LABELS,
      fbPixelEventSourceUrlOptions: FB_PIXEL_EVENT_SOURCE_URL_OPTIONS
    }
  },
  computed: {
    countriesForCompany () {
      return COUNTRIES_FOR_COMPANY.filter(({ value }) =>
        this.$features.feature(this.featureNames.COMPANY).config.availableCountryCodes.includes(value))
    },
    companyDomain () {
      return this.company.slug
        ? `${this.company.slug}.${this.landingpageBaseDomain}`
        : `<max-makler-immobilien>.${this.landingpageBaseDomain}`
    },
    previewLinkGoogleDrive () {
      if (this.company.googleDriveFolderId) {
        const id = this.cleanGoogleDriveId
        if (id) {
          return `https://drive.google.com/embeddedfolderview?id=${id}#grid`
        }
      }
      return null
    },
    previewLinkGooglePerformanceReport () {
      const id = this.cleanGoogleReportId
      if (id) {
        return `https://datastudio.google.com/open/${id}`
      }
      return null
    },
    cleanGoogleDriveId () {
      return extractGoogleId(
        this.company.googleDriveFolderId,
        /(folders\/|open\?id=)([-\w]{25,})|(^[-\w]{25,}$)/
      )
    },
    cleanGoogleReportId () {
      return extractGoogleId(
        this.company.googlePerformanceReportId,
        /(reporting\/|open\/)([-\w]{25,})|(^[-\w]{25,}$)/
      )
    }
  },
  methods: {
    async submit () {
      const isValid = await this.$refs.validationObserver.validate()
      if (!isValid) {
        window.scrollTo({ top: 0 })
        return
      }

      // make it "cacheable" with apollo
      this.company.googleDriveFolderId = this.cleanGoogleDriveId
      this.company.googlePerformanceReportId = this.cleanGoogleReportId

      const {
        id,
        customerNumber,
        name,
        slogan,
        address,
        links,
        commission,
        regionName,
        socials,
        leadCorrespondenceBccEmail,
        fbPixelId,
        fbBusinessManagerId,
        advertisementId,
        performance: {
          facebook: {
            pixelEventSourceUrlType,
            customPixelEventSourceUrl
          }
        }
      } = this.company
      const cleanedSocials = socials.map(({ type, link }) => ({ type, link }))

      try {
        await this.$apollo.mutate({
          mutation: UPDATE_COMPANY,
          variables: {
            input: {
              id,
              customerNumber,
              name,
              slogan,
              commission,
              regionName,
              advertisementId,
              fbPixelId,
              fbBusinessManagerId,
              socials: cleanedSocials,
              leadCorrespondenceBccEmail,
              clientAdvisor: this.clientAdvisorId,
              salesPerson: this.salesPersonId,
              googleDriveFolderId: this.cleanGoogleDriveId,
              googlePerformanceReportId: this.cleanGoogleReportId,
              address: {
                street: address.street,
                zip: address.zip,
                city: address.city,
                country: address.country
              },
              links: {
                website: links.website,
                imprint: links.imprint,
                about: links.about,
                privacy: links.privacy
              },
              performance: {
                facebook: {
                  pixelEventSourceUrlType,
                  customPixelEventSourceUrl
                }
              }
            }
          }
        })

        this.$router.push({
          path: `/companies/${this.company.id}`
        })
      } catch (error) {
        this.setValidationErrors(error, 'company')
        window.scrollTo({ top: 0 })
      }
    }
  }
}
</script>
