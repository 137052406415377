<template>
  <li class="flex items-center pl-4 pr-1 hover:bg-gray-200 group">
    <!-- Status -->
    <fa
      v-if="material.status === 'READY'"
      :icon="faCheck"
      fixed-width
      class="text-green-500"
      title="Dateien liegt vor"
    />
    <fa
      v-if="material.status === 'PENDING'"
      :icon="faClock"
      fixed-width
      class="text-gray-400"
      title="Generierung ausstehend (noch nicht angestoßen)"
    />
    <fa
      v-if="material.status === 'QUEUED'"
      :icon="faSpinner"
      fixed-width
      class="animate-spin"
      title="Befindet sich in der Warteschleife"
    />
    <fa
      v-if="material.status === 'GENERATING'"
      :icon="faSpinner"
      fixed-width
      class="animate-spin"
      title="Wird gerade generiert"
    />
    <fa
      v-if="material.status === 'GENERATION_FAILED'"
      :icon="faExclamationTriangle"
      fixed-width
      class="text-yellow-500"
      title="Generierung fehlgeschlagen"
    />

    <!-- File name -->
    <div class="flex-1 px-4 py-2">
      {{ material.displayName }}
    </div>

    <!-- Mode (manual) -->
    <div class="flex lg:w-32">
      <template v-if="material.type === 'DOWNLOAD'">
        <span
          class="text-sm text-gray-600"
          :class="material.mode !== 'MANUAL' && 'invisible group-hover:visible'"
        >
          manuell
        </span>
        <InputSwitch
          class="invisible ml-2 group-hover:visible"
          :value="material.mode === 'MANUAL'"
          small
          @onChange="switchMode"
        />
      </template>
    </div>

    <!-- Version -->
    <div class="w-6 pl-2">
      <template v-if="hasFile">
        v{{ material.version }}
      </template>
    </div>

    <!-- Update Date -->
    <div class="pl-2 lg:w-40">
      <template v-if="hasFile">
        <span
          class="text-sm text-gray-600"
          :title="formatDateTime(material.updatedAt)"
        >
          {{ formatDate(material.updatedAt) }}
        </span>
      </template>
    </div>

    <!-- Actions -->
    <div class="flex invisible pl-4 space-x-1 group-hover:visible">
      <!-- Action: preview -->
      <div
        v-if="hasFile"
        class="relative flex w-8 group"
      >
        <div class="absolute right-0 z-10 flex flex-col items-center p-1 mb-6 -mt-1 bg-gray-200 rounded-md">
          <a
            v-for="{file} in material.files"
            :key="file.cdnUrl"
            :href="createAttachmentUrl(file)"
            target="_blank"
            title="Vorschau"
            class="btn-icon"
          >
            <fa
              :icon="faEye"
              fixed-width
            />
          </a>
        </div>
      </div>

      <!-- Action: preview disabled -->
      <button
        v-else
        class="btn-icon"
        disabled
      >
        <fa
          :icon="faEye"
          fixed-width
        />
      </button>

      <!-- Action: generate -->
      <button
        v-if="hasRole(ROLES.SUPER_ADMIN) && material.mode === 'GENERATED'"
        title="Generieren"
        class="btn-icon"
        @click="showGenerateModal()"
      >
        <fa
          :icon="faSync"
          fixed-width
        />
      </button>

      <!-- Action: upload -->
      <button
        v-if="material.mode === 'MANUAL'"
        title="Neue Version hochladen"
        class="btn-icon"
        @click="showUploadModal()"
      >
        <fa
          :icon="faUpload"
          fixed-width
        />
      </button>

      <!-- Fallback placeholder if no action -->
      <div
        v-if="!['GENERATED', 'MANUAL'].includes(material.mode)"
        class="w-8"
      />
    </div>
  </li>
</template>

<script>
import {
  faCheck,
  faSpinner,
  faClock,
  faExclamationTriangle,
  faUpload,
  faSync,
  faEye
} from '@fortawesome/free-solid-svg-icons'
import UploadModal from '@/components/modals/landingpage/upload'
import GenerateOneModal from '@/components/modals/landingpage/generate-one'
import InputSwitch from '@/components/form/switch.vue'
import UPDATE_MATERIAL_MODE from '@/graphql/material/updateMaterialMode.gql'
import role from '@/mixins/role'

export default {
  components: {
    InputSwitch
  },

  mixins: [role],

  props: {
    material: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      faCheck,
      faSpinner,
      faClock,
      faExclamationTriangle,
      faUpload,
      faSync,
      faEye
    }
  },

  computed: {
    // material.hasFile is not being updated when changing mode
    // so we make our own check.
    hasFile () {
      return this.material.files.length > 0
    }
  },

  methods: {
    showGenerateModal () {
      this.$modal.show(GenerateOneModal, {
        material: this.material
      })
    },

    showUploadModal () {
      this.$modal.show(UploadModal, {
        material: this.material
      })
    },

    createAttachmentUrl ({ cdnUrl }) {
      const newUrl = new URL(cdnUrl)
      if (!cdnUrl.includes('.mp4')) {
        newUrl.searchParams.append('attachment', 'false')
      }
      return newUrl.toString()
    },

    switchMode () {
      try {
        this.$apollo.mutate({
          mutation: UPDATE_MATERIAL_MODE,
          variables: {
            input: {
              materialId: this.material.id,
              mode: this.material.mode === 'MANUAL' ? 'GENERATED' : 'MANUAL'
            }
          }
        })
      } catch (err) {
        alert('Ändern des Modes fehlgeschlagen!')
      }
    }
  }
}
</script>
