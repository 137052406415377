const getterForName = (collection) => (value) => collection.find(({ value: key }) => value === key)?.name
const getterForInfo = (collection) => (value) => collection.find(({ value: key }) => value === key)?.info
const getterForNext = (collection) => (value) => collection.find(({ value: key }) => value === key)?.next

export const TRIAL_DURATIONS = [
  { duration: { value: 1, unit: 'WEEK' }, name: '1 Woche', value: '1weeks' },
  { duration: { value: 2, unit: 'WEEK' }, name: '2 Wochen', value: '2weeks' }
]
export const DURATIONS = [
  { duration: { value: 1, unit: 'MONTH' }, name: '1 Monat', value: '1month' },
  { duration: { value: 2, unit: 'MONTH' }, name: '2 Monate', value: '2month' },
  { duration: { value: 3, unit: 'MONTH' }, name: '3 Monate', value: '3month' },
  { duration: { value: 6, unit: 'MONTH' }, name: '6 Monate', value: '6months' },
  { duration: { value: 12, unit: 'MONTH' }, name: '12 Monate', value: '12months' },
  { duration: { value: 24, unit: 'MONTH' }, name: '24 Monate', value: '24months' }
]
export const ALL_DURATIONS = [...DURATIONS, ...TRIAL_DURATIONS]
export const getDurationName = getterForName(ALL_DURATIONS)
export const getDurationObject = (value) => ALL_DURATIONS.find(({ value: key }) => value === key)?.duration
export const getDurationNameFromObject = (duration) =>
  ALL_DURATIONS.find(({ duration: { value, unit } }) => value === duration.value && unit === duration.unit)?.name

export const DISCOUNTS = [
  { value: 3, name: '3% Vorauszahlung' },
  { value: 5, name: '5% Vorauszahlung' }
]

export const DISCOUNTS_SETUP = [
  { value: 50, name: '50%' },
  { value: 100, name: '100%' }
]
export const PRICELISTS = [
  { value: 'STANDARD', name: 'Standard DE/AT' },
  { value: 'CH', name: 'Standard CH' },
  { value: 'VOBA', name: 'Volksbank' },
  { value: 'REMAX', name: 'REMAX' }
]
export const getPricelistName = getterForName(PRICELISTS)

export const DOCUMENT_TYPES = [
  { value: 'AVV', name: 'AVV' },
  { value: 'OBF', name: 'OBF' },
  { value: 'SEPA', name: 'SEPA' },
  { value: 'OTHER', name: 'Sonstiges' },
  { value: 'CANCELLATION', name: 'Kündigung' },
  { value: 'CONTRACT', name: 'Vertrag' }
]
export const getDocumentTypeName = getterForName(DOCUMENT_TYPES)

export const CANCELLATION_REASONS = [
  { value: 'OTHER', name: 'Anderes' },
  { value: 'UNHAPPY', name: 'Unzufrieden' },
  { value: 'SUPPLIERCHANGE', name: 'Anbieterwechsel' },
  { value: 'BANKRUPT', name: 'Insolvenz' },
  { value: 'BADLEADQUALITY', name: 'Mangelnde Leadqualität' },
  { value: 'NOSUCCESS', name: 'Kein Erfolg' },
  { value: 'NOTUNDERSTOOD', name: 'Produkt nicht verstanden' },
  { value: 'PROFORMA', name: 'Proforma' }
]
export const getCancellationReasonName = getterForName(CANCELLATION_REASONS)

export const PRODUCT_TYPES = {
  SETUP: 'SETUP',
  EXPERT: 'EXPERT',
  PERFORMANCE: 'PERFORMANCE',
  WEBSITE: 'WEBSITE',
  CREDIT: 'CREDIT',
  BASIC: 'BASIC',
  REMINDER_FEE: 'REMINDER_FEE'
}

export const PAYMENT_METHODS = [
  { value: 'INVOICE', name: 'Rechnung' },
  { value: 'DIRECT_DEBIT', name: 'Bankeinzug' },
  { value: 'PREPAYMENT', name: 'Vorauszahlung' },
  { value: 'BILLWERK', name: 'Billwerk' }
]
export const getPaymentMethodsName = getterForName(PAYMENT_METHODS)

export const SOCIAL_TYPES = [
  { value: 'INSTAGRAM', name: 'instagram' },
  { value: 'FACEBOOK', name: 'facebook' },
  { value: 'LINKEDIN', name: 'linkedin' },
  { value: 'TWITTER', name: 'twitter' },
  { value: 'OTHER', name: 'sonstige' }
]
export const getSocialTypesName = getterForName(SOCIAL_TYPES)

export const COUNTRIES_FOR_ADDRESS = [
  { value: 'DE', name: 'Deutschland' },
  { value: 'CH', name: 'Schweiz' },
  { value: 'AT', name: 'Österreich' },
  { value: 'TR', name: 'Türkei' }
]

export const COUNTRIES_FOR_COMPANY = [
  { value: 'de', name: 'Deutschland' },
  { value: 'ch', name: 'Schweiz' },
  { value: 'at', name: 'Österreich' },
  { value: 'tr', name: 'Türkei' },
  { value: 'gb', name: 'Vereinigtes Königreich' },
  { value: 'us', name: 'Vereinigte Staaten' },
  { value: 'al', name: 'Albanien' },
  { value: 'ad', name: 'Andorra' },
  { value: 'by', name: 'Weißrussland' },
  { value: 'be', name: 'Belgien' },
  { value: 'ba', name: 'Bosnien und Herzegowina' },
  { value: 'bg', name: 'Bulgarien' },
  { value: 'hr', name: 'Kroatien' },
  { value: 'cy', name: 'Zypern' },
  { value: 'cz', name: 'Tschechien' },
  { value: 'dk', name: 'Dänemark' },
  { value: 'ee', name: 'Estland' },
  { value: 'fi', name: 'Finnland' },
  { value: 'fr', name: 'Frankreich' },
  { value: 'gr', name: 'Griechenland' },
  { value: 'hu', name: 'Ungarn' },
  { value: 'is', name: 'Island' },
  { value: 'ie', name: 'Irland' },
  { value: 'it', name: 'Italien' },
  { value: 'lv', name: 'Lettland' },
  { value: 'li', name: 'Liechtenstein' },
  { value: 'lt', name: 'Litauen' },
  { value: 'lu', name: 'Luxemburg' },
  { value: 'mt', name: 'Malta' },
  { value: 'md', name: 'Moldawien' },
  { value: 'mc', name: 'Monaco' },
  { value: 'me', name: 'Montenegro' },
  { value: 'nl', name: 'Niederlande' },
  { value: 'mk', name: 'Nordmazedonien' },
  { value: 'no', name: 'Norwegen' },
  { value: 'pl', name: 'Polen' },
  { value: 'pt', name: 'Portugal' },
  { value: 'ro', name: 'Rumänien' },
  { value: 'ru', name: 'Russland' },
  { value: 'sm', name: 'San Marino' },
  { value: 'rs', name: 'Serbien' },
  { value: 'sk', name: 'Slowakei' },
  { value: 'si', name: 'Slowenien' },
  { value: 'es', name: 'Spanien' },
  { value: 'se', name: 'Schweden' },
  { value: 'ua', name: 'Ukraine' }
]

export const COUNTRIES = {
  de: 'Deutschland',
  ch: 'Schweiz',
  at: 'Österreich',
  tr: 'Türkei',
  gb: 'Vereinigtes Königreich',
  us: 'Vereinigte Staaten',
  al: 'Albanien',
  ad: 'Andorra',
  by: 'Weißrussland',
  be: 'Belgien',
  ba: 'Bosnien und Herzegowina',
  bg: 'Bulgarien',
  hr: 'Kroatien',
  cy: 'Zypern',
  cz: 'Tschechien',
  dk: 'Dänemark',
  ee: 'Estland',
  fi: 'Finnland',
  fr: 'Frankreich',
  gr: 'Griechenland',
  hu: 'Ungarn',
  is: 'Island',
  ie: 'Irland',
  it: 'Italien',
  lv: 'Lettland',
  li: 'Liechtenstein',
  lt: 'Litauen',
  lu: 'Luxemburg',
  mt: 'Malta',
  md: 'Moldawien',
  mc: 'Monaco',
  me: 'Montenegro',
  nl: 'Niederlande',
  mk: 'Nordmazedonien',
  no: 'Norwegen',
  pl: 'Polen',
  pt: 'Portugal',
  ro: 'Rumänien',
  ru: 'Russland',
  sm: 'San Marino',
  rs: 'Serbien',
  sk: 'Slowakei',
  si: 'Slowenien',
  es: 'Spanien',
  se: 'Schweden',
  ua: 'Ukraine'
}

export const LINK_LABELS = [
  { name: 'Webseite', key: 'website' },
  { name: 'Impressum', key: 'imprint' },
  { name: 'Über uns', key: 'about' },
  { name: 'Datenschutz', key: 'privacy' }
]

export const COMPANY_STATE_EVENTS = [
  { value: 'VALIDATE', name: 'Onboarding abschliessen' },
  { value: 'USER', name: 'Onboarding (User)' },
  { value: 'ONBOARDING', name: 'Onboarding starten' },
  { value: 'SELF_ONBOARDING', name: 'Self Onboarding starten' },
  { value: 'NEXT', name: 'Self Onboarding nächster Schritt' },
  { value: 'TRIAL', name: 'Trial starten' },
  { value: 'IDLE', name: 'Aktivieren' },
  { value: 'ONBOARDED', name: 'App freischalten' },
  { value: 'PAYMENT_DELAYED', name: 'Zahlungsverzug' },
  { value: 'CONTRACT_ENDED', name: 'deaktivieren (Vetrag gekündigt)' },
  { value: 'PAID', name: 'Zahlung eingegangen' },
  { value: 'DEACTIVATE', name: 'deaktivieren' },
  { value: 'DEACTIVATE_PAYMENT_DELAY', name: 'deaktivieren (Zahlungsverzug)' },
  { value: 'ACTIVATE', name: 'aktivieren' }
]
export const getCompanyStateEventName = getterForName(COMPANY_STATE_EVENTS)

export const COMPANY_STATES = [
  { value: 'CREATED', name: 'Erstellt' },
  { value: 'SELF_ONBOARDING.WEBSITE', name: 'Registrierung (Website)' },
  { value: 'SELF_ONBOARDING.BILLING', name: 'Registrierung (Zahlungsmittel)' },
  { value: 'ONBOARDING.USER', name: 'Onboarding (User)' },
  { value: 'ONBOARDING.VALIDATION', name: 'Onboarding (Validierung)' },
  { value: 'ACTIVE.IDLE', name: 'Aktiv' },
  { value: 'ACTIVE.TRIAL', name: 'Aktiv (Trial)' },
  { value: 'ACTIVE.PAYMENT_DELAY', name: 'Zahlungsverzug' },
  { value: 'INACTIVE.PAYMENT_DELAY', name: 'Deaktiviert (Zahlungsverzug)' },
  { value: 'INACTIVE.IDLE', name: 'Deaktiviert' }
]

export const COMPANY_STATES_INFO = [
  { value: 'CREATED', info: '' },
  { value: 'ONBOARDING.USER', info: 'Das Büro befindet sich im Modus Onboarding.' },
  { value: 'ONBOARDING.VALIDATION', info: 'Das Büro hat die Validierung abgeschlossen.' },
  { value: 'ACTIVE.IDLE', info: 'Das Büro ist aktiv und kann den vollen Funktionsumfang der App nutzen.' },
  { value: 'ACTIVE.TRIAL', info: 'Das Büro ist aktiv und kann den vollen Funktionsumfang der App nutzen.' },
  { value: 'ACTIVE.PAYMENT_DELAY', info: 'Das Büro ist aktiv und kann den vollen Funktionsumfang der App nutzen.' },
  { value: 'INACTIVE.PAYMENT_DELAY', info: 'Das Büro wurde deaktiviert.' },
  { value: 'INACTIVE.IDLE', info: 'Das Büro wurde deaktiviert.' }
]

export const COMPANY_STATES_INFO_NEXT = [
  {
    value: 'CREATED',
    next: 'Das Büro muss nun in die Lage versetzt werden, Kontaktdaten, etc. selbst einzugeben. Um dies zu ermöglichen, setzen Sie den Status des Büros auf "Onboarding"'
  },
  {
    value: 'ONBOARDING.USER',
    next: 'Sie haben zwei Möglichkeiten: <br> 1) Legen Sie einen ersten Zugang zur App im Bereich <b><a href="#makler">Makler</a></b> an, damit das Büro das Onboarding selbstständig durchlaufen kann. <br> 2) Das Onboarding abschliessen. Durch Klick auf den Button "Onboarding abschliessen" wird geprüft ob das Büro alle erforderlichen Daten eingetragen hat. Fehlende Pflichtangaben sehen Sie unten rot markiert.'
  },
  {
    value: 'ONBOARDING.VALIDATION',
    next: 'Überprüfen Sie die Angaben. Sie haben zwei Möglichkeiten. <br> 1) Geben Sie alle Funktionen der App für das Büro frei. <br> 2) Setzen Sie das Büro erneut auf Onboarding.'
  },
  { value: 'ACTIVE.IDLE', next: 'Alles erledigt. Das Büro ist aktiv.' },
  { value: 'ACTIVE.CANCELLED' },
  { value: 'ACTIVE.PAYMENT_DELAY' },
  {
    value: 'INACTIVE.PAYMENT_DELAY',
    next: 'Sie haben zwei Möglichkeiten: <br> 1) Dem Büro erneut Zugang zum Onboarding-Prozess geben. <br> 2) Nur möglich wenn das Büro vor der Deaktivierung bereits validiert wurde: Das Büro auf dem Stand VOR der Deaktivierung wieder aktivieren. Alle Zugänge, Landingpages und E.Mail Funnels funktionieren dann wieder.'
  },
  {
    value: 'INACTIVE.IDLE',
    next: 'Sie haben zwei Möglichkeiten: <br> 1) Dem Büro erneut Zugang zum Onboarding-Prozess geben. <br> 2) Nur möglich wenn das Büro vor der Deaktivierung bereits validiert wurde: Das Büro auf dem Stand VOR der Deaktivierung wieder aktivieren. Alle Zugänge, Landingpages und E.Mail Funnels funktionieren dann wieder.'
  }
]
export const getCompanyStatesName = getterForName(COMPANY_STATES)
export const getCompanyStatesInfo = getterForInfo(COMPANY_STATES_INFO)
export const getCompanyStatesNextInfo = getterForNext(COMPANY_STATES_INFO_NEXT)
