<template>
  <crud-modal
    title="Firmen ohne Rechnungen für diesen Monat exportieren"
    action="export"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <div class="flex flex-wrap w-full">
      <div class="w-1/2 px-4 mb-3">
        <input-date-validated
          ref="billingMonth"
          v-model="billingMonth"
          label="Abrechnungsmonat"
          type="month"
          rules="required"
        />
      </div>
      <div class="w-1/2 px-4 mb-3">
        <input-date-validated
          ref="lastBillingDate"
          v-model="lastBillingDate"
          label="Letzte Abrechnung"
          rules="required"
        />
      </div>
    </div>
  </crud-modal>
</template>
<script>
import CrudModal from '@/components/modals/crud-modal'
import InputDateValidated from '@/components/form/date-validated'

import { exportCompaniesWithoutInvoice } from '@/lib/export'

export default {
  components: { CrudModal, InputDateValidated },
  data () {
    return { billingDate: new Date() }
  },
  methods: {
    async submit () {
      const billingMonth = this.billingMonth.toISOString().slice(0, 7)
      const lastBillingDate = this.lastBillingDate.toISOString().slice(0, 10)
      await exportCompaniesWithoutInvoice({
        billingMonth,
        lastBillingDate
      })
    }
  }
}
</script>
