<template>
  <section>
    <h1>
      Leads
      <span
        v-if="hasLeads"
        class="ml-2 text-base text-gray-600"
      >
        {{ loadedCount }} / {{ leads.total }}
      </span>
    </h1>

    <div
      v-if="hasLeads"
      class="w-full"
    >
      <div class="flex py-3 text-gray-700 uppercase border-b-4">
        <div class="w-3/12 font-semibold">
          Name
        </div>
        <div class="w-3/12 font-semibold">
          E-Mail
        </div>
        <div class="w-2/12 font-semibold">
          Letzte Aktion
        </div>
        <div class="w-2/12 font-semibold">
          Erstellt
        </div>
        <div class="w-2/12 font-semibold">
          Quelle
        </div>
      </div>

      <router-link
        v-for="lead in leads.entries"
        :key="lead.id"
        :to="`/leads/${lead.id}`"
        class="flex py-3 border-b hover:bg-gray-100 group"
      >
        <div class="w-3/12 truncate">
          {{ lead.firstname }} {{ lead.lastname }}
        </div>
        <div class="w-3/12 truncate">
          {{ lead.email }}
        </div>
        <div class="flex justify-between w-2/12 truncate">
          {{ formatDateTime(lead.lastConversionAt) }}
        </div>
        <div class="flex justify-between w-2/12 truncate">
          {{ formatDateTime(lead.createdAt) }}
        </div>
        <div
          class="flex justify-between w-2/12 "
        >
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-html="getSource(lead)" />

          <div class="hidden mr-4 group-hover:block">
            <fa
              :icon="faChevronRight"
              size="sm"
            />
          </div>
        </div>
      </router-link>

      <button
        v-if="leads.hasMore"
        type="button"
        class="mx-auto mt-4 text-sm btn-flat"
        @click="loadMore"
      >
        Weitere Leads laden
      </button>
    </div>

    <div
      v-else
      class="w-full py-4 text-sm font-semibold text-center text-gray-600 uppercase bg-gray-100 rounded"
    >
      Keine Leads gefunden
    </div>
  </section>
</template>

<script>
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import GET_LEADS from '@/graphql/GetLeads.gql'

const CHUNK_SIZE = 20

export default {
  props: {
    companyId: {
      type: String,
      required: true
    }
  },

  data: () => ({
    faChevronRight
  }),

  apollo: {
    leads: {
      query: GET_LEADS,
      variables () {
        return {
          input: {
            companyId: this.companyId,
            itemsPerPage: this.getOffsetFromUrl() + CHUNK_SIZE,
            page: 1
          }
        }
      }
    }
  },

  computed: {
    hasLeads () {
      return this.leads?.total > 0
    },

    loadedCount () {
      return this.leads?.entries.length ?? 0
    }
  },

  methods: {
    getSource ({ conversions }) {
      const sources = {
        google: 0,
        facebook: 0
      }
      const interactions = [...conversions]
      interactions.map(interaction => {
        if (interaction.origin === 'FB_LEAD_ADS') {
          sources.facebook++
          return
        }

        if (!interaction.source) return

        switch (interaction.source) {
          case 'facebook':
          case 'google':
            sources[interaction.source]++
            break
          default:
            break
        }
      })
      return `Google: ${sources.google}<br>Facebook: ${sources.facebook}<br>`
    },
    loadMore () {
      this.$apollo.queries.leads.fetchMore({
        variables: {
          input: {
            page: parseInt(this.leads.entries.length / CHUNK_SIZE) + 1,
            itemsPerPage: CHUNK_SIZE
          }
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const offset = this.leads.entries.length
          this.reflectOffsetInUrl(offset)

          const prevLeads = previousResult.leads
          const newLeads = fetchMoreResult.leads

          return {
            leads: {
              ...newLeads,
              entries: [
                ...prevLeads.entries,
                ...newLeads.entries
              ]
            }
          }
        }
      })
    },

    reflectOffsetInUrl (offset) {
      this.$router.replace({
        path: this.$route.fullPath,
        query: {
          o: offset
        }
      })
    },

    getOffsetFromUrl () {
      const offset = parseInt(this.$route.query.o)
      return isNaN(offset) ? 0 : offset
    }
  }
}
</script>
