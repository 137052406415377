<template>
  <div v-if="contactPersons">
    <section class="w-full">
      <h1>Ansprechpartner</h1>
      <contact-person
        :contact="contactPerson"
        @edit="showContactPersonUpdateModal(contactPerson, 'GENERAL')"
      />
    </section>

    <Feature :feature-slug="featureNames.CONTRACT">
      <section class="w-full">
        <h1>Ansprechpartner Vertrag</h1>
        <contact-person
          :contact="contactContract"
          @edit="showContactPersonUpdateModal(contactContract, 'CONTRACT')"
        />
      </section>
    </Feature>

    <Feature
      v-slot="{feature}"
      :feature-slug="featureNames.CONTACTS"
    >
      <div>
        <section
          v-if="feature.config.hasAdAgencyContact"
          class="w-full"
        >
          <h1>Ansprechpartner Website</h1>
          <contact-person
            :contact="contactWebsite"
            @edit="showContactPersonUpdateModal(contactWebsite, 'WEBSITE')"
          />
        </section>

        <section
          v-if="feature.config.hasDesignContact"
          class="w-full"
        >
          <h1>Ansprechpartner Werbeagentur</h1>
          <contact-person
            :contact="contactAdAgency"
            @edit="showContactPersonUpdateModal(contactAdAgency, 'AD_AGENCY')"
          />
        </section>
      </div>
    </Feature>

    <Feature :feature-slug="featureNames.PERFORMANCE_MARKETING">
      <div>
        <section class="w-full">
          <h1>Ansprechpartner Google</h1>
          <contact-person
            :contact="contactGoogle"
            @edit="showContactPersonUpdateModal(contactGoogle, 'GOOGLE')"
          />
        </section>

        <section class="w-full">
          <h1>Ansprechpartner Facebook</h1>
          <contact-person
            :contact="contactFacebook"
            @edit="showContactPersonUpdateModal(contactFacebook, 'FACEBOOK')"
          />
        </section>
      </div>
    </Feature>
  </div>
</template>

<script>
import UpdateAdditionalContactModal from '@/components/modals/additionalContact/update'
import ContactPerson from './contact-person.vue'
import GET_COMPANY_CONTACT_PERSONS from '@/graphql/GetCompanyContactPersons.gql'
import feature from '@/mixins/feature'

export default {
  components: { ContactPerson },
  mixins: [feature],
  props: {
    companyId: {
      type: String,
      required: true
    }
  },
  computed: {
    contactPerson () {
      return this.contactPersons.find(({ type }) => type === 'GENERAL') || {}
    },
    contactContract () {
      return this.contactPersons.find(({ type }) => type === 'CONTRACT') || {}
    },
    contactWebsite () {
      return this.contactPersons.find(({ type }) => type === 'WEBSITE') || {}
    },
    contactAdAgency () {
      return this.contactPersons.find(({ type }) => type === 'AD_AGENCY') || {}
    },
    contactGoogle () {
      return this.contactPersons.find(({ type }) => type === 'GOOGLE') || {}
    },
    contactFacebook () {
      return this.contactPersons.find(({ type }) => type === 'FACEBOOK') || {}
    }
  },
  methods: {
    showContactPersonUpdateModal (contactData, contactType) {
      this.$modal.show(
        UpdateAdditionalContactModal,
        {
          companyId: this.companyId,
          contactData,
          contactType
        },
        {
          height: 'auto',
          scrollable: true
        }
      )
    }
  },
  apollo: {
    contactPersons: {
      query: GET_COMPANY_CONTACT_PERSONS,
      variables () {
        return { companyId: this.companyId }
      }
    }
  }
}
</script>
