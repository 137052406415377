// Current prices for BrandEasy (Dez. 2020)
const MIN_PRICE_BRAND_EASY = 0.2735 // 7000 jobs
const MAX_PRICE_BRAND_EASY = 0.3222 // 3000 jobs

function generationCostsBrandEasy (materials) {
  const min = Math.round(MIN_PRICE_BRAND_EASY * materials)
  const max = Math.round(MAX_PRICE_BRAND_EASY * materials)
  return { min, max }
}

function generationCostsMoovly (materials) {
  const min = Math.round(0.4 * materials)
  return { min, max: min }
}

function generationCosts ({ moovlyMaterials, brandEasyMaterials }) {
  const { min, max } = generationCostsBrandEasy(brandEasyMaterials)
  const { min: minMoovly, max: maxMoovly } = generationCostsMoovly(moovlyMaterials)
  return { min: min + minMoovly, max: max + maxMoovly }
}

export {
  generationCosts,
  generationCostsMoovly,
  generationCostsBrandEasy
}
