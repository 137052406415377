<template>
  <div>
    <div class="flex items-center justify-between px-10 py-8 bg-gray-100 border-b rounded-t-lg">
      <h1 class="text-xl font-semibold tracking-wider text-gray-800 uppercase">
        Inaktive E-Mails
      </h1>
    </div>

    <!-- inactive email list -->
    <div class="flex flex-wrap justify-center w-full mx-auto">
      <div
        v-for="inactiveEmail in inactiveEmailUsers"
        :key="inactiveEmail.id"
        style="flex: 0 0 30%"
      />
      <table class="w-full h-full border-0">
        <thead>
          <tr class="text-sm font-semibold tracking-wide text-left text-gray-700 uppercase bg-gray-200">
            <th class="w-1/5 p-4">
              E-Mail
            </th>
            <th class="w-1/5 p-4">
              Firma
            </th>
            <th class="w-1/5 p-4">
              Status
            </th>
            <th class="w-1/5 p-4">
              Datum
            </th>
            <th class="w-1/5 p-4">
              Aktivieren
            </th>
          </tr>
        </thead>
        <tbody class="text-sm">
          <tr v-if="!inactiveEmailUsers.length">
            <td colspan="7">
              <div
                v-if="$apollo.queries.inactiveEmailUsers.loading"
                class="loading"
              >
                <span style="width: 100%;" />
              </div>
              <div
                v-else
                class="p-8 text-base text-center"
              >
                Zu den eingestellten Filtern oder zur Suche ist kein Eintrag vorhanden.
                <a
                  href="#"
                  class="underline"
                  @click.prevent="clearAllFilter"
                >Alle Filter und Suche löschen</a>
              </div>
            </td>
          </tr>
          <tr
            v-for="inactiveEmailUser in inactiveEmailUsers"
            v-else
            :key="inactiveEmailUser.id"
            class="text-gray-500 transition cursor-pointer duration-250 group even:bg-gray-100 odd:bg-white hover:text-gray-700"
            @click="$router.push(`/companies/${inactiveEmailUser.company.id}`) "
          >
            <td
              class="hidden w-3/12 px-4 py-2 group-hover:bg-gray-200 lg:table-cell"
            >
              {{ inactiveEmailUser.email }}
            </td>
            <td
              class="hidden w-3/12 px-4 py-2 group-hover:bg-gray-200 lg:table-cell"
            >
              {{ inactiveEmailUser.company.name }}
            </td>
            <td
              class="hidden w-3/12 px-4 py-2 uppercase group-hover:bg-gray-200 lg:table-cell"
            >
              {{ inactiveEmailUser.emailState.state }}
            </td>
            <td
              class="hidden w-3/12 px-4 py-2 group-hover:bg-gray-200 lg:table-cell"
            >
              {{ formatDate(inactiveEmailUser.emailState.updatedAt) }}
            </td>
            <td
              class="hidden w-3/12 px-4 py-2 group-hover:bg-gray-200 lg:table-cell"
            >
              <button
                v-if="inactiveEmailUser.emailState.state !== 'SPAM_COMPLAINT'"
                class="self-center btn-primary"
                @click.stop="showReactivateEmailModal(inactiveEmailUser.email, inactiveEmailUser.emailState.state)"
              >
                Reaktivieren
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import INACTIVE_EMAIL_USERS from '@/graphql/InactiveEmailUsers.gql'
import DELETE_USER_EMAIL_SUPPRESSION from '@/graphql/DeleteUserEmailSuppression.gql'
import ReactivateEmailModal from '@/components/modals/user/reactivateEmail'
import eventBus, { eventNames } from '@/lib/eventBus'

export default {
  apollo: {
    inactiveEmailUsers: {
      query: INACTIVE_EMAIL_USERS
    }
  },
  data () {
    return {
      inactiveEmailUsers: []
    }
  },
  methods: {
    async reactivateEmail (email) {
      try {
        const { data: { deleteUserEmailSuppression } } = await this.$apollo.mutate({
          mutation: DELETE_USER_EMAIL_SUPPRESSION,
          variables: {
            email
          },
          refetchQueries: [
            { query: INACTIVE_EMAIL_USERS }
          ]
        })
        if (deleteUserEmailSuppression) {
          eventBus.$emit(eventNames.SHOW_SNACKBAR, { message: 'E-Mail erfolgreich reaktiviert', type: 'success' })
        } else {
          eventBus.$emit(eventNames.SHOW_SNACKBAR, { message: 'Etwas ist schief gelaufen', type: 'error' })
        }
      } catch (err) {
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { message: 'Etwas ist schief gelaufen', type: 'error' })
      }
    },
    showReactivateEmailModal (email, state) {
      this.$modal.show(
        ReactivateEmailModal,
        { email, onSubmit: () => { this.reactivateEmail(email) }, state },
        { height: 'auto' }
      )
    }
  }
}
</script>
