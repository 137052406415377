<template>
  <crud-modal
    title="Produkt hinzufügen"
    action="create"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <CreateForm
      :product="product"
      :product-templates="productTemplates"
      :product-readable-ids="productReadableIds"
    />
  </crud-modal>
</template>
<script>
import CreateForm from './create-form'
import CrudModal from '@/components/modals/crud-modal'
import { getDurationObject } from '@/config'

import ADD_PRODUCTS_TO_CONTRACT from '@/graphql/AddProductsToContract.gql'
import GET_COMPANY_CONTRACTS from '@/graphql/GetCompanyContracts.gql'

export default {
  components: {
    CreateForm,
    CrudModal
  },
  props: {
    companyId: {
      type: String,
      required: true
    },
    contract: {
      type: Object,
      required: true
    },
    productTemplates: {
      type: Array,
      default: () => ([])
    },
    productReadableIds: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      product: {
        priceListName: this.contract.priceListName,
        addSetup: true,
        amount: 1,
        product: {},
        followRuntimeProduct: {},
        setupProduct: {}
      }
    }
  },
  methods: {
    async submit () {
      const {
        startDate, templateId, setupTemplate, amount, addSetup, differentFollowRuntimeConditions,
        product: { discountPercentage, duration, wasPaidInAdvance },
        followRuntimeProduct: {
          discountPercentage: discountPercentageFollowRuntime,
          duration: durationFollowRuntime,
          wasPaidInAdvance: wasPaidInAdvanceFollowRuntime
        },
        setupProduct: { discountPercentage: discountSetupPercentage }
      } = this.product
      const addMonth = (date, months) => new Date(new Date(date).setMonth(new Date(date).getMonth() + months))
      const addDay = (date, days) => new Date(new Date(date).setDate(new Date(date).getDate() + days))

      const products = [{
        startDate,
        ...(differentFollowRuntimeConditions && {
          endDate: addDay(addMonth(startDate, getDurationObject(duration).value), -1)
        }),
        templateId,
        duration: getDurationObject(duration),
        discountPercentage: discountPercentage || 0,
        wasPaidInAdvance: wasPaidInAdvance
      }]

      if (differentFollowRuntimeConditions) {
        products.push({
          duration: getDurationObject(durationFollowRuntime),
          startDate: addMonth(startDate, getDurationObject(duration).value),
          templateId,
          discountPercentage: discountPercentageFollowRuntime || 0,
          wasPaidInAdvance: wasPaidInAdvanceFollowRuntime
        })
      }

      if (setupTemplate && addSetup) {
        products.push({
          startDate,
          templateId: setupTemplate,
          discountPercentage: discountSetupPercentage || 0
        })
      }

      await this.$apollo.mutate({
        mutation: ADD_PRODUCTS_TO_CONTRACT,
        variables: {
          input: {
            contractId: this.contract.id,
            products: Array(amount).fill(products).flat()
          }
        },
        refetchQueries: [{ query: GET_COMPANY_CONTRACTS, variables: { id: this.companyId } }]
      })
    }
  }
}
</script>
