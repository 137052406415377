const featureNames = Object.freeze({
  AUTHORIZATION: 'authorization',
  BACKOFFICE_ADMIN: 'backoffice-admin',
  BACKOFFICE_LEADS: 'backoffice-leads',
  BILLWERK: 'billwerk',
  CONTACTS: 'contacts',
  COMPANY_USER: 'company-user',
  COMPANY: 'company',
  CONTRACT: 'contract',
  CRM: 'crm',
  CLIENT_ADVISOR: 'client-advisor',
  NOTIFICATIONS: 'notifications',
  HOPPERMATION: 'hoppermation',
  SALES_PERSON: 'sales-person',
  STATISTICS: 'bo-statistics',
  STATISTIC_DASHBOARD: 'statistic-dashboard',
  TODO: 'todo',
  PERFORMANCE_MARKETING: 'performance-marketing',
  PLOT_VALUATION: 'lp-grundstuecksbewertung',
  VALUATION: 'valuation',
  VARIANT: 'variant',
  ZOHO: 'zoho'
})

export default featureNames
