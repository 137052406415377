<template>
  <div class="modal">
    <div class="modal-content">
      <h1
        class="w-full mb-6 text-xl font-semibold tracking-wide text-gray-800 uppercase"
      >
        Funnels stoppen
      </h1>
      <div
        class="mb-2"
      >
        Es werden alle Funnels gestoppt und der Kunde bekommt keine automatischen E-Mails mehr zugesandt.
      </div>
    </div>
    <div class="modal-footer">
      <button
        class="btn-inline"
        @click="$emit('close')"
      >
        Abbrechen
      </button>
      <button
        :disabled="isSubmitting"
        class="btn-primary"
        @click="submit()"
      >
        Stoppen
      </button>
    </div>
  </div>
</template>

<script>
import UNSUBSCRIBE_LEAD from '@/graphql/UnsubscribeLead.gql'
import GET_LEAD from '@/graphql/GetLead.gql'

export default {
  props: {
    leadId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isSubmitting: false
    }
  },
  methods: {
    async submit () {
      this.isSubmitting = true

      const {
        data: { unsubscribeLead: unsubscribed }
      } = await this.$apollo.mutate({
        mutation: UNSUBSCRIBE_LEAD,
        variables: {
          input: {
            leadId: this.leadId
          }
        },
        refetchQueries: [{ query: GET_LEAD, variables: { id: this.leadId } }]
      })

      if (unsubscribed) {
        this.$emit('close')
      }

      this.isSubmitting = false
    }
  }
}
</script>
