<template>
  <crud-modal
    title="Produkt kündigen"
    action="update"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <cancel-form :data="product" />
  </crud-modal>
</template>
<script>
import CancelForm from '@/components/modals/product/cancel-form'
import CrudModal from '@/components/modals/crud-modal'

import CANCEL_PRODUCT from '@/graphql/CancelProduct.gql'
import GET_COMPANY_CONTRACTS from '@/graphql/GetCompanyContracts.gql'

export default {
  components: {
    CancelForm,
    CrudModal
  },
  props: {
    product: { type: Object, required: true },
    companyId: { type: String, required: true },
    contractId: { type: String, required: true }
  },
  created () {
    this.product.cancelledAt = new Date()
  },
  methods: {
    async submit () {
      const {
        id,
        endDate,
        cancelledAt,
        cancellationReason,
        cancellationComment
      } = this.product

      await this.$apollo.mutate({
        mutation: CANCEL_PRODUCT,
        variables: {
          input: {
            contractId: this.contractId,
            productId: id,
            cancelledAt,
            endDate,
            reason: cancellationReason,
            comment: cancellationComment
          }
        },
        refetchQueries: [{ query: GET_COMPANY_CONTRACTS, variables: { id: this.companyId } }]
      })
    }
  }
}
</script>
