<template>
  <crud-modal
    title="BrandEasy Template hinzufügen"
    action="create"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <div class="mb-6">
      <input-select-validated
        ref="target"
        v-model="target"
        label="BrandEasy Target"
        :options="TARGET"
        rules="required"
      />
    </div>
    <div class="mb-6">
      <input-text-validated
        ref="name"
        v-model="name"
        input-class="w-full"
        label="Name"
        rules="required"
      />
    </div>
    <div class="mb-6">
      <input-text-validated
        ref="templateName"
        v-model="templateName"
        input-class="w-full"
        label="Kampagne Template"
        rules="required"
        placeholder="bot_kam_{variant}-"
      />
    </div>
    <div
      v-if="target === 'PRINT'"
      class="mb-6"
    >
      <input-text-validated
        ref="bleed"
        v-model="bleed"
        input-class="w-full"
        label="Bleed (BrandEasy)"
        rules="required|bleed"
        placeholder="1,2,3,4"
      />
    </div>
  </crud-modal>
</template>

<script>
import CrudModal from '@/components/modals/crud-modal'
import InputSelectValidated from '@/components/form/select-validated'
import InputTextValidated from '@/components/form/text-validated'

import GET_CAMPAIGN from '@/graphql/GetCampaign.gql'
import GET_CAMPAIGN_VIDEO_DIRECTORIES from '@/graphql/GetCampaignVideoDirectories.gql'
import CREATE_BRAND_EASY_MATERIAL_TEMPLATE_FOR_CAMPAIGN from '@/graphql/CreateBrandEasyMaterialTemplateForCampaign.gql'

export default {
  components: { CrudModal, InputSelectValidated, InputTextValidated },
  props: {
    campaignId: { type: String, required: true }
  },
  data () {
    return {
      target: '',
      name: '',
      templateName: 'bot_kam_{variant}-',
      bleed: '',
      TARGET: [
        { value: 'PRINT', name: 'PDF (Print)' },
        { value: 'SCREEN', name: 'PDF (Web)' },
        { value: 'WEB_JPG', name: 'JPG (Web)' }
      ]
    }
  },
  apollo: {
    directories: {
      query: GET_CAMPAIGN_VIDEO_DIRECTORIES,
      variables () {
        return { campaignId: this.campaignId }
      },
      update: ({ directories }) => directories.map((directory) => ({ value: directory, name: directory }))
    }
  },
  methods: {
    async submit () {
      this.$apollo.mutate({
        mutation: CREATE_BRAND_EASY_MATERIAL_TEMPLATE_FOR_CAMPAIGN,
        variables: {
          input: {
            campaignId: this.campaignId,
            name: this.name,
            templateName: this.templateName,
            target: this.target,
            bleed: this.bleed
          }
        },
        refetchQueries: [{ query: GET_CAMPAIGN, variables: { campaignId: this.campaignId } }]
      })
    }
  }
}
</script>
