<template>
  <div>
    <div class="flex items-center justify-between px-10 py-8 bg-gray-100 border-b rounded-t-lg">
      <h1 class="text-xl font-semibold tracking-wider text-gray-800 uppercase">
        Kampagne erstellen
      </h1>
      <router-link
        :to="`/campaigns`"
        class="font-semibold text-gray-600 uppercase hover:text-gray-800"
      >
        Abbrechen
      </router-link>
    </div>

    <CampaignForm
      :on-submit="submit"
      :campaign="campaign"
    />
  </div>
</template>

<script>
import CampaignForm from './form.vue'

import CREATE_CAMPAIGN from '@/graphql/CreateCampaign.gql'

export default {
  components: { CampaignForm },
  data () {
    return {
      campaign: { landingpages: [], type: 'DEFAULT' }
    }
  },
  methods: {
    async submit () {
      const { name, slug, landingpages, type } = this.campaign
      const { data } = await this.$apollo.mutate({
        mutation: CREATE_CAMPAIGN,
        variables: {
          input: {
            name,
            slug,
            landingpages,
            type
          }
        }
      })

      this.$router.push({
        path: `/campaigns/${data.campaign.id}`
      })
    }
  }
}
</script>
