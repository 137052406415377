<template>
  <crud-modal
    title="Moovly Template hinzufügen"
    action="create"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <div class="mb-6">
      <input-text-validated
        ref="templateName"
        v-model="templateName"
        input-class="w-full"
        label="Kampagne Template"
        rules="required"
        placeholder="{variant}_bundestagswahl2021_POST"
      />
    </div>

    <div class="mb-6">
      <input-text-validated
        ref="name"
        v-model="name"
        input-class="w-full"
        label="Kampagne Name"
        rules="required"
        placeholder="Bundestagswahl 2021"
      />
    </div>

    <div class="mb-6">
      <input-select-validated
        ref="resolution"
        v-model="resolution"
        label="Moovly Auflösung"
        :options="RESOLUTION"
      />
    </div>
  </crud-modal>
</template>

<script>
import CrudModal from '@/components/modals/crud-modal'
import InputSelectValidated from '@/components/form/select-validated'
import InputTextValidated from '@/components/form/text-validated'

import GET_CAMPAIGN from '@/graphql/GetCampaign.gql'
import CREATE_MOOVLY_MATERIAL_TEMPLATE_FOR_CAMPAIGN from '@/graphql/CreateMoovlyMaterialTemplateForCampaign.gql'

export default {
  components: { CrudModal, InputSelectValidated, InputTextValidated },
  props: {
    campaignId: { type: String, required: true }
  },
  data () {
    return {
      name: '',
      templateName: '{variant}_',
      resolution: '720p',
      RESOLUTION: [
        { value: '480p', name: '480p' },
        { value: '720p', name: '720p' },
        { value: '1080p', name: '1080p' }
      ]
    }
  },
  methods: {
    async submit () {
      this.$apollo.mutate({
        mutation: CREATE_MOOVLY_MATERIAL_TEMPLATE_FOR_CAMPAIGN,
        variables: {
          input: {
            campaignId: this.campaignId,
            name: this.name,
            templateName: this.templateName,
            resolution: this.resolution
          }
        },
        refetchQueries: [{ query: GET_CAMPAIGN, variables: { campaignId: this.campaignId } }]
      })
    }
  }
}
</script>
