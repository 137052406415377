<template>
  <div class="flex flex-col">
    <div
      v-if="company.slug"
      class="flex flex-row w-full p-10 py-2"
    >
      <img
        v-if="company.images && company.images.logo"
        :src="company.images.logo.url"
        class="object-contain h-24 mr-6"
        height="96"
        width="96"
      >
      <div class="flex flex-col flex-grow min-w-0 max">
        <span class="h-16 pr-4 text-4xl font-semibold text-gray-800 truncate">
          {{ company.name }}
        </span>
        <div class="flex items-end">
          <Feature
            v-slot="{feature}"
            :feature-slug="featureNames.BILLWERK"
          >
            <div>
              <span
                v-if="feature.isActive"
                class="pr-4"
              >{{ company.debtorId }}</span>
              <span
                v-else
                class="pr-4"
              >{{ company.customerNumber }}</span>
            </div>
          </Feature>
          <span class="pr-4">{{ company.slug }}</span>
          <Feature :feature-slug="featureNames.BILLWERK">
            <span class="font-bold">{{ company.productType }}</span>
          </Feature>
          <a
            v-if="company.zohoUrl"
            class="pl-4 font-bold"
            :href="company.zohoUrl"
            target="_blank"
          >CRM <fa :icon="faLink" /></a>
        </div>
      </div>
    </div>
    <state-update
      :company-id="company.id"
      :company-name="company.name"
      :is-onboarding-data-complete="company.isOnboardingDataComplete"
    />
  </div>
</template>

<script>
import StateUpdate from './stateUpdate.vue'
import { faLink } from '@fortawesome/free-solid-svg-icons'

import feature from '@/mixins/feature'

export default {
  components: {
    StateUpdate
  },
  mixins: [feature],
  props: {
    company: { type: Object, default: () => ({}) }
  },
  created () {
    this.faLink = faLink
  }
}
</script>
