<template>
  <section class="w-full">
    <h1 class="flex items-center justify-between">
      Repräsentant
      <button
        class="btn-inline-small"
        @click="showAmbassadorUpdateModal(ambassador)"
      >
        <fa :icon="faPen" />
        Bearbeiten
      </button>
    </h1>
    <div class="w-full -mx-2">
      <div class="relative w-full px-2 py-1">
        <div class="grid gap-2 px-4 py-4 bg-gray-200 rounded md:grid-cols-2 md:grid-rows-2 lg:grid-rows-2 lg:grid-cols-3 group hover:bg-gray-300">
          <div class="col-span-1 lg:row-span-2">
            <div class="lg:flex-1">
              <data-field
                label="Portrait"
                placeholder=""
                :has-value="Boolean(ambassador.image && ambassador.image.url)"
                required
              />
              <img
                v-if="ambassador.image && ambassador.image.url"
                :src="ambassador.image.url"
                class="object-cover w-48 h-48 rounded"
              >
            </div>
          </div>

          <div class="flex flex-col lg:col-span-2">
            <data-field
              label="Anrede"
              :value="ambassador.salutation"
            />
            <data-field
              label="Name"
              :has-value="Boolean(ambassador.name || ambassador.firstname && ambassador.lastname)"
              required
            >
              {{ ambassadorName }}
            </data-field>
            <data-field
              label="Rolle"
              :value="ambassador.role"
              required
            />
            <data-field
              label="E-Mail"
              :value="ambassador.email"
              required
            />
            <data-field
              label="Telefon"
              :value="ambassador.phone"
              required
            />
            <data-field
              label="Mobil"
              :value="ambassador.mobile"
              required
            />
            <data-field
              label="Kontakt-Slogan"
              :value="ambassador.contactSlogan"
            />
            <data-field
              label="Kontakt-Text"
              :value="ambassador.contactText"
            />
          </div>

          <div
            v-if="ambassador.signature"
            class="lg:flex md:col-span-2 lg:row-span-1"
          >
            <div
              class="text-gray-600 lg:w-1/2"
            >
              <div class="font-semibold text-gray-600 uppercase">
                Signatur HTML:
              </div>
              <textarea
                v-model="ambassador.signature.html"
                cols="30"
                rows="5"
                class="w-full p-2 bg-gray-100 rounded border-1"
                disabled
              />
            </div>
            <div class="pt-2 text-gray-600 lg:w-1/2 lg:ml-4 lg:pt-0">
              <div class="font-semibold text-gray-600 uppercase">
                Signatur Text:
              </div>
              <textarea
                v-model="ambassador.signature.text"
                cols="30"
                rows="5"
                class="w-full p-2 bg-gray-100 rounded border-1"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import UpdateAmbassadorModal from '@/components/modals/ambassador/update'
import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons'
import dataField from '../data-field.vue'

export default {
  components: { dataField },
  props: {
    ambassador: { type: Object, default: () => ({}) },
    companyId: { type: String, default: '' }
  },
  data () {
    return {
      faPen, faPlus
    }
  },
  computed: {
    ambassadorName () {
      return this.ambassador.name || `${this.ambassador.firstname} ${this.ambassador.lastname}`
    }
  },
  methods: {
    showAmbassadorUpdateModal (ambassador) {
      this.$modal.show(
        UpdateAmbassadorModal,
        {
          companyId: this.companyId,
          ambassador: {
            ...ambassador,
            image: ambassador.image || {},
            signature: ambassador.signature || {}
          }
        },
        {
          height: 'auto',
          scrollable: true
        }
      )
    }
  }
}
</script>
