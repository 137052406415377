import PropertyType from '@/components/leads/enums/valuation/PropertyType'
import PropertySubType from '@/components/leads/enums/valuation/PropertySubType'

export const readablePropertyType = {
  [PropertyType.APARTMENT]: 'Wohnung',
  [PropertyType.HOUSE]: 'Einfamilienhaus',
  [PropertyType.MULTI_FAMILY_HOUSE]: 'Mehrfamilienhaus',
  [PropertyType.PLOT]: 'Grundstück'
}

export const readablePropertySubType = {
  [PropertySubType.APARTMENT_TERRACED]: 'Erdgeschoss',
  [PropertySubType.APARTMENT_NORMAL]: 'Etagenwohnung',
  [PropertySubType.APARTMENT_STUDIO]: 'Studio',
  [PropertySubType.APARTMENT_MAISONETTE]: 'Maisonette',
  [PropertySubType.APARTMENT_PENTHOUSE]: 'Penthouse',
  [PropertySubType.APARTMENT_ATTIC]: 'Dachgeschoss',
  [PropertySubType.HOUSE_DETACHED]: 'Freistehend',
  [PropertySubType.HOUSE_SEMI_DETACHED]: 'Doppelhaushälfte',
  [PropertySubType.HOUSE_ROW_CORNER]: 'Reihenendhaus',
  [PropertySubType.HOUSE_ROW_MIDDLE]: 'Reihenmittelhaus',
  [PropertySubType.HOUSE_FARM]: 'Landhaus'
}
