<template>
  <crud-modal
    title="Dokument hochladen"
    action="create"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <document-form
      :document="document"
      :product-templates="productTemplates"
    />
  </crud-modal>
</template>
<script>
import DocumentForm from './form'
import CrudModal from '@/components/modals/crud-modal'

import ADD_DOCUMENT from '@/graphql/AddDocumentToContract.gql'

export default {
  components: {
    DocumentForm,
    CrudModal
  },
  props: {
    contractId: {
      type: String,
      required: true
    },
    productTemplates: { type: Array, default: () => ([]) }
  },
  data () {
    return {
      document: { }
    }
  },
  methods: {
    async submit () {
      const { type, comment, file: { file } } = this.document
      await this.$apollo.mutate({
        mutation: ADD_DOCUMENT,
        variables: {
          input: {
            contractId: this.contractId,
            document: {
              type,
              comment,
              file
            }
          }
        }
      })
    }
  }
}
</script>
