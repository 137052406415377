<template>
  <crud-modal
    title="Landingpage löschen"
    action="delete"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <div class="mb-2">
      Soll die Landingpage
      <span class="font-semibold">"{{ slug }}"</span>
      wirklich gelöscht werden?
    </div>
  </crud-modal>
</template>

<script>
import CrudModal from '@/components/modals/crud-modal'
import DELETE_LANDINGPAGE from '@/graphql/DeleteLandingpage.gql'
import GET_COMPANY from '@/graphql/GetCompany.gql'
import eventBus, { eventNames } from '@/lib/eventBus'

export default {
  components: { CrudModal },
  props: {
    companyId: {
      type: String,
      required: true
    },
    landingpageId: {
      type: String,
      required: true
    },
    slug: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isSubmitting: false
    }
  },
  methods: {
    async submit () {
      try {
        await this.$apollo.mutate({
          mutation: DELETE_LANDINGPAGE,
          variables: {
            landingpageId: this.landingpageId
          },
          update: (store, { data: { deleteLandingpage } }) => {
            const data = store.readQuery({
              query: GET_COMPANY,
              variables: { id: this.companyId }
            })

            if (deleteLandingpage) {
              data.company.landingpages = data.company.landingpages.filter(
                lp => lp.id !== this.landingpageId
              )
              store.writeQuery({ query: GET_COMPANY, data })
            }
          }
        })
      } catch (error) {
        if (error.message === 'GraphQL error: Missing permission') {
          eventBus.$emit(eventNames.SHOW_SNACKBAR, { type: 'error', text: 'Keine Berechtigung zum Löschen.' })
        } else {
          eventBus.$emit(eventNames.SHOW_SNACKBAR, { type: 'error', text: 'Fehler beim Löschen.' })
        }
      }
    }
  }
}
</script>
