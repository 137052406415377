<template>
  <crud-modal
    title="Seite bearbeiten"
    action="update"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <div class="flex flex-col w-full mb-2">
      <label>Google Tag Manager:</label>
      <input-text-validated
        v-model="tracking.gtm"
        class="mb-6"
        input-class="w-full"
        rules="gtm"
      />
    </div>
  </crud-modal>
</template>

<script>
import CrudModal from '@/components/modals/crud-modal'
import InputTextValidated from '@/components/form/text-validated'
import UPDATE_COMPANY_SITE from '@/graphql/UpdateCompanySite.gql'
import GET_COMPANY from '@/graphql/GetCompany.gql'

export default {
  components: {
    InputTextValidated,
    CrudModal
  },
  props: {
    companyId: {
      type: String,
      required: true
    },
    tracking: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isSubmitting: false
    }
  },
  methods: {
    async submit () {
      await this.$apollo.mutate({
        mutation: UPDATE_COMPANY_SITE,
        variables: {
          input: {
            companyId: this.companyId,
            tracking: {
              gtm: this.tracking.gtm
            }
          }
        },
        refetchQueries: [
          { query: GET_COMPANY, variables: { id: this.companyId } }
        ]
      })
    }
  }
}
</script>
