<template>
  <div class="flex flex-col w-full mb-1">
    <data-field label="Schlüssel eingetragen">
      {{ propstackConfig.token?.length > 0 ? 'ja' : 'nein' }}
    </data-field>
    <data-field label="Schlüssel gültig">
      {{ propstackConfig.isTokenVerified ? 'ja' : 'nein' }}
    </data-field>
    <data-field label="Gewählte Status">
      {{ statuses }}
    </data-field>
  </div>
</template>

<script>
import PROPSTACK_CONFIG from '@/graphql/PropstackConfig.gql'
import PROPSTACK_CONTACT_STATUSES from '@/graphql/PropstackContactStatuses.gql'
import DataField from '@/components/data-field'

export default {
  components: {
    DataField
  },
  props: {
    companyId: { type: String, default: '' }
  },
  data () {
    return {
      propstackConfig: { selectedContactStatusIds: [] },
      contactStatuses: []
    }
  },
  computed: {
    statuses () {
      const contactStatusesMap = new Map([
        ...this.contactStatuses.map(status => [status.id, status.name]),
        ['ALL', 'Alle Kontakte'],
        ['UNDEFINED', 'Kontakte ohne Status']
      ])
      return this.propstackConfig.selectedContactStatusIds.map(id => contactStatusesMap.get(id)).join(', ')
    }
  },
  apollo: {
    propstackConfig: {
      query: PROPSTACK_CONFIG,
      variables () {
        return {
          companyId: this.companyId
        }
      }
    },
    contactStatuses: {
      query: PROPSTACK_CONTACT_STATUSES,
      variables () {
        return {
          companyId: this.companyId
        }
      }
    }
  }
}
</script>
