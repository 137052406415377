<template>
  <div>
    <div class="flex items-center justify-between px-10 py-8 bg-gray-100 border-b rounded-t-lg">
      <h1 class="text-xl font-semibold tracking-wider text-gray-800 uppercase">
        Landingpage erstellen
      </h1>
      <router-link
        :to="`/landingpages`"
        class="font-semibold text-gray-600 uppercase hover:text-gray-800"
      >
        Abbrechen
      </router-link>
    </div>

    <LandingpageTemplateForm
      :landingpage-template="landingpageTemplate"
      :error-message="serverError"
      :on-submit="submit"
    />
  </div>
</template>

<script>
import LandingpageTemplateForm from './form.vue'
import CREATE_LANDINGPAGE_TEMPLATES from '@/graphql/CreateLandingpageTemplates.gql'

export default {
  components: { LandingpageTemplateForm },

  data () {
    return {
      landingpageTemplate: {
        type: this.$route.query.type,
        slug: this.$route.query.slug,
        name: this.$route.query.name
      },
      serverError: null
    }
  },

  methods: {
    async submit () {
      this.serverError = null

      const { slug, type, name, countryCodes } = this.landingpageTemplate

      try {
        const { data } = await this.$apollo.mutate({
          mutation: CREATE_LANDINGPAGE_TEMPLATES,
          variables: {
            input: {
              slug,
              type,
              name,
              countryCodes: countryCodes === 'all' ? [] : countryCodes
            }
          }
        })

        const { landingpageTemplates } = data
        const createdCount = landingpageTemplates.length
        alert(`${createdCount} Landingpages erstellt: ${landingpageTemplates.map(t => t.countryCode.toUpperCase()).join(', ')}`)

        let redirectPath = '/landingpages'

        if (createdCount > 0) {
          redirectPath = `/landingpages/${landingpageTemplates[0].id}`
        }
        this.$router.push({ path: redirectPath })
      } catch (err) {
        this.serverError = err.message
      }
    }
  }
}
</script>
