
<template>
  <crud-modal
    title="E-Mail reaktivieren"
    action="update"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <div class="mb-10">
      Soll die E-Mail
      <span class="font-semibold">"{{ email }}"</span>
      wirklich reaktiviert werden?
    </div>

    <p v-if="state === 'MANUAL_SUPPRESSION'">
      MANUAL_SUPPRESSION bedeutet, das dieser Empfänger manuell von {{ readablePlatformName }} auf Inaktiv gesetzt wurde.
    </p>
    <p v-if="state === 'HARD_BOUNCE'">
      HARD_BOUNCE bedeutet, dass ein Problem bei der E-Mail des Empängers vorliegt.
      Dies kann bspw. bedeuten, dass das Postfach des Empängers voll ist, oder die E-Mail Adresse inaktiv/invalide ist.
    </p>

    <p class="mt-10">
      Falls diese E-Mail trotz Reaktivierung in der Liste auftaucht, kann der Nutzer nicht reaktiviert werden.
      In diesem Fall resultiert ein Versuch auf Reaktivierung lediglich in einem Herabstufen von  {{ readablePlatformName }} im Mailing.
    </p>
  </crud-modal>
</template>

<script>
import CrudModal from '@/components/modals/crud-modal'
import brandingMixin from '@/mixins/branding'

export default {
  components: { CrudModal },
  mixins: [brandingMixin],
  props: {
    email: {
      type: String,
      required: true
    },
    onSubmit: {
      type: Function,
      default: () => {}
    },
    state: {
      type: String,
      default: ''
    }
  },
  methods: {
    async submit () {
      this.onSubmit()
    }
  }
}
</script>
