export const ROLES = {
  SUPER_ADMIN: 'Super-Admin',
  BO_DEV: 'backoffice:dev',
  ADMIN: 'Admin'
}

export default {
  data () {
    return {
      roles: this.$auth.user.roles,
      ROLES: ROLES
    }
  },
  methods: {
    hasRole (role) {
      return this.$auth.hasRole(role)
    }
  }
}
