<template>
  <div v-if="isActive">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    name: { type: String, required: true },
    selected: { type: Boolean, default: false }
  },
  data () {
    return {
      isActive: false
    }
  },
  mounted () {
    this.isActive = this.selected
  }
}
</script>
