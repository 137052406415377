<template>
  <CrudModal
    title="Mit Billwerk verknüpfen"
    action="update"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <BillwerkOrder
      change
      create
      :order-data="orderData"
      :bookable-sales-plans="bookableSalesPlans"
    />
  </CrudModal>
</template>
<script>
import CrudModal from '@/components/modals/crud-modal'
import featureMixin from '@/mixins/feature'
import CREATE_ORDER from '@/graphql/CreateOrder.gql'
import CHANGE_CONTRACT from '@/graphql/ChangeContract.gql'
import GET_BOOKABLE_SALES_PLANS from '@/graphql/GetBookableSalesPlans.gql'
import BillwerkOrder from '@/components/form/billwerk-order'

export default {
  components: {
    CrudModal,
    BillwerkOrder
  },
  mixins: [featureMixin],
  props: {
    company: { type: Object, required: true }
  },
  data () {
    return {
      isSubmitting: false,
      bookableSalesPlans: [],
      orderData: {
        name: this.company.name,
        contractId: '',
        customerId: '',
        plan: '',
        planVariant: '',
        discount: '',
        discounts: [],
        components: [],
        startDate: null
      }
    }
  },
  apollo: {
    bookableSalesPlans: {
      query: GET_BOOKABLE_SALES_PLANS,
      variables () {
        return {
          countryCode: this.company.country
        }
      }
    }
  },
  methods: {
    async submit () {
      if (this.orderData.contractId && this.orderData.customerId) {
        await this.submitContractChange()
      } else {
        await this.submitOrder()
      }
    },
    async submitOrder () {
      const { id, country } = this.company
      try {
        this.isSubmitting = true
        await this.$apollo.mutate({
          mutation: CREATE_ORDER,
          variables: {
            input: {
              companyId: id,
              countryCode: country,
              companyName: this.orderData.name,
              startDate: this.orderData.startDate,
              planVariantId: this.orderData.planVariant,
              discounts: this.orderData.discounts,
              components: this.orderData.components
            }
          }
        })
        this.isSubmitting = false
        window.location.reload()
      } catch (error) {
        this.setValidationErrors(error, 'company')
        this.isSubmitting = false
      }
    },
    async submitContractChange () {
      try {
        this.isSubmitting = true
        await this.$apollo.mutate({
          mutation: CHANGE_CONTRACT,
          variables: {
            input: {
              companyId: this.company.id,
              contractId: this.orderData.contractId,
              customerId: this.orderData.customerId
            }
          }
        })
        this.isSubmitting = false
        window.location.reload()
      } catch (error) {
        this.setValidationErrors(error, 'company')
        this.isSubmitting = false
      }
    }
  }
}
</script>
