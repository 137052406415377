<template>
  <div>
    <div class="px-10 py-8 bg-gray-100 border-b rounded-t-lg">
      <h1 class="text-xl font-semibold tracking-wider text-gray-800 uppercase">
        Dashboard
      </h1>
    </div>

    <div class="px-10 py-8">
      <div class="flex">
        <router-link
          to="/companies"
          class="flex flex-col justify-center w-1/5 h-32 border-4 border-gray-600 rounded shadow-lg"
        >
          <div class="text-3xl font-bold text-center text-gray-800">
            {{ companies.length }}
          </div>
          <div class="text-xl text-center text-gray-700">
            Immobilienfirmen
          </div>
        </router-link>

        <template v-if="hasInactiveEmailHandling">
          <router-link
            to="/inactive-emails"
            class="flex flex-col justify-center w-1/5 h-32 ml-5 border-4 border-gray-600 rounded shadow-lg"
          >
            <div class="text-3xl font-bold text-center text-gray-800">
              {{ inactiveEmailUsers.length }}
            </div>
            <div class="text-xl text-center text-gray-700">
              Inaktive E-Mails
            </div>
          </router-link>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import LIST_COMPANIES from '@/graphql/ListCompanies.gql'
import INACTIVE_EMAIL_USERS from '@/graphql/InactiveEmailUsers.gql'
import branding from '@/mixins/branding'

export default {
  mixins: [branding],
  apollo: {
    companies: {
      query: LIST_COMPANIES
    },
    inactiveEmailUsers: {
      query: INACTIVE_EMAIL_USERS
    }
  },
  data () {
    return {
      companies: [],
      inactiveEmailUsers: []
    }
  }
}
</script>
