<template>
  <div
    class="px-4 py-3 border-2 rounded"
    :class="classes.root"
    role="alert"
  >
    <div class="flex items-center">
      <div class="py-1">
        <svg
          class="w-6 h-6 mr-4 fill-current"
          :class="classes.icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        ><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg>
      </div>
      <p class="font-bold">
        <slot />
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'success',
      validator: value => [
        'success',
        'warning',
        'error'
      ].indexOf(value) > -1
    }
  },
  computed: {
    classes () {
      return ({
        success: {
          root: 'bg-green-100 border-green-500 text-green-900',
          icon: 'text-green-500'
        },
        error: {
          root: 'bg-red-100 border-red-500 text-red-900',
          icon: 'text-red-500'
        },
        warning: {
          root: 'bg-yellow-100 border-yellow-500 text-yellow-900',
          icon: 'text-yellow-500'
        }
      })[this.type]
    }
  }
}
</script>
