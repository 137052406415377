<template>
  <section class="w-full">
    <h1 class="flex items-center justify-between">
      Rechungsdaten
      <div class="text-sm text-gray-600">
        <button
          class="font-semibold uppercase hover:text-gray-800"
          @click="showCompanyBillingEditModal()"
        >
          <fa :icon="faPen" />
          Bearbeiten
        </button>
      </div>
    </h1>

    <div class="flex flex-wrap w-full">
      <div class="flex-col w-full mb-2 lg:w-1/2">
        <data-field
          label="Umsatzsteuer ID"
          required
          :has-value="Boolean(company.billing.vatId || company.billing.vatIdRequestDate)"
        >
          {{ company.billing.vatId ?
            company.billing.vatId :
            company.billing.vatIdRequestDate && 'Beantragt am: ' + formatDate(company.billing.vatIdRequestDate) }}
        </data-field>
        <data-field
          label="Zahlungsart"
          required
          :has-value="Boolean(company.billing.paymentMethod)"
        >
          {{ getPaymentMethodsName(company.billing.paymentMethod) }}
        </data-field>
      </div>
      <div class="flex-col w-full mb-2 lg:w-1/2">
        <data-field
          label="BIC"
          :value="company.billing.bic"
        />
        <data-field
          label="IBAN"
          :value="company.billing.iban"
        />
        <data-field
          label="SEPA gültig ab"
          :value="formatDate(company.billing.sepaValidAsOf)"
        />
      </div>
      <div class="flex-col w-full mb-2 lg:w-1/2">
        <data-field label="Firmenname">
          {{ company.billing.contact.companyName }}
        </data-field>
        <data-field label="Firmenname Zusatz">
          {{ company.billing.contact.companyNameAddition }}
        </data-field>
        <data-field
          label="Vorname"
          required
          :value="company.billing.contact.firstname"
        />
        <data-field
          label="Nachname"
          required
          :value="company.billing.contact.lastname"
        />
        <data-field
          label="E-Mail"
          required
          :value="company.billing.contact.email"
        />
        <data-field
          label="Telefon"
          required
          :value="company.billing.contact.phone"
        />
      </div>
      <div class="flex-col w-full mb-2 lg:w-1/2">
        <h2 class="flex items-center w-full my-4 font-medium uppercase border-b-2">
          <input
            v-model="company.billing.hasSeparateBillingAddress"
            type="checkbox"
            disabled
            class="w-4 h-4 mr-2 border-2 border-gray-600 form-checkbox"
            :value="company.billing.hasSeparateBillingAddress || false"
          >
          Abweichende Rechnungsadresse
        </h2>
        <data-field label="Straße">
          {{ company.billing.address.street }}
        </data-field>
        <data-field label="PLZ">
          {{ company.billing.address.zip }}
        </data-field>
        <data-field label="Stadt">
          {{ company.billing.address.city }}
        </data-field>
      </div>
    </div>
  </section>
</template>

<script>
import EditCompanyBillingModal from '@/components/modals/company/billing/edit'
import DataField from '@/components/data-field'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { getPaymentMethodsName } from '@/config'

import GET_COMPANY_BILLING from '@/graphql/GetCompanyBilling.gql'

export default {
  components: { DataField },
  props: {
    companyId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      faPen,
      company: {
        billing: {
          contact: {},
          address: {}
        }
      }
    }
  },
  apollo: {
    company: {
      query: GET_COMPANY_BILLING,
      variables () {
        return { id: this.companyId }
      },
      update (data) {
        data.company.billing = data.company.billing || {}
        const { billing, address } = data.company
        const { street, zip, city } = address || {}
        billing.contact = billing.contact || {}
        billing.address = billing.hasSeparateBillingAddress
          ? billing.address || {}
          : { street, zip, city }
        return data.company
      }
    }
  },
  methods: {
    getPaymentMethodsName,
    showCompanyBillingEditModal () {
      this.$modal.show(
        EditCompanyBillingModal,
        {
          company: {
            ...this.company,
            billing: {
              ...this.company.billing,
              contact: { ...this.company.billing.contact },
              address: { ...this.company.billing.address }
            }
          }
        }
      )
    }
  }
}
</script>
