<template>
  <Feature
    v-slot="{feature}"
    :feature-slug="featureNames.COMPANY_USER"
  >
    <section class="w-full">
      <h1 class="flex items-center justify-between">
        User & Zugang
        <div>
          <Feature
            :feature-slug="featureNames.AUTHORIZATION"
            use-is-enabled
          >
            <button
              v-if="hasRole(ROLES.SUPER_ADMIN) && !users.find(user => user.role)"
              class="btn-inline-small"
              @click="showAuthorizationModal()"
            >
              Rechte & Rollen aktivieren
            </button>
          </Feature>
          <button
            class="ml-12 btn-inline-small"
            :disabled="hasMaxUsers(feature.config.maxUsers) && !hasRole(ROLES.SUPER_ADMIN)"
            @click="showUserCreateModal()"
          >
            Hinzufügen
          </button>
        </div>
      </h1>
      <div class="flex justify-center w-full">
        <div
          v-if="hasMaxUsers(feature.config.maxUsers)"
          class="relative px-4 py-3 text-red-700 bg-red-100 border border-red-400 rounded"
          role="alert"
        >
          <strong class="mr-2 font-bold">Hinweis!</strong>
          <span class="block sm:inline">Nutzerlimit für diese Firma erreicht.  {{ users.length }}/{{ feature.config.maxUsers }}</span>
        </div>
      </div>
      <div
        v-if="users.length"
        class="w-full"
      >
        <div class="flex py-3 text-gray-700 uppercase border-b-4">
          <div class="w-3/12 font-semibold">
            Name
          </div>
          <div class="w-3/12 font-semibold">
            E-Mail
          </div>
          <div class="w-2/12 font-semibold">
            Rolle
          </div>
          <div class="w-1/12 font-semibold">
            Letzter Login
          </div>
          <div class="w-1/12 font-semibold">
            Telefonnummer hinterlegt
          </div>
          <div class="w-2/12 font-semibold">
              &nbsp;
          </div>
        </div>

        <div
          v-for="user in users"
          :key="user.id"
          class="flex items-center py-2 border-b hover:bg-gray-100 group"
        >
          <div class="w-3/12 pr-1 truncate">
            {{ user.firstname }} {{ user.lastname }}
          </div>
          <div class="w-3/12 pr-1 truncate">
            {{ user.email }}
          </div>
          <div class="w-2/12 pr-1 truncate">
            {{ user.role?.name }}
          </div>
          <div class="w-1/12 truncate">
            {{ formatDateTime(user.lastLoginAt) }}
          </div>
          <div class="w-1/12 text-center truncate">
            <input
              v-model="user.phone"
              type="checkbox"
              disabled
              class="w-4 h-4 form-checkbox"
            >
          </div>
          <div class="flex items-center justify-end w-2/12 pr-4 space-x-1">
            <span v-if="user.isBlocked"> DEAKTIVIERT </span>
            <button
              title="E-Mail zum Zurücksetzen des Passworts senden"
              class="btn-icon"
              @click="showUserPasswordResetModal(user)"
            >
              <fa
                :icon="faKey"
                fixed-width
              />
            </button>
            <button
              title="User bearbeiten"
              class="btn-icon"
              @click="showUserUpdateModal(user)"
            >
              <fa
                :icon="faPen"
                fixed-width
              />
            </button>
            <button
              :title="`User ${user.isBlocked ? '' : 'de'}aktivieren`"
              :class="`btn-icon ${user.isBlocked ? '': 'btn-icon--delete'}`"
              @click="user.isBlocked? showUserActivateModal(user) : showUserDeactivateModal(user)"
            >
              <fa
                :icon="faPowerOff"
                fixed-width
              />
            </button>
            <button
              title="User löschen"
              class="btn-icon btn-icon--delete"
              @click="showUserDeleteModal(user)"
            >
              <fa
                :icon="faTrash"
                fixed-width
              />
            </button>
          </div>
        </div>
      </div>
      <div
        v-else
        class="w-full py-4 text-sm font-semibold text-center text-gray-600 uppercase bg-gray-100 rounded"
      >
        Keine User gefunden
      </div>
    </section>
  </Feature>
</template>

<script>
import GET_USERS from '@/graphql/GetUsers.gql'

import AuthorizationModal from '@/components/modals/user/authorization'
import CreateUserModal from '@/components/modals/user/create'
import UpdateUserModal from '@/components/modals/user/update'
import DeleteUserModal from '@/components/modals/user/delete'
import DeactivateUserModel from '@/components/modals/user/deactivate'
import ActivateUserModel from '@/components/modals/user/activate'
import UserPasswordResetModal from '@/components/modals/user/password-reset'
import { faTrash, faPen, faKey, faPowerOff } from '@fortawesome/free-solid-svg-icons'
import role from '@/mixins/role'
import feature from '@/mixins/feature'

export default {
  mixins: [role, feature],
  props: {
    companyId: { type: String, default: '' }
  },
  data () {
    return {
      faTrash,
      faPen,
      faKey,
      faPowerOff,
      users: []
    }
  },
  methods: {
    hasMaxUsers (maxUsers) {
      return this.users.length >= maxUsers
    },
    showAuthorizationModal () {
      this.$modal.show(
        AuthorizationModal,
        { companyId: this.companyId },
        { height: 'auto' }
      )
    },
    showUserCreateModal () {
      this.$modal.show(
        CreateUserModal,
        { companyId: this.companyId },
        { height: 'auto' }
      )
    },
    showUserUpdateModal (user) {
      this.$modal.show(
        UpdateUserModal,
        { companyId: this.companyId, user },
        { height: 'auto' }
      )
    },
    showUserDeleteModal (user) {
      this.$modal.show(
        DeleteUserModal,
        { companyId: this.companyId, user },
        { height: 'auto' }
      )
    },
    showUserPasswordResetModal (user) {
      this.$modal.show(
        UserPasswordResetModal,
        { userId: user.id },
        { height: 'auto' }
      )
    },
    showUserDeactivateModal (user) {
      this.$modal.show(
        DeactivateUserModel,
        { user, companyId: this.companyId },
        { height: 'auto' }
      )
    },
    showUserActivateModal (user) {
      this.$modal.show(
        ActivateUserModel,
        { user, companyId: this.companyId },
        { height: 'auto' }
      )
    }
  },
  apollo: {
    users: {
      query: GET_USERS,
      variables () {
        return {
          companyId: this.companyId
        }
      }
    }
  }
}
</script>
