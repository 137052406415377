<template>
  <div :class="classname">
    <label
      v-if="label"
      class="block w-full"
    >{{ label }}:</label>
    <div class="relative inline-block w-full">
      <select
        class="block w-full px-3 py-2 bg-white border-2 rounded appearance-none"
        :class="selectClass"
        :multiple="multiple"
        v-bind="$attrs"
        :value="value"
        :disabled="disabled"
        v-on="listeners"
      >
        <option
          v-if="!value || optional"
          value=""
          :selected="!value"
          :disabled="!optional"
        >
          Auswählen...
        </option>
        <option
          v-for="option in options"
          :key="option.value"
          :value="option.value"
          :selected="multiple ? value.indexOf(option.value) > -1 : option.value === value"
          :disabled="option.disabled"
        >
          {{ option.name }}
        </option>
      </select>
      <div
        v-if="!multiple"
        class="absolute inset-y-0 right-0 flex items-center text-gray-700 pointer-events-none"
      >
        <div class="p-1 pl-2 mr-2">
          <svg
            class="w-4 h-4 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Array, String, Number],
      default: null
    },
    label: {
      type: String,
      default: null
    },
    classname: {
      type: String,
      default: ''
    },
    selectClass: {
      type: [Object, Array, String],
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    multiple: Boolean,
    optional: Boolean,
    disabled: Boolean
  },
  computed: {
    listeners: function () {
      const vm = this
      return {
        ...this.$listeners,
        input: function (event) {
          const value = vm.multiple ? Array.from(event.target.options)
            .filter(option => option.selected)
            .map(option => option.value) : event.target.value

          vm.$emit('input', value)
        }
      }
    }
  }
}
</script>
