<template>
  <crud-modal
    title="Generierung erneut starten?"
    action="generate"
    :on-submit="generate"
    :on-close="() => $emit('close')"
  >
    <p class="px-3 py-3 mt-6 text-yellow-800 bg-yellow-200 bg-opacity-75 rounded-md">
      Die Generierung wurde bereits gestartet. Ein erneutes Generieren bricht den aktuellen Vorgang ab
      und verursacht möglicherweise unnötige Kosten. Die Generierung kann bis zu einer Stunde dauern!<br>
      <br>
      Soll die Generierung wirklich erneut angestoßen werden?
    </p>
  </crud-modal>
</template>

<script>
import CrudModal from '@/components/modals/crud-modal'
import GENERATE_MATERIAL from '@/graphql/material/generateMaterial.gql'
import eventBus, { eventNames } from '@/lib/eventBus'

export default {
  components: {
    CrudModal
  },

  props: {
    material: {
      type: Object,
      required: true
    }
  },

  mounted () {
    if (this.mayGenerateImmediately()) {
      this.$nextTick(() => this.$emit('close'))
      return this.generate()
    }
  },

  methods: {
    async generate () {
      try {
        return await this.$apollo.mutate({
          mutation: GENERATE_MATERIAL,
          variables: {
            materialId: this.material.id
          }
        })
      } catch (error) {
        if (error.message === 'GraphQL error: Missing permission') {
          eventBus.$emit(eventNames.SHOW_SNACKBAR, { type: 'error', text: 'Keine Berechtigung zum Generieren.' })
        } else {
          eventBus.$emit(eventNames.SHOW_SNACKBAR, { type: 'error', text: 'Fehler beim generieren.' })
        }
      }
    },

    mayGenerateImmediately () {
      const isQueuedOrGenerating = ['QUEUED', 'GENERATING'].includes(this.material.status)
      return !isQueuedOrGenerating
    }
  }
}
</script>
