<template>
  <crud-modal
    title="User deaktivieren"
    action="update"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <div class="mb-2">
      Soll die E-Mail
      <span class="font-semibold">"{{ user.email }}"</span>
      wirklich deaktiviert werden?
    </div>
  </crud-modal>
</template>

<script>
import CrudModal from '@/components/modals/crud-modal'
import DEACTIVATE_USER from '@/graphql/DeactivateUser.gql'
import GET_COMPANY from '@/graphql/GetCompany.gql'

export default {
  components: { CrudModal },
  props: {
    user: {
      type: Object,
      required: true
    },
    companyId: {
      type: String,
      required: true
    }
  },
  methods: {
    async submit () {
      await this.$apollo.mutate({
        mutation: DEACTIVATE_USER,
        variables: {
          id: this.user.id,
          refetchQueries: [
            { query: GET_COMPANY, variables: { id: this.companyId } }
          ]
        }
      })
    }
  }
}
</script>
