<template>
  <crud-modal
    title="Gekündigte Verträge"
    action="export"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <div class="flex flex-wrap w-full">
      <div class="w-full px-4 mb-3">
        <input-date-validated
          ref="month"
          v-model="month"
          label="Ablaufender Monat"
          type="month"
          rules="required"
        />
      </div>
    </div>
  </crud-modal>
</template>
<script>
import CrudModal from '@/components/modals/crud-modal'
import InputDateValidated from '@/components/form/date-validated'

import { exportTermiantedContracts } from '@/lib/export'

export default {
  components: { CrudModal, InputDateValidated },
  data () {
    return {
      month: null
    }
  },
  methods: {
    async submit () {
      const monthParam = this.month && this.month.toISOString().slice(0, 7)
      await exportTermiantedContracts(monthParam)
    }
  }
}
</script>
