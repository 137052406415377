<template>
  <crud-modal
    title="Variante ändern"
    action="update"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <div class="flex flex-col w-full mb-2">
      <input-select-validated
        v-model="selectedVariant"
        :options="variants"
        label="Variante"
        rules="required"
      />
    </div>

    <p class="px-3 py-3 mt-6 text-yellow-800 bg-yellow-200 bg-opacity-75 rounded-md">
      Die Änderung der Variante hat keine direkte Auswirkung. Aktuell verknüpfte Materialien
      bleiben bestehen, bis die Generierung der Materialien erneut angestoßen wird.
    </p>
  </crud-modal>
</template>

<script>
import CrudModal from '@/components/modals/crud-modal'
import InputSelectValidated from '@/components/form/select-validated'
import GET_COMPANY from '@/graphql/GetCompany.gql'
import UPDATE_COMPANY_VARIANT from '@/graphql/UpdateCompanyVariant.gql'

import featureMixin from '@/mixins/feature'
import variantMixin from '@/mixins/variant'

export default {
  components: {
    InputSelectValidated,
    CrudModal
  },

  mixins: [featureMixin, variantMixin],

  props: {
    companyId: {
      type: String,
      required: true
    },
    variant: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      selectedVariant: '',
      variants: []
    }
  },

  created () {
    this.selectedVariant = this.variant
    this.variants = this.getMappedVariants()
  },

  methods: {
    submit () {
      return this.$apollo.mutate({
        mutation: UPDATE_COMPANY_VARIANT,
        variables: {
          companyId: this.companyId,
          variant: this.selectedVariant
        },
        refetchQueries: [
          { query: GET_COMPANY, variables: { id: this.companyId } }
        ]
      })
    }
  }
}
</script>
