<template>
  <div class="w-full py-2">
    <h2 class="w-full flex items-center justify-between font-medium uppercase font-bold border-b-4 mb-2">
      Dokumente
      <button
        class="btn-inline-small"
        @click="showCreateDocumentModal()"
      >
        <fa :icon="faPlus" />
        Hinzufügen
      </button>
    </h2>
    <div
      v-for="document in documents"
      :key="document.id"
      class="flex py-1 border-b hover:bg-gray-100 group"
    >
      <div class="w-2/12 px-1">
        {{ getDocumentTypeName(document.type) }}
      </div>
      <div class="truncate w-4/12 px-1">
        <span>{{ document.comment }}</span>
      </div>
      <div class="truncate w-5/12 px-1">
        <a
          :href="document.file.url"
          target="_blank"
        >{{ document.file.name }}</a>
      </div>
      <button
        class="btn-inline-small w-1/12 "
        @click="showDeleteModal(document.file.id)"
      >
        <fa :icon="faTrash" />
      </button>
    </div>
  </div>
</template>

<script>
import CreateDocumentModal from '@/components/modals/document/create'
import DeleteDocumentModal from '@/components/modals/document/delete'
import { faPen, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { getDocumentTypeName } from '@/config'

export default {
  props: {
    documents: { type: Array, default: () => ([]) },
    contractId: { type: String, required: true }
  },
  data () {
    return { faPen, faPlus, faTrash }
  },
  methods: {
    getDocumentTypeName,
    showCreateDocumentModal () {
      this.$modal.show(
        CreateDocumentModal,
        { contractId: this.contractId }
      )
    },
    showDeleteModal (documentId) {
      this.$modal.show(
        DeleteDocumentModal,
        {
          contractId: this.contractId,
          documentId
        }
      )
    }
  }
}
</script>
