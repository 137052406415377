<template>
  <section class="relative text-gray-800 border rounded hover:shadow">
    <div class="flex items-center justify-between px-4 py-2 bg-gray-200 border-b rounded-t group">
      <h4 class="font-semibold">
        {{ title }}
      </h4>

      <router-link
        v-if="createLink"
        :to="createLink"
        class="btn-inline-small"
      >
        <fa
          :icon="faPlus"
          fixed-width
        />
        Landingpage erstellen
      </router-link>
    </div>

    <div
      v-if="hasNoLandingpageTemplates"
      class="p-4 text-gray-500"
    >
      <em>Keine Landingpages gefunden 🤷‍♂️</em>
    </div>

    <ul
      v-else
      class="py-2"
    >
      <LandingpageTemplateListItem
        v-for="landingpageTemplate in landingpageTemplates"
        :key="landingpageTemplate.id"
        v-bind="landingpageTemplate"
      />
    </ul>
  </section>
</template>

<script>
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import LandingpageTemplateListItem from './list-item.vue'

export default {
  components: { LandingpageTemplateListItem },

  props: {
    landingpageTemplates: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: 'Landingpage-Templates'
    },
    createLink: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      faPlus
    }
  },

  computed: {
    hasNoLandingpageTemplates () {
      return this.landingpageTemplates.length === 0
    }
  }
}
</script>
