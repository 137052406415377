<template>
  <crud-modal
    title="Rechte & Rollen aktivieren"
    action="create"
    :on-submit="submit"
    :on-close="() => $emit('close')"
    validation-scope="user"
  >
    <div class="font-sans text-gray-800">
      <div class="mb-4 text-lg font-semibold">
        Rechte & Rollen für diese Firma aktivieren:
      </div>
      <ul class="ml-4 list-disc list-inside">
        <li>Alle Nutzer bekommen die Standard-Rolle zugeteilt.</li>
        <li>Es wird nichts abgerechnet (ggf. manuell abrechnen).</li>
        <li>Es wird keine Rechnung erstellt.</li>
      </ul>
    </div>
  </crud-modal>
</template>

<script>
import CrudModal from '@/components/modals/crud-modal'

import ACTIVATE_AUTHORIZATION from '@/graphql/ActivateAuthorization.gql'

export default {
  components: { CrudModal },
  props: {
    companyId: {
      type: String,
      required: true
    }
  },
  methods: {
    async submit () {
      await this.$apollo.mutate({
        mutation: ACTIVATE_AUTHORIZATION,
        variables: {
          companyId: this.companyId
        }
      })
      window.location.reload()
    }
  }
}
</script>
