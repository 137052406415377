
import Condition from '@/components/leads/enums/valuation/Condition'
import Quality from '@/components/leads/enums/valuation/Quality'
import Reason from '@/components/leads/enums/valuation/Reason'

export const readableCondition = {
  [Condition.RENOVATION_NEEDED]: 'Renovierungsbedürftig',
  [Condition.WELL_MAINTAINED]: 'Gut erhalten',
  [Condition.NEW_OR_RECENTLY_RENOVATED]: 'Neu oder kürzlich renoviert'
}

export const readableQuality = {
  [Quality.SIMPLE]: 'Einfach',
  [Quality.NORMAL]: 'Standard',
  [Quality.HIGH]: 'Hoch',
  [Quality.LUXURY]: 'Luxuriös'
}

export const readableReason = {
  [Reason.PLANNING_TO_SELL]: 'Ich plane den Verkauf dieser Immobilie.',
  [Reason.PRICE_TREND]: 'Ich möchte wissen, ob ich vom Preisanstieg profitiert habe.',
  [Reason.NEED_PROPERTY_BEFORE_SELLING]: 'Ich würde verkaufen, wenn ich eine passende Immobilie finde.',
  [Reason.HERITAGE]: 'Ich habe eine Immobilie geerbt und möchte den Marktpreis erfahren.',
  [Reason.BUYING_INTEREST]: 'Ich interessiere mich für eine Immobilie und möchte den Preis überprüfen.'
}
