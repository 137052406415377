<template>
  <div
    v-if="company.id"
    class="flex"
  >
    <button
      :class="cmsOrganization?.id ? 'btn-secondary' : 'btn-primary'"
      :disabled="loading"
      @click="createOrUpdateCmsOrganizations"
    >
      {{ cmsOrganization?.id ? 'CMS Eintrag für Company updaten' : 'CMS Eintrag für Company anlegen' }}
    </button>
    <button
      v-if="cmsOrganization?.id && !cmsOrganization?.active"
      class="btn-primary ml-4"
      :disabled="loading"
      @click="activateCmsOrganizations"
    >
      CMS Landingpages aktivieren
    </button>
    <button
      v-if="cmsOrganization?.id && cmsOrganization?.active"
      class="btn-primary ml-4"
      :disabled="loading"
      @click="deactivateCmsOrganizations"
    >
      CMS Landingpages deaktivieren
    </button>
  </div>
</template>
<script>
import GET_CMS_ORGANIZATION from '@/graphql/GetCmsOrganization.gql'
import UPDATE_CMS_MAPPING from '@/graphql/CreateOrUpdateCmsOrganizations.gql'
import ACTIVATE_CMS_ORGANIZATIONS from '@/graphql/ActivateCmsOrganizations.gql'
import DEACTIVATE_CMS_ORGANIZATIONS from '@/graphql/DeactivateCmsOrganizations.gql'
import eventBus, { eventNames } from '@/lib/eventBus'
export default {
  props: {
    company: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      loading: false,
      checked: false
    }
  },
  apollo: {
    cmsOrganization: {
      query: GET_CMS_ORGANIZATION,
      variables () {
        return {
          companyId: this.company.id
        }
      },
      skip () {
        return !this.company?.id
      }
    }
  },
  methods: {
    async createOrUpdateCmsOrganizations () {
      try {
        this.loading = true
        await this.$apollo.mutate({
          mutation: UPDATE_CMS_MAPPING,
          variables: {
            companyIds: [this.company.id]
          },
          refetchQueries: ['cmsOrganization']
        })
      } catch (error) {
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { type: 'error', text: 'CMS konnte nicht aktualisiert werden' })
      } finally {
        this.loading = false
      }
    },
    async activateCmsOrganizations () {
      try {
        this.loading = true
        await this.$apollo.mutate({
          mutation: ACTIVATE_CMS_ORGANIZATIONS,
          variables: {
            companyIds: [this.company.id]
          },
          refetchQueries: ['cmsOrganization']
        })
      } catch (error) {
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { type: 'error', text: 'CMS konnte nicht aktiviert werden' })
      } finally {
        this.loading = false
      }
    },
    async deactivateCmsOrganizations () {
      try {
        this.loading = true
        await this.$apollo.mutate({
          mutation: DEACTIVATE_CMS_ORGANIZATIONS,
          variables: {
            companyIds: [this.company.id]
          },
          refetchQueries: ['cmsOrganization']
        })
      } catch (error) {
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { type: 'error', text: 'CMS konnte nicht deaktiviert werden' })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
