<template>
  <crud-modal
    title="Facebook Pixel Access Token setzen"
    action="update"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <form action="#">
      <label class="block mb-6 normal-case">
        <input-text-validated
          v-model="accessToken"
          label="Neues Access Token"
          type="text"
          rules="required"
          input-class="w-full"
          class="w-full"
        /></label>
    </form>
  </crud-modal>
</template>

<script>
import CrudModal from '@/components/modals/crud-modal'
import UPDATE_ACCESS_TOKEN from '@/graphql/updateFbPixelAccessToken.gql'
import GET_COMPANY from '@/graphql/GetCompany.gql'
import InputTextValidated from '@/components/form/text-validated'

export default {
  components: {
    InputTextValidated,
    CrudModal
  },

  props: {
    companyId: { type: String, required: true }
  },

  data () {
    return {
      accessToken: ''
    }
  },

  methods: {
    async submit () {
      await this.$apollo.mutate({
        mutation: UPDATE_ACCESS_TOKEN,
        variables: {
          input: {
            companyId: this.companyId,
            accessToken: this.accessToken
          }
        },
        refetchQueries: [
          { query: GET_COMPANY, variables: { id: this.companyId } }
        ]
      })
    }
  }
}
</script>
