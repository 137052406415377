<template>
  <section class="w-full">
    <h1>Bilder</h1>
    <div class="flex w-full">
      <div
        v-for="image in imageTemplate"
        :key="image.url"
        class="w-1/6 mr-4 border-2 rounded group"
      >
        <div v-if="image.url">
          <img
            :src="image.url"
            class="object-contain w-full h-32"
          >
          <div class="flex justify-between px-2 py-1 bg-gray-300">
            <div class="font-semibold text-gray-600">
              {{ image.title }}
            </div>
            <div class="hidden group-hover:block">
              <button
                v-if="image.url && image.updateImage"
                class="px-1"
                @click="image.updateImage"
              >
                <fa :icon="faPen" />
              </button>
              <button
                v-if="image.url && !image.required && image.deleteImage"
                class="px-1"
                @click="image.deleteImage"
              >
                <fa :icon="faTrash" />
              </button>
            </div>
          </div>
        </div>

        <button
          v-else-if="image.addImage"
          type="button"
          :class="`w-full h-40 text-sm font-semibold ${color(image)} uppercase bg-gray-100 hover:bg-gray-200 hover:text-gray-800`"
          @click="image.addImage"
        >
          <fa :icon="faPlus" />
          <br>{{ image.title }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import AddCompanyImageModal from '@/components/modals/company/image/add'
import UpdateCompanyImageModal from '@/components/modals/company/image/update'
import DeleteCompanyImageModal from '@/components/modals/company/image/delete'
import { faPen, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons'
import feature from '@/mixins/feature'

export default {
  mixins: [feature],
  props: {
    images: { type: Object, default: () => ({ }) },
    companyId: { type: String, default: '' }
  },
  data () {
    return {
      faPen,
      faTrash,
      faPlus
    }
  },
  computed: {
    imageTemplate () {
      return [
        {
          url: this.images?.logo?.url,
          title: 'Logo',
          updateImage: () => this.showCompanyImageUpdateModal({
            type: 'logo',
            image: this.images?.logo
          }),
          required: true,
          isEnabled: () => this.$features.feature(this.featureNames.COMPANY).config.isLogoEditable,
          addImage: () => this.showCompanyImageAddModal('logo')
        },
        {
          url: this.images?.team?.url,
          title: 'Team-Bild',
          updateImage: () => this.showCompanyImageUpdateModal({
            type: 'team',
            image: this.images?.team
          }),
          isEnabled: () => true,
          addImage: () => this.showCompanyImageAddModal('team')
        },
        {
          url: this.images?.office?.url,
          title: 'Büro-Bild',
          updateImage: () => this.showCompanyImageUpdateModal({
            type: 'office',
            image: this.images?.office
          }),
          deleteImage: () => this.showCompanyImageDeleteModal({
            type: 'office',
            image: this.images?.office
          }),
          required: false,
          isEnabled: () => true,
          addImage: () => this.showCompanyImageAddModal('office')
        },
        {
          url: this.images?.region?.url,
          title: 'Region-Bild',
          updateImage: () => this.showCompanyImageUpdateModal({
            type: 'region',
            image: this.images?.region
          }),
          deleteImage: () => this.showCompanyImageDeleteModal({
            type: 'region',
            image: this.images?.region
          }),
          required: false,
          isEnabled: () => true,
          addImage: () => this.showCompanyImageAddModal('region')
        },
        {
          url: this.images?.lpValuationHero?.url,
          title: 'Immobilienbewertung Hero',
          updateImage: () => this.showCompanyImageUpdateModal({
            type: 'lpValuationHero',
            image: this.images?.lpValuationHero
          }),
          deleteImage: () => this.showCompanyImageDeleteModal({
            type: 'lpValuationHero',
            image: this.images?.lpValuationHero
          }),
          required: false,
          isEnabled: () => true,
          addImage: () => this.showCompanyImageAddModal('lpValuationHero')
        }
      ].filter(({ isEnabled }) => isEnabled())
    }
  },
  methods: {
    color (image) {
      return image.required && !image.url ? 'text-red-600' : 'text-gray-600'
    },
    showCompanyImageAddModal (type) {
      this.$modal.show(
        AddCompanyImageModal,
        {
          companyId: this.companyId,
          type
        },
        { height: 'auto' }
      )
    },
    showCompanyImageUpdateModal ({ type, image }) {
      this.$modal.show(
        UpdateCompanyImageModal,
        {
          companyId: this.companyId,
          type,
          image
        },
        {
          height: 'auto'
        }
      )
    },
    showCompanyImageDeleteModal ({ type, image }) {
      this.$modal.show(
        DeleteCompanyImageModal,
        {
          companyId: this.companyId,
          type,
          image
        },
        {
          height: 'auto'
        }
      )
    }
  }
}
</script>
