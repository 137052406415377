<template>
  <section class="w-full">
    <h1 class="flex items-center justify-between">
      Seite
      <div class="text-sm text-gray-600">
        <button
          class="font-semibold uppercase hover:text-gray-800"
          @click="showSiteBuildCreateModal()"
        >
          Neuer Build
        </button>
        <span class="mx-3 font-bold">|</span>
        <button
          class="font-semibold uppercase hover:text-gray-800"
          @click="showSiteUpdateModal()"
        >
          Bearbeiten
        </button>
        <span class="mx-3 font-bold">|</span>
        <a
          :href="site.adminUrl"
          target="blank"
          class="inline-block font-semibold uppercase hover:text-gray-800"
        >
          Netlify
          <fa
            :icon="faExternalLinkSquareAlt"
            size="sm"
          />
        </a>
      </div>
    </h1>

    <div class="flex w-full">
      <div class="w-full lg:w-1/2">
        <div class="flex w-full mb-1">
          <div class="w-2/5 font-semibold text-gray-600 uppercase">
            Seiten ID:
          </div>
          <div class="w-3/5">
            {{ site.id }}
          </div>
        </div>

        <div class="flex w-full mb-1">
          <div class="w-2/5 font-semibold text-gray-600 uppercase">
            URL:
          </div>
          <div class="w-3/5">
            <a
              class="underline"
              :href="site.url"
              target="_blank"
            >
              {{
                site.url
              }}
            </a>
          </div>
        </div>

        <div class="flex w-full mb-1">
          <div class="w-2/5 font-semibold text-gray-600 uppercase">
            Google Tag Manager:
          </div>
          <div
            v-if="site.tracking"
            class="w-3/5"
          >
            {{ site.tracking.gtm }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import UpdateSiteModal from '@/components/modals/company/site/update'
import CreateSiteBuildModal from '@/components/modals/company/site/build/create'
import { faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons'

export default {
  props: {
    site: { type: Object, default: () => ({}) },
    companyId: { type: String, default: '' }
  },
  data () {
    return {
      faExternalLinkSquareAlt
    }
  },
  methods: {
    showSiteBuildCreateModal () {
      this.$modal.show(
        CreateSiteBuildModal,
        {
          companyId: this.companyId
        },
        {
          height: 'auto'
        }
      )
    },
    showSiteUpdateModal () {
      this.$modal.show(
        UpdateSiteModal,
        {
          companyId: this.companyId,
          tracking: { ...this.site.tracking }
        },
        {
          height: 'auto'
        }
      )
    }
  }
}
</script>
