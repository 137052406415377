<template>
  <CrudModal
    title="Vertrag ändern"
    action="update"
    :on-submit="submitContractChange"
    :on-close="() => $emit('close')"
    :error-message="errorMessage"
  >
    <BillwerkOrder
      :order-data="orderData"
      change
      :bookable-sales-plans="[]"
    />
  </CrudModal>
</template>

<script>
import CrudModal from '@/components/modals/crud-modal'
import CHANGE_CONTRACT from '@/graphql/ChangeContract.gql'
import BillwerkOrder from '@/components/form/billwerk-order'

export default {
  components: {
    CrudModal,
    BillwerkOrder
  },
  props: {
    company: { type: Object, required: true }
  },
  data () {
    return {
      errorMessage: '',
      isSubmitting: false,
      orderData: {
        contractId: '',
        customerId: ''
      }
    }
  },
  methods: {
    async submitContractChange () {
      try {
        await this.$apollo.mutate({
          mutation: CHANGE_CONTRACT,
          variables: {
            input: {
              companyId: this.company.id,
              contractId: this.orderData.contractId,
              customerId: this.orderData.customerId
            }
          }
        })
        window.location.reload()
      } catch (error) {
        this.errorMessage = 'Der Vertrag konnte nicht gewechselt werden.'
        throw error
      }
    }
  }
}
</script>
