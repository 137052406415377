<template>
  <crud-modal
    title="Vertrag löschen"
    action="delete"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <span>Vertrag wirklich löschen? Dies kann nicht rückgängig gemacht werden!</span>
  </crud-modal>
</template>
<script>
import CrudModal from '@/components/modals/crud-modal'
import { PRICELISTS } from '@/config'

import GET_COMPANY_CONTRACTS from '@/graphql/GetCompanyContracts.gql'
import DELETE_CONTRACT from '@/graphql/DeleteContract.gql'

export default {
  components: {
    CrudModal
  },
  props: {
    companyId: { type: String, required: true },
    id: { type: String, required: true }
  },
  created () {
    this.priceLists = PRICELISTS
  },
  methods: {
    async submit () {
      await this.$apollo.mutate({
        mutation: DELETE_CONTRACT,
        variables: {
          id: this.id
        },
        update: (store, { data: { contract } }) => {
          const data = store.readQuery({ query: GET_COMPANY_CONTRACTS, variables: { id: this.companyId } })
          data.company.contracts = data.company.contracts.filter(({ id }) => id !== this.id)
          store.writeQuery({ query: GET_COMPANY_CONTRACTS, data, variables: { id: this.companyId } })
        }
      })
    }
  }
}
</script>
