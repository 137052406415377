<template>
  <div>
    <div class="tabs flex text-xl font-semibold tracking-wider text-gray-800 uppercase px-6 bg-gray-100 border-b border-t overflow-x-auto">
      <a
        v-for="tab in tabs"
        :key="href(tab.name)"
        :class="`${tab.isActive && 'is-active bg-gray-400 hover:bg-gray-400'} rounded-md p-2 px-3 my-4 mx-2 hover:bg-gray-300`"
        :href="href(tab.name)"
      >{{ tab.name }}</a>
    </div>

    <div class="tabs-details">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return { tabs: [] }
  },
  watch: {
    '$route.hash': function (hash) {
      this.selectTab(hash)
    }
  },
  created () {
    this.tabs = this.$children
  },
  mounted () {
    if (this.$route.hash) {
      this.selectTab(this.$route.hash)
    } else {
      this.tabs[0].isActive = true
    }
  },
  methods: {
    href (tabName) {
      return '#' + tabName.toLowerCase().replace(/ /g, '-')
    },
    selectTab (selectedTab) {
      this.tabs.forEach(tab => {
        tab.isActive = this.href(tab.name) === selectedTab
      })
    }
  }
}
</script>
