<template>
  <crud-modal
    title="Verifizierungsemail senden"
    action="send"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <div class="mb-6">
      Soll die Verifizierungsemail wirklich an  <span class="font-semibold">{{ verificationEmail }}</span> gesendet werden?
    </div>
    <div class="flex mb-2">
      Der Makler erhält eine E-Mail in welcher er die E-Mail als Absenderemail bestätigen muss.
    </div>
  </crud-modal>
</template>

<script>
import START_HOPPERMATION_ACTIVATION from '@/graphql/emailVerification/StartHoppermationActivation.gql'
import RESEND_HOPPERMATION_DEFAULT_SENDER_VALIDATION from '@/graphql/emailVerification/ResendHoppermationDefaultSenderValidation.gql'
import CrudModal from '@/components/modals/crud-modal'
import eventBus, { eventNames } from '@/lib/eventBus'

export default {
  components: { CrudModal },
  props: {
    companyId: {
      type: String,
      required: true
    },
    verificationEmail: {
      type: String,
      default: ''
    },
    updateQuery: {
      type: Function,
      required: true
    },
    defaultSender: {
      type: Object,
      default: null
    }
  },
  methods: {
    async submit () {
      try {
        if (!this.defaultSender) {
          await this.$apollo.mutate({
            mutation: START_HOPPERMATION_ACTIVATION,
            variables: { companyId: this.companyId },
            update: this.updateQuery
          })
        } else {
          await this.$apollo.mutate({
            mutation: RESEND_HOPPERMATION_DEFAULT_SENDER_VALIDATION,
            variables: { companyId: this.companyId }
          })
        }
      } catch (err) {
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { type: 'error', text: 'Fehler beim Senden der Verifizierungsemail.' })
      }
    }
  }
}
</script>
