<template>
  <crud-modal
    title="Implementierungsdaten exportieren"
    action="export"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <p>In diesem Export erscheinen alle Daten, die zur Implementierung notwendig sind (Einbindungscode, Landingpage-URL, Cover-URL).</p>
  </crud-modal>
</template>

<script>
import CrudModal from '@/components/modals/crud-modal'
import { exportImplementationData } from '@/lib/export'

export default {
  components: {
    CrudModal
  },
  props: {
    companyId: {
      type: String,
      required: true
    }
  },

  methods: {
    async submit () {
      await exportImplementationData(this.companyId)
    }
  }
}
</script>
