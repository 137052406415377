import { getInstance } from '../auth'

const dateTimeString = () => new Date().toISOString().slice(0, 19)

const getHeaders = async () => {
  const authService = await getInstance()
  const token = await authService.getTokenSilently()
  return new Headers({ Authorization: `Bearer ${token}` })
}

const fetchData = async (url) => {
  const endpoint = new URL(url, process.env.VUE_APP_BOTTIMMO_SERVICE)
  const res = await fetch(endpoint,
    {
      headers: await getHeaders()
    })
  return res.text()
}

export const exportImplementationData = async (companyId) => {
  const url = `/implementation-data/${companyId}/export`
  const filename = 'Implementierungsdaten.csv'

  const data = await fetchData(url)
  triggerExport({ filename, data, type: 'text/csv;charset=utf-8' })
}

export const exportCompanies = async ({ extended = false }) => {
  const url = extended ? '/exports/companies?exporttype=extended' : '/exports/companies'
  const filename = extended ? 'companies_extended.csv' : 'companies.csv'

  const data = await fetchData(url)
  triggerExport({ filename, data, type: 'text/csv;charset=utf-8' })
}

export const exportUsers = async () => {
  const url = '/exports/users'
  const data = await fetchData(url)
  triggerExport({ filename: 'user.csv', data, type: 'text/csv;charset=utf-8' })
}

export const exportContractsWithProducts = async () => {
  const url = '/exports/billing/contracts'
  const data = await fetchData(url)
  triggerExport({ filename: `contracts-billing (${dateTimeString()}).csv`, data, type: 'text/csv;charset=utf-8' })
}

export const exportProducts = async ({ billingMonth, lastBillingDate, groupByCompany }) => {
  const url = `/exports/products?billingMonth=${billingMonth}&lastBillingDate=${lastBillingDate}&groupByCompany=${groupByCompany}`
  const data = await fetchData(url)
  triggerExport({ filename: `products-${billingMonth} (${dateTimeString()}).csv`, data, type: 'text/csv;charset=utf-8' })
}

export const exportPricehubble = async ({ billingMonth }) => {
  const url = `/exports/pricehubble?billingMonth=${billingMonth}`
  const data = await fetchData(url)
  triggerExport({ filename: `pricehubble-${billingMonth} (${dateTimeString()}).csv`, data, type: 'text/csv;charset=utf-8' })
}

export const exportCompaniesWithInvoice = async ({ lastBillingDate, billingMonth }) => {
  const url = `/exports/companies-with-invoice?billingMonth=${billingMonth}&lastBillingDate=${lastBillingDate}`
  const data = await fetchData(url)
  triggerExport({ filename: `companies-with-invoice (${dateTimeString()}).csv`, data, type: 'text/csv;charset=utf-8' })
}

export const exportCompaniesWithoutInvoice = async ({ lastBillingDate, billingMonth }) => {
  const url = `/exports/companies-without-invoice?billingMonth=${billingMonth}&lastBillingDate=${lastBillingDate}`
  const data = await fetchData(url)
  triggerExport({ filename: `companies-without-invoice (${dateTimeString()}).csv`, data, type: 'text/csv;charset=utf-8' })
}

export const exportExpertProducts = async (month) => {
  const query = month ? `?billingMonth=${month}` : ''
  const url = `/exports/products/expert${query}`
  const data = await fetchData(url)
  triggerExport({ filename: `products-${month} (${dateTimeString()}).csv`, data, type: 'text/csv;charset=utf-8' })
}

export const exportStartedContracts = async (month) => {
  const query = month ? `?startedMonth=${month}` : ''
  const url = `/exports/started/contracts${query}`
  const data = await fetchData(url)
  triggerExport({ filename: `started-contracts-${month} (${dateTimeString()}).csv`, data, type: 'text/csv;charset=utf-8' })
}

export const exportTermiantedContracts = async (month) => {
  const query = month ? `?terminatedMonth=${month}` : ''
  const url = `/exports/terminated/contracts${query}`
  const data = await fetchData(url)
  triggerExport({ filename: `contracts-terminated-${month} (${dateTimeString()}).csv`, data, type: 'text/csv;charset=utf-8' })
}

export const exportAbacusCompany = async (month) => {
  const query = month ? `?month=${month}` : ''
  const url = `/exports/abacus/companies${query}`
  const data = await fetchData(url)
  triggerExport({ filename: `abacus-companies (${dateTimeString()}).xml`, data, type: 'text/xml;charset=utf-8' })
}

export const exportBillingCompany = async (month) => {
  const query = month ? `?month=${month}` : ''
  const url = `/exports/billing/companies${query}`
  const data = await fetchData(url)
  triggerExport({ filename: `companies-billing (${dateTimeString()}).csv`, data, type: 'text/csv;charset=utf-8' })
}

export const exportAbacusContracts = async ({ billingMonth, billingDate, lastBillingDate, billingPeriod }) => {
  const url = `/exports/abacus/contracts?billingMonth=${billingMonth}&billingDate=${billingDate}&lastBillingDate=${lastBillingDate}` +
    `&billingPeriod=${encodeURIComponent(billingPeriod)}`
  const data = await fetchData(url)
  triggerExport({ filename: `abacus-products (${dateTimeString()}).xml`, data, type: 'text/xml;charset=utf-8' })
}

export const triggerExport = ({ filename, data, type }) => {
  if (data) {
    const blob = window.URL.createObjectURL(new Blob(['\uFEFF' + data], { type }))
    const element = document.createElement('a')
    element.href = blob
    element.setAttribute('download', filename)
    document.body.appendChild(element)

    element.click()
    element.remove()
  }
}
