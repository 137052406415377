<template>
  <section
    v-if="todos"
    class="w-full"
  >
    <h1 class="flex items-center justify-between">
      Missionsfortschritt
    </h1>
    <div class="flex flex-wrap w-full">
      <todo
        v-for="(todo, index) in todos"
        :key="todo.slug"
        :todo="todo"
        :class="{ 'bg-gray-200': index % 2 }"
      />
    </div>
  </section>
</template>

<script>
import Todo from '@/components/company/todo.vue'
import GET_TODOS from '@/graphql/GetToDos.gql'

export default {
  components: {
    Todo
  },
  props: {
    companyId: {
      type: String,
      required: true
    }
  },
  apollo: {
    todos: {
      query: GET_TODOS,
      variables () {
        return {
          input: {
            companyId: this.companyId
          }
        }
      },
      update ({ todos }) {
        return todos.sort((a, b) => b.priority - a.priority)
      }
    }
  }
}
</script>
