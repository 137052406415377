<template>
  <section
    class="w-full"
  >
    <h1 class="flex items-center justify-between">
      Zahlungsanbieter
      <div
        v-if="hasRole(ROLES.BO_DEV)"
        class="text-sm text-gray-600"
      >
        <button
          class="font-semibold uppercase hover:text-gray-800"
          @click="showBillwerkChangeModal()"
        >
          <fa :icon="faPen" />
          Vertrag wechseln
        </button>
      </div>
    </h1>

    <div
      v-if="billingAdminDetails"
      class="flex flex-wrap w-full"
    >
      <div class="flex-col w-full mb-2 lg:w-1/2">
        <data-field label="Kunde">
          <a
            :href="billingAdminDetails.customerAdminUrl"
            target="_blank"
          >{{ billingAdminDetails.customerAdminUrl }}</a>
        </data-field>
        <data-field label="Vertrag">
          <a
            :href="billingAdminDetails.contractAdminUrl"
            target="_blank"
          >{{ billingAdminDetails.contractAdminUrl }}</a>
        </data-field>
      </div>
    </div>
    <div
      v-else
      class="flex flex-wrap w-full text-gray-600"
    >
      <button
        class="mr-6 font-semibold uppercase hover:text-gray-800"
        @click="showBillwerkConnectModal()"
      >
        <fa :icon="faLink" />
        Mit Billwerk verknüpfen
      </button>
    </div>
  </section>
</template>

<script>
import DataField from '@/components/data-field'
import { faLink, faPen } from '@fortawesome/free-solid-svg-icons'
import ConnectWithBillwerkModal from '@/components/modals/company/billwerk/connect'
import ChangeBillwerkModal from '@/components/modals/company/billwerk/change'
import GET_BILLING_ADMIN_DETAILS from '@/graphql/GetBillingAdminDetails.gql'
import role from '@/mixins/role'

export default {
  components: { DataField },
  mixins: [role],
  props: {
    company: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      faLink,
      faPen,
      billingAdminDetails: null
    }
  },
  apollo: {
    billingAdminDetails: {
      query: GET_BILLING_ADMIN_DETAILS,
      variables () {
        return { companyId: this.company.id }
      },
      error (/* error */) {
        return false
      }
    }
  },
  methods: {
    showBillwerkConnectModal () {
      this.$modal.show(
        ConnectWithBillwerkModal,
        {
          company: {
            ...this.company
          }
        }
      )
    },
    showBillwerkChangeModal () {
      this.$modal.show(
        ChangeBillwerkModal,
        {
          company: {
            ...this.company
          }
        }
      )
    }
  }
}
</script>
