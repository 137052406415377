import emitter from 'tiny-emitter/instance'

export default {
  $on: (...args) => emitter.on(...args),
  $once: (...args) => emitter.once(...args),
  $off: (...args) => emitter.off(...args),
  $emit: (...args) => emitter.emit(...args)
}

export const eventNames = {
  USER_LOGGED_IN: 'userLoggedIn',
  SHOW_SNACKBAR: 'showSnackbar'
}
