<template>
  <div class="flex flex-col w-full mb-1">
    <data-field label="Schlüssel eingetragen">
      {{ flowfactConfig.token?.length > 0 ? 'ja' : 'nein' }}
    </data-field>
    <data-field label="Schlüssel gültig">
      {{ flowfactConfig.isTokenVerified ? 'ja' : 'nein' }}
    </data-field>
    <data-field label="Gewählte Tags">
      {{ statuses }}
    </data-field>
  </div>
</template>

<script>
import FLOWFACT_CONFIG from '@/graphql/FlowfactConfig.gql'
import FLOWFACT_CONTACT_TAGS from '@/graphql/FlowfactContactTags.gql'
import DataField from '@/components/data-field'

export default {
  components: {
    DataField
  },
  props: {
    companyId: { type: String, default: '' }
  },
  data () {
    return {
      flowfactConfig: { selectedContactStatusIds: [] },
      flowfactContactTags: []
    }
  },
  computed: {
    statuses () {
      const flowfactContactTagsMap = new Map([
        ...this.flowfactContactTags.map(status => [status.id, status.name]),
        ['ALL', 'Alle Kontakte'],
        ['UNDEFINED', 'Kontakte ohne Tags']
      ])
      return this.flowfactConfig.selectedContactStatusIds.map(id => flowfactContactTagsMap.get(id)).join(', ')
    }
  },
  apollo: {
    flowfactConfig: {
      query: FLOWFACT_CONFIG,
      variables () {
        return {
          companyId: this.companyId
        }
      }
    },
    flowfactContactTags: {
      query: FLOWFACT_CONTACT_TAGS,
      variables () {
        return {
          companyId: this.companyId
        }
      }
    }
  }
}
</script>
