<template>
  <div class="h-full modal">
    <div class="modal-content">
      <h1
        class="w-full mb-6 text-xl font-semibold tracking-wide text-gray-800 uppercase"
      >
        Ausstehende Kundenänderungen
      </h1>
      <div
        class="flex flex-col"
      >
        <textarea
          v-model="pendingChangesForRegeneration"
          rows="8"
          cols="40"
          readonly
        />
      </div>
      <div class="p-4">
        Wenn alle relevanten Materialien / Landingpages manuell neu generiert wurden oder die Änderungen
        nicht relvant sind, können die austehenden Änderungen als erledigt markiert werden.
        Damit verschwindet der Info-Banner in der Makler-App.
      </div>
      <alert
        v-if="errorMessage"
        type="error"
        class="w-2/3 m-8 mt-0"
      >
        {{ errorMessage }}
      </alert>
      <div class="modal-footer">
        <button
          class="btn-inline"
          @click="() => $emit('close')"
        >
          Schließen
        </button>
        <button
          class="btn-primary"
          @click="submit"
        >
          Austehende Änderungen erledigt
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import GET_PENDING_CHANGES_FOR_REGENERATION from '@/graphql/GetPendingChangesForRegeneration.gql'
import UPDATE_IGNORE_CHANGES_FOR_REGENERATION from '@/graphql/UpdateIgnoreChangesForRegeneration.gql'
import alert from '@/components/alert'

export default {
  components: { alert },
  props: {
    companyId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      errorMessage: ''
    }
  },
  methods: {
    async submit () {
      this.errorMessage = ''
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_IGNORE_CHANGES_FOR_REGENERATION,
          variables: {
            companyId: this.companyId
          },
          refetchQueries: [
            { query: GET_PENDING_CHANGES_FOR_REGENERATION, variables: { companyId: this.companyId } }
          ]
        })
        this.$emit('close')
      } catch (error) {
        this.errorMessage = 'Zurücksetzen fehlgeschlagen!'
        throw (error)
      }
    }
  },
  apollo: {
    pendingChangesForRegeneration: {
      query: GET_PENDING_CHANGES_FOR_REGENERATION,
      variables () {
        return { companyId: this.companyId }
      },
      update ({ pendingChangesForRegeneration }) {
        return JSON.stringify(pendingChangesForRegeneration, null, 4)
      }
    }
  }
}
</script>
