<template>
  <div>
    <div
      v-if="updateExists"
      class="relative bg-gray-800"
    >
      <div class="max-w-screen-xl px-3 py-2 mx-auto sm:px-6 lg:px-8">
        <div class="pr-16 sm:text-center sm:px-16">
          <p class="font-medium text-white">
            Eine neue Version der App ist verfügbar.
            <span class="block sm:ml-2 sm:inline-block">
              <button
                class="font-bold text-white underline focus:outline-none"
                @click="refreshApp"
              >
                Jetzt aktualisieren
              </button>
            </span>
          </p>
        </div>
      </div>
    </div>
    <layout v-if="showContent">
      <router-view />
      <BaseSnackbar />
    </layout>
  </div>
</template>

<script>
import Layout from './layouts/default'
import update from './mixins/update'
import brandingMixin from './mixins/branding'
import BaseSnackbar from './components/BaseSnackbar.vue'

export default {
  components: {
    Layout,
    BaseSnackbar
  },
  mixins: [update, brandingMixin],
  computed: {
    showContent () {
      return !this.$auth.isAuthenticated || this.$features.loaded
    }
  },
  created () {
    document.title = `Backoffice ${this.readablePlatformName}`
  }
}
</script>
