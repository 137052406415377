<template>
  <section
    class="w-full h-full"
    style="min-height: 3000px;"
  >
    <iframe
      class="w-full"
      style="min-height: 3000px;"
      :src="url"
      frameborder="0"
      allowfullscreen
    />
  </section>
</template>

<script>
import STATISTIC_DASHBOARD from '@/graphql/StatisticDashboard.gql'

export default {
  props: {
    companyId: {
      type: String,
      required: true
    }
  },
  apollo: {
    url: {
      query: STATISTIC_DASHBOARD,
      variables () {
        return {
          companyId: this.companyId
        }
      }
    }
  }
}
</script>
