import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { setContext } from 'apollo-link-context'
import { createApolloClient } from 'vue-cli-plugin-apollo/graphql-client'
import { getInstance } from './auth'

Vue.use(VueApollo)

const getToken = async () => {
  const authService = await getInstance()

  return new Promise(resolve => {
    if (!authService.loading) {
      resolve(authService.getTokenSilently())
    }

    const unwatch = authService.$watch('loading', loading => {
      if (loading === false) {
        unwatch()
        resolve(authService.getTokenSilently())
      }
    })
  })
}

const authLink = setContext(async (_, { headers }) => {
  try {
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${await getToken()}`,
        'apollo-require-preflight': true
      }
    }
  } catch (error) {
    logError(`Auth0 error: ${error.error_description}`)
    const authService = await getInstance()
    authService.logout()
  }
})

const defaultOptions = {
  apollo: {
    name: 'backoffice-' + process.env.VUE_APP_STAGE,
    version: process.env.COMMIT_REF
  },
  httpEndpoint: process.env.VUE_APP_GRAPHQL_HTTP,
  persisting: false,
  ssr: false,
  link: authLink
}

export function createProvider (options = {}) {
  const { apolloClient } = createApolloClient({
    ...defaultOptions,
    ...options
  })

  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'cache-and-network'
      }
    },
    errorHandler (error) {
      logError(error.message)
    }
  })

  return apolloProvider
}

function logError (message) {
  // eslint-disable-next-line no-console
  console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', message)
}
