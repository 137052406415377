<template>
  <crud-modal
    title="Ansprechpartner bearbeiten"
    action="update"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <contact-person-form
      :contact-person="contact"
    />
  </crud-modal>
</template>
<script>
import ContactPersonForm from './form'
import CrudModal from '@/components/modals/crud-modal'

import GET_COMPANY from '@/graphql/GetCompany.gql'
import UPDATE_COMPANY_CONTACT_PERSON from '@/graphql/UpdateCompanyContactPerson.gql'

export default {
  components: {
    ContactPersonForm,
    CrudModal
  },
  props: {
    companyId: {
      type: String,
      required: true
    },
    contactData: {
      type: Object,
      required: true,
      default: () => ({ })
    },
    contactType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      contact: {
        ...this.contactData,
        address: this.contactData?.address || {},
        image: this.contactData?.image || {}
      }
    }
  },
  methods: {
    async submit () {
      const {
        id,
        companyName,
        salutation,
        firstname,
        lastname,
        email,
        phone,
        mobile,
        note
      } = this.contact

      const addressIsEmpty = JSON.stringify(this.contact.address) === JSON.stringify({})
      const input = {
        id,
        companyId: this.companyId,
        type: this.contactType,
        companyName,
        salutation,
        firstname,
        lastname,
        email,
        phone,
        mobile,
        note,
        ...(!addressIsEmpty && {
          address: {
            street: this.contact.address.street,
            city: this.contact.address.city,
            zip: this.contact.address.zip,
            country: this.contact.address.country
          }
        })
      }

      return this.$apollo.mutate({
        mutation: UPDATE_COMPANY_CONTACT_PERSON,
        variables: {
          input
        },
        refetchQueries: [
          { query: GET_COMPANY, variables: { id: this.companyId } }
        ]
      })
    }
  }
}
</script>
