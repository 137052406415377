<template>
  <div class="h-full modal">
    <div class="modal-content">
      <h1
        class="w-full mb-6 text-xl font-semibold tracking-wide text-gray-800 uppercase"
      >
        {{ title }}
      </h1>
      <ValidationObserver ref="validationObserver">
        <slot />
      </ValidationObserver>

      <alert
        v-if="errorMessage"
        type="error"
        class="mx-3"
      >
        {{ errorMessage }}
      </alert>
    </div>

    <div class="modal-footer">
      <button
        class="btn-inline"
        @click="onClose()"
      >
        Abbrechen
      </button>
      <button
        :disabled="isSubmitting"
        :class="operation.ctaClass"
        @click="submit()"
      >
        <span v-if="isSubmitting">{{ operation.submitting }}</span>
        <span v-else>{{ operation.cta }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import Alert from '@/components/alert'

const ACTIONS = {
  create: {
    submitting: 'wird erstellt',
    cta: 'erstellen',
    ctaClass: 'btn-primary'
  },
  update: {
    submitting: 'wird gespeichert',
    cta: 'speichern',
    ctaClass: 'btn-primary'
  },
  delete: {
    submitting: 'wird gelöscht',
    cta: 'löschen',
    ctaClass: 'btn-danger'
  },
  generate: {
    submitting: 'wird gestartet',
    cta: 'Generierung starten',
    ctaClass: 'btn-primary'
  },
  export: {
    submitting: 'wird exportiert',
    cta: 'exportieren',
    ctaClass: 'btn-primary'
  },
  import: {
    submitting: 'wird importiert',
    cta: 'importieren',
    ctaClass: 'btn-primary'
  },
  send: {
    submitting: 'wird gesendet',
    cta: 'senden',
    ctaClass: 'btn-primary'
  }
}
export default {
  components: { Alert },
  props: {
    title: {
      type: String,
      required: true
    },
    action: {
      type: String,
      required: true
    },
    onSubmit: {
      type: Function,
      required: true
    },
    onClose: {
      type: Function,
      required: true
    },
    errorMessage: {
      type: String,
      default: ''
    },
    valdiationScope: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      validationError: '',
      isSubmitting: false
    }
  },
  computed: {
    operation () {
      return ACTIONS[this.action]
    },
    error () {
      return this.errorMessage || this.validationError
    }
  },
  methods: {
    async submit () {
      if (this.$refs.validationObserver) {
        const isValid = await this.$refs.validationObserver.validate()
        if (!isValid) {
          this.validationError = 'Es sind Fehler aufgetreten.'
          return
        }
      }

      try {
        this.isSubmitting = true

        await this.onSubmit()

        this.isSubmitting = false
        this.onClose()
      } catch (error) {
        this.setValidationErrors(error, this.valdiationScope)
        this.isSubmitting = false
      }
    }
  }
}
</script>
