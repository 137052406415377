<template>
  <section
    class="w-full"
  >
    <h1 class="flex items-center justify-between">
      Manuelles Upgrade
    </h1>

    <div
      class="flex flex-wrap w-full text-gray-600"
    >
      <button
        v-if="productType === 'LIGHT'"
        class="mr-4 btn-danger"
        @click="upgradeToBasic()"
      >
        <fa :icon="faAngleUp" />
        Auf BASIC upgraden
      </button>

      <button
        v-if="productType !== 'EXPERT'"
        class="btn-danger"
        @click="upgradeToExpert()"
      >
        <fa :icon="faAngleDoubleUp" />
        Auf EXPERT upgraden
      </button>
    </div>
  </section>
</template>

<script>
import { faAngleUp, faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons'
import UPGRADE_COMPANY_MANUALLY_TO_BASIC from '@/graphql/UpgradeCompanyManuallyToBasic.gql'
import UPGRADE_COMPANY_MANUALLY_TO_EXPERT from '@/graphql/UpgradeCompanyManuallyToExpert.gql'

export default {
  props: {
    companyId: {
      type: String,
      required: true
    },
    productType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      faAngleUp,
      faAngleDoubleUp
    }
  },
  methods: {
    async upgradeToExpert () {
      const hasConfirmed = confirm('Soll die Firma wirklich auf EXPERT geupgradet werden? Das sollte nur in Ausnahmefällen gemacht werden, z.B. um das Onboarding vorzuziehen. Das ersetzt nicht das Upgrade in Billwerk.')

      if (hasConfirmed) {
        await this.$apollo.mutate({
          mutation: UPGRADE_COMPANY_MANUALLY_TO_EXPERT,
          variables: {
            companyId: this.companyId
          }
        })
        this.$router.go(0)
      }
    },
    async upgradeToBasic () {
      const hasConfirmed = confirm('Soll die Firma wirklich auf BASIC geupgradet werden? Das sollte nur in Ausnahmefällen gemacht werden, z.B. um das Onboarding vorzuziehen. Das ersetzt nicht das Upgrade in Billwerk.')

      if (hasConfirmed) {
        await this.$apollo.mutate({
          mutation: UPGRADE_COMPANY_MANUALLY_TO_BASIC,
          variables: {
            companyId: this.companyId
          }
        })
        this.$router.go(0)
      }
    }
  }
}
</script>
