<template>
  <crud-modal
    title="Begleitmaterial hinzufügen"
    action="create"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <div>
      <input-text-validated
        ref="name"
        v-model="name"
        input-class="w-full mb-4"
        label="Name"
        rules="required"
      />
    </div>
    <div>
      <input-file-validated
        ref="file"
        v-model="file"
        label="Begleitdokument"
        rules="required"
      />
    </div>
    <div class="mt-4">
      <input-select-validated
        ref="purpose"
        v-model="purpose"
        label="Ziel"
        :options="PURPOSE"
        rules="required"
      />
    </div>
    <div class="my-6 text-lg font-bold">
      Gültig für folgende Varianten:
    </div>
    <div class="flex flex-wrap w-full px-2 mb-6">
      <div
        v-for="variant in variants"
        :key="variant.value"
        class="w-1/4 pr-4"
      >
        <label class="inline-flex items-center">
          <input
            v-model="selectedVariants"
            type="checkbox"
            class="w-4 h-4 form-checkbox"
            :value="variant.value"
          >
          <span class="ml-2 text-base normal-case">{{ variant.name }}</span>
        </label>
      </div>
    </div>
    <div class="flex justify-around">
      <button
        class="w-1/3 btn-secondary"
        @click="() => selectedVariants = variants.map(({value}) => value)"
      >
        Alle
      </button>
      <button
        class="w-1/3 btn-secondary"
        @click="() => selectedVariants = []"
      >
        Keine
      </button>
    </div>
  </crud-modal>
</template>

<script>
import CrudModal from '@/components/modals/crud-modal'
import InputFileValidated from '@/components/form/file-validated'
import InputTextValidated from '@/components/form/text-validated'
import InputSelectValidated from '@/components/form/select-validated'

import GET_CAMPAIGN from '@/graphql/GetCampaign.gql'
import CREATE_STATIC_MATERIAL_TEMPLATE_FOR_CAMPAIGN from '@/graphql/CreateStaticMaterialTemplateForCampaign.gql'
import variantMixin from '@/mixins/variant'

export default {
  components: { CrudModal, InputFileValidated, InputTextValidated, InputSelectValidated },
  mixins: [variantMixin],
  props: {
    campaignId: { type: String, required: true }
  },
  data () {
    return {
      name: '',
      variants: [],
      selectedVariants: [],
      file: {},
      purpose: 'SCREEN',
      PURPOSE: [
        { value: 'SCREEN', name: 'SCREEN' },
        { value: 'PRINT', name: 'PRINT' }
      ]
    }
  },
  created () {
    this.variants = this.getMappedVariants()
  },

  methods: {
    async submit () {
      await this.$apollo.mutate({
        mutation: CREATE_STATIC_MATERIAL_TEMPLATE_FOR_CAMPAIGN,
        variables: {
          input: {
            campaignId: this.campaignId,
            name: this.name,
            purpose: this.purpose,
            file: this.file.file,
            variants: this.selectedVariants
          }
        },
        refetchQueries: [{ query: GET_CAMPAIGN, variables: { campaignId: this.campaignId } }]
      })
    }
  }
}
</script>
