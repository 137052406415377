<template>
  <div class="min-h-screen">
    <div class="flex items-center justify-between px-4 py-8 bg-gray-100 rounded-t-lg">
      <input
        v-model="search"
        type="text"
        placeholder="Suchen nach: Name oder Slug"
        class="w-2/5"
      >

      <div class="flex flex-row mr-4">
        <router-link
          to="/landingpages/create"
          class="flex-shrink ml-16 font-semibold text-gray-600 uppercase"
        >
          <fa
            :icon="faPlus"
            fixed-width
          />
          Landingpage erstellen
        </router-link>
      </div>
    </div>

    <div class="flex items-center justify-between px-4 py-4 text-gray-600 bg-gray-100">
      <p class="">
        {{ filteredLpTemplates.length }} Landingpages
        <a
          href="#"
          class="pl-6 underline"
          @click.prevent="clearAllFilter"
        >Alle Filter und Suche löschen</a>
      </p>
    </div>

    <table class="w-full h-full border-0">
      <thead>
        <tr class="text-sm font-semibold tracking-wide text-left text-gray-700 uppercase bg-gray-200">
          <th class="w-3/12 p-4">
            Name
          </th>

          <th class="w-3/12 p-4">
            Slug
          </th>

          <!-- Column type + filter -->
          <th class="w-3/12 p-4">
            <div
              class="flex"
              @click="showTypeFilter = !showTypeFilter"
            >
              <div>Typ</div>
              <fa
                :icon="faFilter"
                class="self-center inline-block ml-2 text-gray-500 cursor-pointer"
                :class="{'text-teal-500': typeFilter.length}"
              />
            </div>
            <div
              v-if="showTypeFilter"
              v-click-outside="hideTypeFilter"
              class="absolute flex flex-col p-2 mt-2 overflow-visible font-light whitespace-no-wrap bg-white border rounded-sm shadow-sm"
            >
              <div
                v-for="availableType in availableTypes"
                :key="availableType"
                class="px-2 py-1 cursor-pointer"
                @click="toggleFilter('typeFilter', availableType)"
              >
                <fa
                  :icon="faCheck"
                  class="self-center inline-block mr-2"
                  :class="{'text-teal-500': typeFilter.includes(availableType)}"
                />
                {{ availableType }}
              </div>
            </div>
          </th>

          <!-- Column country + filter -->
          <th class="relative hidden w-6/12 p-4 cursor-pointer md:table-cell">
            <div
              class="flex"
              @click="showCountryFilter = !showCountryFilter"
            >
              <div>Land</div>
              <fa
                :icon="faFilter"
                class="self-center inline-block ml-2 text-gray-500 cursor-pointer"
                :class="{'text-teal-500': countryFilter.length}"
              />
            </div>
            <div
              v-if="showCountryFilter"
              v-click-outside="hideCountryFilter"
              class="absolute flex flex-col p-2 mt-2 overflow-visible font-light whitespace-no-wrap bg-white border rounded-sm shadow-sm"
            >
              <div
                v-for="countryCode in countryCodes"
                :key="countryCode"
                class="px-2 py-1 cursor-pointer"
                @click="toggleFilter('countryFilter', countryCode)"
              >
                <fa
                  :icon="faCheck"
                  class="self-center inline-block mr-2"
                  :class="{'text-teal-500': countryFilter.includes(countryCode)}"
                />
                <img
                  :src="`/img/country-flags/${countryCode}.png`"
                  class="inline-block w-4"
                >
                {{ countryCode.toUpperCase() }}
              </div>
            </div>
          </th>
        </tr>
      </thead>

      <tbody class="text-sm">
        <tr v-if="!filteredLpTemplates.length">
          <td colspan="7">
            <div
              v-if="$apollo.queries.landingpageTemplates.loading"
              class="loading"
            >
              <span class="w-full" />
            </div>
            <div
              v-else
              class="p-8 text-base text-center"
            >
              Zu den eingestellten Filtern oder zur Suche ist kein Eintrag vorhanden.
              <a
                href="#"
                class="underline"
                @click.prevent="clearAllFilter"
              >Alle Filter und Suche löschen</a>
            </div>
          </td>
        </tr>

        <tr
          v-for="lpTemplate in filteredLpTemplates"
          v-else
          :key="lpTemplate.id"
          class="text-gray-500 transition cursor-pointer duration-250 group even:bg-gray-100 odd:bg-white hover:text-gray-700"
          @click="$router.push(`/landingpages/${lpTemplate.id}`) "
        >
          <td class="hidden w-3/12 px-4 py-2 group-hover:bg-gray-200 sm:table-cell">
            {{ lpTemplate.name }}
          </td>

          <td class="hidden w-3/12 px-4 py-2 group-hover:bg-gray-200 sm:table-cell">
            {{ lpTemplate.slug }}
          </td>

          <td class="hidden w-3/12 px-4 py-2 group-hover:bg-gray-200 sm:table-cell">
            {{ lpTemplate.type }}
          </td>

          <td class="hidden w-6/12 px-4 py-2 uppercase group-hover:bg-gray-200 md:table-cell">
            <img
              :src="`/img/country-flags/${lpTemplate.countryCode}.png`"
              class="inline-block w-4"
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
import { faFilter, faCheck, faPlus } from '@fortawesome/free-solid-svg-icons'
import LIST_LANDINGPAGE_TEMPLATES from '@/graphql/ListLandingpageTemplates.gql'

const ALLOWED_TYPES = [
  'GUIDE',
  'CHECKLIST',
  'QUIZZARD',
  'VALUATION',
  'PLOT_VALUATION',
  'PROPERTY_REQUEST',
  'RETURN_CALL',
  'PROPERTY_TRACKER',
  'LIFE_ANNUITY',
  'PARTIAL_SALE'
]

const searchEscapeRegxChars = (value) => value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },

  data () {
    return {
      faFilter,
      faCheck,
      faPlus,
      availableTypes: ALLOWED_TYPES,
      landingpageTemplates: [],
      countryCodes: [],
      search: '',
      typeFilter: [],
      countryFilter: [],
      showTypeFilter: false,
      showCountryFilter: false
    }
  },

  computed: {
    filteredLpTemplates () {
      return this.landingpageTemplates
        .filter(({ type }) => ALLOWED_TYPES.includes(type))
        .filter(this.typeFilter.length > 0 ? this.filterByType : () => true)
        .filter(this.countryFilter.length > 0 ? this.filterByCountryCode : () => true)
        .filter(this.search.length > 0 ? this.filterBySearch : () => true)
    }
  },

  apollo: {
    landingpageTemplates: {
      query: LIST_LANDINGPAGE_TEMPLATES,
      result ({ data }) {
        if (data) {
          const { landingpageTemplates } = data
          this.countryCodes = [...new Set(
            landingpageTemplates.map(({ countryCode }) => countryCode)
          )]
        }
      }
    }
  },

  mounted () {
    this.popupItem = this.$el
    this.restoreFilter('countryFilter')
    this.restoreFilter('typeFilter')
  },

  methods: {
    last (list) {
      return list[list.length - 1]
    },

    hideTypeFilter () {
      this.showTypeFilter = false
    },

    hideCountryFilter () {
      this.showCountryFilter = false
    },

    // Whole filter logic could be extracted, is used in different components
    restoreFilter (key) {
      if (localStorage.getItem(key)) {
        try {
          this[key] = JSON.parse(localStorage.getItem(key))
        } catch (e) {
          localStorage.removeItem(key)
        }
      }
    },

    saveFilter (key) {
      localStorage.setItem(key, JSON.stringify(this[key]))
    },

    toggleFilter (filterName, item) {
      if (!this[filterName].includes(item)) {
        this[filterName].push(item)
      } else {
        this[filterName].splice(this[filterName].indexOf(item), 1)
      }
      this.saveFilter(filterName)
    },

    filterByType (landingpageTemplate) {
      return this.typeFilter.includes(landingpageTemplate.type)
    },

    filterByCountryCode (landingpageTemplate) {
      return this.countryFilter.includes(landingpageTemplate.countryCode)
    },

    filterBySearch ({ name, slug }) {
      const searchableFields = [name, slug]
      const regx = new RegExp(searchEscapeRegxChars(this.search), 'i')

      return searchableFields.some((value) => regx.test(value))
    },

    clearAllFilter () {
      this.countryFilter = []
      this.typeFilter = []
      this.saveFilter('countryFilter')
      this.saveFilter('typeFilter')
      this.search = ''
    }
  }
}
</script>

<style >
.loading {
  height: 5px;
  position: relative;
}
.loading span {
  display: block;
  height: 100%;
  background-color: rgb(43, 194, 83);
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, rgb(43, 194, 83)),
    color-stop(1, rgb(84, 240, 84))
  );
  background-image: -moz-linear-gradient(
    center bottom,
    rgb(43, 194, 83) 37%,
    rgb(84, 240, 84) 69%
  );
  position: relative;
  overflow: hidden;
  width: 0;
  animation: expandWidth 1s linear;
}
.loading > span:after {
  content: "";
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  z-index: 1;
  background-size: 50px 50px;
  animation: move 2s linear infinite;
}
@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}
@keyframes expandWidth {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
</style>
