<template>
  <crud-modal
    title="Notizen bearbeiten"
    action="update"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <div class="flex flex-wrap w-full">
      <div class="w-full px-4 mb-3">
        <input-textarea-validated
          ref="contract.note"
          v-model="contract.note"
          label="Notiz"
          input-class="w-full h-64"
        />
      </div>
    </div>
  </crud-modal>
</template>
<script>
import CrudModal from '@/components/modals/crud-modal'
import InputTextareaValidated from '@/components/form/textarea-validated'

import UPDATE_CONTRACT_NOTE from '@/graphql/UpdateContractNote.gql'

export default {
  components: { CrudModal, InputTextareaValidated },
  props: {
    contract: { type: Object, required: true }
  },
  methods: {
    async submit () {
      await this.$apollo.mutate({
        mutation: UPDATE_CONTRACT_NOTE,
        variables: {
          input: {
            contractId: this.contract.id,
            note: this.contract.note
          }
        }
      })
    }
  }
}
</script>
