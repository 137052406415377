<template>
  <div class="w-full -mx-2">
    <div class="relative w-full px-2 py-1">
      <div class="grid gap-2 px-4 py-4 bg-gray-200 rounded md:grid-cols-2 md:grid-rows-1 lg:grid-rows-1 lg:grid-cols-2 group hover:bg-gray-300">
        <div class="lg:col-span-2 lg:row-span-1 lg:flex">
          <div class="lg:w-1/2">
            <data-field
              label="Firmenname"
              :value="contact.companyName"
            />
            <data-field
              label="Name"
              :value="contact.companyName"
            >
              {{ contact.salutation }} {{ contact.firstname }} {{ contact.lastname }}
            </data-field>
            <data-field
              label="E-Mail"
              :value="contact.email"
            />
            <data-field
              label="Anschrift"
              :value="contact.email"
            >
              <div v-if="contact.address">
                {{ contact.address.street }} <br>
                {{ contact.address.zip }} {{ contact.address.city }} <br>
                {{ contact.address.country }}
              </div>
            </data-field>
          </div>
          <div class="lg:w-1/2 lg:ml-4">
            <data-field
              label="Telefon"
              :value="contact.phone"
            />
            <data-field
              label="Mobil"
              :value="contact.mobile"
            />
            <data-field
              label="Notiz"
              :value="contact.note"
            />
          </div>
        </div>
        <div class="absolute top-0 right-0 hidden p-1 mt-1 mr-2 bg-gray-300 group-hover:flex">
          <button
            class="px-1 btn-icon"
            @click="edit"
          >
            <fa :icon="faPen" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { faPen } from '@fortawesome/free-solid-svg-icons'
import dataField from '../data-field.vue'

export default {
  components: { dataField },
  props: {
    contact: { type: Object, default: () => {} }
  },
  data () {
    return {
      faPen
    }
  },
  methods: {
    edit () {
      this.$emit('edit')
    }
  }
}
</script>
