<template>
  <crud-modal
    title="Produkte für Abacus exportieren"
    action="export"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <div class="flex flex-wrap w-full">
      <div class="w-1/2 px-4 mb-3">
        <input-date-validated
          ref="billingMonth"
          v-model="billingMonth"
          label="Abrechnungsmonat"
          type="month"
          rules="required"
        />
      </div>
      <div class="w-1/2 px-4 mb-3">
        <input-date-validated
          ref="lastBillingDate"
          v-model="lastBillingDate"
          label="Letzte Abrechnung"
          rules="required"
        />
      </div>
    </div>
    <div class="flex flex-wrap w-full">
      <div class="w-1/2 px-4 mb-3">
        <input-date-validated
          ref="billingDate"
          v-model="billingDate"
          label="Rechnungsdatum"
          rules="required"
        />
      </div>
      <div class="w-1/2 px-4 mb-3">
        <input-text-validated
          ref="billingPeriod"
          v-model="billingPeriod"
          input-class="w-full"
          label="Abrechnungszeitraum"
          rules="required|maxLength:60"
        />
      </div>
    </div>
  </crud-modal>
</template>
<script>
import CrudModal from '@/components/modals/crud-modal'
import InputDateValidated from '@/components/form/date-validated'
import InputTextValidated from '@/components/form/text-validated'

import { exportAbacusContracts } from '@/lib/export'

export default {
  components: { CrudModal, InputDateValidated, InputTextValidated },
  data () {
    return {
      billingMonth: new Date(),
      billingDate: new Date(),
      lastBillingDate: null,
      billingPeriod: ''
    }
  },
  methods: {
    async submit () {
      const billingMonth = this.billingMonth.toISOString().slice(0, 7)
      const billingDate = this.billingDate.toISOString().slice(0, 10)
      const lastBillingDate = this.lastBillingDate.toISOString().slice(0, 10)
      await exportAbacusContracts({
        billingMonth,
        billingDate,
        lastBillingDate,
        billingPeriod: this.billingPeriod
      })
    }
  }
}
</script>
