<template>
  <div>
    <div class="mb-6">
      <input-text-validated
        ref="value.template"
        v-model="value.template"
        input-class="w-full"
        label="Template-Name (bei BrandEasy hochgeladen)"
        rules="required"
        placeholder="bot_kam_{variant}-"
      />
    </div>

    <div class="grid grid-cols-2 mb-6">
      <div class="col-span-1 mr-4">
        <input-select-validated
          ref="value.target"
          v-model="value.target"
          :options="availableTargets"
          label="Ausgabe-Medium"
          rules="required"
        />
      </div>

      <div class="col-span-1">
        <input-text-validated
          v-if="value.target === 'print'"
          ref="value.bleed"
          v-model="value.bleed"
          input-class="w-full"
          label="Bleed"
          placeholder="1,2,3,4"
          rules="required|bleed"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InputTextValidated from '@/components/form/text-validated'
import InputSelectValidated from '@/components/form/select-validated'

export default {
  components: {
    InputTextValidated,
    InputSelectValidated
  },

  props: {
    value: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      availableTargets: [
        { value: 'print', name: 'PDF (Print)' },
        { value: 'screen', name: 'PDF (Web)' },
        { value: 'web-jpg', name: 'JPG (Web)' }
      ]
    }
  }
}
</script>
