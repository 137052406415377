<template>
  <section
    v-if="todo"
    class="w-full"
  >
    <h2 class="text-xl">
      {{ todo.title }}
    </h2>
    <div
      v-if="todo.state === 'OPEN' || todo.state === 'PENDING'"
      class="flex w-full"
    >
      {{ state }}
    </div>
    <div
      v-else
      class="flex w-full"
    >
      {{ state }} am {{ formatDate(todo.updatedAt) }} durch {{ todo.user.firstname }} {{ todo.user.lastname }}
    </div>
  </section>
</template>

<script>

const READBALE_STATES = {
  PENDING: 'Wartet auf Start',
  OPEN: 'Offen',
  STARTED: 'Begonnen',
  FINISHED: 'Erledigt'
}

export default {
  props: {
    todo: {
      type: Object,
      required: true
    }
  },
  computed: {
    state () {
      return READBALE_STATES[this.todo.state]
    }
  }
}
</script>
