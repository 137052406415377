<template>
  <section class="w-full">
    <h1 class="flex items-center justify-between">
      Telefonnummer-Verifizierung
    </h1>

    <div class="flex flex-wrap w-full">
      <div
        class="flex flex-wrap w-full"
      >
        <input-switch
          v-model="isPhoneVerificationEnabled"
          class="mb-6"
          label="Immobilienbewertung"
          @onChange="handleChange"
        />
      </div>
      <div>
        <p>
          Durch die Aktivierung werden Telefonnummern per SMS oder Anruf verifiziert.
          So kann sichergestellt werden, dass ein Kontakt wirklich die korrekte Telefonnummer angibt.
          Überspringt der Kunde die Verifizierung, erhält der Kontakt zwar die Bewertung per E-Mail, kann das PDF aber nicht öffnen.
          In der Detailansicht des Kontaktes in der App kann dies eingesehen werden. <br><br>

          <b>Achtung:</b> Die zusätzliche Hürde der Verifizierung kann dazu führen, dass potentielle Verkäufer die Immobilienbewertung abbrechen. <br>
          <b>Entscheidung:</b> Mehr Bewertungen mit evtl. falschen Telefonnummern? Oder weniger Bewertungen mit überprüfter Nummer? <br><br>

          Änderungen bei der Aktivierung oder Deaktivierung benötigen ein wenig Zeit, um in Kraft zu treten.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import InputSwitch from '@/components/form/switch.vue'
import UPDATE_PHONE_VERIFICATION from '@/graphql/UpdatePhoneVerification.gql'

export default {
  components: { InputSwitch },
  props: {
    settings: { type: Object, default: () => {} },
    companyId: { type: String, default: '' },
    refetchSettings: { type: Function, default: () => {} }
  },
  data: function () {
    return {
      isPhoneVerificationEnabled: this.settings.settings.enabledPhoneVerification.VALUATION
    }
  },
  watch: {
    settings: {
      handler (newValue, oldValue) {
        this.isPhoneVerificationEnabled = newValue.settings.enabledPhoneVerification.VALUATION
      },
      deep: true
    }
  },
  methods: {
    async handleChange (newValue) {
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_PHONE_VERIFICATION,
          variables: {
            input: { enabled: newValue, companyId: this.companyId }
          }
        })
        this.refetchSettings()
      } catch (e) {

      }
    }
  }
}
</script>
