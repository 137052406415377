<template>
  <crud-modal
    title="Neue Datei hochladen"
    action="update"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    {{ material.purpose }}: {{ material.displayName }}

    <div class="w-full">
      <div class="flex flex-col w-full mt-6">
        <div class="flex items-center justify-between mb-1 mr-1">
          <label class="mb-0">Datei (PDF)</label>
        </div>
        <input-file-validated
          ref="mainFile"
          v-model="mainFile"
          :rules="!mainFile ? 'required' : ''"
          accept="application/pdf"
        />
      </div>

      <div class="flex flex-col w-full mt-6">
        <div class="flex items-center justify-between mb-1 mr-1">
          <label class="mb-0">Vorschau-Datei (JPG / PNG)</label>
        </div>
        <input-file-validated
          ref="previewFile"
          v-model="previewFile"
          :rules="!previewFile ? 'required' : ''"
          accept="image/jpg, image/png"
        />
      </div>
    </div>
  </crud-modal>
</template>

<script>
import CrudModal from '@/components/modals/crud-modal'
import InputFileValidated from '@/components/form/file-validated'
import UPLOAD_MATERIAL from '@/graphql/material/uploadMaterial.gql'

export default {
  components: {
    InputFileValidated,
    CrudModal
  },

  props: {
    material: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    mainFile: null,
    previewFile: null
  }),

  methods: {
    async submit () {
      await this.$apollo.mutate({
        mutation: UPLOAD_MATERIAL,
        variables: {
          input: {
            materialId: this.material.id,
            file: this.mainFile.file,
            previewFile: this.previewFile.file
          }
        }
      })
    }
  }
}
</script>
