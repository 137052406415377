<template>
  <validation-provider
    ref="provider"
    v-slot="{ errors }"
    :rules="rules"
  >
    <input-file
      ref="inputFile"
      v-model="model"
      :label="label"
      :group-class="{'error': errors.length}"
      :accept="accept"
      :disabled="disabled"
    />
    <p
      v-if="errors.length"
      class="text-xs text-red-500"
      v-text="errors[0]"
    />
  </validation-provider>
</template>

<script>
import InputFile from './file'

export default {
  components: {
    InputFile
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    rules: {
      type: String,
      default: null
    },
    accept: {
      type: String,
      default: ''
    },
    disabled: Boolean
  },
  data: () => ({
    model: ''
  }),
  watch: {
    model (value) {
      this.$emit('input', value)
    }
  },
  beforeMount () {
    this.model = this.value
  },
  methods: {
    validate () {
      return this.$refs.provider.validate()
    },
    setErrors (errors) {
      this.$refs.provider.setErrors(errors)
    }
  }
}
</script>
