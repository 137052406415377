<template>
  <section class="relative text-gray-800 border rounded hover:shadow">
    <div class="flex items-center justify-between px-4 py-2 bg-gray-200 border-b rounded-t group">
      <h4 class="font-semibold">
        Materialien
      </h4>

      <div>
        <button
          v-if="landingpageTemplateId"
          title="Alle Materialien generieren"
          class="mr-8 btn-inline-small"
          @click="generateMaterialsForTemplate()"
        >
          <fa
            :icon="faGear"
            fixed-width
          />
          Alle Materialien generieren
        </button>

        <button
          v-if="landingpageTemplateId"
          title="Material-Template anlegen"
          class="btn-inline-small"
          @click="showCreateModal()"
        >
          <fa
            :icon="faPlus"
            fixed-width
          />
          Material erstellen
        </button>
      </div>
    </div>

    <div
      v-if="hasNoMaterialTemplates"
      class="p-4 text-gray-500"
    >
      <em>Keine Materialien gefunden 🤷‍♂️</em>
    </div>

    <ul
      v-else
      class="py-2"
    >
      <template v-if="downloadMaterials.length">
        <li class="pl-4 mt-2 text-xs text-gray-600 uppercase">
          Downloads
        </li>
        <MaterialTemplateListItem
          v-for="materialTemplate in downloadMaterials"
          :key="materialTemplate.id"
          v-bind="materialTemplate"
          @deleted="handleMaterialDeleted"
        />
      </template>

      <template v-if="basicAdMaterials.length">
        <li class="pl-4 mt-2 text-xs text-gray-600 uppercase">
          Grundwerbemittel
        </li>
        <MaterialTemplateListItem
          v-for="materialTemplate in basicAdMaterials"
          :key="materialTemplate.id"
          v-bind="materialTemplate"
          @deleted="handleMaterialDeleted"
        />
      </template>

      <template v-if="campaignId && campaignMaterials.length">
        <MaterialTemplateListItem
          v-for="materialTemplate in campaignMaterials"
          :key="materialTemplate.id"
          v-bind="materialTemplate"
          @deleted="handleMaterialDeleted"
        />
      </template>
    </ul>
  </section>
</template>

<script>
import { faPlus, faGear } from '@fortawesome/free-solid-svg-icons'
import MaterialTemplateListItem from './list-item.vue'
import CreateModal from './create-modal'
import GENERATE from '@/graphql/GenerateMaterialsForTemplate.gql'

export default {
  components: { MaterialTemplateListItem },

  props: {
    landingpageTemplateId: {
      type: String,
      default: null
    },
    campaignId: {
      type: String,
      default: null
    },
    materialTemplates: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      faPlus,
      faGear
    }
  },

  computed: {
    downloadMaterials () {
      return this.materialTemplates.filter(({ type }) => type === 'DOWNLOAD')
    },

    basicAdMaterials () {
      return this.materialTemplates.filter(({ type }) => type === 'BASIC_AD')
    },

    campaignMaterials () {
      return this.materialTemplates.filter(({ type }) => type === 'CAMPAIGN')
    },

    hasNoMaterialTemplates () {
      return this.materialTemplates.length === 0
    }
  },

  methods: {
    showCreateModal () {
      this.$modal.show(CreateModal, {
        campaignId: this.campaignId,
        landingpageTemplateId: this.landingpageTemplateId
      })
    },

    handleMaterialDeleted (result) {
      this.$emit('material-deleted', result)
    },

    async generateMaterialsForTemplate () {
      const hasConfirmed = confirm('Wirklich alle Materialien für alle Kunden mit dieser Landingpage generieren?')

      if (hasConfirmed) {
        try {
          const { data: { result } } = await this.$apollo.mutate({
            mutation: GENERATE,
            variables: {
              input: { landingpageTemplateId: this.landingpageTemplateId }
            }
          })
          alert(`${result} Materialien werden generiert`)
        } catch (err) {
          alert(`Generieren fehlgeschlagen: ${err.message}`)
        }
      }
    }
  }
}
</script>
