<template>
  <div class="flex flex-col w-full">
    <product-details-form
      :data="data.product"
      :price-list-name="data.priceListName"
      :is-trial="data.isTrial"
      :product="product"
    >
      <span>{{ product.name }}</span>
    </product-details-form>
    <product-details-form
      :data="data.followRuntimeProduct"
      :price-list-name="data.priceListName"
      :is-trial="data.isTrial"
      :product="product"
      :hidden="!data.differentFollowRuntimeConditions"
    >
      <label class="font-bold">
        <input
          v-model="data.differentFollowRuntimeConditions"
          type="checkbox"
          class="w-4 h-4 mr-1 mb-1 form-checkbox"
        >
        Abweichende Konditionen ab 2. Laufzeit
      </label>
    </product-details-form>
    <product-details-form
      v-if="setupProduct"
      :data="data.setupProduct"
      :price-list-name="data.priceListName"
      :is-trial="data.isTrial"
      :product="setupProduct"
      :hidden="!data.addSetup"
    >
      <label class="font-bold">
        <input
          v-model="data.addSetup"
          type="checkbox"
          class="w-4 h-4 mr-1 mb-1 form-checkbox"
        >
        {{ setupProduct.name }}
      </label>
    </product-details-form>
  </div>
</template>

<script>
import ProductDetailsForm from './product-details-form.vue'

export default {
  components: {
    ProductDetailsForm
  },
  props: {
    data: { type: Object, default: () => ({}) },
    product: { type: Object, required: true },
    setupProduct: { type: Object, default: () => null }
  }
}
</script>
