<template>
  <crud-modal
    :title="`Landingpage ${action}aktivieren`"
    action="update"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <div class="mb-2">
      Soll die Landingpage
      <span class="font-semibold">"{{ landingpage.slug }}"</span>
      wirklich {{ action }}aktiviert werden?
    </div>
  </crud-modal>
</template>

<script>
import CrudModal from '@/components/modals/crud-modal'
import UPDATE_LANDINGPAGE_STATUS from '@/graphql/UpdateLandingpageStatus.gql'
import GET_COMPANY from '@/graphql/GetCompany.gql'

export default {
  components: { CrudModal },
  props: {
    landingpage: {
      type: Object,
      required: true
    },
    companyId: {
      type: String,
      required: true
    }
  },
  computed: {
    action () {
      return this.landingpage.status === 'ACTIVE' ? 'de' : ''
    }
  },
  methods: {
    async submit () {
      await this.$apollo.mutate({
        mutation: UPDATE_LANDINGPAGE_STATUS,
        variables: {
          input: {
            landingpageId: this.landingpage.id,
            status: this.landingpage.status === 'INACTIVE' ? 'ACTIVE' : 'INACTIVE'
          },
          update: (store, { data: { landingpage } }) => {
            const data = store.readQuery({
              query: GET_COMPANY,
              variables: { id: this.companyId }
            })

            data.company.landingpages = [...data.company.landingpages, landingpage]
            store.writeQuery({ query: GET_COMPANY, data })
          }
        }
      })
    }
  }
}
</script>
