<template>
  <section
    class="w-full"
  >
    <h1 class="flex items-center justify-between">
      Manuelles Downgrade
    </h1>

    <div
      class="flex flex-wrap w-full text-gray-600"
    >
      <button
        v-if="productType !== 'LIGHT'"
        class="mr-4 btn-danger"
        @click="downgradeToLight()"
      >
        <fa :icon="faAngleDoubleDown" />
        Auf LIGHT downgraden
      </button>

      <button
        v-if="productType === 'EXPERT'"
        class="btn-danger"
        @click="downgradeToBasic()"
      >
        <fa :icon="faAngleDown" />
        Auf BASIC downgraden
      </button>
    </div>
  </section>
</template>

<script>
import { faAngleDown, faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons'
import DOWNGRADE_COMPANY_MANUALLY_TO_LIGHT from '@/graphql/DowngradeCompanyManuallyToLight.gql'
import DOWNGRADE_COMPANY_MANUALLY_TO_BASIC from '@/graphql/DowngradeCompanyManuallyToBasic.gql'

export default {
  props: {
    companyId: {
      type: String,
      required: true
    },
    productType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      faAngleDown,
      faAngleDoubleDown
    }
  },
  methods: {
    async downgradeToLight () {
      const hasConfirmed = confirm('Soll die Firma wirklich auf LIGHT gedowngradet werden? Das sollte nur in Ausnahmefällen gemacht werden. Das ersetzt nicht das Downgrade in Billwerk.')

      if (hasConfirmed) {
        await this.$apollo.mutate({
          mutation: DOWNGRADE_COMPANY_MANUALLY_TO_LIGHT,
          variables: {
            companyId: this.companyId
          }
        })
        this.$router.go(0)
      }
    },
    async downgradeToBasic () {
      const hasConfirmed = confirm('Soll die Firma wirklich auf BASIC gedowngradet werden? Das sollte nur in Ausnahmefällen gemacht werden. Das ersetzt nicht das Downgrade in Billwerk.')

      if (hasConfirmed) {
        await this.$apollo.mutate({
          mutation: DOWNGRADE_COMPANY_MANUALLY_TO_BASIC,
          variables: {
            companyId: this.companyId
          }
        })
        this.$router.go(0)
      }
    }
  }
}
</script>
