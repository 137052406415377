import { getInstance } from 'feature-config'

export function getFeatureGuard (apolloProvider) {
  return async ({ meta: { feature: featureSlug } }, from, next) => {
    if (!featureSlug) {
      return next()
    }
    const featureConfig = getInstance()
    await featureConfig.loadAllFeatures({ apolloProvider, reload: false })
    const feature = featureConfig.featuresBySlug[featureSlug]
    if (!feature) {
      throw new Error(`Feature not found: ${featureSlug}`)
    }
    if (!feature.isActive) {
      return next({ name: 'dashboard', replace: true })
    }
    next()
  }
}
