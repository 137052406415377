<template>
  <div>
    <label
      v-if="label"
      class="block w-full"
    >
      {{ label }}:
    </label>
    <input
      type="number"
      :class="inputClass"
      class="w-full"
      :placeholder="placeholder"
      :value="value"
      :disabled="disabled"
      v-bind="$attrs"
      @input="$emit('input', $event.target.value)"
      @blur="$emit('blur')"
    >
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
      default: 0
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    inputClass: {
      type: [Object, Array, String],
      default: ''
    },
    disabled: Boolean
  }
}
</script>
