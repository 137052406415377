<template>
  <crud-modal
    title="Sender löschen"
    action="delete"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <div class="mb-6">
      Soll der Sender <span class="font-semibold">{{ email }}</span> wirklich gelöscht werden?
    </div>
    <div
      v-if="isDefault"
      class="text-red-600 font-bold my-2"
    >
      Achtung, das ist die Standardabsenderadresse dieses Kunden.
      Wenn diese Adresse gelöscht wird, werden keine E-Mails mehr über Hoppermation versendet.
    </div>
    <div class="flex mb-2">
      Wenn die Adresse gelöscht wird, kann sie nicht mehr als Absenderemail verwendet werden.
      Der Makler muss ggf. die Absenderemail in seinem E-Mail-Postfach erneut bestätigen.
    </div>
  </crud-modal>
</template>

<script>
import DELETE_HOPPERMATION_SENDER from '@/graphql/emailVerification/DeleteHoppermationSender.gql'
import CrudModal from '@/components/modals/crud-modal'
import eventBus, { eventNames } from '@/lib/eventBus'

export default {
  components: { CrudModal },
  props: {
    senderId: {
      type: String,
      required: true
    },
    email: {
      type: String,
      default: ''
    },
    isDefault: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async submit () {
      try {
        await this.$apollo.mutate({
          mutation: DELETE_HOPPERMATION_SENDER,
          variables: { input: { senderId: this.senderId } },
          refetchQueries: ['HOPPERMATION_SENDERS']
        })
      } catch (err) {
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { type: 'error', text: 'Fehler beim Löschen.' })
      }
    }
  }
}
</script>
