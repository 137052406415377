<template>
  <crud-modal
    title="Rechnungsdaten bearbeiten"
    action="update"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <billing-form :company="company" />
  </crud-modal>
</template>
<script>
import BillingForm from './form'
import CrudModal from '@/components/modals/crud-modal'

import UPDATE_COMPANY_BILLING from '@/graphql/UpdateCompanyBilling.gql'

export default {
  components: {
    BillingForm,
    CrudModal
  },
  props: {
    company: { type: Object, required: true }
  },
  methods: {
    async submit () {
      const {
        vatId, vatIdRequestDate, paymentMethod, bic, iban, hasSeparateBillingAddress, sepaValidAsOf,
        contact: { companyName, companyNameAddition, firstname, lastname, email, phone },
        address: { street, zip, city }
      } = this.company.billing
      await this.$apollo.mutate({
        mutation: UPDATE_COMPANY_BILLING,
        variables: {
          input: {
            companyId: this.company.id,
            paymentMethod,
            vatId,
            vatIdRequestDate,
            sepaValidAsOf,
            bic,
            iban,
            hasSeparateBillingAddress: hasSeparateBillingAddress || false,
            contact: { companyName, companyNameAddition, firstname, lastname, email, phone },
            ...(this.company.billing.hasSeparateBillingAddress && { address: { street, zip, city } })
          }
        }
      })
    }
  }
}
</script>
