<template>
  <section>
    <h1 class="flex justify-between">
      Theme
      <div class="text-sm text-gray-600">
        <button
          v-if="$features.feature(featureNames.COMPANY).config.isColorEditable"
          class="font-semibold uppercase hover:text-gray-800"
          @click="showThemeUpdateModal()"
        >
          Farben anpassen
        </button>
        <span
          v-if="hasRole(ROLES.SUPER_ADMIN)"
          class="mx-3 font-bold"
        >|</span>
        <button
          v-if="hasRole(ROLES.SUPER_ADMIN)"
          class="font-semibold uppercase hover:text-gray-800"
          @click="showCustomCssModal()"
        >
          Custom CSS
        </button>
      </div>
    </h1>

    <div class="flex w-full">
      <div
        class="color-preview"
        :style="getColorPreviewStyle(theme && theme.colors.primary)"
      >
        Primary
      </div>
      <div
        class="ml-4 color-preview"
        :style="getColorPreviewStyle(theme && theme.colors.secondary)"
      >
        Secondary
      </div>
    </div>
  </section>
</template>

<script>
import feature from '@/mixins/feature'
import role from '@/mixins/role'
import color from 'color'
import UpdateColors from './update-colors'
import UpdateCustomCss from './update-custom-css'

export default {
  mixins: [role, feature],
  props: {
    companyId: {
      type: String,
      required: true
    },
    theme: {
      type: Object,
      default: () => ({ })
    }
  },
  methods: {
    getColorPreviewStyle (colorString) {
      if (!colorString) {
        return {
          background: '#EEE',
          color: 'red'
        }
      }
      return {
        background: colorString,
        color: this.getContrastColor(colorString)
      }
    },

    getContrastColor (colorString) {
      const rgb = color(colorString)
      const [red, green, blue] = rgb.color
      return (red * 0.299 + green * 0.587 + blue * 0.114) > 186 ? '#000000' : '#FFFFFF'
    },

    showThemeUpdateModal () {
      this.$modal.show(UpdateColors, {
        companyId: this.companyId,
        colors: this.theme?.colors
      })
    },

    showCustomCssModal () {
      this.$modal.show(UpdateCustomCss, {
        companyId: this.companyId,
        styles: this.theme?.styles
      }, {
        width: '95%',
        height: '95%',
        scrollable: false
      })
    }
  }
}
</script>

<style lang="postcss" scoped>
.color-preview {
  @apply .w-1/3 .py-4 .uppercase .font-semibold .text-center .rounded;
}
</style>
