import { getInstance } from './index'

const authGuard = (fn) => {
  const authService = getInstance()

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn()
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch('loading', loading => {
    if (loading === false) {
      return fn()
    }
  })
}

const authenticated = (to, from, next) => {
  const authService = getInstance()

  authGuard(() => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      return next()
    }

    // Otherwise redirect to home
    return next({ name: 'login' })
  })
}

const hasRole = (permission) => (to, from, next) => {
  const authService = getInstance()

  authGuard(() => {
    if (authService.isAuthenticated && authService.hasRole(permission)) {
      return next()
    }
    return next({ name: 'login' })
  })
}

const publicOnly = (to, from, next) => {
  const authService = getInstance()

  authGuard(() => {
    // If the user is not authenticated, continue with the route
    if (!authService.isAuthenticated) {
      return next()
    }

    // Otherwise redirect to dashboard
    return next({ name: 'dashboard', replace: true })
  })
}

export {
  authenticated,
  hasRole,
  publicOnly
}
