<template>
  <crud-modal
    title="Immobilienfirma löschen"
    action="delete"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <div class="mb-6">
      Soll die Immobilienfirma
      <span class="font-semibold">"{{ companyName }}"</span> wirklich
      gelöscht werden?
    </div>
    <div class="flex mb-2">
      <div class="w-1/3">
        Sämtliche mit der Firma verknüpften Daten werden gelöscht, u.a.:
      </div>
      <ul class="w-2/3 pl-6 list-disc">
        <li>Dateien (Logo, Bilder von Repräsentant)</li>
        <li>Landingpages und zugehörige Materialien</li>
        <li>Leads mit Funnels &amp; Interaktionen</li>
        <li>User</li>
      </ul>
    </div>
  </crud-modal>
</template>

<script>
import CrudModal from '@/components/modals/crud-modal'
import DELETE_COMPANY from '@/graphql/DeleteCompany.gql'

export default {
  components: { CrudModal },
  props: {
    companyId: {
      type: String,
      required: true
    },
    companyName: {
      type: String,
      required: true
    }
  },
  methods: {
    async submit () {
      await this.$apollo.mutate({
        mutation: DELETE_COMPANY,
        variables: {
          id: this.companyId
        }
      })
    }
  }
}
</script>
