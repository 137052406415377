<template>
  <section
    v-if="company"
    class="w-full"
  >
    <h1 class="flex items-center justify-between">
      Performance
      <div class="text-sm text-gray-600">
        <button
          class="font-semibold uppercase hover:text-gray-800"
          @click="showCompanyPerformanceEditModal()"
        >
          <fa :icon="faPen" />
          Bearbeiten
        </button>
      </div>
    </h1>

    <h2 class="my-4 text-xl">
      Facebook
    </h2>
    <div class="flex flex-wrap w-full">
      <div class="flex-col w-full mb-2 lg:w-1/2">
        <data-field
          label="Facebook Link"
          :value="facebook.link"
        />
        <data-field
          label="Budget"
          :value="facebook.budget"
        />
        <data-field
          label="Budget variabel"
          :value="formatBudgetDistribution(facebook.distributeBudget)"
        />
        <data-field label="PLZ">
          {{ formatZip(facebook.zip) }}
        </data-field>
      </div>
    </div>

    <h2 class="my-4 text-xl">
      Google
    </h2>
    <div class="flex flex-wrap w-full">
      <div class="flex-col w-full mb-2 lg:w-1/2">
        <data-field
          label="Köder eigene Website"
          :value="google.target"
        />
        <data-field
          label="E-Mail Rechungsbelege"
          :value="google.email"
        />
        <data-field
          label="Budget"
          :value="google.budget"
        />
        <data-field
          label="Budget variabel"
          :value="formatBudgetDistribution(google.distributeBudget)"
        />
        <data-field label="PLZ">
          {{ formatZip(google.zip) }}
        </data-field>
      </div>
    </div>
  </section>
</template>

<script>
import EditCompanyPerformanceModal from '@/components/modals/company/performance/edit'
import DataField from '@/components/data-field'
import { faPen } from '@fortawesome/free-solid-svg-icons'

import GET_COMPANY_PERFORMANCE_DATA from '@/graphql/GetCompanyPerformanceData.gql'

export default {
  components: { DataField },
  props: {
    companyId: {
      type: String,
      required: true
    }
  },
  data () {
    return { faPen }
  },
  computed: {
    google () {
      return this.company.performance.google
    },
    facebook () {
      return this.company.performance.facebook
    }
  },
  apollo: {
    company: {
      query: GET_COMPANY_PERFORMANCE_DATA,
      variables () {
        return { id: this.companyId }
      }
    }
  },
  methods: {
    formatZip (zip) {
      return zip.filter(Boolean).join(', ')
    },
    formatBudgetDistribution (value) {
      return value === 'yes' ? 'ja' : 'nein'
    },
    showCompanyPerformanceEditModal () {
      this.$modal.show(
        EditCompanyPerformanceModal,
        {
          companyId: this.company.id,
          google: { ...this.company.performance.google },
          facebook: { ...this.company.performance.facebook }
        }
      )
    }
  }
}
</script>
