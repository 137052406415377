<template>
  <div class="flex flex-col w-full">
    <h1 class="my-4 mt-0 text-xl border-b-2">
      Facebook
    </h1>
    <div class="flex flex-wrap">
      <div class="w-full px-3 mb-3">
        <input-text-validated
          ref="facebook.link"
          v-model="facebook.link"
          input-class="w-full"
          label="Facebook Link"
        />
      </div>
      <div class="w-1/2 px-3 mb-3">
        <input-text-validated
          ref="facebook.budget"
          v-model="facebook.budget"
          input-class="w-full"
          label="Facebook Budget"
        />
      </div>
      <div class="w-1/2 px-3 mb-3">
        <input-select-validated
          ref="facebook.distributeBudget"
          v-model="facebook.distributeBudget"
          input-class="w-full"
          label="Budget Variabel"
          :options="yesNoOptions"
        />
      </div>
      <div class="flex flex-wrap w-full">
        <div
          v-for="index in 10"
          :key="index"
          class="w-1/4 px-3 mb-3"
        >
          <input-text-validated
            ref="facebook.budget"
            v-model="facebook.zip[index]"
            input-class="w-full"
            label="PLZ"
          />
        </div>
      </div>
    </div>
    <h1 class="my-4 mt-4 text-xl border-b-2">
      Google
    </h1>
    <div class="flex flex-wrap">
      <div class="w-full px-3 mb-3">
        <input-text-validated
          ref="google.targetUrl"
          v-model="google.targetUrl"
          input-class="w-full"
          label="Google Link für eigene Website"
        />
      </div>
      <div class="w-full px-3 mb-3">
        <input-text-validated
          ref="google.email"
          v-model="google.email"
          input-class="w-full"
          label="E-Mail Rechnungsbelege"
        />
      </div>
      <div class="w-1/2 px-3 mb-3">
        <input-text-validated
          ref="google.budget"
          v-model="google.budget"
          input-class="w-full"
          label="Google Budget"
        />
      </div>
      <div class="w-1/2 px-3 mb-3">
        <input-select-validated
          ref="google.distributeBudget"
          v-model="google.distributeBudget"
          input-class="w-full"
          label="Budget Variabel"
          :options="yesNoOptions"
        />
      </div>
      <div
        v-for="index in 10"
        :key="index"
        class="w-1/4 px-3 mb-3"
      >
        <input-text-validated
          ref="google.budget"
          v-model="google.zip[index]"
          input-class="w-full"
          label="PLZ"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InputTextValidated from '@/components/form/text-validated'
import InputSelectValidated from '@/components/form/select-validated'

export default {
  components: {
    InputTextValidated,
    InputSelectValidated
  },
  props: {
    facebook: { type: Object, default: () => ({}) },
    google: { type: Object, default: () => ({}) }
  },
  data: function () {
    return {
      yesNoOptions: [{ value: 'yes', name: 'ja' }, { value: 'no', name: 'nein' }]
    }
  }
}
</script>
