import { Feature } from 'feature-config'
import featureNames from '@/lib/featureNames'

export default {
  created () {
    this.featureNames = featureNames
  },
  components: { Feature },
  methods: {
    isFeatureActive (featureSlug) {
      return this.$features.feature(featureSlug) && this.$features.feature(featureSlug).isActive
    }
  }
}
