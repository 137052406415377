<template>
  <crud-modal
    title="Tags hinzufügen oder entfernen"
    action="update"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <div class="flex flex-wrap">
      <tag
        v-for="tag in tags"
        :key="tag.id"
        :tag="tag"
        :initial-selection="isTagSelected(tag.id)"
        :on-click="onClick"
        :can-click="true"
      />
    </div>
  </crud-modal>
</template>

<script>
import CrudModal from '@/components/modals/crud-modal'
import UPDATE_COMPANY_TAGS from '@/graphql/UpdateCompanyTags.gql'
import LIST_TAGS from '@/graphql/ListTags.gql'
import Tag from '@/components/tag'
import GET_COMPANY from '@/graphql/GetCompany.gql'

export default {
  apollo: {
    tags: {
      query: LIST_TAGS
    }
  },
  components: { CrudModal, Tag },
  props: {
    companyId: {
      type: String,
      required: true
    },
    companyTags: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      tags: []
    }
  },
  computed: {
    updatedTags () {
      return [...this.companyTags]
    }
  },
  methods: {
    async submit () {
      this.updatedTags.forEach(function (v) { delete v.__typename })
      await this.$apollo.mutate({
        mutation: UPDATE_COMPANY_TAGS,
        variables: {
          input: {
            companyId: this.companyId,
            tags: this.updatedTags
          }
        },
        refetchQueries: [
          { query: GET_COMPANY, variables: { id: this.companyId } }
        ]
      })
    },
    removeFromList (index) {
      this.updatedTags.splice(index, 1)
    },
    isTagSelected (tagId) {
      return this.updatedTags.find(companyTag => companyTag.id.toString() === tagId.toString()) !== undefined
    },
    onClick (tag) {
      if (this.isTagSelected(tag.id)) {
        const index = this.updatedTags.findIndex(companyTag => companyTag.id.toString() === tag.id.toString())
        this.removeFromList(index)
      } else {
        this.updatedTags.push(tag)
      }
    }
  }
}
</script>
