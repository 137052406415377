<template>
  <crud-modal
    title="Farben anpassen"
    action="update"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <div class="flex flex-wrap mb-6">
      <div class="w-full mb-2">
        <label>Primary:</label>
        <div class="flex items-stretch w-full">
          <div class="w-1/2">
            <input-text-validated
              ref="colorsModel.primary"
              v-model="colorsModel.primary"
              input-class="w-full"
              rules="required|hexcolor"
            />
          </div>
          <div
            class="w-1/2 ml-2 rounded"
            :style="{ background: colorsModel.primary }"
          />
        </div>
      </div>

      <div class="w-full mb-2">
        <label>Secondary:</label>
        <div class="flex items-stretch w-full">
          <div class="w-1/2">
            <input-text-validated
              ref="colorsModel.secondary"
              v-model="colorsModel.secondary"
              input-class="w-full"
              rules="required|hexcolor"
            />
          </div>
          <div
            class="w-1/2 ml-2 rounded"
            :style="{ background: colorsModel.secondary }"
          />
        </div>
      </div>
    </div>
  </crud-modal>
</template>

<script>
import UPDATE_COMPANY_THEME from '@/graphql/UpdateCompanyTheme.gql'
import GET_COMPANY from '@/graphql/GetCompany.gql'
import InputTextValidated from '@/components/form/text-validated'
import CrudModal from '@/components/modals/crud-modal'

export default {
  components: {
    InputTextValidated,
    CrudModal
  },
  props: {
    companyId: {
      type: String,
      required: true
    },
    colors: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      colorsModel: {
        primary: '',
        secondary: ''
      }
    }
  },
  created () {
    this.colorsModel = { ...this.colors }
  },
  methods: {
    async submit () {
      await this.$apollo.mutate({
        mutation: UPDATE_COMPANY_THEME,
        variables: {
          input: {
            companyId: this.companyId,
            colors: {
              primary: this.colorsModel.primary,
              secondary: this.colorsModel.secondary
            }
          }
        },
        refetchQueries: [
          { query: GET_COMPANY, variables: { id: this.companyId } }
        ]
      })
    }
  }
}
</script>
