<template>
  <div class="flex flex-wrap">
    <div class="w-7/12 px-2 mb-6">
      <select-validated
        v-model="product.templateId"
        :options="productTemplateOptions"
        label="Produkt"
        rules="required"
        :disabled="disabled"
      />
    </div>
    <div class="w-3/12 px-2 mb-6">
      <input-date-validated
        ref="product.startDate"
        v-model="product.startDate"
        label="Startdatum"
        rules="required"
      />
    </div>
    <div class="w-2/12 px-2 mb-6">
      <input-number-validated
        ref="product.amount"
        v-model.number="product.amount"
        label="Anzahl"
        rules="productAmount|required"
      />
    </div>
    <product-form
      v-if="productTemplate"
      :data="product"
      :product="productTemplate"
      :setup-product="setupProductTemplate"
    />
  </div>
</template>

<script>
import InputNumberValidated from '@/components/form/number-validated'
import SelectValidated from '@/components/form/select-validated'
import InputDateValidated from '@/components/form/date-validated'
import ProductForm from '@/components/modals/product/product-form'

export default {
  components: {
    SelectValidated,
    InputDateValidated,
    ProductForm,
    InputNumberValidated
  },
  props: {
    product: {
      type: Object,
      default: () => ({})
    },
    productTemplates: {
      type: Array,
      default: () => ([])
    },
    disabled: {
      type: Boolean,
      default: false
    },
    productReadableIds: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    productTemplateOptions () {
      return this.selectableTemplates.map((template) => ({
        value: template.id,
        name: template.name
      })).sort((a, b) => a.name.localeCompare(b.name))
    },
    selectableTemplates () {
      return this.productTemplates.filter((template) => template.type !== 'SETUP')
    },
    productTemplate () {
      return this.productTemplates.find(({ id }) => id === this.product.templateId)
    },
    setupProductTemplate () {
      return this.productTemplates.find(({ id }) => id === this.productTemplate.setupTemplate)
    }
  },
  watch: {
    product (value) {
      this.product.setupTemplate = this.productTemplate?.setupTemplate
    }
  }
}
</script>
