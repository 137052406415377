<template>
  <li class="flex items-center px-4 py-2 hover:bg-gray-200 group">
    <span class="w-3/12">
      {{ displayName }}
    </span>

    <span class="w-2/12">
      {{ generationService }}
    </span>

    <div class="w-6/12">
      <span v-if="brandEasyConfig">
        {{ brandEasyConfig.template }} |
        {{ brandEasyConfig.target }}
        <span v-if="brandEasyConfig.target === 'print'">
          | {{ brandEasyConfig.bleed }}
        </span>
      </span>

      <span v-if="moovlyConfig">
        {{ moovlyConfig.template }} |
        {{ moovlyConfig.resolution }}
      </span>

      <span v-if="staticConfig">
        <a
          target="_blank"
          :href="staticConfig.file.cdnUrl"
        >{{ staticConfig.file.name }}</a>
        | {{ staticConfig.variants.join(', ') }}
      </span>
    </div>

    <div class="flex justify-end w-1/12 space-x-1">
      <button
        class="opacity-25 hover:text-green-600 btn-icon hover:opacity-100"
        title="Material zu bestehenden Landingpages hinzufügen, falls noch nicht vorhanden"
        @click="createMaterialsForTemplate()"
      >
        <fa
          :icon="faPlus"
          fixed-width
        />
      </button>
      <button
        class="opacity-25 hover:text-green-600 btn-icon hover:opacity-100"
        title="Material generieren"
        @click="generateMaterialsForTemplate()"
      >
        <fa
          :icon="faGear"
          fixed-width
        />
      </button>
      <button
        class="opacity-25 hover:text-red-600 btn-icon hover:opacity-100"
        title="Material löschen"
        @click="deleteMaterialTemplate()"
      >
        <fa
          :icon="faTrash"
          fixed-width
        />
      </button>
    </div>
  </li>
</template>

<script>
import { faTrash, faPlus, faGear } from '@fortawesome/free-solid-svg-icons'
import DELETE from '@/graphql/DeleteMaterialTemplateWithRelatedMaterials.gql'
import CREATE from '@/graphql/CreateMaterialsForMaterialTemplate.gql'
import GENERATE from '@/graphql/GenerateMaterialsForTemplate.gql'

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    displayName: {
      type: String,
      required: true
    },
    generationService: {
      type: String,
      required: true
    },
    brandEasyConfig: {
      type: Object,
      default: () => {}
    },
    moovlyConfig: {
      type: Object,
      default: () => {}
    },
    staticConfig: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      faTrash,
      faPlus,
      faGear
    }
  },

  methods: {
    async createMaterialsForTemplate () {
      const hasConfirmed = confirm(`Material "${this.displayName}" wirklich zu allen bestehenden Landingpages hinzufügen, falls noch nicht vorhanden? Es wird nichts generiert.`)

      if (hasConfirmed) {
        try {
          await this.$apollo.mutate({
            mutation: CREATE,
            variables: {
              materialTemplateId: this.id
            }
          })

          alert('Materialien zu bestehenden Landingpages hinzugefügt')
        } catch (err) {
          alert(`Hinzufügen fehlgeschlagen: ${err.message}`)
        }
      }
    },
    async generateMaterialsForTemplate () {
      const hasConfirmed = confirm(`Material "${this.displayName}" wirklich für alle Kunden in diesem Land generieren?`)

      if (hasConfirmed) {
        try {
          const { data: { result } } = await this.$apollo.mutate({
            mutation: GENERATE,
            variables: {
              input: { materialTemplateId: this.id }
            }
          })
          alert(`${result} Materialien werden genereiert`)
        } catch (err) {
          alert(`Generieren fehlgeschlagen: ${err.message}`)
        }
      }
    },
    async deleteMaterialTemplate () {
      const hasConfirmed = confirm(`Template "${this.displayName}" wirklich löschen? Es werden außerdem sämtliche bereits generierten Materials gelöscht!`)

      if (hasConfirmed) {
        try {
          const { data } = await this.$apollo.mutate({
            mutation: DELETE,
            variables: {
              materialTemplateId: this.id
            },
            update: (cache, { data: { result } }) => {
              this.$emit('deleted', result)
            }
          })

          alert(`Löschen erfolgreich. ${data.result.deletedMaterialsCount} Materials gelöscht.`)
        } catch (err) {
          alert(`Löschen fehlgeschlagen: ${err.message}`)
        }
      }
    }
  }
}
</script>
