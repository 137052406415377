<template>
  <ValidationObserver
    ref="validationObserver"
    v-slot="{ validated, invalid }"
  >
    <div
      id="wizard"
      class="p-10 pt-0"
    >
      <div
        v-if="validated && invalid"
        class="flex mt-4"
      >
        <div class="w-full">
          <alert type="error">
            Bitte überprüfe die markierten Felder
          </alert>
        </div>
      </div>
      <section class="flex flex-col">
        <div
          v-if="!campaign.id"
          class="flex flex-wrap"
        >
          <div class="px-2 mb-6 ">
            <input-select-validated
              ref="campaign.type"
              v-model="campaign.type"
              :options="campaignTypes"
              label="Kampagnentyp"
              rules="required"
              @onChange="handleChange"
            />
          </div>
        </div>

        <div class="flex flex-wrap">
          <div class="w-1/2 px-2 mb-6">
            <input-text-validated
              ref="campaign.slug"
              v-model="campaign.slug"
              input-class="w-full"
              label="Slug"
              rules="required|slug"
            />
          </div>
          <div class="w-1/2 px-2 mb-6">
            <input-text-validated
              ref="campaign.name"
              v-model="campaign.name"
              input-class="w-full"
              label="Name"
              rules="required"
            />
          </div>
          <div class="w-1/2 px-2 mb-6">
            <input-select-validated
              ref="landingpageSlugs.de"
              v-model="landingpageSlugs.de"
              :options="getLandingpageTemplates('de')"
              label="Landingpage Deutschland (optional)"
              optional
            />
          </div>
          <div class="w-1/2 px-2 mb-6">
            <input-select-validated
              ref="landingpageSlugs.at"
              v-model="landingpageSlugs.at"
              :options="getLandingpageTemplates('at')"
              label="Landingpage Österreich (optional)"
              optional
            />
          </div>
          <div class="w-1/2 px-2 mb-6">
            <input-select-validated
              ref="landingpageSlugs.ch"
              v-model="landingpageSlugs.ch"
              :options="getLandingpageTemplates('ch')"
              label="Landingpage Schweiz (optional)"
              optional
            />
          </div>
          <div class="w-1/2 px-2 mb-6">
            <input-select-validated
              ref="landingpageSlugs.tr"
              v-model="landingpageSlugs.tr"
              :options="getLandingpageTemplates('tr')"
              label="Landingpage Türkei (optional)"
              optional
            />
          </div>
        </div>

        <div class="flex justify-center">
          <div class="pt-8">
            <button
              :disabled="isSubmitting"
              class="btn-primary"
              @click="submit()"
            >
              <span v-if="isSubmitting">Kampagne wird gespeichert</span>
              <span v-else>Kampagne speichern</span>
            </button>
          </div>
        </div>
      </section>
    </div>
  </ValidationObserver>
</template>

<script>
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import InputSelectValidated from '@/components/form/select-validated'
import InputTextValidated from '@/components/form/text-validated'
import Alert from '@/components/alert'

import LIST_LANDINGPAGE_TEMPLATES from '@/graphql/ListLandingpageTemplates.gql'

import readableCampaignTypes from '@/lib/enums/campaign/campaignTypes'

export default {
  components: {
    InputSelectValidated,
    InputTextValidated,
    Alert
  },
  props: {
    campaign: {
      type: Object,
      required: true
    },
    onSubmit: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      landingpageTemplates: [],
      isSubmitting: false,
      faPlus,
      faTrash,
      landingpageSlugs: {
        de: this.getLandingpageSlug('de') || '',
        at: this.getLandingpageSlug('at') || '',
        ch: this.getLandingpageSlug('ch') || '',
        tr: this.getLandingpageSlug('tr') || ''
      }
    }
  },
  computed: {
    campaignTypes () {
      return Object.keys(readableCampaignTypes).map(key => ({ name: readableCampaignTypes[key], value: key }))
    }
  },
  watch: {
    landingpageSlugs: {
      deep: true,
      handler (slugs) {
        this.campaign.landingpages = [
          ...(slugs.de ? [{ country: 'de', landingpageSlug: slugs.de }] : []),
          ...(slugs.at ? [{ country: 'at', landingpageSlug: slugs.at }] : []),
          ...(slugs.ch ? [{ country: 'ch', landingpageSlug: slugs.ch }] : []),
          ...(slugs.tr ? [{ country: 'tr', landingpageSlug: slugs.tr }] : [])
        ]
      }
    }
  },
  apollo: {
    landingpageTemplates: {
      query: LIST_LANDINGPAGE_TEMPLATES
    }
  },
  methods: {
    handleChange (newValue) {
      this.campaign.type = newValue
    },
    getLandingpageSlug (country) {
      return this.campaign.landingpages?.find((lp) => lp.country === country)?.landingpageSlug
    },
    getLandingpageTemplates (country) {
      return Object.values(
        this.landingpageTemplates
          .filter(({ countryCode }) => countryCode === country)
          .reduce((obj, { slug, name }) => {
            obj[slug] = { value: slug, name }
            return obj
          }, {})
      )
    },
    async submit () {
      const isValid = await this.$refs.validationObserver.validate()
      if (!isValid) {
        return
      }

      try {
        this.isSubmitting = true

        await this.onSubmit()

        this.isSubmitting = false
      } catch (error) {
        this.setValidationErrors(error, 'campaign')
        this.isSubmitting = false
      }
    }
  }
}
</script>
