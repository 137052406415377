<template>
  <crud-modal
    title="Kampagne für alle Kunden generieren oder nur für eine Variante / Firma"
    action="generate"
    :on-submit="submit"
    :on-close="() => $emit('close')"
  >
    <div class="flex flex-col">
      <div class="w-full px-4 mb-3">
        <input-select-validated
          ref="variant"
          v-model="filter.variant"
          label="Variante"
          :options="variants"
          optional
        />
      </div>
      <div class="w-full px-4 mb-3">
        <label>Firma:</label>
        <div class="relative flex items-center">
          <autocomplete
            ref="company"
            class="w-full"
            :search="findCompany"
            placeholder="Firma"
            aria-label="Firma"
            :get-result-value="({customerNumber, name}) => `${customerNumber}: ${name}`"
            :debounce-time="500"
            @input="(value) => companySearch = Object.entries(value)"
            @submit="(value) => filter.company = value"
          />
          <button
            class="absolute px-2"
            style="right: 6px"
            @click="resetCompany"
          >
            <fa :icon="faTimes" />
          </button>
        </div>
      </div>
      <div
        v-for="template in campaign.materialTemplates"
        :key="template.id"
      >
        <label class="inline-flex items-center w-full px-4 py-1">
          <input
            v-model="filter.selectedTemplates"
            type="checkbox"
            checked
            class="w-4 h-4 form-checkbox"
            :value="template.id"
          >
          <span class="w-1/3 ml-2 text-base normal-case">{{ template.generationService }}</span>
          <span class="w-2/3 ml-2 text-base normal-case">{{ template.prettyFileName }}</span>
        </label>
      </div>
      <label class="flex items-center w-full px-4 py-3 mt-2 border-t-2 border-b-2">
        <input
          v-model="filter.generationFailedFlag"
          type="checkbox"
          class="w-4 h-4 mr-2 form-checkbox"
          :value="filter.generationFailedFlag"
        >
        <span>Nur fehlgeschlagene Materialien generieren</span>
      </label>
      <div class="w-full mx-4 mt-4">
        Es werden {{ potentialMaterialGenerationCount.STATIC }} Begleitdokumente Materialien zugeordnet.
      </div>
      <div class="w-full mx-4 mt-4">
        Es werden {{ potentialMaterialGenerationCount.MOOVLY }} Video Materialien generiert.
        <span class="font-bold">Dies verursacht Kosten von {{ generationCostsMoovly }} €.</span>
      </div>
      <div class="w-full mx-4 mt-4">
        Es werden {{ potentialMaterialGenerationCount.BRAND_EASY }} BrandEasy Materialien generiert.
        <span class="font-bold">Dies verursacht Kosten von {{ generationCostsBrandEasy }} €.</span>
      </div>
    </div>
  </crud-modal>
</template>

<script>
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'
import InputSelectValidated from '@/components/form/select-validated'
import CrudModal from '@/components/modals/crud-modal'
import { generationCostsBrandEasy, generationCostsMoovly } from '@/lib/generationCosts'

import GENERATE_CAMPAIGN_COUNT from '@/graphql/GenerateCampaignCount.gql'
import LIST_COMPANY_NAMES from '@/graphql/ListCompanyNames.gql'
import GENERATE_CAMPAIGN from '@/graphql/GenerateCampaign.gql'
import variantMixin from '@/mixins/variant'

const escapeRegxChars = (value) =>
  value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')

export default {
  components: { CrudModal, Autocomplete, InputSelectValidated },
  mixins: [variantMixin],
  props: {
    campaign: { type: Object, required: true },
    onSubmit: { type: Function, required: true }
  },
  data: function () {
    return {
      potentialMaterialGenerationCount: {},
      companies: [],
      filter: {
        company: {},
        variant: '',
        selectedTemplates: this.campaign.materialTemplates.map(({ id }) => id),
        generationFailedFlag: false
      },
      companySearch: '',
      faTimes,
      variants: []
    }
  },
  computed: {
    generationCostsBrandEasy () {
      const { min, max } = generationCostsBrandEasy(this.potentialMaterialGenerationCount.BRAND_EASY)
      return min === max ? min : `${min} bis ${max}`
    },
    generationCostsMoovly () {
      const { min, max } = generationCostsMoovly(this.potentialMaterialGenerationCount.MOOVLY)
      return min === max ? min : `${min} bis ${max}`
    },
    requestData () {
      return {
        input: {
          campaignId: this.campaign.id,
          variant: this.filter.variant || null,
          companyId: this.filter.company.id || null,
          materialTemplates: this.filter.selectedTemplates,
          ...(this.filter.generationFailedFlag && { status: ['GENERATION_FAILED'] })
        }
      }
    }
  },
  watch: {
    filter: {
      deep: true,
      handler () {
        this.queryGenerateMaterialsCount()
      }
    }
  },
  created () {
    this.variants = this.getMappedVariants()
  },
  methods: {
    findCompany (search) {
      const regx = new RegExp(escapeRegxChars(search), 'i')
      return this.companies.filter(({ customerNumber, name }) => regx.test(name + customerNumber))
    },
    resetCompany () {
      this.filter.company = {}
      this.$refs.company.value = ''
    },
    async submit () {
      await this.$apollo.mutate({
        mutation: GENERATE_CAMPAIGN,
        variables: this.requestData
      })
      this.onSubmit()
    },
    queryGenerateMaterialsCount () {
      this.$apollo.query({
        query: GENERATE_CAMPAIGN_COUNT,
        variables: this.requestData
      })
    }
  },
  apollo: {
    potentialMaterialGenerationCount: {
      query: GENERATE_CAMPAIGN_COUNT,
      variables () {
        return this.requestData
      }
    },
    companies: {
      query: LIST_COMPANY_NAMES
    }
  }
}
</script>

<style>
.autocomplete-input {
  background-image:  none !important;
}
.autocomplete-result {
  background-image:  none !important;
}
</style>
