<template>
  <div class="flex flex-col">
    <div class="w-full px-2 mb-6 -mx-2 form-group">
      <select-validated
        v-model="document.type"
        :options="pricelists"
        label="Typ"
        rules="required"
        :disabled="disabled"
      />
    </div>
    <div class="w-full px-2 mb-6 -mx-2 form-group">
      <input-file-validated
        ref="document.file"
        v-model="document.file"
        label="Datei"
        accept="application/pdf"
        :disabled="disabled"
      />
    </div>
    <div class="w-full px-2 mb-6 -mx-2 form-group">
      <input-text-validated
        ref="document.comment"
        v-model="document.comment"
        input-class="w-full"
        label="Kommentar"
        :disabled="disabled"
      />
    </div>
  </div>
</template>

<script>
import SelectValidated from '@/components/form/select-validated'
import InputTextValidated from '@/components/form/text-validated'
import InputFileValidated from '@/components/form/file-validated'
import { DOCUMENT_TYPES } from '@/config'

export default {
  components: {
    SelectValidated,
    InputTextValidated,
    InputFileValidated
  },
  props: {
    document: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      pricelists: DOCUMENT_TYPES
    }
  }
}
</script>
